import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IFilterProject } from 'src/app/pages/clients-projects/interface/filter-project.interface';

import {
  IProjectTeam,
  ITeamsFilter,
  OrderByProjecTeam,
} from 'src/app/pages/tower-teams/interface/team-collaborator.interface';
import { TeamService } from 'src/app/pages/tower-teams/repository/team.service';
import {
  EProcess,
  EPermissionType,
} from 'src/app/shared/interfaces/roles.interface';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-projects-allocations',
  templateUrl: './projects-allocations.component.html',
  styleUrls: [],
})
export class ProjectsAllocationsComponent implements OnInit {
  columns = {
    projectPIP: 'PIP',
    projectName: 'Projeto',
    clientName: 'Cliente',
    projectStatusDescription: 'Status',
    projectManagerName: 'Responsável',
    projectInitiation: 'Início',
    projectConclusion: 'Fim',
    redirect: '',
  };
  hasAllocationProject = false;
  pageSize: number = 10;
  pageSizeOptions: Array<number> = [5, 10, 20];
  countDataSource: number = 0;
  teamId!: string;
  EProcess = EProcess;
  EPermissionType = EPermissionType;

  private _subs = new SubSink();

  dataSource: MatTableDataSource<IProjectTeam[]> = new MatTableDataSource<
    IProjectTeam[]
  >();

  displayedColumns: string[] = [
    'projectPIP',
    'projectName',
    'clientName',
    'projectStatusDescription',
    'projectManagerName',
    'projectInitiation',
    'projectConclusion',
    'redirect',
  ];

  filter: ITeamsFilter = {
    page: 1,
    take: 10,
    teamId: this.teamId,
    orderByParam: {
      orderBy: OrderByProjecTeam.PROJECT_NAME,
      asc: true,
    },
  };

  orderOptions: { [key: string]: number } = {
    projectPIP: OrderByProjecTeam.PROJECT_PIP,
    projectName: OrderByProjecTeam.PROJECT_NAME,
    clientName: OrderByProjecTeam.CLIENT_NAME,
    projectManagerName: OrderByProjecTeam.PROJECT_MANAGER,
    projectInitiation: OrderByProjecTeam.INITIATION,
    projectConclusion: OrderByProjecTeam.CONCLUSION,
  };

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _teamService: TeamService
  ) {}

  ngOnInit(): void {
    this.teamId = this._route.snapshot.paramMap.get('teamId')!;
    this.displayedColumns = Object.keys(this.columns);
    this.filter = { ...this.filter, teamId: this.teamId };
    this.getProjects();
  }

  getProjects(): void {
    this._subs.sink = this._teamService
      .getProjectTeamById(this.filter)
      .subscribe((response) => {
        this.getDataSource(response.result);
        this.countDataSource = response.count;


      });
  }

  getDataSource(res: IProjectTeam[]) {
    let arr: any[] = [];
    try {
      res.map((val: IProjectTeam) => {
        const prepareDataSource = {
          projectId: val.projectId,
          projectPIP: val.pip,
          projectName: val.name,
          clientName: val.client,
          projectStatusDescription: val.status,
          projectManagerName: val.responsible,
          projectInitiation: val.startDate,
          projectConclusion: val.endDate,
        };
        this.hasAllocationProject = true;
        arr.push(prepareDataSource);

      });
    } catch (error) {
      console.error(error);
      this.hasAllocationProject = false;
    }
    this.dataSource.data = arr;
  }

  skipPage(event: { skip: number }) {
    this.filter.page = event.skip + 1;
    this.getProjects();
  }

  changeItemsPerPage(items: number) {
    this.filter.page = 1;
    this.filter.take = items;
    this.getProjects();
  }

  changeOrder(orderBy: OrderByProjecTeam) {
    if (this.filter.orderByParam.orderBy === orderBy) {
      this.filter.orderByParam.asc = !this.filter.orderByParam.asc;
    } else {
      this.filter.orderByParam.orderBy = orderBy;
      this.filter.orderByParam.asc = true;
    }
    this.getProjects();
  }

  navigateToAllocationProject(projectId: string): void {
    this._router.navigate(['/alocacao/visualizar-alocacao', projectId]);
  }
}
