<app-title [title]="pageTitle"></app-title>
<ng-container *ngIf="project">
  <div class=" card--custom p-xl m-sm">
      <div class="section-form">
        <div class="row">
          <div class="col-2" >
            <mat-label class="label--custom-project">PIP/Projeto</mat-label>
            <span class="">{{ project.projectPIP}}/{{project.projectName
            }} </span>
          </div>
          <div class="col 3">
            <mat-label class="label--custom-project">Cliente</mat-label>
            <span class="">{{ project.clientName }}</span>
              </div>
          <div class="col 2">
            <mat-label class="label--custom-project"
              >Responsável</mat-label
            >
            <span class="card--custom-label-info">{{ project.projectResponsible }}</span>
          </div>
          <div class="col 3">
            <mat-label class="label--custom-project"
              >Status</mat-label
            >
            <span class="card--custom-label-info">{{ project.projectStatus }}</span>
          </div>
          <div class="col min-content">
            <mat-label class="label--custom-project"
              >Data de início do projeto</mat-label
            >
            <span class="card--custom-label-info">{{ project.startDate | date: 'dd/MM/YYYY'}}
              <mat-icon class="mat-icon-svg icon--width-xs"><img src="./assets/svg/calendar.svg" alt=""></mat-icon></span>

          </div>
          <div class="col min-content">
            <mat-label class="label--custom-project"
              >Data de fim do projeto</mat-label
            >
            <span class="card--custom-label-info">{{ project.endDate | date: 'dd/MM/YYYY'}}
              <mat-icon class="mat-icon-svg icon--width-xs"><img src="./assets/svg/calendar.svg" alt=""></mat-icon></span>
          </div>
        </div>
      </div>
    </div>
</ng-container>

<div>
  <ng-container *ngFor="let item of allocation; let formIndex = index">
    <app-form-allocation-template
      *ngIf="optionsCollaborator && optionsJobfunction && project && allocation"
      (submitEmitter)="receiveAllocationSubmit($event, formIndex)"
      (removeEmitter)="removeAllocation(formIndex, item)"
      [formEnabledList]="formAllocationList"
      [allocation]="item"
      [allocable]="true"
      [minDate]="project.startDate"
      [maxDate]="project.endDate"
    >
    </app-form-allocation-template>

    <div>

    </div>
    <div class="row justify-end">
      <button
        class="btn-no-border m-horizontal-xl hint"
        [disabled]="disableButtonReplyAllocation[formIndex] === true"
        (click)="addAllocation(true, item, formIndex)"
      >
        Replicar alocação
        <img src="./assets/svg/add.svg" alt="add">
      </button>
      <button
        *ngIf="formIndex === allocation.length -1"
        class="btn-no-border m-horizontal-xl hint"
       (click)="addAllocation()"
      >
        Nova Alocação
        <img src="./assets/svg/add.svg" alt="add">
      </button>
    </div>
  </ng-container>

  <div class="row m-xl p-xl justify-end">
    <button
      class="btn secondary m-md"
      (click)="cancelAllocation()"
      type="button"
    >
      Cancelar
    </button>
    <button
      class="btn primary m-md"
      matTooltip="Finalize o cadastro para habilitar o envio"
      [matTooltipDisabled]="!disableButton"
      [disabled]="disableButton"
      (click)="putAllocation()"
    >
      Confirmar alterações
    </button>
  </div>
</div>
