import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LayoutRoutingModule } from './layout-routing.module';
import {MatSidenavModule} from '@angular/material/sidenav';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { MatIconModule } from '@angular/material/icon';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatButtonModule} from '@angular/material/button';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { DirectiveModule } from 'src/app/core/directives/directives.module';

@NgModule({
  declarations: [
    MainLayoutComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutRoutingModule,
    MatSidenavModule,
    ComponentsModule,
    MatIconModule,
    MatButtonToggleModule,
    MatButtonModule,
    PipesModule,
    DirectiveModule
  ],
  exports: [
    MainLayoutComponent
  ]
})
export class LayoutModule { }
