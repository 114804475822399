// import * as moment from "moment";
import * as moment from 'moment';
import { LevelSkillsEnum } from 'src/app/pages/collaborator/enum/level-skills.enum';
import { EstatusAllocationEnum } from 'src/app/pages/collaborator/enum/status-alocation.enum';

export abstract class FilterConfigBase {
  page!: number;
  take!: number;
  orderByParam!: {
    orderBy: number;
    asc: boolean;
  };
}

export class FilterCollaboratorAllocation extends FilterConfigBase {
  searchString?: string;
  towerId: Array<string> = [];
  skillId: Array<string> = [];
  seniorityId: Array<string> = [];
  jobFunctionId: Array<string> = [];
  leaderId: Array<string> = [];
  skillLevel!: number | null;
  statusAllocation: EstatusAllocationEnum;
  startDate?: string;
  endDate?: string;
  periodId?: string;
  override page!: number;
  override take!: number;
  override orderByParam!: {
    orderBy: number;
    asc: boolean;
  };

  constructor() {
    super();
    this.page = 1;
    this.take = 10;
    this.orderByParam = {
      asc: true,
      orderBy: 1,
    };
    this.searchString = '';
    const currentDate: Date = new Date();
    this.getWeek(currentDate);
    this.statusAllocation = EstatusAllocationEnum.Todos;
  }

  getWeek(week: Date) {
    this.startDate = moment(week).weekday(1).toISOString();
    this.endDate = moment(week).weekday(5).toISOString();
  }

  filterChange(key: string, filter: any) {
    switch (key) {
      case 'jobFunctionId':
        this._checkValueJobFunction(filter.id);
        break;
      case 'seniorityId':
        this._checkValueSeniority(filter.id);
        break;
      case 'skillId':
        this._checkValueSkill(filter.id);
        break;
      case 'towerId':
        this._checkValueTower(filter.id);
        break;
      case 'statusAllocation':
        this.statusAllocation = filter;
        break;

      case 'leaderId':
        this._checkValueleader(filter.id);
        break;
      case 'skillLevel':
        this._checkValuelevel(filter);
        break;
    }
  }
  private _checkValueJobFunction(filterId: string) {
    this.jobFunctionId.push(filterId);
    this.jobFunctionId = [...new Set(this.jobFunctionId)];
  }
  private _checkValueSeniority(filterId: string) {
    this.seniorityId.push(filterId);
    this.seniorityId = [...new Set(this.seniorityId)];
  }
  private _checkValueSkill(filterId: string) {
    this.skillId.push(filterId);
    this.skillId = [...new Set(this.skillId)];
  }
  private _checkValueTower(filterId: string) {
    this.towerId.push(filterId);
    this.towerId = [...new Set(this.towerId)];
  }
  private _checkValueleader(filterId: string) {
    this.leaderId.push(filterId);
    this.leaderId = [...new Set(this.leaderId)];
  }
  private _checkValuelevel(filterId: {key:number, value: string}) {
    this.skillLevel = Number(filterId.key);
  }

  removeAll() {
    this.jobFunctionId = [];
    this.seniorityId = [];
    this.towerId = [];
    this.skillId = [];
    this.leaderId = [];
    this.skillLevel = null;
  }

  changeTake(event: number) {
    this.page = 1;
    this.take = event;
  }

  skipPage(event: { skip: number }) {
    this.page = event.skip + 1;
  }
  cleanFilter(key: string, chip?: any) {
    const filter = this[
      key as keyof FilterCollaboratorAllocation
    ] as Array<string>;
    filter.map((value: any, index) => {
      if (value === chip.id) {
        switch (key) {
          case 'jobFunctionId':
            this._removeJobFunction(index);
            break;
          case 'seniorityId':
            this._removeSeniority(index);
            break;
          case 'skillId':
            this._removeSkill(index);
            break;
          case 'towerId':
            this._removeTower(index);
            break;
          case 'leaderId':
            this._removeLeaderId(index);
            break;
        }
      }
    });
    const currentDate: Date = new Date();
    this.getWeek(currentDate);
  }
  private _removeJobFunction(index: number) {
    this.jobFunctionId.splice(index, 1);
  }
  private _removeSeniority(index: number) {
    this.seniorityId.splice(index, 1);
  }
  private _removeSkill(index: number) {
    this.skillId.splice(index, 1);
  }
  private _removeTower(index: number) {
    this.towerId.splice(index, 1);
  }
  private _removeLeaderId(index: number) {
    this.leaderId.splice(index, 1);
  }
  removeskillLevel() {
    this.skillLevel = null;
  }
}

export class IFilterCollaboratorAllocation {
  towerId: Array<string> = [];
  skillId: Array<string> = [];
  seniorityId: Array<string> = [];
  jobFunctionId: Array<string> = [];
}

export interface IDefaultFilter {
  searchString: string | null;
  page: number;
  take: number;
  orderByParam?: {
    orderBy: number;
    asc: boolean;
  };
}
