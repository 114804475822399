<div class="page-bg-secondary">
  <div class="container">
    <button class="btn-backpage m-top-lg" (click)="return()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <div class="content">
      <form
        [formGroup]="profileForm"
        #formDirective="ngForm"
        (ngSubmit)="onSubmitCollaborator(formDirective)"
      >
         <div class="section p-vertical-xl">
          <div class="row align-center">
            <div>
              <h2 >
                {{
                  isEditForm &&
                  (role === eRoleUser.GG ||
                    role === eRoleUser.ADM)
                    ? "Editar Colaborador"
                    : "Cadastro do Colaborador"
                }}
              </h2>
              <ng-container
                *ngIf="
                  isEditForm &&
                  role !== eRoleUser.GG &&
                  role !== eRoleUser.ADM
                "
              >
                <div class="col">
                  <mat-label>Nome</mat-label>
                  <h3 class="font-lg">{{ name }}</h3>
                </div>
              </ng-container>
            </div>
            <ng-template
              permissions
              [role]="EPermissionType.Read"
              [process]="
                isUserCollaborator
                  ? EProcess.Profile
                  : EProcess.CollaboratorPersonalData
              "
            >
              <div
                class="col-md-9"
                [ngClass]="{
                  'disabled-form': formDisabled('fullName') && isEditForm
                }"
              >
                <mat-form-field
                  class="input-outline input--width-lg"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>Nome completo</mat-label>
                  <input
                    type="text"
                    matInput
                    maxlength="100"
                    formControlName="fullName"
                    required
                  />
                </mat-form-field>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="section-form">
          <!-- CONTRACT INFO  -->
          <ng-template
            permissions
            [role]="EPermissionType.Read"
            [process]="
              isUserCollaborator
                ? EProcess.Profile
                : EProcess.CollaboratorContractualinformation
            "
          >
            <div class="row">
              <div class="col-md-3 form-division">
                <h3>Info. Contratuais</h3>
              </div>
              <div class="col-md-9">
                <div class="row divisor --three p-vertical-lg">
                  <div
                    class="disabled-form-input"
                    [ngClass]="{
                      'disabled-form': formDisabled('admission') && isEditForm
                    }"
                  >
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Admissão</mat-label>
                      <input
                        [matDatepicker]="pickerAdmission"
                        [max]="maxAdmissionDate"
                        matInput
                        formControlName="admission"
                        required
                      />
                      <mat-datepicker-toggle
                        *ngIf="!profileForm.controls['admission'].disabled"
                        matSuffix
                        [for]="pickerAdmission"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerAdmission></mat-datepicker>
                    </mat-form-field>
                    <span
                      class="message-error"
                      style="max-width: 300px"
                      *ngIf="
                        profileForm
                          .get('admission')
                          ?.hasError('invalidAdmission') &&
                        profileForm.get('admission')?.value !== null &&
                        profileForm.get('admission')?.value !== ''
                      "
                    >
                      Campo inválido
                    </span>
                  </div>
                  <div
                    class="disabled-form-input"
                    [ngClass]="{
                      'disabled-form':
                        formDisabled('contractTypeId') && isEditForm
                    }"
                  >
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Tipo de Contratação</mat-label>
                      <mat-select formControlName="contractTypeId">
                        <mat-option
                          *ngFor="let option of options?.contractTypes"
                          [value]="option.id"
                        >
                          {{ option.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    class="disabled-form-input"
                    [ngClass]="{
                      'disabled-form': formDisabled('daysOfWeek') && isEditForm
                    }"
                  >
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Dias de trabalho</mat-label>
                      <mat-select formControlName="daysOfWeek" multiple>
                        <mat-select-trigger>
                          <span *ngIf="allSelected.selected"> Dias úteis </span>
                          <span *ngIf="!allSelected.selected">
                            {{ profileForm.controls["daysOfWeek"].value }}
                          </span>
                        </mat-select-trigger>
                        <mat-option
                          #allSelected
                          (click)="toggleAllWorkingDaysSelection()"
                          [value]="0"
                          >Dias úteis</mat-option
                        >
                        <mat-option
                          class="checkbox"
                          *ngFor="
                            let enum of workingDaysSelectOptions | keyvalue
                          "
                          [value]="enum.value"
                          (click)="togglePerOne()"
                        >
                          {{ enum.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row divisor --three p-vertical-lg">
                  <div
                    class="disabled-form-input"
                    [ngClass]="{
                      'disabled-form':
                        formDisabled('workingHours') && isEditForm
                    }"
                  >
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Horas de trabalho diária</mat-label>
                      <input
                        type="text"
                        matInput
                        suffix="h"
                        mask="0"
                        [dropSpecialCharacters]="true"
                        thousandSeparator="."
                        formControlName="workingHours"
                        required
                      />
                    </mat-form-field>
                  </div>
                  <div
                    class="disabled-form-input"
                    [ngClass]="{
                      'disabled-form': formDisabled('towerId') && isEditForm
                    }"
                  >
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Torre</mat-label>
                      <mat-select
                        (selectionChange)="selectTower(true)"
                        formControlName="towerId"
                      >
                        <mat-option
                          *ngFor="let option of options?.towers"
                          [value]="option.id"
                        >
                          {{ option.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    class="disabled-form-input"
                    [ngClass]="{
                      'disabled-form': formDisabled('teamId') && isEditForm
                    }"
                  >
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Equipe</mat-label>
                      <mat-select formControlName="teamId">
                        <mat-option
                          *ngFor="let option of options?.teams"
                          [value]="option.id"
                        >
                          {{ option.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row divisor --three p-vertical-lg">
                  <div
                    class="disabled-form-input"
                    [ngClass]="{
                      'disabled-form':
                        formDisabled('collaboratorLeaderId') && isEditForm
                    }"
                  >
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Líder do Colaborador</mat-label>
                      <mat-select formControlName="collaboratorLeaderId">
                        <mat-option
                          *ngFor="let option of options?.leaders"
                          [value]="option.id"
                        >
                          {{ option.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    class="disabled-form-input"
                    [ngClass]="{
                      'disabled-form':
                        formDisabled('jobFunctionId') &&
                        isEditForm &&
                        role !==
                          (eRoleUser.GG ||
                            eRoleUser.ADM )
                    }"
                  >
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Função</mat-label>
                      <mat-select formControlName="jobFunctionId">
                        <mat-option
                          *ngFor="let option of options?.jobFunctions"
                          [value]="option.id"
                        >
                          {{ option.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    class="disabled-form-input"
                    [ngClass]="{
                      'disabled-form': formDisabled('seniorityId') && isEditForm
                    }"
                  >
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Senioridade</mat-label>
                      <mat-select formControlName="seniorityId">
                        <mat-option
                          *ngFor="let option of options?.senioritys"
                          [value]="option.id"
                        >
                          {{ option.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <mat-checkbox
                    [matTooltip]="
                      !isColaboratorLeader
                        ? 'Este colaborador é líder de outros colaboradores'
                        : ''
                    "
                    class="checkbox"
                    formControlName="isLeader"
                    [ngClass]="{
                      'disabled-form': formDisabled('isLeader') && isEditForm
                    }"
                    >Líder</mat-checkbox
                  >
                </div>
              </div>
            </div>
          </ng-template>

          <!-- COLLABORATOR PROFILE-->
          <ng-template
            permissions
            [role]="EPermissionType.Read"
            [process]="
              isUserCollaborator
                ? EProcess.Profile
                : [
                    EProcess.CollaboratorPersonalData,
                    EProcess.CollaboratorContractualinformation,
                    EProcess.CollaboratorAddress,
                    EProcess.CollaboratorSkills
                  ]
            "
          >
            <div class="row p-top-3xl">
              <div class="col-md-3 form-division">
                <h3>Permissões de perfil</h3>
              </div>
              <div
                class="col-md-9"
                [ngClass]="{
                  'disabled-form': formDisabled('accessProfileId') && isEditForm
                }"
              >
                <div class="row divisor --three p-vertical-lg">
                  <mat-form-field
                    class="input-outline"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-label>Perfil</mat-label>
                    <mat-select
                      formControlName="accessProfileId"
                      [disabled]="true"
                    >
                      <mat-option
                        *ngFor="let option of options?.accessProfile"
                        [value]="option.id"
                      >
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </ng-template>

          <!-- PERSONAL DATA-->
          <ng-template
            permissions
            [role]="EPermissionType.Read"
            [process]="
              isUserCollaborator
                ? EProcess.Profile
                : EProcess.CollaboratorPersonalData
            "
          >
            <div class="row p-vertical-lg">
              <div class="col-md-3 form-division"><h3>Dados Pessoais</h3></div>
              <div class="col-md-9">
                <div class="row divisor --three p-vertical-lg">
                  <div
                    class="disabled-form-input"
                    [ngClass]="{
                      'disabled-form': formDisabled('email') && isEditForm
                    }"
                  >
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Email</mat-label>
                      <input
                        type="email"
                        matInput
                        maxlength="100"
                        formControlName="email"
                        placeholder="Ex. email@example.com"
                      />
                    </mat-form-field>
                  </div>
                  <mat-form-field
                    class="input-outline"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-label>Apelido (opcional) </mat-label>
                    <input
                      maxlength="20"
                      type="text"
                      matInput
                      formControlName="nickname"
                    />
                  </mat-form-field>
                  <div class="disabled-form-input">
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Data de Nascimento</mat-label>
                      <input
                        [matDatepicker]="pickerBirthday"
                        matInput
                        formControlName="birthday"
                        required
                      />
                      <mat-datepicker-toggle
                        *ngIf="!profileForm.controls['birthday'].disabled"
                        matSuffix
                        [for]="pickerBirthday"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerBirthday></mat-datepicker>
                    </mat-form-field>
                    <span
                      class="message-error"
                      style="max-width: 300px"
                      *ngIf="
                        profileForm
                          .get('birthday')
                          ?.hasError('invalidBirthday') &&
                        profileForm.get('birthday')?.value !== null &&
                        profileForm.get('birthday')?.value !== ''
                      "
                    >
                      Campo inválido
                    </span>
                  </div>
                </div>
                <div class="row divisor --three p-vertical-lg">
                  <mat-form-field
                    class="input-outline"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-label>Filhos</mat-label>
                    <mat-select formControlName="children">
                      <mat-option [value]="true">Sim</mat-option>
                      <mat-option [value]="false">Não </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    class="input-outline"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-label>Estado Civil</mat-label>
                    <mat-select required formControlName="maritalStatus">
                      <mat-option
                        *ngFor="let enum of maritalStateOptions | keyvalue"
                        [value]="enum.key"
                      >
                        {{ enum.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <ng-container
                  *ngIf=" role === eRoleUser.GG || role === eRoleUser.ADM || isUserCollaborator
                  "
                  >
                  <mat-form-field
                  class="input-outline"
                  appearance="outline"
                  floatLabel="always"
                  >
                  <mat-label>Email pessoal</mat-label>
                  <input
                  type="email"
                  matInput
                  formControlName="personalEmail"

                  />
                  </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="role === eRoleUser.GG">
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Gênero</mat-label>
                      <mat-select required formControlName="gender">
                        <mat-option value="0">Masculino</mat-option>
                        <mat-option value="1">Feminino</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-template>

          <!-- ADRESSES -->
          <ng-template
            permissions
            [role]="EPermissionType.Read"
            [process]="
              isUserCollaborator
                ? EProcess.Profile
                : EProcess.CollaboratorAddress
            "
          >
            <div class="row">
              <div class="col-md-3 form-division">
                <h3>Endereço</h3>
              </div>
              <div class="col-md-9">
                <ng-container formArrayName="addresses">
                  <ng-container
                    *ngFor="let levelForm of addresses.controls; let i = index"
                  >
                    <div [formGroupName]="i">
                      <div class="row divisor --three p-vertical-lg">
                        <mat-form-field
                          class="input-outline"
                          appearance="outline"
                          floatLabel="always"
                        >
                          <mat-label>Identificar endereço</mat-label>
                          <input
                            type="text"
                            placeholder="Ex. Casa"
                            matInput
                            [maxlength]="maxLenghtString"
                            formControlName="addressIdentity"
                            required
                          />
                        </mat-form-field>
                        <mat-form-field
                          class="input-outline"
                          appearance="outline"
                          floatLabel="always"
                        >
                          <mat-label>CEP</mat-label>
                          <input
                            type="text"
                            placeholder="00000-000"
                            matInput
                            formControlName="zipCode"
                            mask="00000-000"
                            required
                            (change)="
                              getAddressByZipCode(
                                addresses.at(i).value.zipCode,
                                i
                              )
                            "
                          />
                        </mat-form-field>
                        <mat-form-field
                          class="input-outline"
                          appearance="outline"
                          floatLabel="always"
                        >
                          <mat-label>Logradouro</mat-label>
                          <input
                            type="text"
                            maxlength="30"
                            placeholder="Rua"
                            matInput
                            formControlName="publicArea"
                            required
                          />
                        </mat-form-field>
                        <mat-checkbox
                          class="checkbox"
                          (change)="selectPrincipal(i)"
                          formControlName="principal"
                        >
                          Endereço principal
                        </mat-checkbox>
                      </div>
                      <div class="row divisor">
                        <mat-form-field
                          class="input-outline input--width-xl"
                          appearance="outline"
                          floatLabel="always"
                        >
                          <mat-label>Endereço</mat-label>
                          <input
                            type="text"
                            [maxlength]="maxLenghtString"
                            matInput
                            formControlName="localAddress"
                            required
                          />
                        </mat-form-field>
                        <mat-form-field
                          class="input-outline input--width-xxs-tertiary"
                          appearance="outline"
                          floatLabel="always"
                        >
                          <mat-label>Número</mat-label>
                          <input
                            type="text"
                            maxlength="10"
                            matInput
                            formControlName="number"
                            required
                          />
                        </mat-form-field>
                      </div>
                      <div class="row divisor --three p-vertical-lg">
                        <mat-form-field
                          class="input-outline input--width-sm"
                          appearance="outline"
                          floatLabel="always"
                        >
                          <mat-label>Complemento</mat-label>
                          <input
                            type="text"
                            matInput
                            maxlength="50"
                            formControlName="complement"
                          />
                        </mat-form-field>
                      </div>
                      <div class="row divisor --three p-vertical-lg">
                        <mat-form-field
                          class="input-outline"
                          appearance="outline"
                          floatLabel="always"
                        >
                          <mat-label>Bairro</mat-label>
                          <input
                            type="text"
                            maxlength="50"
                            matInput
                            formControlName="neighborhood"
                            required
                          />
                        </mat-form-field>
                        <mat-form-field
                          class="input-outline"
                          appearance="outline"
                          floatLabel="always"
                        >
                          <mat-label>Cidade</mat-label>
                          <input
                            type="text"
                            maxlength="60"
                            matInput
                            formControlName="city"
                            required
                          />
                        </mat-form-field>
                        <mat-form-field
                          class="input-outline"
                          appearance="outline"
                          floatLabel="always"
                        >
                          <mat-label>Estado</mat-label>
                          <input
                            type="text"
                            maxlength="30"
                            matInput
                            formControlName="state"
                            required
                          />
                        </mat-form-field>
                        <mat-form-field
                          class="input-outline p-top-lg"
                          appearance="outline"
                          floatLabel="always"
                        >
                          <mat-label>País</mat-label>
                          <input
                            type="text"
                            matInput
                            maxlength="40"
                            formControlName="Country"
                            required
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template
                  permissions
                  [role]="EPermissionType.CreateUpdate"
                  [process]="
                    isUserCollaborator
                      ? EProcess.Profile
                      : EProcess.CollaboratorAddress
                  "
                >
                  <div class="row m-bottom-lg" *ngIf="addresses.length === 1">
                    <button
                      (click)="addFormControlAddress()"
                      class="btn-no-border-custom"
                    >
                      <mat-icon>add</mat-icon>
                      Add endereço
                    </button>
                  </div>
                  <div class="row m-bottom-lg" *ngIf="addresses.length === 2">
                    <button
                      (click)="deleteAddress()"
                      class="btn-no-border-custom"
                    >
                      <mat-icon>delete</mat-icon>
                      Remover endereço
                    </button>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-template>

          <!-- SKILLS -->
          <ng-template
            permissions
            [role]="EPermissionType.Read"
            [process]="
              isUserCollaborator
                ? EProcess.Profile
                : EProcess.CollaboratorSkills
            "
          >
            <div class="row">
              <div class="col-md-3 form-division">
                <h3>Habilidades</h3>
              </div>
              <div class="col-md-9 p-right-xl">
                <div class="box m-vertical-xl p-md box-max-height">
                  <ng-template
                    permissions
                    [role]="EPermissionType.CreateUpdate"
                    [process]="
                      isUserCollaborator
                        ? EProcess.Profile
                        : EProcess.CollaboratorSkills
                    "
                  >
                    <div class="row p-vertical-xl align-center">
                      <mat-form-field
                        class="input-outline"
                        appearance="outline"
                        floatLabel="always"
                      >
                        <mat-label class="input-label">Habilidades</mat-label>
                        <mat-select formControlName="skillSelected">
                          <mat-option
                            *ngFor="let option of options?.skills"
                            [value]="option"
                          >
                            {{ option?.description }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <button
                        mat-mini-fab
                        type="button"
                        (click)="addFormSkills()"
                      >
                        <mat-icon>add</mat-icon>
                      </button>
                      <span
                        *ngIf="
                          profileForm.get('collaboratorSkills')?.value ===
                            null ||
                          profileForm.get('collaboratorSkills')?.value
                            .length === 0 ||
                          profileForm.get('collaboratorSkills')?.value === ''
                        "
                        class="message-error"
                        style="max-width: 300px"
                      >
                        Campo obrigatório
                      </span>
                    </div>
                  </ng-template>
                  <div>
                    <ng-container formArrayName="collaboratorSkills">
                      <ng-container
                        *ngFor="let levelForm of skills.controls; let i = index"
                      >
                        <div
                          class="row p-vertical-md align-center space-between"
                          [formGroupName]="i"
                        >
                          <div class="row align-center">
                            <input
                              *ngIf="levelForm.value.id"
                              type="text"
                              matInput
                              formControlName="id"
                              required
                              hidden
                            />
                            <input
                              type="text"
                              matInput
                              formControlName="skillsId"
                              required
                              hidden
                            />
                            <input
                              type="text"
                              matInput
                              formControlName="description"
                              required
                              hidden
                            />
                            <p>{{ levelForm.value.description }}</p>
                          </div>
                          <div class="row align-center">
                            <mat-form-field
                              class="input-outline"
                              appearance="outline"
                              floatLabel="always"
                            >
                              <mat-label class="input-label">Nível</mat-label>
                              <mat-select
                                formControlName="level"
                                (selectionChange)="addLevel(i)"
                              >
                                <mat-option
                                  *ngFor="
                                    let enum of levelSelectOptions | keyvalue
                                  "
                                  [value]="enum.key"
                                >
                                  {{ enum.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <ng-template
                              permissions
                              [role]="EPermissionType.CreateUpdate"
                              [process]="
                                isUserCollaborator
                                  ? EProcess.Profile
                                  : EProcess.CollaboratorSkills
                              "
                            >
                              <button
                                mat-mini-fab
                                class="cancel"
                                type="button"
                                (click)="deleteSkills(i)"
                              >
                                <mat-icon>close</mat-icon>
                              </button>
                            </ng-template>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <!-- SALARY INFO  -->
          <!-- <div class="row" formGroupName="collaboratorSalaryData">
          <div class="col-md-3 form-division">
            <h2>Info. Salariais</h2>
          </div>
          <div class="col-md-9">
            <div class="row divisor --three p-vertical-lg">
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Salário</mat-label>
                <span matTextPrefix>R$&nbsp;</span>
                <input
                  type="number"
                  matInput
                  formControlName="salary"
                  required
                />
              </mat-form-field>
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Plano odontológico</mat-label>
                <input type="text" matInput formControlName="dentalPlan" />
              </mat-form-field>
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Ajuda de custo</mat-label>
                <span matTextPrefix>R$&nbsp;</span>
                <input
                  type="number"
                  matInput
                  formControlName="allowance"
                  required
                />
              </mat-form-field>
            </div>
            <div class="row divisor --three p-vertical-lg">
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Seguro de vida</mat-label>
                <span matTextPrefix>R$&nbsp;</span>
                <input
                  type="number"
                  matInput
                  formControlName="lifeInsurance"
                  required
                />
              </mat-form-field>
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Subsídio de equipamento</mat-label>
                <span matTextPrefix>R$&nbsp;</span>
                <input
                  type="number"
                  matInput
                  formControlName="equipmentSubsidy"
                  required
                />
              </mat-form-field>
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Vale alimentação</mat-label>
                <input type="text" matInput formControlName="mealTicket" />
              </mat-form-field>
            </div>
            <div class="row divisor --three p-vertical-lg">
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Plano de saúde/ Auxílio médico</mat-label>
                <span matTextPrefix>R$&nbsp;</span>
                <input
                  type="number"
                  matInput
                  formControlName="healthInsurance"
                  required
                />
              </mat-form-field>
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Bônus 13º</mat-label>
                <span matTextPrefix>R$&nbsp;</span>
                <input
                  type="number"
                  matInput
                  formControlName="thirteenthBonus"
                  required
                />
              </mat-form-field>
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Faturável</mat-label>
                <mat-select formControlName="billable">
                  <mat-option value="true">Sim</mat-option>
                  <mat-option value="false">Não</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div> -->
        </div>
        <div class="row justify-end p-lg">
          <ng-template
            permissions
            [role]="EPermissionType.Read"
            [process]="EProcess.Collaborator"
          >
            <button
              class="btn secondary m-horizontal-xl"
              type="button"
              (click)="returnToCollaboratorList()"
            >
              Cancelar
            </button>
          </ng-template>
          <ng-template
            permissions
            [role]="EPermissionType.CreateUpdate"
            [process]="
              isUserCollaborator
                ? EProcess.Profile
                : [
                    EProcess.CollaboratorPersonalData,
                    EProcess.CollaboratorContractualinformation,
                    EProcess.CollaboratorAddress,
                    EProcess.CollaboratorSkills
                  ]
            "
          >
            <button
              [disabled]="profileForm.invalid"
              class="btn primary"
              type="submit"
            >
              {{ isEdit ? "Salvar" : "Cadastrar" }}
            </button>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
</div>
