import { Component, OnInit, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { IDataSourceProjectList } from 'src/app/pages/clients-projects/interface/datasource-project.interface';
import { COLUMNS_LIST_PROJECT } from 'src/app/shared/models/table-columns/columns-list-project';
import {
  FILTER_PERIOD_PROJECT,
  FILTER_STATUS,
} from './period-select-project.const';
import { ClientService } from 'src/app/pages/clients-projects/repository/client.service';
import {
  IOptionsListProject,
  IProjectList,
} from 'src/app/pages/collaborator/interface/project-options.interface';
import { FilterProjectsAllocation } from 'src/app/shared/models/filter-projects.model';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import { formatDate } from '@angular/common';
import { SubSink } from 'subsink';
import { AllocationFilterService, EFilterType } from 'src/app/core/services/filters/allocation-filter.service';
@Component({
  selector: 'app-page-allocation-project-list',
  templateUrl: './page-allocation-project-list.component.html',
  styles: [],

})
export class PageAllocationProjectListComponent implements OnInit {
  dataSource: MatTableDataSource<IDataSourceProjectList> =
    new MatTableDataSource<IDataSourceProjectList>();
  searchByNameCtrl: FormControl = new FormControl();
  period: FormControl = new FormControl();
  periodSelectOptions = FILTER_PERIOD_PROJECT;
  statusSelectOptions = FILTER_STATUS;
  columns = COLUMNS_LIST_PROJECT;
  filterConfig: FilterProjectsAllocation = this.filterService.getFilter(EFilterType.PROJECT);
  pageSizeOptions: Array<number> = [5, 10, 20];
  displayedColumns: string[] = [];
  filterForm!: FormGroup;
  chipsValue!: any;
  chipsFormControl: Array<string> = this.filterService.getChipsFormControl(EFilterType.PROJECT);
  chipsList: Array<any> = this.filterService.getChipsList(EFilterType.PROJECT);
  optionsListProject: IOptionsListProject[] = [];
  projectsList: IProjectList[] = [];
  countDataSource: number = 0;

  private _subs = new SubSink();

  clients: IOptionsListProject[] = [];

  isUpdatedClient: boolean = false;

  constructor(
    private _router: Router,
    private _allocationService: AllocationService,
    private _clientService: ClientService,
    private _fb: FormBuilder,
    private filterService: AllocationFilterService

  ) {
    this.displayedColumns = Object.keys(this.columns);
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  ngOnInit(): void {
    this.searchByNameCtrl.setValue(this.filterConfig.searchString);
    this.getProjectAll(true);
    this.createFormGroup();
    this.changeFilter();
    this.getSelectOptions();
  }

  projectAllocation(projectId: string) {
    this._router.navigate(['/alocacao/gerenciar-alocacao/projeto', projectId]);
  }

  clearFilter() {
    this.filterConfig = new FilterProjectsAllocation();
    this.searchByNameCtrl.reset();
    this.getProjectAll();
  }
  getOrderByValue(key: string): number {
    switch (key) {
      case 'projectName':
        return 1;
      case 'clientName':
        return 2;
      case 'projectPIP':
        return 3;
      case 'descriptionStatusAllocation':
        return 4;
      case 'projectManager':
        return 5;
      case 'startDate':
        return 6;
      case 'endDate':
        return 7;
      default:
        return 0;
    }
  }
  ordenar(orderBy: number) {
    if (this.filterConfig.orderByParam.orderBy === orderBy) {
      this.filterConfig.orderByParam.asc = !this.filterConfig.orderByParam.asc;
    } else {
      this.filterConfig.orderByParam.orderBy = orderBy;
      this.filterConfig.orderByParam.asc = true;
    }
    this.getProjectAll(true);
  }

  changeFilter() {
    this.filterForm.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe((filterValue: any) => {
        if(!this.isUpdatedClient) {
          this.handleFilterChange(filterValue);
        }
      });
  }

  handleFilterChange(filterValue: any, changeProjectList: boolean = false) {
    this.dataSource.filter = '';
    Object.keys(filterValue).forEach((key) => {
      if (filterValue[key] === null || filterValue[key] === undefined) {
        delete filterValue[key];
      } else if (filterValue[key]) {
        if (!this.chipsList.includes(filterValue[key])) {
          this.filterConfig.filterChange(key, filterValue[key]);
          this.addFilterValueToChips(filterValue[key], key);
        }
      }
    });
    this.filterConfig.startDate = filterValue.startDate!;
    this.filterConfig.endDate = filterValue.endDate!;
    this.getProjectAll(changeProjectList);
  }

  getSelectOptions() {
    this._clientService.getCollaboratorSelectProject().subscribe((res) => {
      this.optionsListProject = res;
      this.sortOptions();
      this.setFormValues();
      this.clients = [...this.optionsListProject];
    });
  }

  getProjectAll(changeProjectList: boolean = false) {
    this.filterService.setChipsFormControl(this.chipsFormControl, EFilterType.PROJECT);
    this.filterService.setFilter(this.filterConfig, EFilterType.PROJECT);
    this.filterService.setChipsList(this.chipsList, EFilterType.PROJECT);
    this._allocationService
      .getAllByProjectsFilter(this.filterConfig)
      .subscribe((res) => {
        this.getDataSource(res.result);
        this.countDataSource = res.count;

        if(changeProjectList) {
          // Filtrar projectsList para mostrar somente as opções que estão em dataSource
          this.projectsList = this.projectsList.filter(project => {
            return this.dataSource.data.some(data => data.projectPIP === project.pip);
          });
        }    
        
        setTimeout(() => {
          this.isUpdatedClient = false;
        }, 300);
      });
  }

  searchBy() {
    this.filterConfig.searchString = this.searchByNameCtrl.value;
    this.getProjectAll();
  }

  createFormGroup() {
    this.filterForm = this._fb.group({
      searchString: null,
      projectPIP: { value: null, disabled: true },
      projectId: { value: null, disabled: true },
      clientId: null,
      startDate: null,
      endDate: null,
    });
    
    this.filterForm.setControl('clientId', new FormControl(null, { updateOn: 'submit' }));
  }

  enableExtraFields(clientId: string | null): void {
    const projectIdControl = this.filterForm.get('projectId');
    if (projectIdControl) {
      if (clientId) {
        projectIdControl.enable();
      } else {
        projectIdControl.disable();
      }
    }

    const pipControl = this.filterForm.get('projectPIP');
    if (pipControl) {
      if (clientId) {
        pipControl.enable();
      } else {
        pipControl.disable();
      }
    }
  }

  addFilterValueToChips(filterValue: any, formName: string) {
    const hasChipControl = this.chipsFormControl.indexOf(formName);
    if (hasChipControl >= 0) {
      this.chipsList.splice(hasChipControl, 1);
      this.chipsFormControl.splice(hasChipControl, 1);
    }
    if (filterValue !== null && filterValue !== undefined) {
      this.chipsFormControl.push(formName);
      if (formName === 'startDate') {
        const startDateMoment = this.filterForm.controls['startDate'].value;
        const formattedStartDate = startDateMoment.format('DD/MM/YYYY');
        this.chipsList.push({ name: 'Inicio: ' + formattedStartDate });
        filterValue.startDate = formattedStartDate;
      } else if (formName === 'endDate') {
        const endDateMoment = this.filterForm.controls['endDate'].value;
        const formattedEndDate = endDateMoment.format('DD/MM/YYYY');
        this.chipsList.push({ name: 'Fim: ' + formattedEndDate });
        filterValue.endDate = formattedEndDate;
      } else {
        this.chipsList.push(filterValue);
      }
    }
  }

  removeAllChips() {
    this.filterConfig.removeAll();
    this.chipsList = [];
    this.chipsFormControl = [];
    this.filterForm.patchValue({
      period: null,
      startDate: null,
      endDate: null,
      projectPIP: null,
      projectId: null,
      clientId: null,
    });
    this.resetAutocompleteFilters();
    this.enableExtraFields(null);
  }

  removeFilterValueChips(chip: any) {    
    let chipIndex = this.chipsList.indexOf(chip);
    this.chipsList.splice(chipIndex, 1);
    Object.keys(this.filterForm.value).forEach((controlName) => {
      if (this.chipsFormControl.at(chipIndex) === controlName) {
        this.filterConfig.cleanFilter(controlName);
        this.filterForm.controls[controlName].setValue('');

        if (controlName === 'clientId') {
          // Encontrar e remover projectId e projectPIP de chipsList
          this.chipsFormControl.splice(chipIndex, 1);

          this.removeChipAndClearControl('projectId');
          this.removeChipAndClearControl('projectPIP');
          this.enableExtraFields(null);
          this.resetAutocompleteFilters();
          return;
        }
      }
    });
    this.chipsFormControl.splice(chipIndex, 1);
  }

  resetAutocompleteFilters() {
    this.clients = [...this.optionsListProject];
  }

  private removeChipAndClearControl(controlName: string) {
    const index = this.chipsFormControl.indexOf(controlName);
    if (index !== -1) {
      this.chipsList.splice(index, 1);
      this.chipsFormControl.splice(index, 1);
      this.filterForm.controls[controlName].setValue(null);
      this.filterConfig.cleanFilter(controlName);
    }
  }

  getDataSource(res: IDataSourceProjectList) {
    let arr: IDataSourceProjectList[] = [];
    try {
      if (Array.isArray(res)) {
        arr = res.map((project: any) => ({
          clientId: project.clientId,
          clientName: project.clientName,
          descriptionStatusAllocation: project.descriptionStatusAllocation,
          statusAllocationId: project.statusAllocationId,
          projectId: project.projectId,
          projectManager: project.projectManager,
          projectName: project.projectName,
          projectPIP: project.projectPIP,
          startDate: formatDate(project.startDate, 'dd/MM/yyyy', 'en-US'),
          endDate: formatDate(project.endDate, 'dd/MM/yyyy', 'en-US'),
        }));
      }
    } catch (error) {
      console.error('DEU ERRO', error);
    }
    this.dataSource.data = arr;
  }

  updateProjectsBasedOnClient() {
    this.removeChipAndClearControl('projectId');
    this.removeChipAndClearControl('projectPIP');

    const selectedClientId = this.filterForm.controls['clientId'].value.id;
    const selectedClient = this.optionsListProject.find(
      (option) => option.clients.id === selectedClientId
    );

    this.sortByProperty(this.projectsList, 'name');
    
    this.projectsList = selectedClient
      ? (selectedClient.clients.projects as IProjectList[])
      : [];
  }

  private sortOptions() {
    this.optionsListProject.sort((a, b) => {
      return a.clients.name.localeCompare(b.clients.name);
    });
  }

  private sortByProperty(array: any[], property: string) {
    array.sort((a, b) => {
      const propA = a[property] ? a[property].toString() : '';
      const propB = b[property] ? b[property].toString() : '';
      return propA.localeCompare(propB);
    });
  }

  changeItemsPerPage(event: number) {
    this.filterConfig.changeTake(event);
    this.getProjectAll();
  }

  skipPage(event: { skip: number }) {
    this.filterConfig.skipPage(event);
    this.getProjectAll();
  }

  navigateToAllocationProject(projectId: string): void {
    this._router.navigate(['/alocacao/visualizar-alocacao', projectId])
  }

  setFormValues() {
    this.filterForm.patchValue({
      clientId: this.optionsListProject.find(
        (option) => option.clients.id === this.filterConfig.clientId
      )?.clients || null,
      projectId: this.projectsList.find(
        (project) => project.id === this.filterConfig.projectId
      ) || null,
      projectPIP: this.projectsList.find(
        (project) => project.pip === this.filterConfig.projectPIP
      )?.pip || null,
      startDate: this.filterConfig.startDate,
      endDate: this.filterConfig.endDate,
    });

    // Verifica se há um valor em clientId e chama updateProjectsBasedOnClient
    const clientId = this.filterForm.get('clientId')?.value;
    
    if (clientId) {
      this.enableExtraFields(clientId.id);
      this.updateProjectsBasedOnClient();
      this.filterForm.controls['projectId'].setValue(this.projectsList.find(
        (project) => project.id === this.filterConfig.projectId
      ) || null);
      this.filterForm.controls['projectPIP'].setValue(this.projectsList.find(
        (project) => project.pip === this.filterConfig.projectPIP
      )?.pip || null);
    }
  }

  changeChipList(
    event: any = null,
    controlName: string,
    value?: any
  ): void {    
    if (!event.isUserInput) {
      return;
    }    
    setTimeout(() => {
      this.isUpdatedClient = true;
      this.filterForm.controls[controlName].setValue(value?.clients, { emitEvent: false });
      this.enableExtraFields(value?.clients?.id);
      this.handleFilterChange(this.filterForm.value, true);
      this.updateProjectsBasedOnClient();
    }, 300);
  }

  displayFn(control: string, option: any): string {        
    if(!this.optionsListProject) {
      return '';
    }
    
    return option ? option?.name : '';
  }

  filterAutocomplete(controlName: string, target: any) {
    let value = target?.value ?? '';
    
    switch(controlName) {
      case 'clientId':
        this.clients = this.optionsListProject.filter((option) => option.clients.name?.toLowerCase().includes(value?.trim()?.toLowerCase()));
        break;
      default:
        break
    }
  }
}
