<mat-tab-group
  animationDuration="0ms"
  class="tab-page"
  [selectedIndex]="tabSelected"
  (selectedTabChange)="changeURLparams($event)"
>
  <mat-tab label="Cadastrar perfis de acesso">
    <app-create-access-profile *ngIf="currentRoute === 'cadastrar'"></app-create-access-profile>

  </mat-tab>
  <mat-tab label="Gerenciar perfis de acesso">
    <app-manage-access-profile *ngIf="currentRoute === 'gerenciar'"></app-manage-access-profile>

  </mat-tab>
</mat-tab-group>
