<div class="modal-dialog-close">
  <div class="p-horizontal-xl">
    <h2 class="m-bottom-lg">Salvar habilidades</h2>
    <p class="m-bottom-lg">
      Não é possível <strong>excluir habilidades registradas </strong>, pois
      isso afeta os dados de alocação. Apenas os administradores poderão
      excluir.
    </p>
    <p class="m-bottom-lg">
      <strong>Tenha certeza das habilidades cadastradas.</strong>
    </p>
    <div class="col align-center m-bottom-lg">
      <button class="btn btn--width-md primary" (click)="confirmSubmit()">
        Salvar
      </button>
    </div>
  </div>
  <div class="position m-top-lg">
    <button class="position btn-icon-close" (click)="close()">
      <img src="./assets/svg/close-button.svg" alt="" />
    </button>
  </div>
</div>
