<div class="row page-header space-between p-horizontal-3xl">
  <div class="col page-title bold">
    <h2>Visualização e gestão de perfis de Acesso</h2>
  </div>
</div>

<div class="p-horizontal-3xl">
  <form [formGroup]="form">
    <div class="manage-filter">
      <div class="flex flex-column">
        <div class="flex justify-between">
          <div class="select-box flex align-center flex-nowrap justify-between">
            <mat-label class="input-label--custom">Função: </mat-label>
            <mat-form-field
              class="rounded input--width-xs-secondary m-sm"
              appearance="fill"
              floatLabel="always"
            >
              <mat-select
                class="custom-select"
                formControlName="function"
                (ngModelChange)="changeOptions('function', functionOptions)"
              >
                <mat-option
                  *ngFor="let option of functionOptions"
                  [value]="option.id"
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="select-box flex align-center flex-nowrap justify-between">
            <mat-label class="input-label--custom">Perfil: </mat-label>
            <mat-form-field
              class="rounded input--width-xs-secondary m-sm"
              appearance="fill"
              floatLabel="always"
            >
              <mat-select
                class="custom-select"
                formControlName="profile"
                (ngModelChange)="changeOptions('profile', profileOptions)"
              >
                <mat-option
                  *ngFor="let option of profileOptions"
                  [value]="option.id"
                >
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="select-box flex align-center flex-nowrap justify-between">
            <mat-label class="input-label--custom">Status: </mat-label>
            <mat-form-field
              class="rounded input--width-xs-secondary m-sm"
              appearance="fill"
              floatLabel="always"
            >
              <mat-select
                class="custom-select"
                formControlName="status"
                (ngModelChange)="changeOptions('status', statusOptions)"
              >
                <mat-option
                  *ngFor="let option of statusOptions"
                  [value]="option.id"
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="input-box flex align-center flex-1 p-top-lg">
          <mat-form-field
            class="rounded search-button"
            appearance="fill"
            floatLabel="always"
            placeholder="Digite sua pesquisa"
          >
            <input
              placeholder="Faça uma busca"
              type="text"
              matInput
              [formControl]="search"
            />
            <mat-icon matPrefix>search</mat-icon>
            <mat-icon
              *ngIf="search.value && search.value.length >= 1"
              (click)="clearSearch()"
              matSuffix
              >backspace</mat-icon
            >
            <button
              matSuffix
              class="btn primary btn--width-xxs"
              (click)="handleSearch()"
            >
              Buscar
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="p-horizontal-3xl p-top-sm chip-box">
  <mat-chip-list>
    <span class="icon-info" *ngIf="chipsList.length > 0"> Filtros:</span>
    <ng-container *ngFor="let chip of chipsList">
      <mat-chip *ngIf="chip" (click)="removeFilterValueChips(chip)">
        <span *ngIf="chip.description">{{ chip.description }}</span>
        <mat-icon>clear</mat-icon>
      </mat-chip>
    </ng-container>
    <mat-chip
      class="remove-chip"
      *ngIf="chipsList.length > 0"
      (click)="removeAllChips()"
    >
      <span class="error">Excluir todos</span>
      <mat-icon>clear</mat-icon>
    </mat-chip>
  </mat-chip-list>
</div>

<div class="pt-4 p-horizontal-3xl">
  <app-manage-access-profile-table
    [dataSource]="dataSource"
    [count]="count"
    [profileOptions]="profileOptions"
    (changeFilter)="changeFilter($event)"
    (changeCollaborator)="changeCollaborator($event)"
  >
  </app-manage-access-profile-table>
</div>
