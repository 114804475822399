import { Location } from '@angular/common';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { Observable, catchError, map, of } from 'rxjs';
import { FileService } from 'src/app/core/services/file.service';
import { MessageService } from 'src/app/core/services/message.service';
import { AccountService } from 'src/app/pages/account/repository/account.service';
import { UserRoleService } from 'src/app/pages/account/repository/user-role.service';
import { LevelSkillsEnum } from 'src/app/pages/collaborator/enum/level-skills.enum';
import { MaritalEnum } from 'src/app/pages/collaborator/enum/marital.enum';
import { ERoleUser } from 'src/app/pages/collaborator/enum/role.enum';
import { DaysOfWeekEnum } from 'src/app/pages/collaborator/enum/working-days.enum';
import {
  IAddressViaCep,
  IAddress,
} from 'src/app/pages/collaborator/interface/address.interface';
import { ICollaborator } from 'src/app/pages/collaborator/interface/collaborator.interface';
import { IOptionsCollaborator } from 'src/app/pages/collaborator/interface/options-collaborator.interface';
import { AddressService } from 'src/app/pages/collaborator/repository/address.service';
import { CollaboratorService } from 'src/app/pages/collaborator/repository/collaborator.service';
import {
  EPermissionType,
  EProcess,
} from 'src/app/shared/interfaces/roles.interface';
import { Collaborator } from 'src/app/shared/models/Collaborator.model';
import { StringUtil } from 'src/app/shared/utils/string';
import { ArrayValidators } from 'src/app/shared/validators/array.validator';
import { DateValidators } from 'src/app/shared/validators/date.validator';
import { StringValidators } from 'src/app/shared/validators/string.validator';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styles: [],
})
export class ProfileComponent implements OnInit, OnDestroy {
  private _subs = new SubSink();
  currentRoute!: string;
  collaboratorId: string = '';
  @Input() collaborator!: Collaborator;

  collaboratorInative: boolean = false;

  profileForm!: FormGroup;
  maxAdmissionDate!: string;
  EProcess = EProcess;
  EPermissionType = EPermissionType;

  workingDaysSelectOptions: string[] = [];
  levelSelectOptions: string[] = [];
  maritalStateOptions: string[] = [];

  options!: IOptionsCollaborator;
  options$!: Observable<IOptionsCollaborator>;

  address!: IAddressViaCep;
  address$!: Observable<IAddressViaCep>;

  isEditing: boolean = false;
  isUserCollaborator: boolean = false;

  imageChangedEvent: string = '';
  croppedImage: string = '';

  imageSrc: string = '';
  role!: number | null;
  eRoleUser = ERoleUser;

  @ViewChild('UploadFileInput') uploadFileInput: ElementRef | undefined;
  @ViewChild('fileUpload', { static: false }) fileUpload:
    | ElementRef
    | undefined;
  files: Array<{ data: File; inProgress: boolean; progress: number }> = [];

  @ViewChild('allSelected')
  private allSelected!: MatOption;

  constructor(
    private _fb: FormBuilder,
    private _collaboratorService: CollaboratorService,
    private _addressService: AddressService,
    private _messageService: MessageService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _fileService: FileService,
    private _userRoleService: UserRoleService,
    private _accountService: AccountService,
    private _location: Location
  ) {}

  ngOnInit(): void {
    this.role = this.getAccessProfile();

    try {
      this.collaboratorId = this._route.snapshot.paramMap.get(
        'collaboratorId'
      ) as string;
      this.currentRoute = this._router.url.split('/')[1];
      this.createFormGroup();
      this.getSelectOptions();
      this.createSelectOptions();
    } catch (error) {
      this._messageService.openErrorMessage(['Algo deu errado']);
      this._router.navigate(['colaboradores']);
    }

    if (
      this.collaboratorId === this._accountService.localStorage.collaboratorId
    ) {
      this.isUserCollaborator = true;
    }
  }

  updateCollaborator() {
    this.getCollaboratorById();
    this.getProfileImage();
  }

  setPathValue(collaborator: ICollaborator) {
    let days: string[] = [];

    this.profileForm.patchValue({
      email: collaborator.email,
      personalEmail: collaborator.personalEmail,
      fullName: collaborator.fullName,
      nickname: collaborator.nickname,
      gender: `${collaborator.gender}`,
      birthday: this.fillDateNew(collaborator.birthday),
      maritalStatus: `${collaborator.maritalStatus}`,
      isLeader: collaborator.isLeader,
      children: collaborator.children,
      admission: this.fillDate(collaborator.admission),
      contractTypeId: collaborator.contractTypeId,
      workingHours: collaborator.workingHours,
      towerId: collaborator.towerId,
      teamId: collaborator.teamId,
      jobFunctionId: collaborator.jobFunctionId,
      seniorityId: collaborator.seniorityId,
      collaboratorLeaderId: collaborator.collaboratorLeaderId,
      accessProfileId: collaborator.accessProfileId,
    });

    collaborator.address.map((address: IAddress, index) => {
      if (index === 1) {
        this.addFormControlAddress();
      } else {
        this.deleteAddress();
      }
      this.addresses.at(index).patchValue({
        zipCode: address.zipCode,
        number: address.number,
        addressIdentity: address.addressIdentity,
        publicArea: address.publicArea,
        complement: address.complement,
        neighborhood: address.neighborhood,
        localAddress: address.localAddress,
        city: address.city,
        state: address.state,
        country: address.country,
        id: address.id,
        principal: address.principal,
      });
    });

    if (
      collaborator.collaboratorSchedule &&
      collaborator.collaboratorSchedule.workingDays
    ) {
      if (
        collaborator.collaboratorSchedule.workingDays.length ===
        this.workingDaysSelectOptions.length
      ) {
        setTimeout(() => {
          this.allSelected?.select();
          this.toggleAllWorkingDaysSelection();
        }, 1000);
      } else {
        collaborator.collaboratorSchedule.workingDays.map((day) => {
          days.push(DaysOfWeekEnum[day]);
        });
        this.profileForm.patchValue({
          daysOfWeek: days,
        });
      }
    }
    this.selectTower();
  }

  createFormGroup() {
    this.profileForm = this._fb.group({
      fullName: ['', Validators.required],
      personalEmail: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      nickname: [null],
      gender: [null],
      birthday: ['', Validators.required],
      maritalStatus: ['', Validators.required],
      children: ['', Validators.required],
      isLeader: [false, Validators.required],
      accessProfileId: ['', Validators.required],
      admission: [
        '',
        [Validators.required, DateValidators.dateMaxValidator(30)],
      ],
      contractTypeId: ['', Validators.required],
      daysOfWeek: ['', Validators.required],
      workingHours: [
        '',
        [
          Validators.required,
          StringValidators.minNumber(4),
          StringValidators.maxNumber(8),
        ],
      ],
      towerId: ['', Validators.required],
      teamId: ['', Validators.required],
      jobFunctionId: ['', Validators.required],
      seniorityId: ['', Validators.required],
      collaboratorLeaderId: [''],
      addresses: this._fb.array(
        [
          this._fb.group({
            addressIdentity: [''],
            publicArea: [''],
            localAddress: [''],
            number: [''],
            complement: [''],
            neighborhood: [''],
            zipCode: [''],
            city: [''],
            state: [''],
            country: [''],
            principal: [true],
          }),
        ],
        [ArrayValidators.minLength(1), ArrayValidators.maxLength(2)]
      ),
    });
  }

  getCollaboratorById() {
    this.collaboratorInative = this.collaborator.inactive ?? false;
    this.maxAdmissionDate = this.collaborator.getMaxAdmissionDate();
    this.setPathValue(this.collaborator as ICollaborator);
    this.disableForm();
  }

  createSelectOptions() {
    this.workingDaysSelectOptions = Object.keys(DaysOfWeekEnum).filter((f) =>
      isNaN(Number(f))
    );
    this.levelSelectOptions = Object.keys(LevelSkillsEnum).filter((f) =>
      isNaN(Number(f))
    );
    this.maritalStateOptions = Object.keys(MaritalEnum).filter((f) =>
      isNaN(Number(f))
    );
  }

  resetForm() {
    const collaborator = this.collaborator as ICollaborator;
    this.setPathValue(collaborator as ICollaborator);
    this.disableForm();
  }

  disableForm() {
    this.profileForm.disable();
    this.isEditing = false;
  }

  enableForm() {
    this.profileForm.enable();
    this.profileForm.controls['accessProfileId'].disable();
    this.validateFieldDisable();
    this.isEditing = true;
  }

  validateFieldDisable() {
    if (
      !this._userRoleService.hasRole(
        EProcess.CollaboratorContractualinformation,
        EPermissionType.CreateUpdate
      )
    ) {
      this.profileForm.controls['collaboratorLeaderId'].disable();
      this.profileForm.controls['admission'].disable();
      this.profileForm.controls['contractTypeId'].disable();
      this.profileForm.controls['workingHours'].disable();
      this.profileForm.controls['jobFunctionId'].disable();
      this.profileForm.controls['seniorityId'].disable();
      this.profileForm.controls['isLeader'].disable();
      this.profileForm.controls['towerId'].disable();
      this.profileForm.controls['teamId'].disable();
      this.profileForm.controls['daysOfWeek'].disable();
    }

    if (
      !this.isUserCollaborator &&
      !this._userRoleService.hasRole(
        EProcess.CollaboratorPersonalData,
        EPermissionType.CreateUpdate
      )
    ) {
      this.profileForm.controls['fullName'].disable();
      this.profileForm.controls['nickname'].disable();
      this.profileForm.controls['gender'].disable();
      this.profileForm.controls['birthday'].disable();
      this.profileForm.controls['maritalStatus'].disable();
      this.profileForm.controls['children'].disable();
    }

    if (
      !this.isUserCollaborator &&
      !this._userRoleService.hasRole(
        EProcess.CollaboratorAddress,
        EPermissionType.CreateUpdate
      )
    ) {
      this.profileForm.controls['addresses'].disable();
    }
  }

  getSelectOptions() {
    this.options$ = this._collaboratorService.getCollaboratorSelect();

    this._subs.add(
      this.options$.subscribe((options: IOptionsCollaborator) => {
        this.options = options;
        this.updateCollaborator();
      })
    );
  }

  get collaboratorSalaryData() {
    return this.profileForm.controls['collaboratorSalaryData'] as FormGroup;
  }

  togglePerOne() {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
    }
    if (
      this.profileForm.controls['daysOfWeek'].value.length ==
      this.workingDaysSelectOptions.length
    )
      this.allSelected.select();
  }

  toggleAllWorkingDaysSelection() {
    if (this.allSelected?.selected) {
      this.profileForm.controls['daysOfWeek'].patchValue([
        ...this.workingDaysSelectOptions.map((item) => item),
        0,
      ]);
    } else {
      this.profileForm.controls['daysOfWeek'].patchValue([]);
    }
  }

  selectTower(resetTeam: boolean = false) {
    try {
      this.options.towers.filter((tower) => {
        if (tower.id === this.profileForm.controls['towerId'].value) {
          this.options.teams = tower.teams;
          this.profileForm.controls['teamId']?.enable();
        }
      });
      if (resetTeam) this.profileForm.controls['teamId'].reset();
    } catch (error) {
      this.profileForm.controls['teamId']?.disable();
    }
  }

  get addresses() {
    return this.profileForm.controls['addresses'] as FormArray;
  }

  getAddressByZipCode(zipCode: string, addressIndex: number) {
    this.address$ = this._addressService.getAddressByZipCode(zipCode);
    this._subs.add(
      this.address$.subscribe((options: IAddressViaCep) => {
        if (options.erro) {
          this._messageService.openErrorMessage(['CEP INVÁLIDO']);
        }
        this.fillAddressForm(options, addressIndex);
      })
    );
  }

  addFormControlAddress() {
    if (this.addresses.length === 1) {
      const addressForm = this._fb.group({
        addressIdentity: [''],
        publicArea: [''],
        localAddress: [''],
        number: [''],
        complement: [''],
        neighborhood: [''],
        zipCode: [''],
        city: [''],
        state: [''],
        country: [''],
        principal: [false],
      });
      this.addresses.push(addressForm);
    }
  }

  fillAddressForm(address: IAddressViaCep, addressIndex: number) {
    this.addresses.at(addressIndex).patchValue({
      localAddress: address.logradouro,
      complement: address.complemento,
      neighborhood: address.bairro,
      city: address.localidade,
      state: address.uf,
      country: address.pais,
    });
  }

  deleteAddress() {
    this.addresses.at(0).patchValue({ principal: true });
    this.addresses.removeAt(1);
  }

  selectPrincipal(addressIndex: number) {
    if (this.addresses.length === 1)
      this.addresses.at(0).patchValue({ principal: true });
    else {
      this.addresses.value.map((address: IAddress, index: number) => {
        if (index != addressIndex) {
          this.addresses.at(addressIndex).value.principal === true
            ? this.addresses.at(index).patchValue({ principal: false })
            : this.addresses.at(index).patchValue({ principal: true });
        }
      });
    }
  }

  fillDate(DateString: string) {
    const date = new Date(DateString);
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();

    if (date.getDate() < 10) day = day.substr(0, 0) + '0' + day.substr(0);
    if (date.getMonth() < 9) month = month.substr(0, 0) + '0' + month.substr(0);

    return `${date.getFullYear()}-${month}-${day}`;
  }

  fillDateNew(dateString: string | null): string {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  stringToNumber(value: string): number {
    return Number(value);
  }

  getDaysOfWeek() {
    const dayOfWeek = this.profileForm.controls['daysOfWeek'].value;
    const response = dayOfWeek
      .map((day: DaysOfWeekEnum) => {
        const indexOfS = Object.values(DaysOfWeekEnum).indexOf(
          day as unknown as DaysOfWeekEnum
        );
        const key = Object.keys(DaysOfWeekEnum)[indexOfS];
        if (key) return Number(key);
        return 0;
      })
      .reduce((sum: number, current: number) => sum + current, 0);

    return response;
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append('file', file.data);
    file.inProgress = true;
    this._fileService
      .postProfileImage(this.collaboratorId, formData)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .subscribe((event: any) => {
        this._messageService.openSuccessMessage('Imagem carregada');
        window.location.reload();

        if (typeof event === 'object') {
        }
      });
  }

  private uploadFiles() {
    if (this.fileUpload) this.fileUpload.nativeElement.value = '';
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  onClickUpload(fileBlob: Blob) {
    let fileUpload: any;
    if (this.fileUpload) fileUpload = this.fileUpload.nativeElement;
    for (let index = 0; index < fileUpload.files.length; index++) {
      const file = fileUpload.files[index];
      const newfile: File = new File([fileBlob], file.name, {
        lastModified: file.lastModified,
        type: file.type,
      });
      this.files.push({ data: newfile, inProgress: false, progress: 0 });
    }
    this.uploadFiles();
  }

  getProfileImage() {
    this._fileService
      .getFileByCollaboratorId(this.collaboratorId)
      .then((response) => response.blob())
      .then((blob) => {
        const urlCreator = window.URL || window.webkitURL;
        if (blob.type === 'image/png') {
          this.croppedImage = urlCreator.createObjectURL(blob);
        }
      })
      .catch((error) => console.error(error));
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(image: ImageCroppedEvent) {
    if (image.objectUrl) this.croppedImage = image.objectUrl;
    if (image.blob) this.onClickUpload(image.blob);
  }

  onSubmitCollaborator() {
    try {
      this.profileForm.enable();
      const formValue = this.profileForm.value;
      const collaborator: ICollaborator = {
        email: this.collaborator.email,
        personalEmail: formValue.personalEmail,
        fullName: StringUtil.capitalize(formValue.fullName),
        nickname: formValue.nickname,
        gender: formValue.gender,
        birthday: new Date(formValue.birthday).toISOString(),
        maritalStatus: formValue.maritalStatus,
        children: formValue.children,
        admission: new Date(formValue.admission).toISOString(),
        contractTypeId: formValue.contractTypeId,
        workingHours: this.stringToNumber(formValue.workingHours),
        jobFunctionId: formValue.jobFunctionId,
        teamId: formValue.teamId,
        seniorityId: formValue.seniorityId,
        collaboratorLeaderId: formValue.collaboratorLeaderId,
        isLeader: formValue.isLeader,
        towerId: formValue.towerId,
        collaboratorSkills: this.collaborator.collaboratorSkills,
        accessProfileId: this.collaborator.accessProfileId,
        collaboratorSchedule: {
          daysOfWeek: this.getDaysOfWeek(),
          workingDays: [],
        },
        address: formValue.addresses,
        id: this.collaboratorId,
      };

      this._subs.add(
        this._collaboratorService
          .putCollaborator(collaborator)
          .subscribe((response) => {
            if (response.success === true) {
              this._messageService.openSuccessMessage(
                'Cadastro alterado com sucesso.'
              );
              if (this.isUserCollaborator) {
                this._accountService.localStorage.setNameUser(
                  collaborator.fullName
                );
              } else {
                this._router.navigate(['/colaboradores']);
                return;
              }
            } else if (response.errors.length > 0) {
              this._messageService.openErrorMessage(response.errors);
            }
            this.disableForm();
            // this.resetForm();
            // this.getCollaboratorById();
          })
      );
    } catch (error) {
      this.disableForm();
    }
  }

  collaboratorAdvancedEdition() {
    if (this.isUserCollaborator) {
      this._router.navigate(['meu-perfil/', this.collaboratorId]);
    } else {
      this._router.navigate(['gerenciar-colaborador/', this.collaboratorId]);
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  navigateBack() {
    this._location.back();
  }
  getAccessProfile() {
    let role = this._accountService.localStorage.getProfile();
    return role;
  }
}
