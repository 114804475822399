export const COLUMNS_LIST_PROJECT = {
  projectPIP: 'PIP',
  projectName: 'Projeto',
  clientName: 'Cliente',
  descriptionStatusAllocation: 'Status',
  projectManager: 'Responsável',
  startDate: 'Início',
  endDate: 'Fim',
  acao: 'Ação',
  acoes:'',
}

