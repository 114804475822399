import { MatTableDataSource } from '@angular/material/table';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { COLUMNS_MANAGE_ACCESS_PROFILE } from './columns-manage-access-profile';
import {
  IMaganeAccessProfile,
  IOptionsFilter,
  IUpdateAccessProfile,
  Ordenation,
  Pagination,
} from 'src/app/pages/permission/interface/manage-access';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ProfileService } from 'src/app/pages/permission/repository/profile.service';

@Component({
  selector: 'app-manage-access-profile-table',
  templateUrl: './manage-access-profile-table.component.html',
  styles: [],
})
export class ManageAccessProfileTableComponent implements OnInit, OnDestroy {
  @Input() profileOptions: IOptionsFilter[] = [];
  @Input() count: number = 0;
  @Input() dataSource: MatTableDataSource<IMaganeAccessProfile> =
    new MatTableDataSource<IMaganeAccessProfile>();

  @Output() changeCollaborator = new EventEmitter<IMaganeAccessProfile>();
  @Output() changeFilter = new EventEmitter<{
    pagination: Pagination;
    ordenation: Ordenation;
  }>();

  displayedColumns: string[] = [];
  columns = COLUMNS_MANAGE_ACCESS_PROFILE;
  countDataSource: number = 0;

  itemsPerPage: number = 10;
  statusSelectOptions: string[] = [];
  optionSelected!: string;

  pagination: Pagination = { skip: 1, take: 10 };
  ordenation: Ordenation = {
    orderBy: 1,
    asc: true,
  };
  filter!: {
    pagination: Pagination;
    ordenation: Ordenation;
  };

  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _profileService: ProfileService
  ) {
    this.displayedColumns = Object.keys(this.columns);
  }

  ngOnInit(): void {
    this.filter = { pagination: this.pagination, ordenation: this.ordenation };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearTableData() {
    this.dataSource.data = [];
    this.countDataSource = 0;
  }

  getOrderByValue(key: string): number {
    switch (key) {
      case 'fullName':
        return 1;
      case 'jobFunctionDescription':
        return 2;
      case 'email':
        return 3;
      default:
        return 0;
    }
  }

  changeOrder(orderBy: number) {
    if (this.ordenation.orderBy === orderBy) {
      this.ordenation.asc = !this.ordenation.asc;
    } else {
      this.ordenation.orderBy = orderBy;
      this.ordenation.asc = true;
    }

    this.changeFilter.emit(this.filter);
  }

  skipPage(event: { skip: number }) {
    this.pagination.skip = event.skip + 1;
    this.changeFilter.emit(this.filter);
  }

  handleConfirmation(element: IMaganeAccessProfile): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'Confirmar alterações?',
        buttonTxt: 'Sim',
        confirm: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      element.editing = false;

      if (result) {
        this.saveAccessProfile(element);
      } else {
        element.accessProfileId = element.accessProfileIdAux!;
      }
    });
  }

  saveAccessProfile(element: IMaganeAccessProfile): void {
    let data: IUpdateAccessProfile = {
      collaboratorId: element.id,
      accessProfileId: element.accessProfileId,
    };

    this._profileService
      .updateCollaboratorWithAccessProfile(data)
      .subscribe((response) => {
        if (response) {
          element.accessProfileIdAux = element.accessProfileId;
          this.changeCollaborator.emit(element);
        }
      });
  }

  getinitialsNameCollaborator(name: string): string {
    const initialsArray = name.split(' ').filter((name) => {
      if (name) return name;
      return '';
    });
    const firstLetter = initialsArray[0][0];
    const secondLetter =
      initialsArray.length > 1
        ? initialsArray[initialsArray.length - 1][0]
        : '';
    return `${firstLetter}${secondLetter}`;
  }

  cancelEdit(element: IMaganeAccessProfile): void {
    element.editing = false;
    element.accessProfileId = element.accessProfileIdAux!;
  }
}
