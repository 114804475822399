<div class="modal-dialog-close">
  <div class="p-horizontal-xl">
    <h1 *ngIf="!isEdit">Cadastrar Torre</h1>
    <h1 *ngIf="isEdit">Editar Torre</h1>
    <form [formGroup]="towerForm" (ngSubmit)="onSubmitTower()">
      <div class="col align-center">
        <mat-form-field
          class="input-outline input--width-md m-bottom-lg"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>Nome da torre</mat-label>
          <input
            type="text"
            matInput
            formControlName="description"
            maxlength="50"
          />
        </mat-form-field>
        <button
          class="btn btn--width-md primary"
          [disabled]="towerForm.invalid"
          type="submit"
        >
          {{ isEdit ? "Salvar" : "Cadastrar" }}
        </button>
      </div>
    </form>
  </div>
  <div class="position">
    <button class="position btn-icon-close" (click)="close()"><img src="./assets/svg/close-button.svg" alt=""></button>
  </div>
</div>
