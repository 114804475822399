import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
  AllocationByCollaborator,
  AllocationProjectCollaborator,
} from 'src/app/pages/allocation/interface/project-allocation.interface';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import { EFilterAllocationOption } from 'src/app/pages/collaborator/enum/period-alocation.enum';
import {
  calculatePercentagesForMonth,
  calculatePercentagesForPeriod,
} from '../period-view-project-allocation/period.strategy';

@Component({
  selector: 'app-collaborator-view-allocation',
  templateUrl: './collaborator-view-allocation.component.html',
})
export class CollaboratorViewAllocationComponent implements OnInit, OnDestroy {
  @Input() collaborators: AllocationProjectCollaborator[] = [];
  @Input() projectId: string = '';

  collaboratorsAllocation: AllocationByCollaborator[] = [];

  eventSubscription!: Subscription;

  constructor(
    private _router: Router,
    private _allocationService: AllocationService
  ) {}

  ngOnInit(): void {
    this.listenEvents();
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) this.eventSubscription.unsubscribe();
  }

  listenEvents(): void {
    this.eventSubscription =
      this._allocationService.getAllocationPercentageEvent.subscribe({
        next: (response) => {
          if (response) {
            this.getPercentagesByCollaborator(response.period, response.type);
          }
        },
      });
  }

  getPercentagesByCollaborator(
    period: { month: string; year: number }[] | { month: number; year: number },
    type: EFilterAllocationOption
  ): void {
    switch (type) {
      case EFilterAllocationOption.Mes:
        this.collaboratorsAllocation = calculatePercentagesForMonth(
          this.collaborators,
          period as { month: number; year: number }
        );
        break;
      case EFilterAllocationOption.Total:
        this.collaboratorsAllocation = calculatePercentagesForPeriod(
          this.collaborators,
          period as any[]
        );
    }
  }

  viewCollaborator(collaborator: AllocationByCollaborator): void {
    this._router.navigate([
      `/alocacao/gerenciar-alocacao/colaborador/${
        collaborator.collaboratorId
      }/${true}/${this.projectId}/${true}`,
    ]);
  }

  getinitialsNameCollaborator(collaboratorName: string): string {
    const initialsArray = collaboratorName.split(' ').filter((name) => {
      if (name) return name;
      return '';
    });
    const firstLetter = initialsArray[0][0];
    const secondLetter =
      initialsArray.length > 1
        ? initialsArray[initialsArray.length - 1][0]
        : '';
    return `${firstLetter}${secondLetter}`;
  }

}
