export interface IteamCollaborator {
  fullName: string;
  imageURL: string;
  id: string;
  isLeader: boolean;
}

export interface ITeamsFilter {
  teamId: string | null;
  page: number;
  take: number;
  orderByParam: {
    orderBy: number;
    asc: boolean;
  };
}

export interface ICollaboratorTeam {
  collaboratorId: string;
  collaboratorImage: string;
  collaboratorName: string;
  seniority: string;
  jobFunction: string;
  skills: string[];
  allocationPercentage:string;
  journey: string;
  totalHours: number;

}
export interface IProjectTeam {
  projectId: string;
  pip: string;
  name: string;
  client: string;
  status: string;
  responsible:string;
  startDate: string;
  endDate: number;
}

export enum OrderByCollaboratorTeam {
  NAME = 1,
  HOURS = 2,
}

export enum OrderByProjecTeam {
  PROJECT_PIP = 1,
  PROJECT_NAME= 2,
  CLIENT_NAME = 3,
  PROJECT_MANAGER = 4,
  INITIATION = 5,
  CONCLUSION = 6,
}
