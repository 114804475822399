<div class="content">
  <div class="row page-header space-between p-vertical-xl">
    <div class="col page-title m-sm">
      <h1>{{ pageTitle }}</h1>
    </div>
    <ng-template permissions [role]="'Create/Update'" [process]="'Tower'">
      <button
        class="btn secondary btn--width-xxxs m-sm"
        (click)="openDialogTower()"
      >
        {{ btnText1 }}
      </button>
    </ng-template>
    <ng-template permissions [role]="'Create/Update'" [process]="'Team'">
      <button
        class="btn secondary btn--width-xxxs m-sm"
        (click)="openDialogTeam()"
      >
        {{ btnText2 }}
      </button>
    </ng-template>
  </div>

  <div class="m-sm table-expansive primary">
    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      class="table-primary"
    >
      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of displayedColumns"
      >
        <td
          mat-cell
          *matCellDef="let element; let index = index"
          class="cursor-pointer"
        >
          <ng-container *ngIf="column != 'status'">
            {{ element[column] }}
          </ng-container>
          <ng-container *ngIf="column == 'status'">
            <ng-template
              permissions
              [role]="'Create/Update'"
              [process]="'Tower'"
            >
              <mat-icon (click)="openDialogTower(element)">
                <img src="./assets/svg/edit-white.svg" alt=""
              /></mat-icon>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="column == 'icon'">
            <div *ngIf="element.teams.length > 0">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            class="element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <ng-container>
              <div
                class="element-description col cursor-pointer"
                *ngFor="let team of element.teams"
                (click)="teamDetail(team.id)">
                <div
                  class="row"
                  [ngClass]="{ inactive: team.inactive === true }"
                >
                  <div class="col justify-center">{{ team.description }}</div>
                  <div class="row align-center">
                    <ng-container
                      *ngIf="team.leaderId && team.collaborators?.length > 0"
                      >Líder:</ng-container
                    >
                    <div class="m-left-md row">
                      <ng-container
                        *ngFor="let collaborator of team.collaborators"
                      >
                        <div
                          *ngIf="collaborator.id === team.leaderId"
                          class="table--image-container"
                        >
                          <img
                            *ngIf="collaborator.imageURL"
                            class="table--image-profile"
                            [src]="collaborator.imageURL | safeResourceUrl"
                          />
                          <img
                            style="width: 50px"
                            src="./assets/img/account_circle.png"
                            *ngIf="!collaborator.imageURL"
                          />
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="row align-center">
                    <ng-container *ngIf="team.collaborators?.length > 0"
                      >Equipe:</ng-container
                    >
                    <div class="m-left-md row">
                      <ng-container
                        *ngFor="
                          let collaborator of team.collaborators;
                          let collaboratorCount = index
                        "
                      >
                        <div
                          class="table--image-container"
                          *ngIf="collaboratorCount < 4"
                        >
                          <img
                            *ngIf="collaborator.imageURL"
                            class="table--image-profile"
                            [src]="collaborator.imageURL | safeResourceUrl"
                          />
                          <img
                            class="table--icon-photo"
                            src="./assets/img/account_circle.png"
                            *ngIf="!collaborator.imageURL"
                          />
                        </div>
                        <div
                          class="table--image-container"
                          *ngIf="collaboratorCount === 4"
                        >
                          <img
                            *ngIf="collaborator.imageURL"
                            class="table--image-profile"
                            [src]="collaborator.imageURL"
                          />
                          <img
                            class="table--icon-photo"
                            src="./assets/img/account_circle.png"
                            *ngIf="!collaborator.imageURL"
                          />
                          <!-- <mat-icon class="table--icon-photo" *ngIf="!collaborator.imageURL">person</mat-icon> -->

                          <span class="table--span-profile"> +5</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="mat-column-status justify-center"></div>
                  <ng-template
                    permissions
                    [role]="'Create/Update'"
                    [process]="'Team'"
                  >
                    <div class="mat-column-status justify-center">
                      <mat-icon
                        (click)="openDialogTeam(team)"
                        (click)=" $event.stopPropagation()"
                        class="cursor-pointer"
                        ><img src="./assets/svg/edit-black.svg" alt=""
                      /></mat-icon>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="disclaimer">
        <td mat-header-cell *matHeaderCellDef colspan="1">
          <mat-select
            (selectionChange)="changeItemsPerPage($event.value)"
            class="rounded input--width-sm m-md"
            [(ngModel)]="pageSize"
          >
            <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
              {{ option }} itens por página
            </mat-option>
          </mat-select>
        </td>
      </ng-container>
      <tr mat-footer-row *matHeaderRowDef="['disclaimer']"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="element-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
      ></tr>
    </table>

    <app-paginator
      *ngIf="countDataSource > filter.take"
      [length]="countDataSource"
      [pageSize]="filter.take"
      (skipPage)="skipPage($event)"
    ></app-paginator>
  </div>
</div>
