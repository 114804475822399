<div class="modal-dialog-close">
<div class="m-vertical-xl">
  <h1 *ngIf="!isEdit">Cadastrar Habilidade</h1>
  <h1 *ngIf="isEdit">Editar Habilidade</h1>
  <form [formGroup]="skillForm" (ngSubmit)="onSubmitSkill()">
    <div class="col align-center">
      <mat-form-field
        class="input-outline input--width-md m-bottom-lg"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Papel *</mat-label>
        <mat-select formControlName="skillTypeId"
            >
         <!-- [disabled]="isEdit" -->
          <mat-option *ngFor="let option of options" [value]="option.id"

          >
            {{ option.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="input-outline input--width-md m-bottom-lg"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Nome da habilidade</mat-label>
        <input type="text" matInput formControlName="description" maxlength="50" />
      </mat-form-field>
      <button *ngIf="!isEdit" class="btn btn--width-md primary"
      [disabled]="!skillForm.controls['description'].value || !skillForm.controls['skillTypeId'].value"
      type="submit">
        Cadastrar
      </button>
      <button *ngIf="isEdit" class="btn btn--width-md primary" [disabled]="!skillForm.controls['description'].value" type="submit">
        Salvar
      </button>
    </div>
  </form>
</div>
<div class="position">
  <button class="position btn-icon-close" (click)="close()"><img src="./assets/svg/close-button.svg" alt=""></button>
</div>
</div>
