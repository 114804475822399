<div
  class="p-xl message-sucess col align-center justify-center"
  [ngClass]="data.type"
>
  <mat-icon class="icon-width">{{ icon[data.type] }}</mat-icon>
<div [ngClass]="{'titleError': !data.type, 'titleSuccess': data.type}">
  <span>{{ data.title }}</span>
</div>
  <div class="messageError" style="max-width: 450px;">
    <span>{{ data.message }}</span>
  </div>
</div>
