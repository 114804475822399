import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { environment } from 'src/environments/environment.development';
import { IClient } from '../interface/client.interface';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  endpoint: string = 'Client';

  constructor(public http: HttpClient) {}

  getAllClients(): Observable<IClient[]> {
    return this.http
      .get<any>(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(map((response: IApiResponse) => response.result));
  }

  getAllClientsByFilter(filter: any): Observable<IApiResponse<IClient[]>> {
    return this.http.post<IApiResponse<IClient[]>>(
      `${environment.apiUrl}/${this.endpoint}/listByFiltersAsync`,
      filter
    );
  }

  postClient(client: IClient): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.post<any>(url, client);
  }

  putClient(client: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.put<any>(url, client);
  }

  inactivateClient(idClient?: string): Observable<any> {
    const path = 'inactivate';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}/${idClient}`;
    return this.http.delete<any>(url);
  }

  getCollaboratorSelectProject(): Observable<any> {
    const path = 'ListByProject';
    return this.http
      .get<any>(`${environment.apiUrl}/${this.endpoint}/${path}`)
      .pipe(map((response: IApiResponse) => response.result));
  }
}
