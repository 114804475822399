import { Component, OnInit } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
} from '@angular/router';
import { MessageService } from 'src/app/core/services/message.service';
import { AccountService } from 'src/app/pages/account/repository/account.service';
import { CollaboratorService } from 'src/app/pages/collaborator/repository/collaborator.service';
import {
  EPermissionType,
  EProcess,
} from 'src/app/shared/interfaces/roles.interface';
import { Collaborator } from 'src/app/shared/models/Collaborator.model';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';
import { AddCollaboratorSkillComponent } from 'src/app/pages/collaborator/modal/add-collaborator-skill/add-collaborator-skill.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
  styles: [],
})
export class PageDetailComponent implements OnInit {
  collaborator!: Collaborator;
  collaboratorId: string = '';
  currentRoute!: string;
  ERouteProfile = ERouteProfile;
  EPermissionType = EPermissionType;
  EProcess = EProcess;
  isUserCollaborator: boolean = false;
  tabSelected: number = 0;

  private _subs = new SubSink();

  constructor(
    private _collaboratorService: CollaboratorService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _messageService: MessageService,
    private _accountService: AccountService,
    private _location: Location,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    try {
      this.collaboratorId = this._route.snapshot.paramMap.get(
        'collaboratorId'
      ) as string;
      this.getCollaboratorById();
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentRoute = event.url.slice(1);
        }
      });
    } catch (error) {
      this._messageService.openErrorMessage(['Algo deu errado']);
      this._router.navigate(['colaboradores']);
    }
    this.changeTab();
  }
  changeTab() {
    const path = this._location.path();
    this.currentRoute = path.split('/')[1];
    if (
      this.currentRoute === ERouteProfile.Profile ||
      ERouteProfile.Collaborator
    )
      this.tabSelected = 0;
    if (
      this.currentRoute === ERouteProfile.ProfileSkill ||
      this.currentRoute === ERouteProfile.CollaboratorSkill
    )
      this.tabSelected = 1;
    // if (
    //     this.currentRoute === ERouteProfile.Absence ){
    //       this.tabSelected = 2;
    //     }
  }

  changeURLparams(event: any) {
    this.tabSelected = event.index;
    if (
      this.currentRoute === ERouteProfile.Collaborator ||
      this.currentRoute === ERouteProfile.CollaboratorSkill
    ) {
      this.tabSelected === 0
        ? this._location.replaceState(
            `${ERouteProfile.Collaborator}/${this.collaboratorId}`
          )
        : this._location.replaceState(
            `${ERouteProfile.CollaboratorSkill}/${this.collaboratorId}`
          );
    }
    if (
      this.currentRoute === ERouteProfile.Profile ||
      this.currentRoute === ERouteProfile.ProfileSkill ||
      this.currentRoute === ERouteProfile.Absence
    ) {
      switch (this.tabSelected) {
        case 0:
          this._location.replaceState(
            `${ERouteProfile.Profile}/${this.collaboratorId}`
          )
          break;
          case 1:
            this._location.replaceState(
              `${ERouteProfile.ProfileSkill}/${this.collaboratorId}`
            );
            break;
            case 2:
              this._location.replaceState(
                `${ERouteProfile.Absence}/${this.collaboratorId}`
              );
              break;

        default:
          break;
      }

    }
  }

  getCollaboratorById() {
    this._subs.add(
      this._collaboratorService
        .getCollaboratorById(this.collaboratorId)
        .subscribe((response) => {
          this.collaborator = new Collaborator(response as Collaborator);
          if (
            this.collaborator.idCollaborator ===
            this._accountService.localStorage.collaboratorId
          ) {
            this.isUserCollaborator = true;
          }
        })
    );
  }

  navigateBack() {
    this._location.back();
  }

  openDialogCollaboratorSkill() {
    let dialogRef;
    dialogRef = this.dialog.open(AddCollaboratorSkillComponent, {
      panelClass: 'modal-dialog-close-type',
      height: '589px',
      width: '520px',
      data: { collaboratorId: this.collaboratorId },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.reload === true) window.location.reload();
    });
  }
  redirectToRestRequest() {
    window.open('https://apps.powerapps.com/play/e/default-97301bc6-aabf-4213-b140-e9574488f252/a/30c7c543-a9f5-4327-9f49-265e2625d296?tenantId=97301bc6-aabf-4213-b140-e9574488f252&sourcetime=1709813579383','_blank');
  }
}

export enum ERouteProfile {
  Profile = 'meu-perfil-detalhes',
  Collaborator = 'detalhes-colaborador',
  ProfileSkill = 'meu-perfil-habilidades',
  CollaboratorSkill = 'habilidades-colaborador',
  Absence = 'meu-perfil-descanso',
}
