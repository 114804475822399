import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ForrmatDateService{
    private selectedPeriodSubject = new BehaviorSubject<string>('Semana');
    selectedPeriod$ = this.selectedPeriodSubject.asObservable();
      setSelectedPeriod(period: string): void {
      this.selectedPeriodSubject.next(period);
    }
}
