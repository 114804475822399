import { DaysOfWeekEnum } from 'src/app/pages/collaborator/enum/working-days.enum';

export class Period {
  key!: 'week' | 'month';
  value!: 1 | 2;
  description!: 'Semanais' | 'Mensais';
  workingDaysCollaborator!: Array<DaysOfWeekEnum>;
  workingDays = [1, 2, 4, 8, 16];
  workingHours: number = 0;
  workingHoursPerPeriod: number = 0;
  percentHours!: number;
  hours!: number;
  percentage!: number;

  constructor(workingDays: Array<DaysOfWeekEnum>, workingHours: number) {
    this.workingDaysCollaborator = workingDays;
    this.workingHours = workingHours;
  }

private  _calculatePercent(percent: number) {
  const BASE_PERCENTAGE: number = 0.01;
  return Math.trunc(BASE_PERCENTAGE * this.workingHoursPerPeriod * percent);
}
private  _calculateHours(hours: number) {
  const BASE_PERCENTAGE: number = 100;
  return Math.trunc((BASE_PERCENTAGE * hours)/this.workingHoursPerPeriod);
}

  initValues(period: 'week' | 'month' = 'month') {
    this.key = period;
    if (this.key === 'week') {
      this.value = 1;
      this.description = 'Semanais';
      this.calculateWeekWorkingDays();
    } else if (this.key === 'month') {
      this.value = 2;
      this.description = 'Mensais';
      this.calculateMonthWorkingDays();
    }
  }

  calculateWeekWorkingDays() {
    const daysWeek = this.workingDaysCollaborator.length;
    this.workingHoursPerPeriod = this.workingHours * daysWeek;
  }

  calculateMonthWorkingDays() {
    const daysWeek: Array<{ day: number }> = [
      { day: 0 },
      { day: 0 },
      { day: 0 },
      { day: 0 },
      { day: 0 },
      { day: 0 },
      { day: 0 },
    ];
    const currentDate = new Date();
    const currentMonth = currentDate.getUTCMonth();
    const currentYear = currentDate.getUTCFullYear() + 1;

    const data = new Date(currentYear, currentMonth, 0);
    const firstDay = new Date(currentYear, currentMonth - 1, 1).getUTCDay();
    const daysMonth = data.getDate();
    let daysWorkByMonth = 0;
    let count = 0;

    for (let first = firstDay; first <= 6; first++) {
      if (count === daysMonth) break;
      daysWeek[first].day += 1;
      count++;

      if (first == 6 && count < daysMonth) {
        first = -1;
      }
    }

    this.workingDaysCollaborator.map((day) => {
      const dayIndex = this.workingDays.indexOf(day) + 1;
      daysWorkByMonth += daysWeek[dayIndex].day;
    });

    this.workingHoursPerPeriod = this.workingHours * daysWorkByMonth;
  }

  calculatePeriod(isPercentage: boolean = false, allocationPeriod: number){
    isPercentage === true?
      this.calculatePercentHoursByPeriod(allocationPeriod):
      this.calculatePercentByHours(allocationPeriod);

  }
  calculatePercentHoursByPeriod(percent: number): void {
    this.percentage = percent;
    this.hours = this._calculatePercent(percent);
  }
  calculatePercentByHours(hours: number): void {
    this.hours = hours;
    this.percentage = this._calculateHours(hours);
  }
}
