import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-manage-allocation',
  templateUrl: './manage-allocation.component.html',
  styles: [],
})
export class ManageAllocationComponent implements OnInit {
  currentRoute!: string;
  collaboratorId!: string;
  collaboratorsId!: Array<string>;
  allocable!: boolean;
  projectId!: string;
  projectsId!: Array<string>;
  viewByProject: boolean = false;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _location: Location
  ) {
    try {
      this.collaboratorId = this._route.snapshot.paramMap.get(
        'collaboratorId'
      ) as string;
      const allocable = this._route.snapshot.paramMap.get(
        'allocable'
      ) as string;

      this.viewByProject =
        this._route.snapshot.paramMap.get('viewByProject') === 'true'
          ? true
          : false;

      allocable && allocable === 'true'
        ? (this.allocable = true)
        : (this.allocable = false);
    } catch (error) {
      console.log(error);
    }
    const extras = this._router.getCurrentNavigation()?.extras.state;
    if (extras) this.collaboratorsId = extras['ids'];
    this.currentRoute = this._router.url.substring(
      this._router.url.lastIndexOf('/') + 1
    );

    try {
      this.projectId = this._route.snapshot.paramMap.get('projectId') as string;
    } catch (error) {
      console.log(error);
    }
  }

  ngOnInit(): void {}

  navigateTo(): void {
    if (this.projectId && this.viewByProject) {
      this._router.navigate(['/alocacao/visualizar-alocacao', this.projectId]);
      return;
    }

    if (this.projectId) {
      this._router.navigate(['/alocacao/projetos']);
      return;
    }

    if (this.collaboratorId) {
      this._router.navigate(['/alocacao/colaboradores']);
      return;
    }

    this._location.back();
  }
}
