import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountGuard } from 'src/app/core/guards/account.guard';
import { PageSkillListComponent } from './pages/page-skill-list/page-skill-list.component';
import { PermissionGuard } from 'src/app/core/guards/permission.guard';

const routes: Routes = [
  {
    path: 'habilidades',
    component: PageSkillListComponent,
    canActivate: [PermissionGuard],
    data: {
      process: 'Skills',
      permission: 'Read',
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SkillRoutingModule {}

export const SkillRouting: ModuleWithProviders<SkillRoutingModule> =
  RouterModule.forChild(routes);
