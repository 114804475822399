export class SkillManager {
  constructor(private collaboratorId: string, private dataSource: any) {}

  extractFormValues(skillForm: any) {
    return {
      skillTypeId: skillForm.value.skillType.id,
      skillId: skillForm.value.skills.id,
      level: skillForm.value.skillLevel.value,
    };
  }

  addSkillToCollaborator(skillToAddArray: any, newSkills: any) {
    skillToAddArray.collaboratorId = this.collaboratorId;
    const skillIndex = skillToAddArray.skillsAdd.findIndex(
        (skill: any) => skill.skillId === newSkills.skillId
      );
    if(skillIndex != -1) return skillToAddArray;
    skillToAddArray.skillsAdd.push(newSkills);
    return skillToAddArray;
  }

  getAllSkillId(dataSource: any) {
    return dataSource.data.flatMap((skillType: any) => {
      return skillType.skills.flatMap((skill: any) => skill.skillId);
    });
  }

  filterSkill(options: any, allSkillsId: string[]){
    return options.skills.filter((skill: any) => 
      !allSkillsId.includes(skill.id)
   );
  }

  createSkill(skillForm: any) {
    return {
      skillId: skillForm.value.skills.id,
      skillName: skillForm.value.skills.description,
      level: skillForm.value.skillLevel.description,
      inactive: false,
      canRemove: true,
    };
  }
  removeSkill(skillToAddArray: any, skill: string) {
    const skillIndex = skillToAddArray.skillsAdd.findIndex(
      (skill: any) => skill.skillId === skill.skillId
    );
    skillToAddArray.skillsAdd.splice(skillIndex, 1);
    return skillToAddArray;
  }
  inactivateSkill(skill: any, reasonInactivation: string = ''){
    return {
      skillId: skill.skillId,
      reasonInactivation: reasonInactivation,
      collaboratorId: this.collaboratorId
    }
  }
  deleteSkill(skill: any){
    return{
        collaboratorId: this.collaboratorId,
        skillsId: skill.skillId
    }
  }
}
