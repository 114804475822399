import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IProfileProcessPermission, ModulePermission } from 'src/app/core/guards/permission.guard';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { ERoleUser } from '../../collaborator/enum/role.enum';
import {
  permissionType,
  process,
} from 'src/app/shared/interfaces/roles.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService extends BaseApiService {
  endpoint: string = 'AccessProfile';
  roles!: ModulePermission[];
  profile!: ERoleUser;

  changeUserRoles: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  changeUserRoles$ = this.changeUserRoles.asObservable();

  constructor(protected override http: HttpClient) {
    super(http);
  }

  hasRole(process: process, permission: permissionType): boolean {
    if (this.roles) {
      return this.roles.some(
        (role) =>
          role.process === process && role.permissions.includes(permission)
      );
    } else {
      return false;
    }
  }

  getPermissionsByUser() {
    const path = 'GetPermissionsByUser';
    return this.get<IProfileProcessPermission>(this.endpoint, path);
  }
}
