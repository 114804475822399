<div class="modal-dialog-close">
  <div class="p-horizontal-xl">
     <h2 class="m-bottom-lg" *ngIf="!isEdit">Cadastro de Cliente</h2>
    <h2 class="m-bottom-lg" *ngIf="isEdit">Editar Cliente</h2>
    <form [formGroup]="clientForm" (ngSubmit)="onSubmitClient()">
      <div class="col align-center ">
        <mat-form-field
          class="input-outline input--width-sm m-bottom-lg"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>Nome do cliente</mat-label>
          <input maxlength="40" type="text" matInput formControlName="name" />
        </mat-form-field>

        <div>
          <mat-checkbox
            formControlName="internationalCustomer"
            class="checkbox input--width-sm"
          >
            Cliente internacional
          </mat-checkbox>
        </div>
        <ng-container *ngIf="clientForm.get('internationalCustomer')?.value === true">
          <mat-form-field
            class="input-outline input--width-sm m-vertical-lg"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>País</mat-label>
            <input maxlength="40" type="text" matInput formControlName="country" />
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="clientForm.get('internationalCustomer')?.value === true">
        <mat-form-field
        class="input-outline input--width-sm m-vertical-lg"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Documento:</mat-label>
        <input maxlength="40" type="text" matInput formControlName="documentRegistration" />
      </mat-form-field>
      </ng-container>
    <ng-container *ngIf="clientForm.get('internationalCustomer')?.value === false">
        <mat-form-field
        class="input-outline input--width-sm m-vertical-lg"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>CNPJ:</mat-label>
        <input
        mask="00.000.000/0000-00"
        type="text" matInput formControlName="documentRegistration" />
      </mat-form-field>
    </ng-container>
        <button
          class="btn no-width primary"
          [disabled]="clientForm.invalid"
          type="submit"
        >
          {{ isEdit ? "Salvar" : "Cadastrar" }}
        </button>
      </div>
    </form>
  </div>
  <div class="position">
    <button class="position btn-icon-close" (click)="close()">
      <img src="./assets/svg/close-button.svg" alt="" />
    </button>
  </div>
</div>
