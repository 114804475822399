import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EstatusDescansoEnum } from 'src/app/pages/collaborator/enum/status-alocation.enum';
import { IListIRest } from 'src/app/pages/collaborator/interface/collaborator-profile.interface';
import { ICollaboratorRestlFilter } from 'src/app/pages/collaborator/interface/collaborator-rest-filter.interface';
import { CollaboratorService } from 'src/app/pages/collaborator/repository/collaborator.service';
import { Collaborator } from 'src/app/shared/models/Collaborator.model';

@Component({
  selector: 'app-collaborator-rest',
  templateUrl: './collaborator-rest.component.html',
  styleUrls: [],
})
export class CollaboratorRestComponent implements OnInit {
  @Input() collaborator!: Collaborator;
  @Input() collaboratorId!: string;
  rests: IListIRest[] = [];

  filterForm!: FormGroup;
  optionsStatus: string[] = [];
  optionsYear = [
    { id: '01', description: '2023' },
    { id: '02', description: '2024' },
  ];

  constructor(
    private _collaboratorService: CollaboratorService,
    private _fb: FormBuilder
  ) {}

  filter: ICollaboratorRestlFilter = {
    yearId: '',
    statusRestId: '',
  };

  ngOnInit() {
    this.createFormGroup();
    this.getStatusRest();
    this.listenFormChanges();
    this.rests = this._collaboratorService.getRests();
  }

  getRests(): void {
    this._collaboratorService
      .getAllCollaboratorsRestByFilter(this.filter)
      .subscribe((response) => {
        if (response.success === true) {
        }
      });
  }

  listenFormChanges() {
    this.filterForm.valueChanges.subscribe((value) => {
      this.filter = {
        ...this.filter,
        ...value,
      };
      this.getRests();
    });
  }

  createFormGroup() {
    this.filterForm = this._fb.group({
      yearId: [''],
      statusRestId: [''],
    });
  }

  getStatusRest(): any {
    this.createSelectOptions();
    return this.filterForm.controls['statusRestId'].value;
  }

  createSelectOptions() {
    this.optionsStatus = Object.values(EstatusDescansoEnum).filter(
      (value) => typeof value === 'string'
    ) as string[];
  }

  getStatusColor(status: string) {
    switch (status) {
      case String(EstatusDescansoEnum['Em análise']):
        return '#F1C40F';
      case String(EstatusDescansoEnum['Coletivo']):
        return '#575759';
      case String(EstatusDescansoEnum['Aprovado']):
        return '#2ECC71';
      case String(EstatusDescansoEnum['Recusado']):
        return '#E74C3C';
      default:
        return '';
    }
  }

  getStatusEnumName(statusValue: number): string {
    return EstatusDescansoEnum[statusValue];
  }

  getStatusClass(statusRest: string): object {
    const classes = {
      'status-approved': statusRest == 'Aprovado',
      'status-collective': statusRest == 'Coletivo',
      'status-analysis': statusRest == 'Em análise',
      'status-refused': statusRest == 'Recusado',
    };
    return classes;
  }
}
