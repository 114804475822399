import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MessageService } from 'src/app/core/services/message.service';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import { IClient } from 'src/app/pages/clients-projects/interface/client.interface';
import { IProject } from 'src/app/pages/clients-projects/interface/project.interface';
import { ClientService } from 'src/app/pages/clients-projects/repository/client.service';
import { ProjectService } from 'src/app/pages/clients-projects/repository/project.service';
import { CollaboratorService } from 'src/app/pages/collaborator/repository/collaborator.service';
import { SubSink } from 'subsink';
import { ITemplateForm } from '../../collaborator/form-allocation-template/form-allocation-template.component';
import {
  IGenericData,
  IOptionsCollaborator,
} from 'src/app/pages/collaborator/interface/options-collaborator.interface';
import { Allocation } from 'src/app/shared/models/allocation.model';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ICollaboratorAllocation } from 'src/app/pages/allocation/interface/collaborator-allocation.interface';

@Component({
  selector: 'app-page-create-project-allocation',
  templateUrl: './page-create-project-allocation.component.html',
  styles: [],
})
export class PageCreateProjectAllocationComponent implements OnInit {
  @Input() projectsId: Array<string> = [];

  pageTitle: string = 'Cadastrar alocação por projeto';

  allocation: Array<Allocation> = [];

  optionsCollaborator$!: Observable<ICollaboratorAllocation[]>;
  optionsProject$!: Observable<IProject[]>;
  optionsClients$!: Observable<IClient[]>;
  optionsJobFunction$!: Observable<IOptionsCollaborator>;
  optionsCollaborator!: ICollaboratorAllocation[];
  optionsClients!: IClient[];
  optionsProject!: IProject[];
  optionsJobfunction!: IGenericData[];

  formProjectList: Array<ITemplateForm> = new Array();
  formAllocationList: Array<ITemplateForm> = new Array();

  disableButtonReplyAllocation: Array<boolean> = [true];
  disableButton: boolean = true;

  projectSelected!: IProject;

  private _subs = new SubSink();

  constructor(
    private _projectService: ProjectService,
    private _collaboratorService: CollaboratorService,
    private _clientsService: ClientService,
    private _allocationService: AllocationService,
    private _messageService: MessageService,
    public dialog: MatDialog,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.initAllocation();
    this.getSelectOptions();
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  getSelectOptions() {
    this.optionsProject$ = this._projectService.getAllbyProjects();
    this.optionsCollaborator$ =
      this._collaboratorService.getAllCollaboratorsAllocation();
    this.optionsClients$ = this._clientsService.getAllClients();
    this.optionsJobFunction$ =
      this._collaboratorService.getCollaboratorSelect();
    // this.projectsId ? this.initAllocation() : this.addAllocation();
    this._subs.add(
      this.optionsClients$.subscribe((clients: IClient[]) => {
        this.optionsClients = clients;
        this.formProjectList.push({
          type: 'client',
          index: 0,
          list: clients,
          isDisabled: false,
        });
      }),
      // INFO PROJECT FORM
      this.optionsProject$.subscribe((projects: IProject[]) => {
        this.formProjectList.push({
          type: 'project',
          index: 1,
          list: projects,
          value: [],
          isDisabled: false,
        });
        this.formProjectList.push({
          type: 'projectResponsible',
          index: 2,
          isDisabled: true,
        });
        this.formProjectList.push({
          type: 'projectStatus',
          index: 3,
          isDisabled: true,
        });
        this.optionsProject = projects;
      }),

      // ALLOCATION FORM

      this.optionsJobFunction$.subscribe((options: IOptionsCollaborator) => {
        this.optionsJobfunction = options.jobFunctions;
        this.formAllocationList.push({
          type: 'jobFunction',
          index: 0,
          list: options.jobFunctions,
          isDisabled: false,
        });
      }),

      this.optionsCollaborator$.subscribe(
        (collaborator: ICollaboratorAllocation[]) => {
          // console.log(collaborator)
          this.optionsCollaborator = collaborator;
          this.formAllocationList.push({
            type: 'collaborator',
            index: 1,
            list: collaborator,
            isDisabled: false,
          });
        }
      )
    );
  }

  initAllocation() {
    const newAllocation = new Allocation();
    this.allocation.push(newAllocation);
  }

  receiveProjectSubmit(event: any) {
    this.projectSelected = event.data.project;
    if (this.allocation.length > 0) {
      this.allocation.map((allocation) => {
        allocation.createAllocationByProject(this.projectSelected);
      });
    }
  }

  receiveProjectStatusForm(formValid: boolean) {
    if(this.projectSelected && !formValid){
      for (let allocationIndex = this.allocation.length - 1; allocationIndex >= 0; allocationIndex--) {
        this.removeAllocation(allocationIndex);
      }
    }
  }

  receiveAllocationSubmit(allocation: any, formIndex: number) {
    this.allocation.at(formIndex)?.setAllocation(allocation.data);
    this.updateCollaboratorSelected();
    this.disableButtonReplyAllocation[formIndex] = false;
    this.disableButton = false;
  }
  collaboratorIdSelected!: Array<string>;

  addAllocation(
    reply: boolean = false,
    lastAllocation?: Allocation,
    formIndex: number = 0
  ) {
    const newAllocation = new Allocation();
    newAllocation.createAllocationByProject(this.projectSelected);
    this.updateCollaboratorSelected();
    if (reply === true) {
      if (lastAllocation) newAllocation.replyAllocation(lastAllocation);
      this.allocation.splice(formIndex + 1, 0, newAllocation);
      this.disableButtonReplyAllocation.splice(formIndex + 1, 0, true);
    } else {
      this.allocation.push(newAllocation);
      this.disableButtonReplyAllocation.push(true);
    }
    this.disableButton = true;
  }

  removeAllocation(formIndex: number) {
    if (formIndex === 0 && this.allocation.length === 1) {
      this.addAllocation();
    }
    this.updateCollaboratorSelected();
    this.allocation.splice(formIndex, 1);
    this.disableButton = this.hasInvalidAllocation();
    this.disableButtonReplyAllocation.splice(formIndex, 1);
  }

  updateCollaboratorSelected(){
    this.collaboratorIdSelected = this.allocation.flatMap(allocation => allocation.collaboratorId);
  }
  hasInvalidAllocation() {
    const hasInvalidAllocation = this.allocation.filter(
      (allocation: Allocation) => {
        return !allocation.isValid();
      }
    );
    let disableButton: boolean;
    hasInvalidAllocation.length
      ? (disableButton = true)
      : (disableButton = false);
    return disableButton;
  }

  cancelAllocation() {
    const message: string =
      'Você tem certeza que deseja cancelar essa alocação?';
    const buttonTxt: string = 'Sim';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._router.navigate(['/alocacao/colaboradores']);
      }
    });
  }

  postAllocation() {
    this.allocation.forEach((allocation) => {
      if (allocation.id == null) {
        delete allocation.id;
      }
    });
    const overAllocated = this.allocation.some( (allocation) => {
      return allocation.overAllocation === true;
    })
    if (overAllocated) {
      const message: string =
        'Você selecionou um colaborador que já está super alocado. Tem certeza que deseja continuar com a alocação?';
      const buttonTxt: string = 'Sim';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: message,
          buttonTxt: buttonTxt,
          confirm: false,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.allocationRequest();
        }
      });
    } else {
      this.allocationRequest();
    }
   }

  private allocationRequest(){
    this._subs.add(
      this._allocationService
        .postAllocation(this.allocation)
        .subscribe((response) => {
          if (response.success === true) {
            this._messageService.openSuccessMessage(
              'Cadastro realizado com sucesso.'
            );
            this._router.navigate(['/alocacao/colaboradores']);
          } else if (response.errors.length > 0) {
            this._messageService.openErrorMessage(response.errors);
          }
        })
    );
  }
}
