import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormControlStatus,
  ValidationErrors,
} from '@angular/forms';
import { Observable, map, startWith, debounceTime } from 'rxjs';
import { Allocation } from 'src/app/shared/models/allocation.model';
import { IClient } from 'src/app/pages/clients-projects/interface/client.interface';
import { IProject } from 'src/app/pages/clients-projects/interface/project.interface';
import { Period } from 'src/app/shared/models/period.model';
import { SubSink } from 'subsink';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import {
  ICollaboratorAllocation,
  IOverallocate,
} from 'src/app/pages/allocation/interface/collaborator-allocation.interface';
import { CHECKBOX_OPTIONS } from './checkbox-option.const';

@Component({
  selector: 'app-form-collaborator-allocation',
  templateUrl: './form-collaborator-allocation.component.html',
  styles: [],
})
export class FormCollaboratorAllocationComponent
  implements OnInit, OnDestroy, OnChanges
{
  occurrence = CHECKBOX_OPTIONS;
  allocationForm!: FormGroup;
  filteredOptionsCollaborator!: Observable<ICollaboratorAllocation[]>;
  filteredOptionsProject!: Observable<IProject[]>;
  filteredOptionsClients!: Observable<IClient[]>;
  minDate?: string;
  maxDate!: string;
  textAreaPlaceholderPreVenda: string =
    'Escreva suas observações sobre esta pré-venda, ou seja, o porquê da ação. Ex: treinamentos, workshops, squad, projetos, etc.';
  textAreaPlaceholderAusencia: string =
    'Escreva o motivo da ausência do colaborador (férias, afastamento, licença...)';
  workingHours!: number;
  collaboratorPeriod!: Period;
  myControl = new FormControl();
  imageSrc?: string;

  observationValue!: string;
  validStatus: boolean = false;

  jobFunction!: string;
  skills!: string;
  tower!: string;
  leader!: string;
  seniority!: string;
  collaboratorProjects: Array<{id: string, name:string}> = [];

  private _subs = new SubSink();

  overAllocate: boolean = false;
  overAbsence: boolean = false;
  overAllocateMessage!: string;
  suffixPeriod: 'h' | '%' | '' = '%';

  hoursSelected: string = '';

  @Input() collaborators: ICollaboratorAllocation[] = [];
  @Input() projects: IProject[] = [];
  @Input() clients: IClient[] = [];
  @Input() allocation!: Allocation;
  @Input() allocations: Allocation[] = [];
  @Input() isReservationOrAbsence: boolean = false;
  @Input() isAbsence: boolean = false;

  @Output() submitEmitter: EventEmitter<any> = new EventEmitter();
  @Output() removeEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() formStatusValid: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private _fb: FormBuilder,
    private _allocationService: AllocationService,
    private cdr: ChangeDetectorRef
  ) {
    this.createFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allocations'] && this.allocations.length > 0) {
      this.validateAllocation();
    }
  }

  ngOnInit(): void {
    this.changeOption();
    if (!this.allocation.collaboratorId) {
      this.allocationForm.controls['allocationPeriod'].enable();
    } else {
      this.allocationForm.controls['allocationPeriod'].disable();
      let collaborator = this.findCollaboratorById(
        this.allocation.collaboratorId
      );
      this.setCollaboratorAttribute(collaborator);
    }

    if (this.allocation){ 
    this.setValue();}
    this.allocationForm.statusChanges.pipe(debounceTime(400)).subscribe((res) => {
      let isAbsence = this.allocationForm.get('isAbsence')?.value;

        if (!isAbsence) {
          let observationChange = this.checkObservationChange();
          this.emitValidValuesForm(res, !observationChange);
        } else if (isAbsence) {
          this.emitValidValuesForm(res, false);
        }
        // this.getFormValidationErrors();
        if (!this.allocationForm.untouched)
          this.allocationForm.markAllAsTouched();
      });

    this.allocationForm.controls['collaborator'].valueChanges.subscribe({
      next: (response) => {
        this.overAllocate = false;
        this.allocationForm.controls['client'].setErrors(null);
        this.allocationForm.controls['project'].setErrors(null);
      },
    });
    this.isPercentageValidator();

    setTimeout(() => {
      this.cdr.detectChanges();
    });
   // this.isOverHoursPeriod();
  }

  isPercentageValidator() {
    const isPercentage = this.allocationForm.controls['isPercentage'].value;
    if (isPercentage === true) {
      this.allocationForm.controls['allocationPeriod'].setValidators([
        Validators.required,
        Validators.min(10),
      ]);
    } else {
      this.allocationForm.controls['allocationPeriod'].setValidators([
        Validators.required,
        Validators.min(1),
      ]);
    }
  }

  getFormValidationErrors() {
    Object.keys(this.allocationForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors | null =
        this.allocationForm.controls[`${key}`].errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError]
          );
        });
      }
    });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  emitValidValuesForm(
    res: FormControlStatus,
    callOverallocate: boolean = true
  ): void {
    if (this.checkFormValidateWithoutObservation()) {
      this.emitValidForm(false);
      this.onSubmitAllocation(callOverallocate);
    } else if (res === 'INVALID') {
      this.emitValidForm(false);
    } else if (res === 'VALID') {
      this.emitValidForm(true);
      this.onSubmitAllocation(callOverallocate);
    }
  }

  setValue(): void {
    let project = this.projects.find(
      (project) => project.id === this.allocation.projectId
    );

    let client = this.clients.find(
      (client) => client.id === this.allocation.clientId
    );

    let collaborator = this.collaborators.find(
      (collaborator) =>
        collaborator.collaboratorId === this.allocation.collaboratorId
    );

    this.allocationForm.patchValue({
      allocationPeriod: this.allocation.isPercentage
        ? this.allocation.percentage
        : this.allocation.hours,
      startDate: this.allocation.startDate,
      endDate: this.allocation.endDate,
      isReservation: this.allocation.isReservation,
      isAbsence: this.allocation.isAbsence,
      project: project,
      client: client,
      collaborator: collaborator,
      hours: this.allocation.hours,
      isPercentage: this.allocation.isPercentage,
      observation: this.allocation.observation,
    });

    this.suffixPeriod = this.allocation.isPercentage ? '%' : 'h';

    if (collaborator) this.changeCollaborator(collaborator);
    this.onCheckboxChangeReservation();
  }

  changeOption(): void {
    this.filteredOptionsCollaborator = this.allocationForm.controls[
      'collaborator'
    ].valueChanges.pipe(
      startWith(''),
      map((value: ICollaboratorAllocation) => {
        this.allocation.collaboratorId = value?.collaboratorId;
        if(value){
          this.jobFunction = value?.jobFunction?.description;
          this.skills = value?.collaboratorSkills && value?.collaboratorSkills?.length > 0 ? value?.collaboratorSkills.map((skill) => skill.skills.description).join(', ') : '';
          this.tower = value?.tower?.description;
          this.leader = value?.collaboratorLeader?.fullName;
          this.seniority = value?.seniority?.description;
        }
        return this.changeCollaborator(value);
      })
    );

    this.filteredOptionsProject = this.allocationForm.controls[
      'project'
    ].valueChanges.pipe(
      startWith(''),
      map((value: IProject) => {
        this.allocation.projectId = value?.id;
        return this.changeProject(value);
      })
    );
    this.filteredOptionsClients = this.allocationForm.controls[
      'client'
    ].valueChanges.pipe(
      startWith(''),
      map((value: IClient) => {
        return this.changeClient(value);
      })
    );
  }

  changePeriod(event: any) {
    if (event.value === true) {
      this.suffixPeriod = '%';
      this.allocationForm.controls['allocationPeriod'].setValidators([
        Validators.required,
        Validators.min(10),
      ]);
    } else {
      this.allocationForm.controls['allocationPeriod'].setValidators([
        Validators.required,
        Validators.min(1),
      ]);
      this.suffixPeriod = 'h';
    }
    this.allocationForm.controls['isPercentage'].setValue(event.value);
  }

  changeHourPeriod(event: any) {
    if (event.value === true) {
      this.suffixPeriod = '%';
      this.allocationForm.controls['allocationPeriod'].setValidators([
        Validators.required,
        Validators.min(10),
      ]);
    } else {
      this.allocationForm.controls['allocationPeriod'].setValidators([
        Validators.required,
        Validators.min(1),
      ]);
      this.suffixPeriod = 'h';
    }
    this.allocationForm.controls['isPercentage'].setValue(event.value);
  }

  createFormGroup(): void {
    this.allocationForm = this._fb.group({
      collaborator: ['', [Validators.required]],
      project: ['', [Validators.required]],
      client: [''],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      allocationPeriod: [Validators.required, Validators.min(1)],
      isReservation: [false, [Validators.required]],
      isAbsence: [false, [Validators.required]],
      isPercentage: [true],
      observation: [''],
    });
  }

  changeClient(client: IClient): IClient[] {
    try {
      const currentDate = new Date();
      const maxDate = new Date(
        currentDate.getFullYear() + 1,
        currentDate.getUTCMonth(),
        currentDate.getUTCDate()
      );
      this.minDate = currentDate.toISOString().slice(0, -14);
      this.maxDate = maxDate.toISOString().slice(0, -10);
    } catch (error) {
      console.log(error);
    }
    const name = typeof client === 'string' ? client : client?.name;
    return name ? this._filterClient(name as string) : this.clients?.slice();
  }

  validateAllocation() {
    let value = this.allocationForm.getRawValue();
    if (
      value?.collaborator &&
      !(typeof value?.collaborator === 'string') &&
      value?.project &&
      !(typeof value?.project === 'string')
    ) {
      let valid =
        this.allocations.filter(
          (allocation) =>
            allocation.collaboratorId === value?.collaborator?.collaboratorId &&
            allocation.projectId === value?.project?.id
        ).length <= 1;
      if (valid) {
        this.allocationForm.controls['collaborator'].setErrors(null);
        this.allocationForm.controls['project'].setErrors(null);
      } else {
        this.allocationForm.controls['collaborator'].setErrors({
          notUnique: true,
        });
        this.allocationForm.controls['project'].setErrors({
          notUnique: true,
        });
      }
    }
  }

  changeCollaborator(
    collaborator: ICollaboratorAllocation
  ): ICollaboratorAllocation[] {
    try {
      if (collaborator && collaborator.collaboratorSchedule) {
        // this.isOverallocate();
        this.collaboratorPeriod = new Period(
          collaborator.collaboratorSchedule.workingDays,
          collaborator.workingHours
        );

        this.collaboratorPeriod.initValues();
        this.imageSrc = collaborator?.imageURL;
        this.allocationForm.controls['allocationPeriod'].enable();
        // this.allocationForm.controls['allocationPeriod'].setValue(8);
      } else if (this.allocationForm.controls['collaborator'].dirty) {
        this.allocationForm.controls['collaborator'].setErrors({});
        this.imageSrc = undefined;
      }
    } catch (error) {
      console.log(error);
    }

    const name =
      typeof collaborator === 'string'
        ? collaborator
        : collaborator?.collaboratorName;
    return name
      ? this._filterCollaborator(name as string)
      : this.collaborators?.slice();
  }

  isOverHoursPeriod() {
    const collaborator = this.allocationForm.controls['collaborator'].value;
    const form = {
      collaboratorId: collaborator.collaboratorId,
      dateInitial: this.allocationForm.controls['startDate'].value,
      dateEnd: this.allocationForm.controls['endDate'].value,
      percentage: this.allocationForm.controls['allocationPeriod'].value,
      isPercentage: this.allocationForm.controls['isPercentage'].value,
      hours: this.allocationForm.controls['allocationPeriod'].value,
    };
    this._allocationService.postHoursPeriod(form).subscribe((response) => {
      this.hoursSelected = response.result;
    });
  }

  isOverallocate() {
    const collaborator = this.allocationForm.controls['collaborator'].value;
    const isReservation = this.allocationForm.controls['isReservation'].value;
    const isAbsence = this.allocationForm.controls['isAbsence'].value;
    const overAllocated: IOverallocate = {
      collaboratorId: collaborator.collaboratorId,
      startDate: this.allocationForm.controls['startDate'].value,
      endDate: this.allocationForm.controls['endDate'].value,
      percentage: Number(
        this.allocationForm.controls['allocationPeriod'].value
      ),
      allocationId: this.allocation.id!,
    };
    if (isReservation || isAbsence) {
      const client = this.allocationForm.controls['client'].value;
      if (client && client.id) {
        overAllocated.clientId = client.id;
      }
    } else if (this.allocationForm.controls['project'].value) {
      const project = this.allocationForm.controls['project'].value;
      if (project && project.id) {
        overAllocated.projectId = project.id;
      }
    }

    this._subs.add(
      this._allocationService
        .postOverallocate(overAllocated)
        .subscribe((response) => {
          this.overAllocateMessage = '';

          if (response) {
            if (response.existingAllocation === true) {
              this.overAllocate = response.existingAllocation;
              this.overAllocateMessage = response.existingAllocationMessage;
              isReservation
                ? this.allocationForm.controls['client'].setErrors({})
                : this.allocationForm.controls['project'].setErrors({});
            } else {
              this.overAllocate = response.overallocated;
              this.overAbsence = response.absence;
              this.overAllocateMessage = response.message;
            }
          } else {
            this.overAllocate = false;
            this.overAllocateMessage = '';
          }
          this.allocation.overAllocation! = !response
            ? false
            : this.overAllocate;
          this.allocation.overAbsence! = !response ? false : this.overAbsence;
          this.overAbsence = response.absence;
        })
    );
    this.isOverHoursPeriod();
  }

  changeProject(project: IProject): IProject[] {
    try {
      if (project && project.projectInitiation) {
        this.minDate = this.fillDate(
          this.checkCurrentDay(project.projectInitiation.slice(0, -9))
        );
        this.maxDate = project.projectConclusion.slice(0, -9);
      } else if (this.allocationForm.controls['project'].dirty) {
        this.allocationForm.controls['project'].setErrors({});
      }
    } catch (error) {
      console.log(error);
    }

    const name = typeof project === 'string' ? project : project?.name;
    return name ? this._filterProject(name as string) : this.projects?.slice();
  }

  fillDate(DateString: string) {
    const date = new Date(DateString);
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();

    if (date.getDate() < 10) day = day.substr(0, 0) + '0' + day.substr(0);
    if (date.getMonth() < 9) month = month.substr(0, 0) + '0' + month.substr(0);

    return `${date.getFullYear()}-${month}-${day}`;
  }

  checkCurrentDay(date: string): string {
    const currentDate = new Date();
    const projectDate = new Date(date);
    let dateFormat;

    if (projectDate > currentDate) {
      dateFormat = projectDate;
    } else {
      currentDate.setDate(currentDate.getDate() - 10);
      dateFormat = currentDate;
    }
    return dateFormat.toDateString();
  }

  onCheckboxChangeReservation(event:any = null): void {
    event?.source?.value === '2' && this.allocationForm.controls['isAbsence'].value === true?
    this.allocationForm.controls['isReservation'].setValue(false):
    this.allocationForm.controls['isAbsence'].setValue(false);
    
    const isReservation = this.allocationForm.controls['isReservation'].value;
    const isAbsence = this.allocationForm.controls['isAbsence'].value;
    console.log(event?.source?.value, isReservation, isAbsence)
    if (isReservation || isAbsence) {
      this.isReservationOrAbsence = true;
      this.allocationForm.controls['client'].reset();
      this.allocationForm.controls['client'].setValidators([
        Validators.required,
      ]);
      this.allocationForm.controls['client'].updateValueAndValidity();
      this.allocationForm.controls['observation'].reset();
      this.allocationForm.controls['observation'].setValidators([
        Validators.required,
      ]);
      this.allocationForm.controls['observation'].updateValueAndValidity();
      this.allocationForm.controls['project'].reset();
      this.allocationForm.controls['project'].clearValidators();
      this.allocationForm.controls['project'].updateValueAndValidity();

      if (isAbsence) {
        
        this.allocationForm.controls['isPercentage'].setValue(true);
        this.allocationForm.controls['allocationPeriod'].setValue(100);
        this.suffixPeriod = '%';
        this.allocationForm.controls['isPercentage'].disable();
        this.allocationForm.controls['allocationPeriod'].disable();
        this.isAbsence = true;
      } else {
        this.allocationForm.controls['isAbsence'].setValue(false);
        this.allocationForm.controls['observation'].reset();
        this.allocationForm.controls['isPercentage'].updateValueAndValidity();
        this.allocationForm.controls['allocationPeriod'].enable();
        this.isAbsence = false;
      }

      if (isAbsence) {
        let client =
          this.clients.filter((client) => client.forAbsence)[0] ?? null;

        this.allocationForm.controls['client'].setValue(client);
        this.changeClient(client);
      }
    } else{
      this.isReservationOrAbsence = false;
      this.allocationForm.controls['isPercentage'].enable();
      this.allocationForm.controls['allocationPeriod'].enable();

      this.allocationForm.controls['observation'].reset();
      this.allocationForm.controls['observation'].clearValidators();
      this.allocationForm.controls['observation'].updateValueAndValidity();
      this.allocationForm.controls['client'].reset();
      this.allocationForm.controls['client'].clearValidators();
      this.allocationForm.controls['client'].updateValueAndValidity();
      this.allocationForm.controls['project'].setValidators([
        Validators.required,
      ]);
      this.allocationForm.controls['project'].updateValueAndValidity();
      this.allocationForm.controls['project'].updateValueAndValidity();
      this.isAbsence = false;
    }
     
   
  }

  private _filterCollaborator(name: string): ICollaboratorAllocation[] {
    const filterValue = name.toLowerCase();
    return this.collaborators?.filter((option) =>
      option.collaboratorName.toLowerCase().includes(filterValue)
    );
  }

  private _filterClient(name: string): IClient[] {
    const filterValue = name.toLowerCase();
    return this.clients?.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filterProject(name: string): IProject[] {
    const filterValue = name.toLowerCase();
    return this.projects?.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  displayFnCollaborator(collaborator: ICollaboratorAllocation): string {
    return collaborator && collaborator.collaboratorName
      ? collaborator.collaboratorName
      : '';
  }

  displayFnClient(selected: IClient): string {
    return selected && selected.name ? selected.name : '';
  }
  displayFnProject(selected: IProject): string {
    return selected && selected.name
      ? selected?.projectPIP + '-' + selected.name
      : '';
  }
  emitValidForm(formValid: boolean): void {
    this.formStatusValid.emit(formValid);
  }
  onSubmitAllocation(callOverallocate: boolean = true): void {
    if (callOverallocate) this.isOverallocate();
    const data = this.allocationForm.value;

    if (this.isAbsence === true) {
      data.isPercentage = true;
      data.allocationPeriod = 100;
    }

    this.submitEmitter.emit({ data: data });
  }

  removeAllocation(): void {
    this.removeEmitter.emit(true);
  }

  checkObservationChange(): boolean {
    let newValueObservation = this.allocationForm.controls['observation'].value;

    let validate = this.observationValue !== newValueObservation;

    this.observationValue = newValueObservation;

    return validate;
  }

  checkFormValidateWithoutObservation(): boolean {
    let isReservation = this.allocationForm.controls['isReservation'].value;

    if (!isReservation) return false;

    let isObservationValid = false;
    let controlsInvalid = [];
    let controls = this.allocationForm.controls;

    for (const key in controls) {
      if (controls[key].invalid) {
        controlsInvalid.push(key);
      }
    }

    isObservationValid =
      controlsInvalid.includes('observation') && controlsInvalid.length === 1;

    return isObservationValid;
  }

  findCollaboratorById(collaboratorId: string): ICollaboratorAllocation {
    return this.collaborators.find(
      (collaborator) => collaborator.collaboratorId === collaboratorId
    )!;
  }

  setCollaboratorAttribute(collaborator: ICollaboratorAllocation): void {
    if (collaborator) {
      this.jobFunction = collaborator.jobFunction?.description;
      this.skills =
        collaborator.collaboratorSkills &&
        collaborator.collaboratorSkills?.length > 0
          ? collaborator.collaboratorSkills
              .map((skill) => skill.skills.description)
              .join(', ')
          : '';
      this.tower = collaborator.tower?.description;
      this.leader = collaborator.collaboratorLeader?.fullName;
      this.seniority = collaborator.seniority?.description;
    }
  }
}
