import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { environment } from 'src/environments/environment.development';
import {
  IManageAccessFilter,
  IUpdateAccessProfile,
} from '../interface/manage-access';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  endpoint: string = 'AccessProfile';

  constructor(public http: HttpClient) {}

  getProfile(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(map((response: IApiResponse) => response.result));
  }

  postProfile(profileType: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.post<any>(url, profileType);
  }

  putProfile(profileType: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.put<any>(url, profileType);
  }

  inactivateProfile(idprofile?: string): Observable<any> {
    const path = 'inactivate';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}/${idprofile}`;
    return this.http.delete<any>(url);
  }

  getCollaboratorsWithAccessProfile(
    filter: IManageAccessFilter
  ): Observable<any> {
    const path = 'ListByFiltersAsync';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    return this.http.post<any>(url, filter);
  }

  updateCollaboratorWithAccessProfile(
    data: IUpdateAccessProfile
  ): Observable<any> {
    const path = 'CollaboratorProfile';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    return this.http.put<any>(url, data);
  }
}
