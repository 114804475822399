import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISkillType } from '../../interface/skill-type.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { SkillService } from '../../repository/skill.service';
import { MessageService } from 'src/app/core/services/message.service';
import { SkillTypeService } from '../../repository/skill-type.service';
import { StringUtil } from 'src/app/shared/utils/string';

@Component({
  selector: 'app-skill-type-manage-modal',
  templateUrl: './skill-type-manage-modal.component.html',
  styles: [],
})
export class SkillTypeManageModalComponent implements OnInit, OnDestroy{
  skillTypeForm!: FormGroup;
  private _subs = new SubSink();
  private _id: string | undefined;
  isEdit: boolean = false;
  constructor(
    private _fb: FormBuilder,
    private _skillTypeService: SkillTypeService,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<SkillTypeManageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.createFormGroup();

    try {
      this._id = this.data.id;
      this.isEdit = true;
      this.skillTypeForm.patchValue({
        description: this.data.description,
        id: this.data.id,
      })
    } catch (error) {
      this.isEdit = false;
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  createFormGroup() {
    this.skillTypeForm = this._fb.group({
      description: ['', Validators.required],
      id: ''
    });
  }

  onSubmitSkillType(){
    const newSkillType = this.skillTypeForm.value;
    newSkillType.description = StringUtil.capitalize(newSkillType.description ?? '');

    if(!this.isEdit){
      delete newSkillType.id;
      this._subs.add(
        this._skillTypeService
          .postSkillType(newSkillType)
          .subscribe((response) => {
            if (response.success === true) {
              this._messageService.openSuccessMessage(
                'Cadastro realizado com sucesso.'
              );
            } else if (response.errors.length > 0) {
              this._messageService.openErrorMessage(response.errors);
            }
           this.close({reload: true});
          })
      );
    } else{
      this._subs.add(
        this._skillTypeService
          .putSkillType(newSkillType)
          .subscribe((response) => {
            if (response.success === true) {
              this._messageService.openSuccessMessage(
                'Cadastro alterado com sucesso.'
              );
            } else if (response.errors.length > 0) {
              this._messageService.openErrorMessage(response.errors);
            }
           this.close({reload: true});
          })
      );
    }

  }


  close(emit = { reload: false }): void {
    this.dialogRef.close(emit);
  }
}
