import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllocationComponent } from './pages/container/allocation/allocation.component';
import { ManageAllocationComponent } from './pages/container/manage-allocation/manage-allocation.component';
import { ViewAllocationComponent } from './pages/container/view-allocation/view-allocation.component';
import { PermissionGuard } from 'src/app/core/guards/permission.guard';

const routes: Routes = [
  {
    path: 'alocacao',
    redirectTo: 'alocacao/colaboradores',
  },
  {
    path: 'alocacao/colaboradores',
    component: AllocationComponent,
    canActivate:[PermissionGuard],
    data: {
      process: 'Allocation',
      permission: 'Read',
    }
  },
  {
    path: 'alocacao/projetos',
    component: AllocationComponent,
    canActivate:[PermissionGuard],
    data: {
      process: 'Allocation',
      permission: 'Read',
    }
  },
  {
    path: 'alocacao/emprestimos',
    component: AllocationComponent,
    canActivate:[PermissionGuard],
    data: {
      process: 'Allocation',
      permission: 'Read',
    }
  },
  {
    path: 'alocacao/gerenciar-alocacao',
    component: ManageAllocationComponent,
    canActivate:[PermissionGuard],
    data: {
      process: 'Allocation',
      permission: 'Create/Update',
    }
  },
  {
    path: 'alocacao/gerenciar-alocacao/:path',
    component: ManageAllocationComponent,
    canActivate:[PermissionGuard],
    data: {
      process: 'Allocation',
      permission: 'Create/Update',
    }
  },
  {
    path: 'alocacao/gerenciar-alocacao/:path/:collaboratorId/:allocable/:projectId/:viewByProject',
    component: ManageAllocationComponent,
    canActivate:[PermissionGuard],
    data: {
      process: 'Allocation',
      permission: 'Create/Update',
    }
  },
  {
    path: 'alocacao/gerenciar-alocacao/:path/:collaboratorId/:allocable',
    component: ManageAllocationComponent,
    canActivate:[PermissionGuard],
    data: {
      process: 'Allocation',
      permission: 'Create/Update',
    }
  },
  {
    path: 'alocacao/gerenciar-alocacao/:path/:projectId',
    component: ManageAllocationComponent,
    canActivate:[PermissionGuard],
    data: {
      process: 'Allocation',
      permission: 'Create/Update',
    }
  },
  {
    path: 'alocacao/visualizar-alocacao/:projectId',
    component: ViewAllocationComponent,
    canActivate:[PermissionGuard],
    data: {
      process: 'Allocation',
      permission: 'Read',
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AllocationRoutingModule {}
