import { Injectable } from '@angular/core';
import { IProjectFilter } from 'src/app/pages/clients-projects/interface/datasource-project.interface';

type chip = {
  controlName: string;
  description: string;
};

@Injectable({
  providedIn: 'root'
})
export class ProjectFilterService {
  private filter: IProjectFilter = {
    searchString: null,
    projectStatusId: null,
    projectPIP: null,
    clientId: null,
    projectInitiation: null,
    projectConclusion: null,
    page: 1,
    take: 20,
    orderByParam: { orderBy: 1, asc: true },
  };

  private chipsList: Array<chip> = [];

  constructor() { }

  setFilter(filter: IProjectFilter) {
    this.filter = filter;
  }

  getFilter() {
    return this.filter;
  }

  setChipsList(chipsList: Array<chip>) {
    this.chipsList = [...chipsList];
  }

  getChipsList() {
    return this.chipsList;
  }

  resetChipsList() {
    this.chipsList = [];
  }

  resetFilter() {
    this.filter = {
      searchString: null,
      projectStatusId: null,
      projectPIP: null,
      clientId: null,
      projectInitiation: null,
      projectConclusion: null,
      page: 1,
      take: 20,
      orderByParam: { orderBy: 1, asc: true },
    };
  }
}
