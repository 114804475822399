import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MessageDialogComponent } from 'src/app/shared/components/message-dialog/message-dialog.component';
import { ModalErrorComponent } from 'src/app/shared/components/modal-error/modal-error.component';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(public dialog: MatDialog) {}

  openSuccessMessage(message: string, duration?: number) {
    this.openMessageDialog(message, 'success', duration);
  }

  openSuccessMessageDefault(message: string, duration?: number) {
    return this.openMessageDialog(message, 'success', duration);
  }

  openErrorMessage(errors: Array<string>, duration?: number) {
    if (errors.length > 0) {
      errors.map((errorMessage: string) =>
        this.openMessageDialog(errorMessage, 'error', duration)
      );
    }
  }
  openErrorMessageDefault(error: string, duration?: number): Observable<any> {
    return this.openMessageDialog(error, 'error', duration);
  }

  private openMessageDialog(
    message: string,
    type: 'success' | 'error' | 'warn' | 'info',
    duration?: number
  ) {
    let openDialog = this.dialog.open(MessageDialogComponent, {
      data: { message: message, type: type, duration: duration },
    });

    return openDialog.afterClosed();
  }
  openModalMessageError(title: string, message: string, duration?: number) {
    this.modalMessageDialog(title, message, undefined, duration);
  }

  openModalMessageSuccses(
    title: string,
    message: string,
    type?: string,
    duration?: number
  ) {
    this.modalMessageDialog(title, message, type, duration);
  }

  private modalMessageDialog(
    title: string,
    message: string,
    type?: 'success' | 'error' | 'warn' | 'info' | string,
    duration?: number
  ) {
    this.dialog.open(ModalErrorComponent, {
      data: { title: title, message: message, type: type, duration: duration },
    });
    if (duration && duration > 0) {
      setTimeout(() => {
        this.dialog.closeAll();
      }, duration);
    }
  }
}
