import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'src/app/core/services/message.service';
import { IFilterCollaboratorLoan, ILoan,
} from 'src/app/pages/allocation/interface/filter-collaborator-loan';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import { ConfirmDialogLoanComponent } from 'src/app/shared/components/confirm-dialog-loan/confirm-dialog-loan.component';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-lending-view',
  templateUrl: './lending-view.component.html',
  styleUrls: [],
})
export class LendingViewComponent implements OnInit {
  private _subs = new SubSink();
  searchByNameCtrl: FormControl = new FormControl();
  loans: ILoan[] = [];
  filter: IFilterCollaboratorLoan = {
    searchString: '',
  };

  constructor(
    public allocation: AllocationService,
    public dialog: MatDialog,
    private _messageService: MessageService
  ) {}

  ngOnInit() {
    this.getAllLoan();
  }

  searchBy() {
    this.filter.searchString = this.searchByNameCtrl.value;
    this.getAllLoan();
  }

  clearFilter() {
    this.searchByNameCtrl.reset();
    this.filter.searchString = null;
    this.getAllLoan();
  }

  refusLoan(item: ILoan) {
    let message: string = '';
    let buttonTxt: string = '';
    const messageInativacao = 'Recusar empréstimo';
    message = messageInativacao;
    buttonTxt = 'RECUSAR';
    const dialogRefs = this.dialog.open(ConfirmDialogLoanComponent, {
      data: { message: message, buttonTxt: 'RECUSAR', confirm: false },
      panelClass: 'modal-dialog-close-type',
    });
    dialogRefs.afterClosed().subscribe((result) => {
      if (result) {
        const { reason } = result;
        this._subs.add(
          this.allocation
            .refuseLoan(item.allocationId, reason)
            .subscribe((response) => {
              if (response.success === true) {
                this._messageService.openSuccessMessage('Empréstimo recusado');
                this.getAllLoan();
              } else if (response.errors.length > 0) {
                this._messageService.openErrorMessage(response.errors);
                this.getAllLoan();
              }
            })
        );
      }
    });
  }

  getAllLoan() {
    this._subs.add(
      this.allocation.getAllLoan(this.filter).subscribe((response) => {
        this.loans = response.result;
        this.loans.forEach((emprestimo) => {
          emprestimo.startDateLoan = this.formatDate(emprestimo.startDateLoan);
          emprestimo.endDateLoan = this.formatDate(emprestimo.endDateLoan);
        });
      })
    );
  }

  formatDate(date: string): string {
    const dateFormat = date.split('T')[0].split('-');
    const formattedDate = `${dateFormat[2]}/${dateFormat[1]}/${dateFormat[0]}`;
    return formattedDate;
  }
}
