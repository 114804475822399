<div class="row page-header space-between p-lg">
  <div class="col col-sm-8 page-title">
    <h2>{{ title }}</h2>
  </div>
  <div *ngIf="btnText" class="col col-sm-4 align-end justify-center page-title">
    <button (click)="onClickEmmiter()" class="btn secondary btn--width-sm m-sm">
      {{ btnText }}
    </button>
  </div>
</div>
