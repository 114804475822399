<div class="content">
  <div class="flex align-center column-gap-16 m-md">
    <div class="profile-title">
      <h1>{{ collaborator.fullName }}</h1>
    </div>
    <div class="flex-1">
      <div class="border-division"></div>
    </div>
  </div>

  <mat-card class="m-xl editable-card" [ngClass]="{ 'edit-active': isEditing }">
    <mat-card-content>
      <div class="row">
        <div class="col-profile-photo">
          <div class="profile-photo--container">
            <ng-container *ngIf="croppedImage">
              <img class="photo" [src]="croppedImage | safeResourceUrl" />
            </ng-container>
            <mat-icon class="icon--photo" *ngIf="!croppedImage"
              >person
            </mat-icon>
            <div style="max-width: 356px; opacity: 0">
              <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                alignImage="center"
                [aspectRatio]="3 / 4"
                format="png"
                [resizeToWidth]="356"
                (imageCropped)="imageCropped($event)"
              ></image-cropper>
            </div>
            <div
              *ngIf="isUserCollaborator"
              class="overlay"
              (click)="fileUpload.click()"
            >
              <div class="text"><mat-icon>upload</mat-icon> Carregar foto</div>
            </div>
          </div>
          <input
            type="file"
            #fileUpload
            id="fileUpload"
            name="fileUpload"
            multiple="multiple"
            (change)="fileChangeEvent($event)"
            accept="image/*"
            style="display: none"
          />
        </div>
        <div
          class="col editabled-card--content"
        >
          <mat-tab-group>
            <form [formGroup]="profileForm">
              <ng-template
                permissions
                [role]="EPermissionType.Read"
                [process]="
                  isUserCollaborator
                    ? EProcess.Profile
                    : EProcess.CollaboratorPersonalData
                "
              >
                <mat-tab label="Perfil">
                  <div class="col min-content">
                    <h2 class="p-left-lg">Dados Pessoais</h2>
                  </div>

                  <div class="row divisor --three p-vertical-md">
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Nome completo</mat-label>
                      <input
                        type="text"
                        maxlength="100"
                        matInput
                        formControlName="fullName"
                        required
                      />
                    </mat-form-field>
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Apelido (opcional)</mat-label>
                      <input
                        maxlength="20"
                        type="text"
                        matInput
                        formControlName="nickname"
                      />
                    </mat-form-field>
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Data de Nascimento</mat-label>
                      <input
                        [matDatepicker]="pickerBirthday"
                        matInput
                        formControlName="birthday"
                        required
                      />
                      <mat-datepicker-toggle
                        *ngIf="!profileForm.controls['birthday'].disabled"
                        matSuffix
                        [for]="pickerBirthday"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerBirthday></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="row divisor --three p-vertical-md">
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Estado Civil</mat-label>
                      <mat-select formControlName="maritalStatus">
                        <mat-option
                          *ngFor="let enum of maritalStateOptions | keyvalue"
                          [value]="enum.key"
                        >
                          {{ enum.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Filhos</mat-label>
                      <mat-select formControlName="children">
                        <mat-option [value]="true">Sim</mat-option>
                        <mat-option [value]="false">Não</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="role === eRoleUser.GG">
                      <mat-form-field
                        class="input-outline"
                        appearance="outline"
                        floatLabel="always"
                      >
                        <mat-label>Gênero</mat-label>
                        <mat-select formControlName="gender">
                          <mat-option value="0">Masculino</mat-option>
                          <mat-option value="1">Feminino</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        role === eRoleUser.GG ||
                        role === eRoleUser.ADM ||
                        isUserCollaborator
                      "
                    >
                      <mat-form-field
                        class="input-outline"
                        appearance="outline"
                        floatLabel="always"
                      >
                        <mat-label>Email pessoal</mat-label>
                        <input
                          type="email"
                          matInput
                          formControlName="personalEmail"
                        />
                      </mat-form-field>
                    </ng-container>
                  </div>

                  <ng-template
                    permissions
                    [role]="EPermissionType.CreateUpdate"
                    [process]="
                      isUserCollaborator
                        ? EProcess.Profile
                        : EProcess.Permission
                    "
                  >
                    <div class="row divisor --three p-vertical-md">
                      <mat-form-field
                        class="input-outline"
                        appearance="outline"
                        floatLabel="always"
                        disabled="true"
                      >
                        <mat-label>Perfil</mat-label>
                        <mat-select formControlName="accessProfileId">
                          <ng-container
                            *ngFor="let option of options?.accessProfile"
                          >
                            <mat-option
                              *ngIf="
                                option.id ===
                                profileForm.controls['accessProfileId'].value
                              "
                              [value]="option.id"
                            >
                              {{ option.name }}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </ng-template>
                </mat-tab>
              </ng-template>
              <ng-template
                permissions
                [role]="EPermissionType.Read"
                [process]="
                  isUserCollaborator
                    ? EProcess.Profile
                    : EProcess.CollaboratorContractualinformation
                "
              >
                <mat-tab label="Dados Pessoais">
                  <div class="col min-content">
                    <h2 class="p-left-lg">Info. Contratuais</h2>
                  </div>
                  <div class="row divisor --three p-vertical-md">
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Admissão</mat-label>
                      <input
                        [matDatepicker]="pickerAdmission"
                        matInput
                        [max]="maxAdmissionDate"
                        formControlName="admission"
                        required
                      />
                      <mat-datepicker-toggle
                        *ngIf="!profileForm.controls['admission'].disabled"
                        matSuffix
                        [for]="pickerAdmission"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerAdmission></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Tipo de Contratação</mat-label>
                      <mat-select formControlName="contractTypeId">
                        <mat-option
                          *ngFor="let option of options?.contractTypes"
                          [value]="option.id"
                        >
                          {{ option.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Dias de trabalho</mat-label>
                      <mat-select formControlName="daysOfWeek" multiple>
                        <mat-select-trigger>
                          <span *ngIf="allSelected.selected"> Dias úteis </span>
                          <span *ngIf="!allSelected.selected">
                            {{ profileForm.controls["daysOfWeek"].value }}
                          </span>
                        </mat-select-trigger>
                        <mat-option
                          #allSelected
                          (click)="toggleAllWorkingDaysSelection()"
                          [value]="0"
                          >Dias uteis
                        </mat-option>
                        <mat-option
                          class="checkbox"
                          *ngFor="
                            let enum of workingDaysSelectOptions | keyvalue
                          "
                          [value]="enum.value"
                          (click)="togglePerOne()"
                        >
                          {{ enum.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="row divisor --three p-vertical-md">
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Horas de trabalho diária</mat-label>
                      <input
                        type="text"
                        matInput
                        suffix="h"
                        mask="0"
                        [dropSpecialCharacters]="true"
                        thousandSeparator="."
                        formControlName="workingHours"
                        required
                      />
                    </mat-form-field>
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Torre</mat-label>
                      <mat-select
                        (selectionChange)="selectTower(true)"
                        formControlName="towerId"
                      >
                        <mat-option
                          *ngFor="let option of options?.towers"
                          [value]="option.id"
                        >
                          {{ option.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Equipe</mat-label>
                      <mat-select formControlName="teamId">
                        <mat-option
                          *ngFor="let option of options?.teams"
                          [value]="option.id"
                        >
                          {{ option.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="row divisor --three p-vertical-md">
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Líder do Colaborador</mat-label>
                      <mat-select formControlName="collaboratorLeaderId">
                        <mat-option
                          *ngFor="let option of options?.leaders"
                          [value]="option.id"
                        >
                          {{ option.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Função</mat-label>
                      <mat-select formControlName="jobFunctionId">
                        <mat-option
                          *ngFor="let option of options?.jobFunctions"
                          [value]="option.id"
                        >
                          {{ option.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field
                      class="input-outline"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>Senioridade</mat-label>
                      <mat-select formControlName="seniorityId">
                        <mat-option
                          *ngFor="let option of options?.senioritys"
                          [value]="option.id"
                        >
                          {{ option.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </mat-tab>
              </ng-template>
              <ng-template
                permissions
                [role]="EPermissionType.Read"
                [process]="
                  isUserCollaborator
                    ? EProcess.Profile
                    : EProcess.CollaboratorAddress
                "
              >
                <mat-tab label="Endereço">
                  <div class="col min-content">
                    <h2 class="p-left-lg">Endereço</h2>
                  </div>
                  <ng-container formArrayName="addresses">
                    <ng-container
                      *ngFor="let address of addresses.controls; let i = index"
                    >
                      <div class="row" *ngIf="address.value.principal === true">
                        <h3>Endereço principal</h3>
                      </div>
                      <div
                        [formGroupName]="i"
                        [ngClass]="{ 'border-division': i === 0 }"
                      >
                        <div class="row divisor --three p-vertical-md">
                          <mat-form-field
                            class="input-outline"
                            appearance="outline"
                            floatLabel="always"
                          >
                            <mat-label>Identificar endereço</mat-label>
                            <input
                              maxlength="100"
                              type="text"
                              placeholder="Ex. Casa"
                              matInput
                              formControlName="addressIdentity"
                              required
                            />
                          </mat-form-field>
                          <mat-form-field
                            class="input-outline"
                            appearance="outline"
                            floatLabel="always"
                          >
                            <mat-label>CEP</mat-label>
                            <input
                              type="text"
                              placeholder="00000-000"
                              matInput
                              formControlName="zipCode"
                              mask="00000-000"
                              required
                              (change)="
                                getAddressByZipCode(
                                  addresses.at(i).value.zipCode,
                                  i
                                )
                              "
                            />
                          </mat-form-field>
                          <mat-form-field
                            class="input-outline"
                            appearance="outline"
                            floatLabel="always"
                          >
                            <mat-label>Logradouro</mat-label>
                            <input
                              type="text"
                              maxlength="30"
                              placeholder="Rua"
                              matInput
                              formControlName="publicArea"
                              required
                            />
                          </mat-form-field>
                          <mat-checkbox
                            class="checkbox"
                            (change)="selectPrincipal(i)"
                            formControlName="principal"
                          >
                            Endereço principal
                          </mat-checkbox>
                        </div>
                        <div class="row divisor --three p-vertical-md">
                          <mat-form-field
                            class="input-outline input--width-xl"
                            appearance="outline"
                            floatLabel="always"
                          >
                            <mat-label>Endereço</mat-label>
                            <input
                              type="text"
                              maxlength="100"
                              matInput
                              formControlName="localAddress"
                              required
                            />
                          </mat-form-field>
                          <mat-form-field
                            class="input-outline input--width-xs"
                            appearance="outline"
                            floatLabel="always"
                          >
                            <mat-label>Número</mat-label>
                            <input
                              type="text"
                              maxlength="10"
                              matInput
                              formControlName="number"
                              required
                            />
                          </mat-form-field>
                          <mat-form-field
                            class="input-outline input--width-xxs-secondary"
                            appearance="outline"
                            floatLabel="always"
                          >
                            <mat-label>Complemento</mat-label>
                            <input
                              type="text"
                              maxlength="50"
                              matInput
                              formControlName="complement"
                            />
                          </mat-form-field>
                        </div>
                        <div class="row divisor --three p-vertical-md">
                          <mat-form-field
                            class="input-outline"
                            appearance="outline"
                            floatLabel="always"
                          >
                            <mat-label>Bairro</mat-label>
                            <input
                              type="text"
                              maxlength="50"
                              matInput
                              formControlName="neighborhood"
                              required
                            />
                          </mat-form-field>
                          <mat-form-field
                            class="input-outline"
                            appearance="outline"
                            floatLabel="always"
                          >
                            <mat-label>Cidade</mat-label>
                            <input
                              type="text"
                              maxlength="60"
                              matInput
                              formControlName="city"
                              required
                            />
                          </mat-form-field>
                          <mat-form-field
                            class="input-outline"
                            appearance="outline"
                            floatLabel="always"
                          >
                            <mat-label>Estado</mat-label>
                            <input
                              type="text"
                              maxlength="30"
                              matInput
                              formControlName="state"
                              required
                            />
                          </mat-form-field>
                        </div>
                        <div class="row divisor --three p-vertical-md">
                          <mat-form-field
                            class="input-outline"
                            appearance="outline"
                            floatLabel="always"
                          >
                            <mat-label>País</mat-label>
                            <input
                              type="text"
                              maxlength="40"
                              matInput
                              formControlName="country"
                              required
                            />
                          </mat-form-field>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-template
                    permissions
                    [role]="EPermissionType.CreateUpdate"
                    [process]="
                      isUserCollaborator
                        ? EProcess.Profile
                        : EProcess.CollaboratorAddress
                    "
                  >
                    <div
                      class="row m-bottom-lg"
                      *ngIf="addresses.length === 1 && isEditing"
                    >
                      <button
                        (click)="addFormControlAddress()"
                        class="btn-no-border"
                      >
                        <mat-icon>add</mat-icon>
                        Add endereço
                      </button>
                    </div>
                    <div
                      class="row m-bottom-lg"
                      *ngIf="addresses.length === 2 && isEditing"
                    >
                      <button (click)="deleteAddress()" class="btn-no-border">
                        <mat-icon>delete</mat-icon>
                        Remover endereço
                      </button>
                    </div>
                  </ng-template>
                </mat-tab>
              </ng-template>
            </form>
          </mat-tab-group>
          <div *ngIf="isEditing" class="row row--position-bottom justify-end">
            <button
              class="btn btn--width-xxs m-horizontal-lg secondary"
              type="button"
              (click)="resetForm()"
            >
              Cancelar
            </button>
            <button
              class="btn btn--width-xxs primary"
              type="submit"
              [disabled]="profileForm.invalid"
              (click)="onSubmitCollaborator()"
            >
              Salvar
            </button>
          </div>
          <div *ngIf="isEditing" class="row row--position-top justify-end">
            <button
              class="btn-no-border-custom"
              type="button"
              (click)="collaboratorAdvancedEdition()"
            >
              Configurações avançadas
            </button>
          </div>
          <ng-template
            permissions
            [role]="EPermissionType.CreateUpdate"
            [process]="
              isUserCollaborator
                ? EProcess.Profile
                : [
                    EProcess.CollaboratorAddress,
                    EProcess.CollaboratorContractualinformation,
                    EProcess.CollaboratorPersonalData,
                    EProcess.CollaboratorSkills
                  ]
            "
          >
            <ng-container *ngIf="!collaboratorInative">
              <div
                *ngIf="isEditing === false"
                class="btn-edit-card"
                (click)="enableForm()"
              >
                <button class="btn-no-border-custom" (click)="enableForm()">
                  <mat-icon
                    ><img src="./assets/svg/edit-black.svg" alt=""
                  /></mat-icon>
                </button>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
