import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import { AccountRoutingModule } from './account-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AccountService } from './repository/account.service';
import { AccountGuard } from 'src/app/core/guards/account.guard';
import { LoginGuard } from 'src/app/core/guards/login.guard';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AccountContainerComponent } from './pages/container/account-container/account-container.component';
import { VerifyEmailComponent } from './pages/forgot-password/verify-email/verify-email.component';
import { InsertCodeComponent } from './pages/forgot-password/insert-code/insert-code.component';
import { ResetPasswordComponent } from './pages/forgot-password/reset-password/reset-password.component';
import { MatIconModule } from '@angular/material/icon';
import { LogVerifyService } from './repository/log-verify.service';
import { ComponentsModule } from './../../shared/components/components.module';



@NgModule({
  declarations: [
    LoginComponent,
    AccountContainerComponent,
    VerifyEmailComponent,
    InsertCodeComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatIconModule,
    ComponentsModule
  ],
  providers: [
    AccountService,
    AccountGuard,
    LoginGuard,
    LogVerifyService
],
})
export class AccountModule { }
