export const COLUMNS_ALLOCATION_COLLABORATOR = {
  select: 'select',
  info: 'info',
  fullName: 'Colaborador',
  jobFunction: 'Função',
  seniority: 'Senioridade',
  leader: 'Liderança',
  absenteeCollaborator:'',
  hours: 'Horas alocadas',
  edition: 'edition'
};
