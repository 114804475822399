import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-container',
  templateUrl: './account-container.component.html',
  styles: [
  ]
})
export class AccountContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
