import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControlStatus,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable, Subscription, debounceTime, map, startWith } from 'rxjs';
import { MessageService } from 'src/app/core/services/message.service';
import {
  ICollaboratorAllocation,
  IOverallocate,
} from 'src/app/pages/allocation/interface/collaborator-allocation.interface';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import { IClient } from 'src/app/pages/clients-projects/interface/client.interface';
import { IProject } from 'src/app/pages/clients-projects/interface/project.interface';
import { IGenericData } from 'src/app/pages/collaborator/interface/options-collaborator.interface';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Allocation } from 'src/app/shared/models/allocation.model';
import { Period } from 'src/app/shared/models/period.model';
import { SubSink } from 'subsink';
import { CHECKBOX_OPTIONS } from '../form-collaborator-allocation/checkbox-option.const';
import { Router } from '@angular/router';

@Component({
  selector: 'app-form-allocation-template',
  templateUrl: './form-allocation-template.component.html',
  styles: [],
})
export class FormAllocationTemplateComponent implements OnInit, OnDestroy {
  overAllocate: boolean = false;
  overAbsence: boolean = false;
  @Input() isRemovable: boolean = true;
  @Input() allocationByCollaborator: boolean = true;

  overAllocateMessage!: string;
  allocationForm!: FormGroup;
  overMessage!: string;

  filteredOptionsProject!: Observable<IProject[]>;
  projects: IProject[] = [];
  projectManager: string = '';
  status: string = '';
  @Input() minDate!: string;
  @Input() maxDate!: string;
  suffixPeriod: 'h' | '%' | '' = '';
  hoursSelected: string = '';

  filteredOptionsClients!: Observable<IClient[]>;
  clients: IClient[] = [];

  filteredOptionsJobFunction!: Observable<IGenericData[]>;
  jobfunctions: IGenericData[] = [];

  filteredOptionsCollaborator!: Observable<ICollaboratorAllocation[]>;
  @Input() collaboratorIdSelected!: Array<string>;
  collaboratorPeriod!: Period;
  @Input() collaborators: ICollaboratorAllocation[] = [];
  @Input() collaborator!: ICollaboratorAllocation;
  imageSrc?: string;

  jobFunctionCollaborator!: string;
  skills!: string;
  tower!: string;
  leader!: string;
  seniority!: string;

  defaultIdCollaborator: string = '00000000-0000-0000-0000-000000000000';
  jobFunctionIdSelected!: string;

  observationValue!: string;
  validStatus: boolean = false;

  occurrence = CHECKBOX_OPTIONS;
  isReservationOrAbsence: boolean = false;
  textAreaPlaceholderPreSale: string =
    'Escreva suas observações sobre esta pré-venda, ou seja, o porquê da ação. Ex: treinamentos, workshops, squad, projetos, etc.';
  textAreaPlaceholderAbsent: string =
    'Escreva o motivo da ausência do colaborador (férias, afastamento, licença...)';

  showAllocationWarning: boolean = false;
  textAllocationWarning: string = 'Confirme as alterações da alocação atual para que os cálculos de horas sejam mais precisos.';

  @Input() formEnabledList: Array<ITemplateForm> = new Array();
  @Input() allocation: Allocation = new Allocation();
  @Input() allocable!: boolean;
  editable: boolean = true;

  @Output() submitEmitter: EventEmitter<any> = new EventEmitter();
  @Output() removeEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() formStatusValid: EventEmitter<boolean> = new EventEmitter();

  changeOverallocationSubscription!: Subscription;

  private _subs = new SubSink();
  isReservation!: boolean;
  isAbsence!: boolean;
  isFirst: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private _allocationService: AllocationService,
    private _messageService: MessageService,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private _router: Router
  ) {
    this.createFormGroup();
  }

  getFormValidationErrors() {
    Object.keys(this.allocationForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors | null =
        this.allocationForm.controls[`${key}`].errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError]
          );
        });
      }
    });
  }

  ngOnInit(): void {
    this.formEnabledList = this.formEnabledList.sort(function (a, b) {
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      return 0;
    });
    this.formEnabledList.map((form) => {
      if (form.type === 'project') {
        this.enableForm(form.type);
        this.projects = form.list as IProject[];
        this.changeProjectOption();
      }
      if (form.type === 'client') {
        this.enableForm(form.type);
        this.clients = form.list as IClient[];
        this.changeClientOption();
      }
      if (form.type === 'collaborator') {
        this.collaborators = form.list as ICollaboratorAllocation[];
        this.changeCollaboratorOption();
      }
      if (form.type === 'jobFunction') {
        this.jobfunctions = form.list as IGenericData[];
        this.changeJobFunctionOption();
      }
    });

    if (this.allocation) {
      this.resetAllocationDate();

      if (
        (this.allocation.id &&
          this.allocation.id !== this.defaultIdCollaborator) ||
        this.allocable === false
      ) {
        this.editable = false;
        this.minDate = this.allocation.startDate;
      }
      this.setValue();
      this.changeOption();
    }

    if (this.maxDate && this.minDate) {
      if (this.allocation.id) {
        const checkDate = this.checkCurrentDay(this.minDate);
        this.minDate = this.fillDate(checkDate);
        this.allocationForm.controls['startDate'].clearValidators();
        this.allocationForm.controls['startDate'].updateValueAndValidity();
      } else {
        const checkDate = this.checkCurrentDay(this.minDate);
        this.minDate = this.fillDate(checkDate);
      }
    }

    this.allocationForm.statusChanges
      .pipe(debounceTime(400))
      .subscribe((res) => {
        let isAbsence = this.allocationForm.get('isAbsence')?.value;

        if(!isAbsence) {
          let observationChange = this.checkObservationChange();
          this.emitValidValuesForm(res, !observationChange);
        } else if(isAbsence) {
          this.emitValidValuesForm(res, false);
        }
        // this.getFormValidationErrors();
        if (!this.allocationForm.untouched)
          this.allocationForm.markAllAsTouched();
      });

    this.changeOverallocationSubscription =  this._allocationService.changeOverallocationEvent.subscribe((res) => {
      this.resetOverallocation();
    });
    this.isPercentageValidator();
    if(this.allocationForm.controls['allocationPeriod'].value && this.allocationForm.controls['collaborator'].value) {
      this.isOverHoursPeriod();
    }
  }

  ngOnDestroy(): void {
    if (this.changeOverallocationSubscription) {
      this.changeOverallocationSubscription.unsubscribe();
    }
  }

  isPercentageValidator() {
      const isPercentage = this.allocationForm.controls['isPercentage'].value;
       if (isPercentage === true) {
       this.allocationForm.controls['allocationPeriod'].setValidators([
          Validators.required,
          Validators.min(10),
        ]);
      } else {
        this.allocationForm.controls['allocationPeriod'].setValidators([
          Validators.required,
          Validators.min(1),
        ]);
      }
  }

  createFormGroup(): void {
    this.allocationForm = this._fb.group({
      collaborator: [''],
      project: [''],
      client: [''],
      projectResponsible: [''],
      projectStatus: [''],
      jobFunction: [''],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      allocationPeriod: [Validators.required],
      isReservation: [],
      observation: [''],
      isAbsence: [],
      isPercentage: [true],
      inactive: [''],
    });
  }

  setValue(): void {
    let project = this.projects.find(
      (project) => project.id === this.allocation.projectId
    );

    let client = this.clients.find(
      (client) => client.id === this.allocation.clientId
    );

    let collaborator = this.collaborators.find(
      (collaborator) =>
        collaborator.collaboratorId === this.allocation.collaboratorId
    );

    let jobFunction = this.jobfunctions.find(
      (jobFunction) => jobFunction.id === this.allocation.jobFunctionId
    );

    if (this.allocation.jobFunctionId) {
      this.allocationForm.controls['jobFunction'].setValue(jobFunction);
      this.jobFunctionIdSelected = this.allocation.jobFunctionId;
    }
    if (!collaborator && this.collaborator) collaborator = this.collaborator;
    if (this.allocation.isAbsence === true) {
      this.allocation.percentage = 100;
    }
    this.allocationForm.patchValue({
      allocationPeriod: this.allocation.isPercentage
        ? this.allocation.percentage
        : this.allocation.hours,
      isReservation: this.allocation.isReservation,
      isAbsence: this.allocation.isAbsence,
      project: project,
      client: client,
      collaborator: collaborator,
      observation: this.allocation.observation,
      hours: this.allocation.hours,
      isPercentage: this.allocation.isPercentage,
    });

    this.suffixPeriod = this.allocation.isPercentage ? '%' : 'h';

    if (this.allocation.startDate) {
      if (this.allocation.startDate.length > 10) {
        this.allocation.startDate = this.fillDate(this.allocation.startDate);
      }
      this.allocationForm.controls['startDate'].setValue(
        this.allocation.startDate
      );
    }
    if (this.allocation.endDate) {
      if (this.allocation.endDate.length > 10) {
        this.allocation.endDate = this.fillDate(this.allocation.endDate);
      }
      this.allocationForm.controls['endDate'].setValue(this.allocation.endDate);
    }

    if (collaborator) {
      this.changeCollaborator(collaborator);
    }
    if (
      (this.allocation.isReservation === true ||
        this.allocation.isAbsence === true) &&
      client
    ) {
      if (this.allocation.isReservation === true) this.isReservation = true;
      if (this.allocation.isAbsence === true) {
        this.isAbsence = true;
        this.allocationForm.controls['allocationPeriod'].disable();
      }
      this.isReservationOrAbsence = true;
      this.enableForm('client');
      this.disableForm('projectResponsible');
      this.disableForm('project');
      this.changeClient(client);
    }
    if (!this.allocable) this.allocationForm.controls['observation'].disable();
    if (project) {
      const checkDate = this.checkCurrentDay(project.projectInitiation);

      this.minDate = this.fillDate(checkDate);
      this.maxDate = this.fillDate(project.projectConclusion);
    }
    this.cdRef.detectChanges();
  }

  fillDate(DateString: string) {
    const date = new Date(DateString);
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();

    if (date.getDate() < 10) day = day.substr(0, 0) + '0' + day.substr(0);
    if (date.getMonth() < 9) month = month.substr(0, 0) + '0' + month.substr(0);

    return `${date.getFullYear()}-${month}-${day}`;
  }

  formatDate(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }

  checkCurrentDay(date: string): string {
    const currentDate = new Date();
    const projectDate = new Date(date);
    let dateFormat;

    if (projectDate > currentDate) {
      dateFormat = projectDate;
    } else {
      currentDate.setDate(currentDate.getDate() - 10);
      dateFormat = currentDate;
    }
    return dateFormat.toDateString();
  }

  enableForm(formName: string) {
    this.allocationForm.controls[formName].setValidators([Validators.required]);
    this.allocationForm.controls[formName].updateValueAndValidity();
  }

  disableForm(formName: string) {
    this.allocationForm.controls[formName].reset();
    this.allocationForm.controls[formName].clearValidators();
    this.allocationForm.controls[formName].updateValueAndValidity();
  }

  changeOption(): void {
    this.allocationForm.controls['allocationPeriod'].valueChanges.subscribe(
      (allocationPeriod: any) => {
        if (this.collaboratorPeriod) {
          this.collaboratorPeriod.calculatePeriod(
            this.allocationForm.controls['isPercentage'].value,
            allocationPeriod
          );
        }
      }
    );
  }

  changePeriod(event: any) {
    if (event.value === true) {
      this.suffixPeriod = '%';
      this.allocationForm.controls['allocationPeriod'].setValidators([
        Validators.required,
        Validators.min(10),
      ]);
    } else {
      this.allocationForm.controls['allocationPeriod'].setValidators([
        Validators.required,
        Validators.min(1),
      ]);
      this.suffixPeriod = 'h';
    }
    this.allocationForm.controls['isPercentage'].setValue(event.value);
    this.allocationForm.controls['allocationPeriod'].setValue(
      event.value ? this.allocation.percentage : this.allocation.hours
    );
  }

  emitValidValuesForm(res: FormControlStatus, callOverallocate: boolean = true): void {
    if(this.checkFormValidateWithoutObservation()) {
      this.emitValidForm(false);
      this.onSubmitAllocation(callOverallocate);
    } else if (res === 'INVALID') {
      this.emitValidForm(false);
    } else if (res === 'VALID') {
      this.emitValidForm(true);
      this.onSubmitAllocation(callOverallocate);
    }
  }

  emitValidForm(formValid: boolean): void {
    this.formStatusValid.emit(formValid);
  }

  onSubmitAllocation(callOverallocate: boolean = true): void {
    if (this.allocationForm.controls['collaborator'].value) {
      if(callOverallocate)
        this.isOverallocate();
    }
    if (this.allocationForm.controls['project'].value)
      this.allocation.projectId =
        this.allocationForm.controls['project'].value['id'];
    this.allocation.updateAllocation(this.allocationForm.value);
    this.allocation.checkIsPercentage(
      this.allocationForm.controls['allocationPeriod'].value
    );

    if (this.allocation.isValid() === true && this.isRemovable === true) {
      if (
        (this.allocation.isReservation || this.allocation.isAbsence) &&
        this.allocation.projectId != null
      ) {
        this.allocation.createByReservation(this.allocationForm.value);
      } else if (
        (this.allocation.isReservation || this.allocation.isAbsence) &&
        this.allocation.projectId === null
      ) {
      } else if (!this.allocation.projectId) {
        this.allocationForm.controls['project'].value['id'];
        this.allocation.updateAllocation(this.allocationForm.value);
        this.allocation.checkIsPercentage(
          this.allocationForm.controls['allocationPeriod'].value
        );
      }
      this.submitEmitter.emit({ data: this.allocation });
    } else if (this.isRemovable === false) {
      this.submitEmitter.emit({ data: this.allocationForm.value });
    } else if (this.allocation.isValid() === false && !this.allocation.id) {
      const project = this.allocationForm.controls['project'].value;
      const collaborator = this.allocationForm.controls['collaborator'].value;
      if (!project) {
        this.allocation.updateAllocationByProject(
          collaborator,
          this.allocationForm.value
        );
        this.allocation.checkIsPercentage(
          this.allocationForm.controls['allocationPeriod'].value
        );
      } else {
        this.allocation.createAllocation(
          this.allocationForm.value,
          project,
          collaborator
        );
        this.allocation.checkIsPercentage(
          this.allocationForm.controls['allocationPeriod'].value
        );
      }
      const allocationPeriod =
        this.allocationForm.controls['allocationPeriod'].value;
      this.allocation.checkIsPercentage(allocationPeriod);

      if (this.clientIdSelected)
        this.allocation.clientId = this.clientIdSelected;

      this.submitEmitter.emit({ data: this.allocation });
    } else {
      if (this.clientIdSelected)
        this.allocation.clientId = this.clientIdSelected;
      this.allocation.endDate = this.allocationForm.controls['endDate'].value;
      this.submitEmitter.emit({ data: this.allocation });
    }
  }

  removeAllocation(): void {
    this.removeEmitter.emit(true);
  }

  //#region project

  changeProjectOption(): void {
    this.filteredOptionsProject = this.allocationForm.controls[
      'project'
    ].valueChanges.pipe(
      startWith(''),
      map((project: IProject) => {
        return this.changeProject(project);
      })
    );
  }

  changeProject(project: IProject) {
    this.isFirst = true
    try {
      if (project && project.projectInitiation) {
        if (this.isRemovable === false) {
          this.allocationForm.controls['startDate'].disable();
          this.allocationForm.controls['endDate'].disable();
        }
        this.allocationForm.controls['projectResponsible'].disable();
        this.allocationForm.controls['projectStatus'].disable();
        this.allocationForm.controls['isReservation'].setValue(false);

        this.minDate = this.fillDate(this.checkCurrentDay(project.projectInitiation.slice(0, -9))) ;
        this.maxDate = project.projectConclusion.slice(0, -9);
        this.projectManager = project.projectManager.fullName;
        this.status = project.projectStatus.description;

        if (!this.collaborator) {
          this.allocationForm.patchValue({
            startDate: this.fillDate(project.projectInitiation),
            endDate: this.fillDate(project.projectConclusion),
          });
        }
      } else if (this.allocationForm.controls['project'].dirty) {
        this.allocationForm.controls['startDate'].setValue(null);
        this.allocationForm.controls['endDate'].setValue(null);
        this.minDate = '';
        this.maxDate = '';
        this.projectManager = '';
        this.status = '';
      }
    } catch (error) {
      console.log(error);
    }
    const name = typeof project === 'string' ? project : project?.name;
    return name ? this._filterProject(name as string) : this.projects?.slice();
  }

  private _filterProject(name: string): IProject[] {
    const filterValue = name.toLowerCase();
    return this.projects?.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  displayFnProject(selected: IProject): string {
    return selected && selected.name
      ? selected?.projectPIP + '-' + selected.name
      : '';
  }

  //#endregion

  //#region client

  changeClientOption() {
    this.filteredOptionsClients = this.allocationForm.controls[
      'client'
    ].valueChanges.pipe(
      startWith(''),
      map((client: IClient) => {
        return this.changeClient(client);
      })
    );
  }

  clientIdSelected!: string;
  changeClient(client: IClient): IClient[] {
    try {
      this.clientIdSelected = client?.id;
      this.allocationForm.controls['project'].reset();
      this.allocationForm.controls['projectResponsible'].reset();
      this.allocationForm.controls['projectStatus'].reset();
      const currentDate = new Date();
      const maxDate = new Date(
        currentDate.getFullYear() + 1,
        currentDate.getUTCMonth(),
        currentDate.getUTCDate()
      );
      this.minDate = currentDate.toISOString().slice(0, -14);
      this.maxDate = maxDate.toISOString().slice(0, -14);
    } catch (error) {
      console.log(error);
    }
    const name = typeof client === 'string' ? client : client?.name;
    return name ? this._filterClient(name as string) : this.clients?.slice();
  }

  private _filterClient(name: string): IClient[] {
    const filterValue = name.toLowerCase();
    return this.clients?.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  displayFnClient(selected: IClient): string {
    return selected && selected.name ? selected.name : '';
  }
  //#endregion

  //#region collaborator
  changeCollaboratorOption() {
    this.filteredOptionsCollaborator = this.allocationForm.controls[
      'collaborator'
    ].valueChanges.pipe(
      startWith(''),
      map((collaborator: ICollaboratorAllocation) => {
        this.allocation.collaboratorId = collaborator?.collaboratorId;
        if(collaborator){
        this.jobFunctionCollaborator = collaborator?.jobFunction?.description;
        this.skills = collaborator?.collaboratorSkills && collaborator?.collaboratorSkills?.length > 0 ? collaborator?.collaboratorSkills.map((skill) => skill.skills.description).join(', ') : '';
        this.tower = collaborator?.tower?.description;
        this.leader = collaborator?.collaboratorLeader?.fullName;
        this.seniority = collaborator?.seniority?.description;
        }
        return this.changeCollaborator(collaborator);
      })
    );
  }

  changeCollaborator(
    collaborator: ICollaboratorAllocation
  ): ICollaboratorAllocation[] {
    try {
      if (collaborator) {
        this.allocation.createAllocationByCollaboratorId(collaborator);
        this.imageSrc = collaborator?.imageURL;

        this.allocationForm.controls['allocationPeriod'].enable();
        // if (!this.allocationForm.controls['allocationPeriod'].value)
        // this.allocationForm.controls['allocationPeriod'].setValue(100);
        if (collaborator.collaboratorSchedule) {
          this.collaboratorPeriod = new Period(
            collaborator.collaboratorSchedule.workingDays,
            8
          );

          this.collaboratorPeriod.initValues();
          if (this.collaboratorPeriod) {
            this.collaboratorPeriod.calculatePeriod(
              this.allocationForm.controls['isPercentage'].value,
              this.allocationForm.controls['allocationPeriod'].value
            );
            // this.collaboratorPeriod.calculateHoursPercentByPeriod(
            //   this.allocationForm.controls['percentage'].value
            // );
            // this.allocationForm.controls['hours'].setValue(
            //   this.collaboratorPeriod.percentHours
            // );
          }
        }
      } else if (this.allocationForm.controls['collaborator'].dirty) {
        this.allocationForm.controls['collaborator'].setErrors({});
        this.imageSrc = undefined;
      }
    } catch (error) {
      console.log(error);
    }

    const name =
      typeof collaborator === 'string'
        ? collaborator
        : collaborator?.collaboratorName;
    return name
      ? this._filterCollaborator(name as string)
      : this.collaborators?.slice();
  }

  private _filterCollaborator(name: string): ICollaboratorAllocation[] {
    const filterValue = name.toLowerCase();
    return this.collaborators?.filter((option) =>
      option.collaboratorName.toLowerCase().includes(filterValue)
    );
  }

  displayFnCollaborator(collaborator: ICollaboratorAllocation): string {
    return collaborator && collaborator.collaboratorName
      ? collaborator.collaboratorName
      : '';
  }

  isOverallocate() {
    const collaborator = this.allocationForm.controls['collaborator'].value;
    const isReservation = this.allocationForm.controls['isReservation'].value;
    const isAbsence = this.allocationForm.controls['isAbsence'].value;
    const overAllocated: IOverallocate = {
      collaboratorId: collaborator.collaboratorId,
      startDate: this.allocationForm.controls['startDate'].value,
      endDate: this.allocationForm.controls['endDate'].value,
      percentage: Number(
        this.allocationForm.controls['allocationPeriod'].value
      ),
      allocationId: this.allocation.id!,
    };

    if (isReservation || isAbsence) {
      const client = this.allocationForm.controls['client'].value;
      overAllocated.clientId = client.id;
    } else if (this.allocationForm.controls['project'].value) {
      const project = this.allocationForm.controls['project'].value;
      overAllocated.projectId = project.id;
    }

    if(this.allocation.id && this.allocationForm.dirty) {
      this.showAllocationWarning = true;
    } else {
      this.showAllocationWarning = false;
    }

    this._subs.add(
      this._allocationService
        .postOverallocate(overAllocated)
        .subscribe((response) => {
          if (response) {
            if (response.existingAllocation === true) {
              this.overAllocate = response.existingAllocation;
              this.overAllocateMessage = response.existingAllocationMessage;
              isReservation
                ? this.allocationForm.controls['client'].setErrors({})
                : this.allocationForm.controls['project'].setErrors({});
            } else {
              this.overAllocate = response.overallocated;
              this.overAbsence = response.absence;
              this.overAllocateMessage = response.message;
            }
            this.overMessage = response.message;
          } else{
            this.resetOverallocation();
            this._allocationService.changeOverallocationEvent.next(this.overAllocate);
          }
          this.allocation.overAllocation! = !response ? false : this.overAllocate;
          this.allocation.overAbsence! = !response ? false : this.overAbsence;
        })
    );
    this.isOverHoursPeriod();
  }

  isOverHoursPeriod() {
    const collaborator = this.allocationForm.controls['collaborator'].value;
    const form = {
      collaboratorId: collaborator.collaboratorId,
      dateInitial: this.allocationForm.controls['startDate'].value,
      dateEnd: this.allocationForm.controls['endDate'].value,
      percentage: this.allocationForm.controls['allocationPeriod'].value,
      isPercentage: this.allocationForm.controls['isPercentage'].value,
      hours: this.allocationForm.controls['allocationPeriod'].value,
    };
    if(collaborator.collaboratorId ){
      this._allocationService.postHoursPeriod(form).subscribe((response) => {
        this.hoursSelected = response.result;
      });
    }

  }

  resetOverallocation() {
    this.overMessage = '';
    this.overAllocate = false;
    this.overAllocateMessage = '';
    this.allocation.overAllocation! = false;
  }

  collaboratorDisabled(option: ICollaboratorAllocation): boolean {
    const value = option.projects.find(
      (project) => project && project.id === this.allocation.projectId
    );
      if( this.collaboratorIdSelected && !value){
        return !!this.collaboratorIdSelected.includes(option.collaboratorId)

      }

    return !!value;
  }

  //#endregion

  //#region JobFunction
  changeJobFunctionOption() {
    this.filteredOptionsJobFunction = this.allocationForm.controls[
      'jobFunction'
    ].valueChanges.pipe(
      startWith(''),
      map((jobFunction: IGenericData) => {
        return this.changeJobFunction(jobFunction);
      })
    );
  }

  changeJobFunction(jobFunction: IGenericData) {
    try {
      if (jobFunction.id) this.jobFunctionIdSelected = jobFunction.id;
      if (this.allocationForm.controls['collaborator'].value) {
        const collaboratorSelected =
          this.allocationForm.controls['collaborator'].value;
        if (
          collaboratorSelected.jobFunction.id !== this.jobFunctionIdSelected
        ) {
          this.allocationForm.controls['collaborator'].reset();
          this.imageSrc = '';
        }
      }
    } catch (error) {
      this.jobFunctionIdSelected = '';
    }
    const name =
      typeof jobFunction === 'string' ? jobFunction : jobFunction?.description;
    return name
      ? this._filterJobFunction(name as string)
      : this.jobfunctions?.slice();
  }

  private _filterJobFunction(name: string): IGenericData[] {
    const filterValue = name.toLowerCase();
    return this.jobfunctions?.filter((option) =>
      option.description.toLowerCase().includes(filterValue)
    );
  }

  displayFnJobFunction(selected: IGenericData): string {
    return selected && selected.description ? selected.description : '';
  }
  //#endregion

  //#region reservation

  onCheckboxChangeReservation(event: any, type: 'reservation' | 'absence'): void {
    const isReservation = this.allocationForm.controls['isReservation'].value;
    const isAbsence = this.allocationForm.controls['isAbsence'].value;

    this.allocation.isReservation = isReservation;
    this.allocation.isAbsence = isAbsence;

    this.isReservationOrAbsence = event.checked;

    if (isReservation === true || isAbsence === true) {
      this.enableForm('client');
      this.disableForm('project');
      this.allocation.clientId = null as any;

      this.allocationForm.controls['client'].reset();

      if (event.source.value === '1') {
        this.allocationForm.controls['isAbsence'].setValue(false);
        this.allocationForm.controls['observation'].reset();
        this.allocationForm.controls['isPercentage'].enable();
        this.allocationForm.controls['allocationPeriod'].enable();
        this.isAbsence = false;
        this.isReservation = true;
      } else if (event.source.value === '2') {
        this.allocationForm.controls['isReservation'].setValue(false);
        this.allocationForm.controls['isPercentage'].setValue(true);
        this.allocationForm.controls['allocationPeriod'].setValue(100);
        this.suffixPeriod = '%';
        this.allocationForm.controls['isPercentage'].disable();
        this.allocationForm.controls['allocationPeriod'].disable();
        this.isReservation = false;
        this.isAbsence = true;
      }

      if(isAbsence && type === 'absence') {
        let client = this.clients.filter(client => client.forAbsence)[0] ?? null;

        this.allocationForm.controls['client'].setValue(client);

        this.changeClient(client);
        this.allocation.clientId = client.id;
      }
    } else if (event.checked === false) {
      this.allocationForm.controls['isPercentage'].enable();
      this.allocationForm.controls['allocationPeriod'].enable();
      this.isReservationOrAbsence = false;
      this.disableForm('client');
      this.enableForm('project');
      this.isAbsence = false;
    }

    this.allocationForm.controls['observation'].setValidators([
      Validators.required,
    ]);
    this.allocationForm.controls['observation'].updateValueAndValidity();
  }

  inactivateAllocation(event: any) {
    const message: string = 'Deseja inativar essa alocação?';
    const buttonTxt: string = 'Sim';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._subs.add(
          this._allocationService
            .inactivateAllocation(this.allocation.id!)
            .subscribe(
              (response) => {
                if (response.success === true) {
                  this._messageService.openSuccessMessage(
                    'Operação realizada com sucesso'
                  );
                  const url = this._router.url;
                  this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this._router.navigate([`/${url}`]);
                  });
                  this.removeAllocation();
                } else if (response.errors.length > 0) {
                  this._messageService.openErrorMessage(response.errors);
                  event.source.checked = !event.checked;
                  event.checked = event.source.checked;
                }
              },
              () => {
                this._messageService.openErrorMessage([
                  'Erro encontrado, tente novamente',
                ]);
                event.source.checked = !event.checked;
                event.checked = event.source.checked;
              }
            )
        );
      } else {
        event.source.checked = !event.checked;
        event.checked = event.source.checked;
      }
    });
  }

  checkObservationChange(): boolean {
    let newValueObservation = this.allocationForm.controls['observation'].value;

    let validate = this.observationValue !== newValueObservation;

    this.observationValue = newValueObservation;

    return validate;
  }

  resetAllocationDate() {
    if (!this.allocation.endDate && this.allocation.startDate) {
      this.allocation.startDate = '';
    }
  }

  checkFormValidateWithoutObservation(): boolean {
    let isReservation = this.allocationForm.controls['isReservation'].value;

    if(!isReservation) return false;

    let isObservationValid = false;
    let controlsInvalid = [];
    let controls = this.allocationForm.controls;

    for (const key in controls) {
      if (controls[key].invalid) {
        controlsInvalid.push(key);
      }
    }

    isObservationValid = controlsInvalid.includes('observation') && controlsInvalid.length === 1;

    return isObservationValid;
  }
}

export interface ITemplateForm {
  type: string;
  index: number;
  list?:
    | Array<IProject>
    | Array<IClient>
    | Array<ICollaboratorAllocation>
    | Array<IGenericData>;
  label?: string;
  value?: any;
  isDisabled: boolean;
}
