import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-confirm-dialog-inactivation',
  templateUrl: './confirm-dialog-inactivation.component.html',
  styleUrls: [],
})
export class ConfirmDialogInactivationComponent implements OnInit {
  formInative!: FormGroup;
  minDate!: Date;

  constructor(
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmDialogInactivationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      buttonTxt: string;
      title: string;
      message: string;
       }
  ) {}

  ngOnInit() {
    this.createFormGroup();
    this.setMinMaxDates();
  }

  setMinMaxDates() {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate()-7);
  }

  createFormGroup() {
    this.formInative = this._fb.group({
      exclusionReason: [''],
      exclusionDate: ['',[Validators.required]],
   });
  }

  confirmInactivation() {
    const exclusionDateValue = this.formInative.value.exclusionDate;
    if (exclusionDateValue !== null && exclusionDateValue !== undefined) {
      const exclusionDate = new Date(exclusionDateValue);
      if (!isNaN(exclusionDate.getTime())) {
        const exclusionDateISOString = exclusionDate.toISOString();
        let formData = {
          exclusionReason: this.formInative.value.exclusionReason,
          exclusionDate: exclusionDateISOString,
        };
      }
    }
  }

  submitForm() {
    const exclusionDateValue = this.formInative.value.exclusionDate;
    const exclusionDate = new Date(exclusionDateValue);
    const exclusionDateISOString = exclusionDate.toISOString();
    let formData = {
      exclusionReason: this.formInative.value.exclusionReason,
      exclusionDate: exclusionDateISOString,
    };
    this.dialogRef.close({
      reason: formData.exclusionReason,
      exclusionDate: formData.exclusionDate,
    });
  }

  isValidReason(): boolean {
    const exclusionReason = this.formInative.get('exclusionReason')?.value;
     return (
      exclusionReason &&
      exclusionReason.length >= 5 &&
      exclusionReason.length <= 120
    );
  }

  close(): void {
    this.dialogRef.close();
  }

}
