export interface IOptionsCollaborator {
  [key: string]: any;
  contractTypes: Array<IGenericData>;
  towers: Array<ITower>;
  jobFunctions: Array<IGenericData>;
  teams?: Array<IGenericData>;
  senioritys: Array<IGenericData>;
  skills: Array<ISkillOption>;
  skillLevel: Array<ISkillLevel>;
  accessProfile: Array<{
    name: string;
    id: string;
  }>;
  leaders: Array<{
    name: string;
    id: string;
  }>;
  cities: Array<{description: string}>
}

export interface IGenericData {
  id?: string;
  description: string;
  createdAt: string;
  updatedAt: string | null;
  createdBy: string;
  updatedBy: string | null;
  inactive: boolean;
  name?: string;
}

export interface IskillLevel{
  value?: string;
  description: string;
 }
export interface ITower extends IGenericData{
  teams?: Array<IGenericData>;
}
export interface ISkillOption extends IGenericData{
   skillId: string | undefined;
   skillsId?: string;
   skillTypeId?: string;
}
export interface ISkillLevel {
  description: string;
  value?: string;
}

