<div class="content m-top-4xl">
  <button class="btn-backpage" (click)="navigateTo()">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <app-page-create-collaborator-allocation
    [collaboratorsId]="collaboratorsId"
    *ngIf="currentRoute === 'colaborador' && !collaboratorId"
  ></app-page-create-collaborator-allocation>

  <app-page-update-collaborator-allocation
    [projectId]="projectId"
    [viewByProject]="viewByProject"
    [collaboratorId]="collaboratorId"
    [allocable]="allocable"
    *ngIf="collaboratorId"
  ></app-page-update-collaborator-allocation>

  <app-page-create-project-allocation
    [projectsId]="projectsId"
    *ngIf="currentRoute === 'projeto' && !projectId"
  ></app-page-create-project-allocation>

  <app-page-update-project-allocation
    [projectId]="projectId"
    *ngIf="projectId && !viewByProject"
  >
  </app-page-update-project-allocation>
</div>
