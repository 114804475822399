<div class="modal-dialog-close">
  <div class="m-vertical-xl">
    <h1 *ngIf="!isEdit">Cadastrar Papel</h1>
    <h1 *ngIf="isEdit">Editar Papel</h1>
    <form [formGroup]="skillTypeForm" (ngSubmit)="onSubmitSkillType()">
      <mat-form-field
        class="input-outline input--width-sm m-bottom-lg"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label *ngIf="!isEdit">Cadastrar papel</mat-label>
        <mat-label *ngIf="isEdit">Editar papel</mat-label>
        <input
          type="text"
          matInput
          formControlName="description"
          maxlength="50"
        />
      </mat-form-field>
         <button

        class="btn no-width primary"
        [disabled]="!skillTypeForm.controls['description'].value"
        type="submit"
      >
     {{ isEdit ? 'Salvar' : 'Cadastrar' }}
      </button>
    </form>
  </div>
  <div class="position">
    <button class="position btn-icon-close" (click)="close()"><img src="./assets/svg/close-button.svg" alt=""></button>
  </div>
</div>
