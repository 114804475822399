import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  MatDateRangeSelectionStrategy,
  DateRange,
} from '@angular/material/datepicker';
import { ForrmatDateService } from 'src/app/core/services/forrmat-date.service';

@Injectable()
export class DateFormatSelectDay<D>
  implements MatDateRangeSelectionStrategy<D>
{
  private selectedPeriod: string = 'Semana';

  constructor(
    private _dateAdapter: DateAdapter<D>,
    private _dateFormatService: ForrmatDateService
  ) {
    this._dateFormatService.selectedPeriod$.subscribe((period) => {
      this.selectedPeriod = period;
    });
  }
  setPeriod(period: string): void {
    this.selectedPeriod = period;
  }
  selectionFinished(date: D | null): DateRange<D> {
    return this.selectDateRange(date);
  }
  createPreview(activeDate: D | null): DateRange<D> {
    return this.selectDateRange(activeDate);
  }

  private selectDateRange(date: D | null): DateRange<D> {
    if (!date) {
      return new DateRange<D>(null, null);
    }
    const selectedPeriod = this.selectedPeriod;
    switch (this.selectedPeriod) {
      case 'Semana':
        return this._createWeekdayRange(date);
      case 'Quinzena':
        return this._createQuinzenaRange(date);
      case 'Mês':
        return this._createMonthRange(date);
      case 'Trimestre':
        return this._createQuarterRange(date);
      default:
        return new DateRange<D>(null, null);
    }
  }

  private _createWeekdayRange(date: D): DateRange<D> {
    const dayOfWeek = this._dateAdapter.getDayOfWeek(date);
    const daysUntilMonday = (dayOfWeek + 6) % 7;
    const daysUntilFriday = (4 - dayOfWeek + 7) % 7;
    const start = this._dateAdapter.addCalendarDays(date, -daysUntilMonday);
    const end = this._dateAdapter.addCalendarDays(date, daysUntilFriday + 1);
    return new DateRange<D>(start, end);
  }

  private _createQuinzenaRange(date: D): DateRange<D> {
    const previousMonday = this._getPreviousMonday(date);
    const end = this._dateAdapter.addCalendarDays(previousMonday, 19);
    return new DateRange<D>(previousMonday, end);
  }

  private _getPreviousMonday(date: D): D {
    const currentDayOfWeek = this._dateAdapter.getDayOfWeek(date);
    const daysUntilMonday = (currentDayOfWeek + 6) % 7;
    return this._dateAdapter.addCalendarDays(date, -daysUntilMonday);
  }

  private _createMonthRange(date: D): DateRange<D> {
    const firstDayOfMonth = this._dateAdapter.createDate(
      this._dateAdapter.getYear(date),
      this._dateAdapter.getMonth(date),
      1
    );
    const lastDayOfMonth = this._getLastDayOfMonth(date);
    return new DateRange<D>(firstDayOfMonth, lastDayOfMonth);
  }

  private _getLastDayOfMonth(date: D): D {
    const currentYear = this._dateAdapter.getYear(date);
    const currentMonth = this._dateAdapter.getMonth(date);
    let nextMonth;
    let nextYear;
    if (currentMonth === 0) {
      nextMonth = 1;
      nextYear = currentYear;
    } else {
      nextMonth = currentMonth + 1;
      nextYear = currentYear;
      if (nextMonth === 12) {
        nextMonth = 0;
        nextYear += 1;
      }
    }
      const firstDayOfNextMonth = this._dateAdapter.createDate(
      nextYear,
      nextMonth,
      1
    );
    return this._dateAdapter.addCalendarDays(firstDayOfNextMonth, -1);
  }



  private _createQuarterRange(date: D): DateRange<D> {
    const previousMonday = this._getPreviousQuarter(date);
    const end = this._dateAdapter.addCalendarDays(previousMonday, 80);
    return new DateRange<D>(previousMonday, end);
  }

  private _getPreviousQuarter(date: D): D {
    const currentDayOfWeek = this._dateAdapter.getDayOfWeek(date);
    const daysUntilMonday = (currentDayOfWeek + 6) % 7;
    return this._dateAdapter.addCalendarDays(date, -daysUntilMonday);
  }
}
