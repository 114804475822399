import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-select-allocation',
  templateUrl: './modal-select-allocation.component.html',
  styles: [],
})
export class ModalSelectAllocationComponent implements OnInit {
  constructor(
    private _router: Router,
    public dialogRef: MatDialogRef<ModalSelectAllocationComponent>
  ) {}

  ngOnInit(): void {}

  manageAllocation(path: string = '') {
    this._router.navigate([`alocacao/gerenciar-alocacao/${path}`]);
    this.close();
  }
  close(): void {
    this.dialogRef.close();
  }
}
