<div
  *ngIf="this.loader.getLoading()"
  class="cssload-container"
  [ngClass]="{ centralize: isLogin }"
>
  <div class="cssload-speeding">
    <img src="./assets/img/Logo1_color_cinza.png" alt="" srcset="" />
    <span class="cssload-text m-top-lg">Carregando </span>
  </div>
</div>
