import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TowerTeamsRoutingModule } from './tower-teams-routing.module';
import { PageTowerTeamListComponent } from './pages/page-tower-team-list/page-tower-team-list.component';
import { TeamManageModalComponent } from './modal/team-manage-modal/team-manage-modal.component';
import { TowerManageModalComponent } from './modal/tower-manage-modal/tower-manage-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { DirectiveModule } from 'src/app/core/directives/directives.module';
import { TeamCollaboratorsComponent } from './pages/detail/components/team-collaborators/team-collaborators.component';
import { TeamsDetailComponent } from './pages/detail/containers/teams-detail/teams-detail.component';
import { ProjectsAllocationsComponent } from './pages/detail/components/projects-allocations/projects-allocations.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


@NgModule({
  declarations: [
    PageTowerTeamListComponent,
    TowerManageModalComponent,
    TeamManageModalComponent,
    TeamCollaboratorsComponent,
    TeamsDetailComponent,
    ProjectsAllocationsComponent

  ],
  imports: [
    CommonModule,
    TowerTeamsRoutingModule,
    MatTableModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    FormsModule,
    ComponentsModule,
    PipesModule,
    DirectiveModule,
    MatCardModule,
    MatTabsModule,
    MatTableModule,
    MatIconModule,
    MatSortModule,
    MatTooltipModule,
    TooltipModule.forRoot(),
    DirectiveModule,
    PipesModule,
  ],
})
export class TowerTeamsModule { }
