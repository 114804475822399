import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountGuard } from 'src/app/core/guards/account.guard';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { CollaboratorModule } from '../collaborator/collaborator.module';
import { SkillModule } from '../skills/skill.module';
import { TowerTeamsModule } from '../tower-teams/tower-teams.module';
import { ClientsProjectsRoutingModule } from '../clients-projects/clients-projects-routing.module';
import { AllocationRoutingModule } from '../allocation/allocation-routing.module';
import { PermissionRoutingModule } from '../permission/permission-routing.module';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AccountGuard],
    children: [
      {
        path: '',
        loadChildren: () => CollaboratorModule,
      },
      {
        path: '',
        loadChildren: () => SkillModule,
      },
      {
        path: '',
        loadChildren: () => TowerTeamsModule,
      },
      {
        path: '',
        loadChildren: () => ClientsProjectsRoutingModule,
      },
      {
        path: '',
        loadChildren: () => AllocationRoutingModule,
      },
      {
        path: '',
        loadChildren: () => PermissionRoutingModule,
      },
    ],
  },{
    path: '***',
    redirectTo: '/colaboradores',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
