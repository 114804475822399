import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ModalSelectAllocationComponent } from '../../../modal/modal-select-allocation/modal-select-allocation.component';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from 'src/app/pages/account/repository/account.service';
import { ERoleUser } from 'src/app/pages/collaborator/enum/role.enum';

@Component({
  selector: 'app-allocation',
  templateUrl: './allocation.component.html',
  styles: [],
})
export class AllocationComponent implements OnInit {
  currentRoute!: string;
  tabSelected: number = 0;
  role!: number | null;
  eRoleUser = ERoleUser;

  constructor(
    private _router: Router,
    private location: Location,
    public dialog: MatDialog,
    private _accountService: AccountService,
  ) {
    this.currentRoute = this._router.url.substring(
      this._router.url.lastIndexOf('/') + 1
    );
    this.changeTab();
  }

  ngOnInit(): void {
    this.role = this.getAccessProfile();
    this.changeTab();
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url.slice(1);
      }
    });
  }
  changeTab() {
    if (this.currentRoute === 'clientes') this.tabSelected = 0;
    if (this.currentRoute === 'projetos') this.tabSelected = 1;
    if (this.currentRoute === 'emprestimos') this.tabSelected = 2;
  }


  changeURLparams(event: any) {
    this.tabSelected = event.index;
    const routes = ['colaboradores', 'projetos', 'emprestimos'];
    this.location.replaceState(`/alocacao/${routes[this.tabSelected]}`);
    this.currentRoute = routes[this.tabSelected];
  }

  openAllocationModal() {
    let dialogRef;
    dialogRef = this.dialog.open(ModalSelectAllocationComponent);
  }
  getAccessProfile() {
    let role = this._accountService.localStorage.getProfile();
    return role;
  }
}
