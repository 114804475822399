<div class="content">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="filterForm">
        <div class="row space-between">
          <div class="row align-center">
            <mat-label class="input-label--custom">Função: </mat-label>
            <mat-form-field
              class="rounded light-bg input--width-xs-secondary m-sm"
              appearance="fill"
              floatLabel="always"
            >
              <mat-select class="custom-select" formControlName="skillTypeId">
                <mat-option
                  *ngFor="let option of optionsSkillType"
                  [value]="option.id"
                  (onSelectionChange)="
                    changeChipList('skillTypeId', option.description, option.id)
                  "
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row align-center">
            <mat-label class="input-label--custom">Habilidade: </mat-label>
            <mat-form-field
              class="rounded light-bg input--width-xs-secondary m-sm"
              appearance="fill"
              floatLabel="always"
            >
              <mat-select class="custom-select" formControlName="skillsId">
                <ng-container *ngFor="let option of optionsDisplay">
                  <mat-option
                    [value]="option.id"
                    (onSelectionChange)="
                      changeChipList('skillsId', option.description, option.id)
                    "
                  >
                    {{ option.description }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row align-center">
            <mat-label class="input-label--custom">Nível: </mat-label>
            <mat-form-field
              class="rounded light-bg input--width-xs-secondary m-sm"
              appearance="fill"
              floatLabel="always"
            >
              <mat-select class="custom-select" formControlName="skillLevel">
                <mat-option
                  *ngFor="let option of options?.skillLevel"
                  [value]="option.value"
                  (onSelectionChange)="
                    changeChipList(
                      'skillLevel',
                      option.description,
                      option.value
                    )
                  "
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
      <div class="row p-vertical-xl">
        <mat-chip-list>
          <span class="icon-info p-horizontal-xs" *ngIf="chipsList.length > 0">
            <mat-icon
              matTooltipClass="info-tooltip"
              [matTooltipPosition]="'above'"
              matTooltip="Nos campos do filtro, você pode escolher filtrar mais de um em cada categoria."
              >info</mat-icon
            >
            Filtros:</span
          >
          <ng-container *ngFor="let chip of chipsList">
            <mat-chip (click)="removeFilterValueChips(chip)">
              <span>{{ chip.description }}</span>
              <mat-icon>clear</mat-icon>
            </mat-chip>
          </ng-container>
          <mat-chip
            class="remove-chip"
            *ngIf="chipsList.length > 0"
            (click)="removeAllChips()"
          >
            <span class="error">Excluir todos</span>
            <mat-icon>clear</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div>
        <button
          class="btn-no-border-custom m-left-xl m-vertical-xl"
          [disabled]="showForm"
          (click)="enableFormSkill()"
        >
          <mat-icon>add</mat-icon>
          Nova Habilidade
        </button>
        <form *ngIf="showForm" [formGroup]="skillForm">
          <div
            class="row p-vertical-md align-center space-between form-row p-right-xl"
          >
            <mat-form-field
              class="input-outline input--width-md m-bottom-lg"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Selecione um papel:</mat-label>
              <mat-select class="custom-select" formControlName="skillType">
                <mat-option
                  *ngFor="let option of optionsSkillType"
                  [value]="option"
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="input-outline input--width-md m-bottom-lg"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Selecionar habilidade:</mat-label>
              <mat-select
                class="custom-select"
                [disabled]="!skillForm.controls['skillType'].value"
                formControlName="skills"
              >
                <ng-container *ngFor="let option of optionsNewSkillDisplay">
                  <mat-option [value]="option">
                    {{ option.description }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="input-outline input--width-md m-bottom-lg"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Selecione um nível</mat-label>
              <mat-select
                class="custom-select"
                [disabled]="!skillForm.controls['skillType'].value"
                formControlName="skillLevel"
              >
                <mat-option
                  *ngFor="let option of options?.skillLevel"
                  [value]="option"
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button
              mat-mini-fab
              (click)="prepareNewSkill()"
              [disabled]="
                !skillForm.controls['skills'].value ||
                !skillForm.controls['skillLevel'].value
              "
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </form>
      </div>
      <div class="table-expansive light">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
          <ng-container
            matColumnDef="{{ column }}"
            *ngFor="let column of displayedColumns"
          >
            <td mat-cell *matCellDef="let element; let index = index">
              <ng-container *ngIf="column != 'status'">
                {{ element[column] }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td
              mat-cell
              *matCellDef="let element"
              [attr.colspan]="displayedColumns.length"
            >
              <div class="element-detail" [@detailExpand]="'expanded'">
                <ng-container>
                  <div
                    class="element-description col"
                    *ngFor="let skill of element.skills; let skillIndex = index"
                  >
                    <div
                      class="row"
                      [ngClass]="{ inactive: skill.inactive === true }"
                    >
                      <div class="col" style="padding-left: 60px">
                        {{ skill.skillName }}
                      </div>
                      <div class="col">
                        <mat-form-field
                          class="rounded light-bg input--width-xs-secondary touched-warning"
                          appearance="fill"
                          floatLabel="always"
                        >
                          <mat-select
                            (selectionChange)="updateSkill(skill, $event)"
                            [ngModel]="skill.level"
                          >
                            <mat-option
                              *ngFor="let enum of levelSelectOptions | keyvalue"
                              [value]="enum.value"
                            >
                              {{ enum.value }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col">
                        <div class="row align-center justify-end">
                          <ng-template
                            *ngIf="!skill.canRemove"
                            permissions
                            [role]="EPermissionType.CreateUpdate"
                            [process]="EProcess.CollaboratorSkills"
                          >
                            <mat-slide-toggle
                              [(ngModel)]="!skill.inactive"
                              (change)="
                                inactivateSkill(
                                  $event,
                                  skill,
                                  skillIndex,
                                  element.id
                                )
                              "
                              style="padding-right: 60px"
                              class="switch"
                            ></mat-slide-toggle>
                          </ng-template>
                          <ng-template
                            *ngIf="skill.canRemove"
                            permissions
                            [role]="EPermissionType.CreateUpdate"
                            [process]="EProcess.CollaboratorSkills"
                          >
                            <button
                              mat-mini-fab
                              type="button"
                              class="cancel"
                              (click)="removeNewSkill(skill, element.id)"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                          </ng-template>
                          <ng-template
                            *ngIf="!skill.canRemove"
                            permissions
                            [role]="EPermissionType.Delete"
                            [process]="EProcess.CollaboratorSkills"
                          >
                            <button
                              mat-mini-fab
                              type="button"
                              class="cancel p-right-xl"
                              (click)="deleteSkill(skill)"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <tr
            mat-row
            *matRowDef="let element; columns: displayedColumns"
            class="expansive-row"
            [class.expanded-row]="expandedElement === element"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="detail-row"
          ></tr>
        </table>
        <app-paginator
          *ngIf="countDataSource > filter.take"
          [length]="countDataSource"
          [pageSize]="filter.take"
          (skipPage)="skipPage($event)"
        ></app-paginator>
      </div>
      <div class="row justify-end m-vertical-xl">
        <button
          class="btn primary btn--width-xxs"
          (click)="openDialogCollaboratorSkill()"
          [disabled]="
            this.skillToAddArray.skillsAdd.length === 0 &&
            this.skillToUpdateArray.length === 0
          "
        >
          Salvar
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
