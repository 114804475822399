<table
  matSort
  mat-table
  [dataSource]="dataSource"
  class="table-primary table-clickable"
>
  <ng-container
    matColumnDef="{{ column.key }}"
    *ngFor="let column of columns | keyvalue"
  >
    <th mat-header-cell *matHeaderCellDef>
      <div class="alinhar-icon">
        <span>{{ column.value }}</span>

        <ng-container
          *ngIf="
            column.key !== 'imageUrl' &&
            column.key !== 'edition' &&
            column.key !== 'accessProfileName'
          "
        >
          <mat-icon (click)="changeOrder(getOrderByValue(column.key))">
            <img src="./assets/svg/orderBy.svg" alt="" />
          </mat-icon>
        </ng-container>
      </div>
    </th>
    <td mat-cell *matCellDef="let element; let index = index">
      <div
        *ngIf="
          (column.key !== 'edition' &&
            column.key !== 'accessProfileName' &&
            column.key !== 'imageUrl') ||
          (column.key === 'accessProfileName' && !element.editing)
        "
        [class]="'row-' + index"
      >
        {{ element[column.key] }}
      </div>
      <div
        *ngIf="column.key === 'accessProfileName' && !!element.editing"
        [class]="'row-' + index"
      >
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm border-field"
          appearance="fill"
          floatLabel="always"
        >
          <mat-select
            class="custom-select"
            [(ngModel)]="element.accessProfileId"
          >
            <mat-option
              *ngFor="let option of profileOptions"
              [value]="option.id"
            >
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex align-center" *ngIf="column.key === 'edition'">
        <mat-icon
          *ngIf="!element.editing"
          class="mat-icon"
          aria-hidden="true"
          (click)="element.editing = true"
          ><img src="./assets/svg/edit-box.svg" alt=""
        /></mat-icon>

        <div class="flex align-center">
          <mat-icon
            *ngIf="!!element.editing"
            title="Cancelar"
            (click)="cancelEdit(element)"
          >
            <img src="./assets/svg/cancel.svg" alt="" />
          </mat-icon>

          <mat-icon
            *ngIf="!!element.editing"
            class="confirmation"
            title="Confirmar"
            (click)="handleConfirmation(element)"
          >
            <img src="./assets/svg/confirmation.svg" alt="" />
          </mat-icon>
        </div>
      </div>
      <div *ngIf="column.key === 'imageUrl'" [class]="'row-' + index">
        <div class="img-table flex justify-center align-center">
          <img
            *ngIf="element.imageURL"
            [src]="element.imageURL | safeResourceUrl"
            alt=""
          />
          <div
            *ngIf="!element.imageURL"
            class="flex justify-center align-center"
          >
            {{ getinitialsNameCollaborator(element.fullName!) }}
          </div>
        </div>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<app-paginator
  *ngIf="count > pagination.take"
  [length]="count"
  [pageSize]="pagination.take"
  (skipPage)="skipPage($event)"
></app-paginator>
