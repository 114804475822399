<section class="content m-bottom-2xl">
  <div class="flex align-center section-spacing">
    <ng-container>
      <button class="btn-backpage" (click)="navigateBack()">
        <mat-icon>chevron_left</mat-icon>
      </button>
    </ng-container>

    <div class="profile-title">
      <h1 class="p-horizontal-xl">{{ team?.teamName }}</h1>
    </div>
    <div class="flex-1">
      <div class="border-division"></div>
    </div>
  </div>
  <mat-card class="card-logo">
    <mat-card-content>
      <div class="flex col-12">
        <div class="teams-logo col-3">
          <div class="text">{{ team?.teamName }}</div>
        </div>
        <div class="col-9 p-xl">
          <div>
            <h3>Sobre a equipe</h3>
            <div class="flex justify-end position-absolut right top">
              <ng-template
                permissions
                [process]="EProcess.Allocation && EProcess.Team"
                [role]="EPermissionType.Read && EPermissionType.CreateUpdate"
              >
                <button class="btn-no-border-custom">
                  <mat-icon (click)="openDialogTeam(team)" matTooltip="Editar"
                    ><img src="./assets/svg/edit-black.svg" alt=""
                  /></mat-icon>
                </button>
              </ng-template>
            </div>
            <div class="section-form">
              <div class="flex col-12">
                <div class="flex-1 col-5">
                  <div>
                    <mat-label class="label--custom-project"
                      >Líder da equipe:</mat-label
                    >
                    <span>{{ team?.responsible }}</span>
                  </div>
                  <div>
                    <mat-label class="label--custom-project">Torre</mat-label>
                    <span>{{ team?.tower }}</span>
                  </div>
                </div>
                <div class="flex col-7">
                  <div>
                    <mat-label class="label--custom-project"
                      >Clientes
                    </mat-label>
                    <span
                      matTooltipClass="teams-tooltip"
                      [matTooltipPosition]="'right'"
                      matTooltip="{{ getNamesClientstooltip() }}"
                      >{{
                        team && team.clients && getNamesClients().length > 0
                          ? getNamesClients()
                          : "Não existe clientes."
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <ng-template
    permissions
    [process]="EProcess.Allocation"
    [role]="EPermissionType.Read"
  >
    <mat-tab-group animationDuration="0ms" class="tab-page p-top-xl">
      <mat-tab label="Colaboradores da equipe">
        <ng-container>
          <app-team-collaborators></app-team-collaborators>
        </ng-container>
      </mat-tab>
      <mat-tab label="Projetos com alocações">
        <ng-container>
          <app-projects-allocations></app-projects-allocations>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</section>
