import {
  EPermissionType,
  EProcess,
} from 'src/app/shared/interfaces/roles.interface';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormGroupDirective,
  FormControl,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MessageService } from 'src/app/core/services/message.service';
import { SubSink } from 'subsink';
import { LevelSkillsEnum } from '../../enum/level-skills.enum';
import { MaritalEnum } from '../../enum/marital.enum';
import { DaysOfWeekEnum } from '../../enum/working-days.enum';
import {
  IOptionsCollaborator,
  ISkillOption,
} from '../../interface/options-collaborator.interface';
import { CollaboratorService } from '../../repository/collaborator.service';
import { ICollaboratorProfile } from '../../interface/collaborator-profile.interface';
import { MatDialog } from '@angular/material/dialog';
import { StringValidators } from 'src/app/shared/validators/string.validator';
import { StringUtil } from 'src/app/shared/utils/string';
import { UserRoleService } from 'src/app/pages/account/repository/user-role.service';

@Component({
  selector: 'app-page-registration-collaborator',
  templateUrl: './page-registration-collaborator.component.html',
  styles: [],
})
export class PageRegistrationCollaboratorComponent implements OnInit {
  profileForm!: FormGroup;
  options!: IOptionsCollaborator;
  options$!: Observable<IOptionsCollaborator>;
  maritalState = MaritalEnum;
  workingDaysSelectOptions: string[] = [];
  levelSelectOptions: string[] = [];
  maritalStateOptions: string[] = [];
  maxAdmissionDate!: string;
  EProcess = EProcess;
  EPermissionType = EPermissionType;
  private _subs = new SubSink();

  collaborator: ICollaboratorProfile = {
    id: '',
    fullName: '',
    email: '',
    personalEmail: '',
    admission: '',
    isLeader: false,
    contractTypeId: '',
    workingHours: 0,
    jobFunctionId: '',
    teamId: '',
    seniorityId: '',
    collaboratorLeaderId: '',
    towerId: '',
    accessProfileId: '',
    collaboratorSchedule: {
      daysOfWeek: 0,
      workingDays: [],
    },
  };

  @ViewChild('allSelected')
  private allSelected!: MatOption;

  constructor(
    private _fb: FormBuilder,
    private _collaboratorService: CollaboratorService,
    private _messageService: MessageService,
    private _router: Router,
    public dialog: MatDialog,
    private _userRoleService: UserRoleService,
  ) {
    this.getSelectOptions();
    this.createFormGroup();
    this.createSelectOptions();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  createFormGroup() {
    this.maxAdmissionDate = this.getMaxAdmissionDate();
    this.profileForm = this._fb.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.required, this.emailValidator, Validators.email]],
      personalEmail: ['', [Validators.required,Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')] ],
      admission: ['', [Validators.required, this.validateDate]],
      contractTypeId: ['', Validators.required],
      daysOfWeek: ['', Validators.required],
      workingHours: [
        '',
        [
          Validators.required,
          StringValidators.minNumber(4),
          StringValidators.maxNumber(8),
        ],
      ],
      towerId: ['', Validators.required],
      teamId: ['', Validators.required],
      collaboratorLeaderId: [''],
      jobFunctionId: ['', Validators.required],
      seniorityId: ['', Validators.required],
      isLeader: [false, Validators.required],
      accessProfileId: ['', Validators.required],
    });
  }

  emailValidator(control: FormControl) {
    const email = control.value;
    if (email && !email.includes('@performait.com')) {
      return { invalidEmail: true };
    }
    return null;
  }

  createSelectOptions() {
    this.workingDaysSelectOptions = Object.keys(DaysOfWeekEnum).filter((f) =>
      isNaN(Number(f))
    );
    this.levelSelectOptions = Object.keys(LevelSkillsEnum).filter((f) =>
      isNaN(Number(f))
    );
    this.maritalStateOptions = Object.keys(MaritalEnum).filter((f) =>
      isNaN(Number(f))
    );
  }

  getSelectOptions() {
    this.options$ = this._collaboratorService.getCollaboratorSelect();

    this._subs.add(
      this.options$.subscribe((options: IOptionsCollaborator) => {
        this.options = options;
        this.options.skills.map((skill: ISkillOption) => {
          skill.skillsId = skill.id;
          skill.id = undefined;
        });
      })
    );
  }

  fillDate(DateString: string) {
    const date = new Date(DateString);
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();

    if (date.getDate() < 10) day = day.substr(0, 0) + '0' + day.substr(0);
    if (date.getMonth() < 9) month = month.substr(0, 0) + '0' + month.substr(0);

    return `${date.getFullYear()}-${month}-${day}`;
  }

  get collaboratorSalaryData() {
    return this.profileForm.controls['collaboratorSalaryData'] as FormGroup;
  }

  togglePerOne() {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
    }
    if (
      this.profileForm.controls['daysOfWeek'].value.length ==
      this.workingDaysSelectOptions.length
    )
      this.allSelected.select();
  }

  toggleAllWorkingDaysSelection() {
    if (this.allSelected.selected) {
      this.profileForm.controls['daysOfWeek'].patchValue([
        ...this.workingDaysSelectOptions.map((item) => item),
        0,
      ]);
    } else {
      this.profileForm.controls['daysOfWeek'].patchValue([]);
    }
  }

  selectTower() {
    try {
      this.options.towers.filter((tower) => {
        if (tower.id === this.profileForm.controls['towerId'].value) {
          this.options.teams = tower.teams;
          this.updateTeamId();
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  updateTeamId() {
    const towerId = this.profileForm.controls['towerId'].value;
    const teamId = this.findTeamIdByTowerId(towerId);
    this.profileForm.controls['teamId'].setValue(teamId);
  }

  findTeamIdByTowerId(towerId: any): any {
    const tower = this.options.towers.find((tower) => tower.id === towerId);
    if (tower && tower.teams && tower.teams.length > 0) {
      return tower.teams[0].id;
    } else {
      return null;
    }
  }

  stringToNumber(value: string): number {
    return Number(value);
  }

  getDaysOfWeek() {
    const dayOfWeek = this.profileForm.controls['daysOfWeek'].value;
    const response = dayOfWeek
      .map((day: DaysOfWeekEnum) => {
        const indexOfS = Object.values(DaysOfWeekEnum).indexOf(
          day as unknown as DaysOfWeekEnum
        );
        const key = Object.keys(DaysOfWeekEnum)[indexOfS];
        if (key) return Number(key);
        return 0;
      })
      .reduce((sum: number, current: number) => sum + current, 0);

    return response;
  }

  getMaxAdmissionDate() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    const maxDate = new Date(
      currentDate.getFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate()
    );
    return maxDate.toISOString().slice(0, -14);
  }

  onSubmitCollaborator(formDirective: FormGroupDirective) {
    const formValue = this.profileForm.value;
    const collaborator: ICollaboratorProfile = {
      email: formValue.email,
      personalEmail: formValue.personalEmail,
      fullName: StringUtil.capitalize(formValue.fullName),
      admission: new Date(formValue.admission).toISOString(),
      contractTypeId: formValue.contractTypeId,
      workingHours: this.stringToNumber(formValue.workingHours),
      jobFunctionId: formValue.jobFunctionId,
      teamId: formValue.teamId,
      seniorityId: formValue.seniorityId,
      collaboratorLeaderId: formValue.collaboratorLeaderId,
      isLeader: formValue.isLeader,
      towerId: formValue.towerId,
      accessProfileId: formValue.accessProfileId,
      collaboratorSchedule: {
        daysOfWeek: this.getDaysOfWeek(),
        workingDays: [],
      },
    };
    if (this.profileForm.valid) {
      this._subs.add(
        this._collaboratorService
          .postProfileCollaborator(collaborator)
          .subscribe((response) => {
            if (response.success === true) {
              const type = 'success';
              const title = 'O cadastro foi realizado com sucesso!';
              const message =
                'Um e-mail foi enviado para o e-mail do colaborador, solicitando a continuidade do cadastro.';
              this._messageService.openModalMessageSuccses(
                title,
                message,
                type,
                6000
              );
              formDirective.resetForm();
              this._router.navigate(['/colaboradores']);
            } else if (response.errors.length > 0) {
              this._messageService.openErrorMessage(response.errors);
            }
          })
      );
    }
  }

  returnToCollaboratorList() {
    this._router.navigate(['colaboradores']);
  }
  validateDate(control: FormControl) {
    const admission = control.value;
    if (typeof admission === 'string') {
      const admissionDate = new Date(admission);
      const currentDate = new Date();
      const referenceDate = new Date(currentDate);
      referenceDate.setDate(currentDate.getDate() - 30);
      if (admissionDate < referenceDate) {
        return { invalidAdmission: true };
      }
    }

    return null;
  }

  desabilityFields() {
    if (
      !this._userRoleService.hasRole(
        EProcess.CollaboratorPersonalData,
        EPermissionType.CreateUpdate
      )
    ) {
      this.profileForm.get('fullName')?.disable();
      this.profileForm.get('email')?.disable();
    }

    if (
      !this._userRoleService.hasRole(
        EProcess.CollaboratorContractualinformation,
        EPermissionType.CreateUpdate
      )
    ) {
      this.profileForm.get('admission')?.disable();
      this.profileForm.get('contractTypeId')?.disable();
      this.profileForm.get('workingHours')?.disable();
      this.profileForm.get('daysOfWeek')?.disable();
      this.profileForm.get('jobFunctionId')?.disable();
      this.profileForm.get('teamId')?.disable();
      this.profileForm.get('seniorityId')?.disable();
      this.profileForm.get('isLeader')?.disable();
      this.profileForm.get('towerId')?.disable();
      this.profileForm.get('collaboratorLeaderId')?.disable();
    }

    if (
      !this._userRoleService.hasRole(
        EProcess.Permission,
        EPermissionType.CreateUpdate
      )
    ) {
      this.profileForm.get('accessProfileId')?.disable();
    }
  }
}
