export type process =
  | 'Collaborator'
  | 'Collaborator/Address'
  | 'Collaborator/Contractualinformation'
  | 'Collaborator/PersonalData'
  | 'Collaborator/Skills'
  | 'Skills'
  | 'Tower'
  | 'Team'
  | 'Client'
  | 'Project'
  | 'Allocation'
  | 'Profile'
  | 'Permission';

export enum EProcess {
  Collaborator = 'Collaborator',
  CollaboratorAddress = 'Collaborator/Address',
  CollaboratorContractualinformation = 'Collaborator/Contractualinformation',
  CollaboratorPersonalData = 'Collaborator/PersonalData',
  CollaboratorSkills = 'Collaborator/Skills',
  Skills = 'Skills',
  Tower = 'Tower',
  Team = 'Team',
  Client = 'Client',
  Project = 'Project',
  Allocation = 'Allocation',
  Profile = 'Profile',
  Permission = 'Permission',
}

export type permissionType = 'Read' | 'Delete' | 'Create/Update' | 'Teste';

export enum EPermissionType {
  Read = 'Read',
  Delete = 'Delete',
  CreateUpdate = 'Create/Update',
}