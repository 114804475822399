<section class="content m-bottom-2xl">
  <div class="flex align-center column-gap-16 section-spacing">
    <div class="profile-title">
      <h1>Alocações</h1>
    </div>
    <div class="flex-1">
      <div class="border-division"></div>
    </div>
    <div
      class="card-highlight"
      [ngClass]="{
        sucess: allocationPercentage > 75,
        warn: allocationPercentage <= 75 && allocationPercentage >= 50,
        error: allocationPercentage < 50,
        alert: allocationPercentage > 100
      }"
    >
      {{ allocationPercentage }}&#37; de alocação
    </div>
    <button
    class="btn primary btn--width-xxxs"
    (click)="redirectToRestRequest()"
  >
  Solicitar descanso
  </button>
  </div>
  <div class="p-lg">
    <mat-card>
      <mat-card-content>
        <div *ngIf="hasAllocation">
          <table
            mat-table
            [dataSource]="dataSource"
            matSort
            class="table-collaborator table-secondary"
          >
            <ng-container
              matColumnDef="{{ column.key }}"
              *ngFor="let column of columns | keyvalue; let last = last"
            >
              <th mat-header-cell mat-sort-header *matHeaderCellDef  class="p-xs" >
                <div class="alinhar-icon p-xs">
                  <span>{{ column.value }}</span>
                  <ng-container>
                    <mat-icon (click)="changeOrder(orderOption[column.key])">
                      <img src="./assets/svg/orderBy.svg" alt="" />
                    </mat-icon>
                  </ng-container>
                </div>
              </th>
              <div>
                <td
                  mat-cell
                  *matCellDef="let element; let index = index"
                  #elRow
                >
                  <ng-container
                    *ngIf="
                      column.key != 'startDate' &&
                      column.key != 'endDate' &&
                      column.key != 'percentage'
                    "
                  >
                    <div
                      [ngClass]="{
                        'statusTableCell sucess flex align-center justify-center bold uppercase':
                          column.key == 'status',
                        'underline dark-purple':
                          column.key == 'projectResponsible'
                      }"
                    >
                      {{ element[column.key] }}
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="column.key == 'startDate' || column.key == 'endDate'"
                  >
                    {{ element[column.key] | date : "dd/MM/yyyy" }}
                  </ng-container>
                  <ng-container *ngIf="column.key == 'percentage'">
                    {{ element[column.key] }}&#37;
                  </ng-container>
                </td>
              </div>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <div *ngIf="!hasAllocation" class="flex justify-center bold p-xl">
          Sem alocações no momento.
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</section>
