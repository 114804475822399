
  <app-title [title]="pageTitle"></app-title>
  <ng-container *ngFor="let item of allocation; let formIndex = index">
    <app-form-collaborator-allocation
      *ngIf="optionsProject && optionsCollaborator"
      [projects]="optionsProject"
      [collaborators]="optionsCollaborator"
      [clients]="optionsClients"
      [allocation]="item"
      (removeEmitter)="removeAllocation(formIndex, item)"
      (submitEmitter)="receiveSubmit($event, formIndex)"
      (formStatusValid)="receiveStatusForm($event, formIndex)"
      [isReservationOrAbsence]="isReservationOrAbsence"
      [isAbsence]="isAbsence"
      [allocations]="allocation"
          >
    </app-form-collaborator-allocation>
    <div class="row justify-end">
      <button
      class="btn-no-border m-horizontal-xl hint"
      [disabled]="disableButtonReplyAllocation[formIndex]"
      (click)="addCollaborator(true, item, formIndex)">
      Replicar alocação
      <img src="./assets/svg/add.svg" alt="add">
    </button>
    <button
      *ngIf="formIndex === allocation.length -1" class="btn-no-border m-horizontal-xl hint"
      (click)="addCollaborator()">
      Nova alocação
      <img src="./assets/svg/add.svg" alt="add">
     </button>
      </div>
  </ng-container>
  <div class="row m-xl p-xl justify-end">
    <button class="btn secondary m-md"
    (click)="cancelAllocation()"
    type="button">Cancelar</button>
    <button
      class="btn primary m-md"
      matTooltip="Finalize o cadastro para habilitar o envio"
      [matTooltipDisabled]="disableButton"
      [disabled]="disableButton"
      (click)="postAllocation()"
    >
      Confirmar alocação
    </button>
  </div>
