import { Component, OnInit } from '@angular/core';
import {  NavigationEnd,  Router } from '@angular/router';
import {Location} from "@angular/common";

@Component({
  selector: 'app-client-project',
  templateUrl: './client-project.component.html',
  styles: [],
})
export class ClientProjectComponent implements OnInit {
  currentRoute!: string;
  tabSelected: number = 0;

  constructor(private _router: Router,
    private location: Location ) {
    this.currentRoute = this._router.url.substring(this._router.url.lastIndexOf('/') + 1);
    this.changeTab();
  }

  ngOnInit(): void {
    this.changeTab();
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url.slice(1);
      }
    });
  }
  changeTab(){
    if(this.currentRoute === 'clientes') this.tabSelected = 0;
    if(this.currentRoute === 'projetos') this.tabSelected = 1;
  }

  changeURLparams(event: any){
    this.tabSelected = event.index;
    this.tabSelected === 0?
    this.location.replaceState(`/clientes-e-projetos/clientes`):
    this.location.replaceState(`/clientes-e-projetos/projetos`);
    const path  = this.location.path();
    this.currentRoute = path.substring(path.lastIndexOf('/') + 1);
  }
}
