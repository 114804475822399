import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageClientListComponent } from './pages/components/clients/page-client-list/page-client-list.component';
import { ClientsProjectsRoutingModule } from './clients-projects-routing.module';
import { ClientProjectComponent } from './pages/container/client-project/client-project.component';
import { ClientManageModalComponent } from './modal/client-manage-modal/client-manage-modal.component';
import { MatTableModule } from '@angular/material/table';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule} from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatSortModule } from '@angular/material/sort';
import { NgxMaskModule } from 'ngx-mask';
import { MatTabsModule } from '@angular/material/tabs';
import { PageManageProjectComponent } from './pages/components/projects/page-manage-project/page-manage-project.component';
import { PageProjectsListComponent } from './pages/components/projects/page-projects-list/page-projects-list.component';
import { DirectiveModule } from 'src/app/core/directives/directives.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    ClientProjectComponent,
    PageClientListComponent,
    ClientProjectComponent,
    ClientManageModalComponent,
    PageManageProjectComponent,
    PageProjectsListComponent,
  ],
  imports: [
    CommonModule,
    ClientsProjectsRoutingModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatTabsModule,
    FormsModule,
    ComponentsModule,
    NgxMaskModule,
    DirectiveModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatAutocompleteModule
  ]
})


export class ClientsProjectsModule { }
