import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { UserRoleService } from 'src/app/pages/account/repository/user-role.service';
import {
  EProcess,
  permissionType,
  process,
} from 'src/app/shared/interfaces/roles.interface';

@Directive({
  selector: '[permissions]',
})
export class RoleDirective implements OnInit {
  @Input() process!: process | process[];
  @Input() role!: permissionType;

  constructor(
    private _userRoleService: UserRoleService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}
  ngOnInit(): void {
    if (this.process && this.role) {
      if (this.process === EProcess.Profile) {
        this.addTemplate();
      } else {
        if (typeof this.process === 'string') {
          let permission = this._userRoleService.hasRole(
            this.process,
            this.role
          );
          permission ? this.addTemplate() : this.clearTemplate();
        } else if (this.process.length > 0) {
          let permission = false;
          this.process.forEach((process) => {
            permission = this._userRoleService.hasRole(process, this.role)
              ? true
              : permission;
          });
          permission ? this.addTemplate() : this.clearTemplate();
        }
      }
    }
  }

  private addTemplate() {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private clearTemplate() {
    this.viewContainer.clear();
  }
}
