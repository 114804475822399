import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { LoginGuard } from 'src/app/core/guards/login.guard';
import { AccountContainerComponent } from './pages/container/account-container/account-container.component';
import { VerifyEmailComponent } from './pages/forgot-password/verify-email/verify-email.component';
import { InsertCodeComponent } from './pages/forgot-password/insert-code/insert-code.component';
import { ResetPasswordComponent } from './pages/forgot-password/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: 'account',
    component: AccountContainerComponent,
    canActivate: [LoginGuard],
    children: [{
      path: 'login',
      component: LoginComponent,
    },
    {
      path: 'login/:idLog',
      component: LoginComponent,
    }, 
    {
      path: 'gerar-codigo',
      component: VerifyEmailComponent,
    },
    {
      path: 'verificacao',
      component: InsertCodeComponent,
    },
    {
      path: 'reset-password',
      component: ResetPasswordComponent,
    }]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}

export const AccountRouting: ModuleWithProviders<AccountRoutingModule> = RouterModule.forChild(routes);