<div class="content">
  <form [formGroup]="filterForm">
    <div class="row">
      
      <div class="row align-center">
        <mat-label class="input-label--custom">Início:</mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <input
            matInput
            [matDatepicker]="picker1"
            formControlName="startDate"


          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="row align-center">
        <mat-label class="input-label--custom">Fim:</mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <input
            matInput
            [matDatepicker]="picker2"
            formControlName="endDate"

          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="row align-center">
        <mat-label class="input-label--custom">Cliente:</mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <input
            type="text"
            matInput
            [matAutocomplete]="client"
            formControlName="clientId"
            (keyup)="filterAutocomplete('clientId', $event.target)"
          />
          <mat-autocomplete
            #client="matAutocomplete"
            class="custom-select"
            [displayWith]="displayFn.bind(this, 'clients')"
          >
            <mat-option
              *ngFor="let option of clients"
              [value]="option.clients"
              (onSelectionChange)="
                changeChipList($event, 'clientId', option)
              "
            >
              {{ option?.clients?.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="row align-center">
        <mat-label class="input-label--custom">PIP:</mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <mat-select
            class="custom-select"
            formControlName="projectPIP"
            [attr.title]="
              filterForm.controls['projectPIP'].value
                ? null
                : 'Escolha um cliente para habilitar esta opção'
            "
          >
            <mat-option
              *ngFor="let project of projectsList"
              [value]="project.pip"
            >
              {{ project.pip }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row align-center">
        <mat-label class="input-label--custom">Projeto:</mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <mat-select
            class="custom-select"
            formControlName="projectId"
            [attr.title]="
              filterForm.controls['clientId'].value
                ? null
                : 'Escolha um cliente para habilitar esta opção'
            "
          >
            <mat-option
              *ngFor="let project of projectsList"
              [value]="project"
              >{{ project.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col p-md">
        <mat-form-field
          class="rounded input--width-full search-button"
          appearance="fill"
          floatLabel="always"
          placeholder="Faça uma busca"
        >
          <input
            placeholder="Faça uma busca"
            type="text"
            matInput
            [formControl]="searchByNameCtrl"
          />
          <mat-icon matPrefix>search</mat-icon>

          <mat-icon
          title="Limpar pesquisa"
            *ngIf="searchByNameCtrl?.value?.length >= 1"
            (click)="clearFilter()"
            matSuffix
            >backspace</mat-icon
          >
          <button
            matSuffix
            class="btn primary btn--width-xxs"
            (click)="searchBy()"
          >
            Buscar
          </button>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="row m-md">
    <mat-chip-list>
      <span class="icon-info" *ngIf="chipsList.length > 0">
        <mat-icon
        matTooltipClass='info-tooltip'
        [matTooltipPosition]="'above'"
        matTooltip="Nos campos do filtro, você pode escolher filtrar mais de um em cada categoria."
          >info</mat-icon
        >
        Filtros:</span
      >
      <ng-container *ngFor="let chip of chipsList">
        <mat-chip *ngIf="chip" (click)="removeFilterValueChips(chip)">
          <span *ngIf="!chip.name">{{ chip }}</span>
          <span *ngIf="chip.name">{{ chip.name }}</span>
          <mat-icon>clear</mat-icon>
        </mat-chip>
      </ng-container>
      <mat-chip
        class="remove-chip"
        *ngIf="chipsList.length > 0"
        (click)="removeAllChips()"
      >
        <span class="error">Excluir todos</span>
        <mat-icon>clear</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div class="table-expansive">
    <table
      multiTemplateDataRows
      mat-table
      [dataSource]="dataSource"
      matSort
      class="table-primary"
    >
      <ng-container
        matColumnDef="{{ column.key }}"
        *ngFor="let column of columns | keyvalue"
      >
        <th mat-header-cell *matHeaderCellDef>
          <div class="alinhar-icon">
            <ng-container>
              {{ column.value }}
            </ng-container>
            <ng-container
              *ngIf="
                column.key !== 'acao' &&
                column.key !== 'acoes'
              "
            >
              <mat-icon (click)="ordenar(getOrderByValue(column.key))"
                ><img src="./assets/svg/orderBy.svg" alt=""
              /></mat-icon>
            </ng-container>
          </div>
        </th>

        <td mat-cell *matCellDef="let element; let index = index" #elRow>
          <div
            *ngIf="column.key == 'acao'"
            style="padding-bottom: 10px; margin-right: 10px"
            class="mat-column-status"
          >
            <mat-icon
              ><button
                (click)="projectAllocation(element['projectId'])"
                class="btn-table"
              >
                Alocar
              </button></mat-icon
            >
          </div>
          <div
            *ngIf="column.key == 'acoes'"
            class="mat-column-status"
            (click)="navigateToAllocationProject(element['projectId'])"
          >
            <mat-icon
              ><img width="32px" src="./assets/svg/edit.svg" alt=""
            /></mat-icon>
          </div>
          <div
            *ngIf="column.key != 'acao'"
            [ngClass]="{
              pill: column.key === 'descriptionStatusAllocation',
              'pill-status-1': element[column.key] === 'Pausado',
              'pill-status-2': element[column.key] === 'Encerrado',
              'pill-status-3': element[column.key] === 'Para iniciar',
              'pill-status-4': element[column.key] === 'Em Andamento',
              'pill-status-5': element[column.key] === 'Cancelado'
            }"
          >
            {{ element[column.key] }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="disclaimer">
        <td mat-header-cell *matHeaderCellDef colspan="2">
          <mat-select
            (selectionChange)="changeItemsPerPage($event.value)"
            [(value)]="filterConfig.take"
            class="rounded input--width-xs-secondary m-md"
          >
            <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
              {{ option }} itens por página
            </mat-option>
          </mat-select>
        </td>
      </ng-container>
      <tr mat-footer-row *matHeaderRowDef="['disclaimer']"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="element-row"
      ></tr>
    </table>
    <app-paginator
      *ngIf="countDataSource > filterConfig.take"
      [length]="countDataSource"
      [pageSize]="filterConfig.take"
      (skipPage)="skipPage($event)"
    ></app-paginator>
  </div>
</div>
