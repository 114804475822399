<div class="horizontal-xl">
  <div class="modalConfirme">
    <div class="titleModal">
      <p>{{ data.message }}</p>
    </div>
    <div class="centralize" mat-dialog-actions>
      <button
        class="btn btn--width-xs m-2xl primary"
        mat-button
        [mat-dialog-close]="true"
        cdkFocusInitial
      >
        {{ data.buttonTxt }}
      </button>
    </div>
  </div>
</div>
