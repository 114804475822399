export class SkillTypeManager {
    constructor(private collaboratorId: string, private dataSource: any) {}
  
    extractFormValues(skillForm: any) {
      return {
        skillTypeId: skillForm.value.skillTypeId,
        skillsId: skillForm.value.skillsId,
        level: skillForm.value.skillLevel,
      };
    }
  
    findSkillTypeIndex(skillForm: any) {
      return this.dataSource.data.findIndex(
        (skillType: any) => skillType.id === skillForm.value.skillType.id
      );
    }
    findSkillTypeIndexById(skillTypeId: string) {
        return this.dataSource.data.findIndex(
          (skillType: any) => skillType.id === skillTypeId
        );
      }
  
    createSkillType(skillForm: any, skill: any) {
      return {
        id: skillForm.value.skillType.id,
        description: skillForm.value.skillType.description,
        status: true,
        skills: [skill],
      };
    }
  }