import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  url: string = 'https://viacep.com.br/ws';
  type: string = 'json';

  constructor(public http: HttpClient) {}

  getAddressByZipCode(zipCode: string): Observable<any> {
    return this.http.get<any>(`${this.url}/${zipCode}/${this.type}`);
  }
}
