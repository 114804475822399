import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { UserService } from '../../../repository/user.service';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { IVerificationCode } from '../../../interface/reset-password.interface';
import { MessageService } from 'src/app/core/services/message.service';

@Component({
  selector: 'app-insert-code',
  templateUrl: './insert-code.component.html',
  styles: [],
})
export class InsertCodeComponent implements OnInit {
  private _subs = new SubSink();
  codeForm!: FormGroup;
  email!: string;
  constructor(
    private _fb: FormBuilder,
    private _userService: UserService,
    private _router: Router,
    private _messageService: MessageService,
  ) {
    this.createFormGroup();
    try {
      const navigation = this._router.getCurrentNavigation()?.extras.state as {
        email: string;
      };
      this.email = navigation.email;
    } catch (error) {
      if (!this.email) this.returnTologin();
    }
  }

  ngOnInit(): void {}

  createFormGroup() {
    this.codeForm = this._fb.group({
      verificationCode: ['', [Validators.required]],
    });
  }

  onSubmitVerifyCode() {
    const verificationCode: IVerificationCode = {
      email: this.email,
      verificationCode: Number(this.codeForm.value['verificationCode']),
    };

    if (this.codeForm.dirty && this.codeForm.valid) {
      this._subs.sink = this._userService
        .validateVerificationCode(verificationCode)
        .subscribe(
          (response) => {
            this.validateCodeSucess(response);
          },
          (failed) => {
            this.validateCodeFailed(failed.error.errors);
          }
        );
    } else {
      this.codeForm.markAllAsTouched();
    }
  }

  validateCodeSucess(response: IApiResponse) {
    if (!response) {
      const navigationExtras: NavigationExtras = {
        state: { email: this.email },
      };
      this._router.navigate(['account/reset-password'], navigationExtras);
    }
  }

  validateCodeFailed(errors: Array<string>) {
    this._messageService.openErrorMessage(errors);
    this.returnTologin();
  }

  returnTologin() {
    this._router.navigate(['account/login']);
  }
}
