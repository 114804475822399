import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-legend-item',
  templateUrl: './legend-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendItemComponent {
  @Input() color!: 'green' | 'yellow' | 'red' | 'gray' | 'purple';
  @Input() text: string = '';

  colors: { [key: string]: string } = {
    green: '#2ECC71',
    yellow: '#FAA831',
    red: '#E74C3C',
    gray: '#BDC3C7',
    purple: '#8E44AD',
  };
}
