import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountGuard } from 'src/app/core/guards/account.guard';
import { PermisionsComponent } from './pages/container/permisions/permisions.component';
import { PermissionGuard } from 'src/app/core/guards/permission.guard';

const routes: Routes = [
  {
    path: 'permissoes',
    redirectTo: 'permissoes/cadastrar',
  },
  {
    path: 'permissoes/cadastrar',
    component: PermisionsComponent,
    canActivate: [PermissionGuard],
    data: {
      process: 'Permission',
      permission: 'Create/Update',
    },
  },
  {
    path: 'permissoes/gerenciar',
    component: PermisionsComponent,
    canActivate: [PermissionGuard],
    data: {
      process: 'Permission',
      permission: 'Create/Update',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PermissionRoutingModule {}
