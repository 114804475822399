import { Injectable } from '@angular/core';
import { User } from '../../../shared/models/user.model';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';
import { Observable, Subject } from 'rxjs';
import { LocalStorage } from 'src/app/core/guards/localstorage';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  public localStorage = new LocalStorage();

  changeDisableLogin = new Subject<boolean>();

  constructor(public http: HttpClient) {}

  login(user: User): Observable<any> {
    const response = this.http.post(`${environment.apiUrl}/login`, user);
    return response;
  }

  loginByToken(token: string): Observable<IApiResponse> {
    let model = {
      token,
    };

    return this.http.post<IApiResponse>(`${environment.apiUrl}/login`, model);
  }
}
