<mat-tab-group
  animationDuration="0ms"
  class="tab-page"
  [selectedIndex]="tabSelected"
  (selectedTabChange)="changeURLparams($event)"
>
  <ng-template permissions [role]="'Read'" [process]="'Client'">
    <mat-tab label="Clientes">
      <app-page-client-list
        *ngIf="currentRoute === 'clientes'"
      ></app-page-client-list>
    </mat-tab>
  </ng-template>
  <ng-template permissions [role]="'Read'" [process]="'Project'">
    <mat-tab label="Projetos">
      <app-page-projects-list
        *ngIf="currentRoute === 'projetos'"
      ></app-page-projects-list>
    </mat-tab>
  </ng-template>
</mat-tab-group>
