import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class LogVerifyService {

  endpoint: string = 'LogVerifyEmail';

  constructor(public http: HttpClient) { }

  verifyEmail(idLog: string): Observable<any>{
    const path: string = 'verifyEmailActivation'
    const response = this.http
    .post( `${environment.apiUrl}/${this.endpoint}/${path}/${idLog}`, null)
    return response;
  }
}

 