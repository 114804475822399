import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/core/services/message.service';
import { SkillTypeManageModalComponent } from 'src/app/pages/skills/modal/skill-type-manage-modal/skill-type-manage-modal.component';
import { SubSink } from 'subsink';
import { ProfileService } from '../../repository/profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-manage-modal',
  templateUrl: './profile-manage-modal.component.html',
  styles: [],
})
export class ProfileManageModalComponent implements OnInit {
  isEdit: boolean = false;
  profileTypeForm!: FormGroup;
  private _subs = new SubSink();
  private _id: string | undefined;
  constructor(
    private _router: Router,
    private _fb: FormBuilder,
    private _profileService: ProfileService,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<SkillTypeManageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.createFormGroup();

    try {
      this._id = this.data.id;
      this.isEdit = true;
      this.profileTypeForm.patchValue({
        name: this.data.name,
        id: this.data.id,
      });
    } catch (error) {
       this.isEdit = false;
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  createFormGroup() {
    this.profileTypeForm = this._fb.group({
      id:'',
      name: ['', Validators.required],
    });
  }

  onSubmitProfileType() {
    const newProfileType = this.profileTypeForm.value;
    const form = {
       name: newProfileType.name,
    }
    if(!this.isEdit){
    this._subs.add(
      this._profileService.postProfile(form).subscribe((response) => {
        if (response.success === true) {
          this._messageService.openSuccessMessage(
            'Cadastro realizado com sucesso.'
          );
          this.reloadComponent();
        } else if (response.errors.length > 0) {
          this._messageService.openErrorMessage(response.errors);
        }
        this.close({ reload: true });
      })
    );
  }else{
    this._subs.add(
      this._profileService.putProfile(newProfileType).subscribe((response) => {
        if (response.success === true) {
          this._messageService.openSuccessMessage(
            'Cadastro alterado com sucesso.'
          );
          this.reloadComponent();
        } else if (response.errors.length > 0) {
          this._messageService.openErrorMessage(response.errors);
        }
        this.close({ reload: true });
      })
    );
  }
  }
  close(emit = { reload: false }): void {
    this.dialogRef.close(emit);
  }

  reloadComponent() {
    const url = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([`/${url}`]);
    });
  }
}
