import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserRoleService } from 'src/app/pages/account/repository/user-role.service';
import { Location } from '@angular/common';
import { MessageService } from '../services/message.service';
import {
  permissionType,
  process,
} from 'src/app/shared/interfaces/roles.interface';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(
    private _userRoleService: UserRoleService,
    private _location: Location,
    private _messageService: MessageService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Observable<boolean | UrlTree>((observer) => {
      this._userRoleService.changeUserRoles$.subscribe((value) => {        
        if (value) {
          let processValue = next.data['process'];
          let permission = false;

          if(typeof processValue === 'string'){
            permission = this._userRoleService.hasRole(
              next.data['process'],
              next.data['permission']
            );
          } else if(processValue?.length > 0){
            processValue.forEach((item: any) => {
              permission = this._userRoleService.hasRole(
                item,
                next.data['permission']
              ) ? true : permission;
            });
          }

          if (!permission) {
            this._messageService
              .openErrorMessageDefault('Acesso não autorizado', 4000)
              .subscribe((response) => this._location.back());
          }
          observer.next(permission);
          observer.complete();
        }
      });
    });
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}

export interface IProfileProcessPermission {
  profileProcessPermissions: ModulePermission[];
  token: string;
}

export class ModulePermission {
  process!: process;
  permissions!: Array<permissionType>;

  constructor(permission: ModulePermission) {
    Object.assign(this, permission);
  }
}
