import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export abstract class BaseApiService {

  private _apiUrl: string = environment.apiUrl

  constructor(protected http: HttpClient) { }
  
  get<T>(endpoint: string, path: string = '', param: string = ''): Observable<T> {  

    let url = `${this._apiUrl}/${endpoint}/${path}`;
    if(param) url += `/${param}`
    return this.http
    .get<IApiResponse>(url)
    .pipe(map((response: IApiResponse) => response.result));
  }

  handleError(err: any, path: string){
    return err ;
  }
}
