<div class="p-top-lg">
  <div>
    <table mat-table [dataSource]="dataSource" matSort class="table-primary table-clickable">
      <ng-container
        matColumnDef="{{ column.key }}"
        *ngFor="let column of columns | keyvalue; let last = last"
      >
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          [ngClass]="{ 'p-th-none': last }"
        >
          <ng-container *ngIf="column.key != 'redirect'">
            {{ column.value }}
          </ng-container>
          <ng-container
            *ngIf="
              column.key !== 'projectStatusDescription' &&
              column.key !== 'redirect'
            "
          >
            <mat-icon (click)="changeOrder(orderOptions[column.key])"
              ><img src="./assets/svg/orderBy.svg" alt=""
            /></mat-icon>
          </ng-container>
        </th>

        <td mat-cell *matCellDef="let element; let index = index" #elRow>
          <div
            *ngIf="column.key == 'redirect'"
            class="mat-column-status cursor-pointer"
          >
            <div
              class="mat-column-status"
              (click)="navigateToAllocationProject(element.projectId)"
            >
              <mat-icon
                ><img width="32px" src="./assets/svg/edit.svg" alt=""
              /></mat-icon>
            </div>
          </div>
          <div
            *ngIf="column.key != 'redirect'"
            [ngClass]="{
              pill: column.key === 'projectStatusDescription',
              'pill-status-1': element[column.key] === 'Pausado',
              'pill-status-2': element[column.key] === 'Encerrado',
              'pill-status-3': element[column.key] === 'Para iniciar',
              'pill-status-4': element[column.key] === 'Em Andamento',
              'pill-status-5': element[column.key] === 'Cancelado'
            }"
          >
            <span
              *ngIf="
                column.key !== 'projectInitiation' &&
                  column.key !== 'projectConclusion';
                else date
              "
              >{{ element[column.key] }}</span
            >
            <ng-template #date>
              <span>{{ element[column.key] | date : "dd/MM/yyyy" }}</span>
            </ng-template>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="disclaimer">
        <td mat-header-cell *matHeaderCellDef colspan="1">
          <mat-select
            (selectionChange)="changeItemsPerPage($event.value)"
            class="rounded input--width-sm m-md"
            [(ngModel)]="pageSize"
          >
            <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
              {{ option }} itens por página
            </mat-option>
          </mat-select>
        </td>
      </ng-container>
      <tr mat-footer-row *matHeaderRowDef="['disclaimer']"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <ng-container>
      <div class="has-content">
        <mat-card *ngIf="!hasAllocationProject">
          Sem projetos com alocações.
        </mat-card>
      </div>
    </ng-container>
    <app-paginator
      *ngIf="countDataSource > filter.take"
      [length]="countDataSource"
      [pageSize]="filter.take"
      (skipPage)="skipPage($event)"
    ></app-paginator>
  </div>
</div>
