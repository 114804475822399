<div class="modal-dialog-close">
  <div class="p-horizontal-xl">
    <h2 class="m-bottom-xl">Inativar habilidades</h2>
    <p class="m-bottom-xl">
      Não é possível <strong>excluir habilidades registradas </strong>, pois
      isso afeta os dados de alocação. Apenas os administradores poderão
      excluir.
    </p>
    <p class="m-bottom-xl">
      <strong>Ao desativar uma habilidade</strong>, ela não será mais visível
      nas buscas, mas permanecerá no seu perfil.
    </p>
    <div class="col m-vertical-md align-center">
      <mat-form-field
        class="input-outline m-vertical-xl input--width-md"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Digite um motivo</mat-label>
        <input
          type="text"
          maxlength="200"
          matInput
          [formControl]="reasonInactivation"
          required
        />
      </mat-form-field>
      <button [disabled]="reasonInactivation.invalid" class="btn btn--width-md primary" (click)="confirmSubmit()">
        Inativar
      </button>
    </div>
  </div>
  <div class="position m-top-xl">
    <button class="position btn-icon-close" (click)="close()">
      <img src="./assets/svg/close-button.svg" alt="" />
    </button>
  </div>
</div>
