<div class="content">
  <div class="row page-header space-between p-vertical-lg">
    <div class="col page-title">
      <h1>{{ pageTitle }}</h1>
    </div>
    <ng-template
      permissions
      [role]="'Create/Update'"
      [process]="'Collaborator'"
    >
      <button
        class="btn secondary btn--width-sm"
        (click)="createCollaborator()"
      >
        {{ btnText }}
      </button>
    </ng-template>
  </div>
  <form [formGroup]="filterForm">
    <div class="filter-ctn">
      <div class="row align-center p-vertical-md input-filter-collaborator">
        <mat-form-field
          class="rounded input--width-full search-button"
          appearance="fill"
          floatLabel="always"
        >
          <input
            placeholder="Faça uma busca"
            type="text"
            matInput
            [formControl]="searchByNameCtrl"
          />
          <mat-icon matPrefix>search</mat-icon>
          <mat-icon
            title="Limpar pesquisa"
            *ngIf="searchByNameCtrl?.value?.length >= 1"
            (click)="clearFilter()"
            matSuffix
            >backspace</mat-icon
          >
          <button
            matSuffix
            class="btn primary btn--width-xxs"
            (click)="searchBy()"
          >
            Buscar
          </button>
        </mat-form-field>
      </div>
      <div class="row" *ngIf="!!options">
        <div class="row align-center">
          <mat-label class="input-label--custom">Função: </mat-label>
          <mat-form-field
            class="rounded input--width-xs-secondary m-sm"
            appearance="fill"
            floatLabel="always"
          >
            <input
              type="text"
              matInput
              [matAutocomplete]="jobFunction"
              formControlName="jobFunctionId"
            />
            <mat-autocomplete
              #jobFunction="matAutocomplete"
              class="custom-select"
              [displayWith]="displayFn.bind(this, 'jobFunctions')"
              [autoSelectActiveOption]="true"
            >
              <mat-option
                *ngFor="let option of jobFunctions"
                [value]="option.id"
                (onSelectionChange)="
                  changeChipList($event, 'jobFunctionId', option.description, option.id)
                "
              >
                {{ option.description }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="row align-center">
          <mat-label class="input-label--custom">Status: </mat-label>
          <mat-form-field
            class="rounded input--width-xs-secondary m-sm"
            appearance="fill"
            floatLabel="always"
          >
            <mat-select
              class="custom-select"
              formControlName="inactive"
              (selectionChange)="changeChipList($event, 'inactive', !filterForm.controls['inactive'].value ? 'Ativo' : 'Inativo')"
            >
              <mat-option [value]="false"> Ativo </mat-option>
              <mat-option [value]="true"> Inativo </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row align-center">
          <mat-label class="input-label--custom">Habilidade: </mat-label>
          <mat-form-field
            class="rounded input--width-xs-secondary m-sm"
            appearance="fill"
            floatLabel="always"
          >
            <input
              type="text"
              matInput
              [matAutocomplete]="skill"
              formControlName="skillsId"
            />
            <mat-autocomplete
              class="custom-select"
              #skill="matAutocomplete"
              [displayWith]="displayFn.bind(this, 'skills')"
            >
              <mat-option
                *ngFor="let option of skills"
                [value]="option.id"
                (onSelectionChange)="
                  changeChipList($event, 'skillsId', option.description, option.id)
                "
              >
                {{ option.description }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="row align-center">
          <mat-label class="input-label--custom">Nível: </mat-label>
          <mat-form-field
            class="rounded input--width-xs-secondary m-sm"
            appearance="fill"
            floatLabel="always"
          >
            <mat-select class="custom-select" formControlName="skillLevel">
              <mat-option
                *ngFor="let option of options?.skillLevel"
                [value]="option.value"
                (onSelectionChange)="
                  changeChipList($event, 'skillLevel', option.description, option.value)
                "
              >
                {{ option.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row align-center">
          <mat-label class="input-label--custom">Cidade: </mat-label>
          <mat-form-field
            class="rounded input--width-xs-secondary m-sm"
            appearance="fill"
            floatLabel="always"
          >
            <input
              type="text"
              matInput
              [matAutocomplete]="city"
              formControlName="city"
            />
            <mat-autocomplete
              class="custom-select"
              #city="matAutocomplete"
              [displayWith]="displayFn.bind(this, 'cities')"
            >
              <mat-option
                *ngFor="let option of cities"
                [value]="option.description"
                (onSelectionChange)="
                  changeChipList($event, 'city', option.description, option.description)
                "
              >
                {{ option.description }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="row align-center">
          <mat-label class="input-label--custom">Torre:</mat-label>
          <mat-form-field
            class="rounded input--width-xs-secondary m-sm"
            appearance="fill"
            floatLabel="always"
          >
            <input
              type="text"
              matInput
              [matAutocomplete]="tower"
              formControlName="towerId"
            />
            <mat-autocomplete
              class="custom-select"
              [displayWith]="displayFn.bind(this, 'towers')"
              #tower="matAutocomplete"
            >
              <mat-option
                *ngFor="let option of towers"
                [value]="option.id"
                (onSelectionChange)="
                  changeChipList($event, 'towerId', option.description, option.id)
                "
              >
                {{ option.description }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>

  <div class="row p-vertical-md">
    <mat-chip-list>
      <span class="icon-info p-horizontal-xs" *ngIf="chipsList.length > 0">
        <mat-icon
          matTooltipClass="info-tooltip"
          [matTooltipPosition]="'above'"
          matTooltip="Nos campos do filtro, você pode escolher filtrar mais de um em cada categoria."
          >info</mat-icon
        >
        Filtros:</span
      >
      <ng-container *ngFor="let chip of chipsList">
        <mat-chip (click)="removeFilterValueChips(chip)">
          <span>{{ chip.description }}</span>
          <mat-icon>clear</mat-icon>
        </mat-chip>
      </ng-container>
      <mat-chip
        class="remove-chip"
        *ngIf="chipsList.length > 0"
        (click)="removeAllChips()"
      >
        <span class="error">Excluir todos</span>
        <mat-icon>clear</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div class="p-bottom-xl">
    <table
      mat-table
      [dataSource]="dataSource"
      class="table-primary table-clickable table-collaborator"
    >
      <ng-container
        matColumnDef="{{ column.key }}"
        *ngFor="let column of columns | keyvalue; let last = last"
      >
        <th
          class="p-xs"
          [ngClass]="{ 'p-th-none': last }"
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-img': column.key == 'fullName' }"
        >
          <div class="alinhar-icon p-xs">
            <span>{{ column.value }}</span>

            <ng-container
              *ngIf="column.key !== 'inactive' && column.key !== 'edit'"
            >
              <mat-icon (click)="changeOrder(orderOptions[column.key])">
                <img src="./assets/svg/orderBy.svg" alt="" />
              </mat-icon>
            </ng-container>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let index = index"
          #elRow
          [ngClass]="{ 'td-img': column.key == 'fullName' }"
        >
          <ng-template permissions [role]="'Delete'" [process]="'Collaborator'">
            <ng-container *ngIf="column.key == 'inactive'">
              <div *ngIf="!element.excludedCollaborator" class="p-xs">
                <mat-slide-toggle
                  class="switch"
                  [(ngModel)]="!element.inactive"
                  (change)="collaboratorInactivation($event, element, index)"
                ></mat-slide-toggle>
              </div>
            </ng-container>
          </ng-template>
          <ng-container *ngIf="column.key == 'fullName'">
            <div class="img-ctn">
              <div class="img-table flex justify-center align-center">
                <img
                  *ngIf="element.imageURL"
                  [src]="element.imageURL | safeResourceUrl"
                  alt=""
                />
                <img
                  *ngIf="!element.imageURL"
                  src="./assets/img/account_circle.png"
                  alt=""
                />
              </div>
            </div>
          </ng-container>
          <div
            [ngClass]="{ inactive: element.inactive === true }"
            [class]="'row-' + index"
            class="p-xs"
            *ngIf="
              column.key != 'inactive' &&
              column.key != 'imageUrl' &&
              column.key != 'edit'
            "
          >
            {{ element[column.key] }}
          </div>
          <div *ngIf="column.key === 'edit'" class="flex justify-center p-xs">
            <button
              title="Editar colaborador"
              (click)="editCollaborator(element.collaboratorId, column.key)"
              class="btn-icon-edit"
            >
              <ng-template
                permissions
                [role]="EPermissionType.CreateUpdate"
                [process]="[
                  EProcess.CollaboratorPersonalData,
                  EProcess.CollaboratorContractualinformation,
                  EProcess.CollaboratorAddress,
                  EProcess.CollaboratorSkills
                ]"
              >
                <mat-icon>
                  <img src="./assets/svg/edit-black.svg" alt=""
                /></mat-icon>
              </ng-template>

              <ng-template
                permissions
                [role]="EPermissionType.Read"
                [process]="[
                  EProcess.CollaboratorPersonalData,
                  EProcess.CollaboratorContractualinformation,
                  EProcess.CollaboratorAddress,
                  EProcess.CollaboratorSkills
                ]"
              >
                <ng-container *ngIf="!isEditable">
                  <mat-icon>
                    <img
                      class="view-table"
                      src="./assets/svg/visibility.svg"
                      alt=""
                  /></mat-icon>
                </ng-container>
              </ng-template>
            </button>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="disclaimer">
        <td mat-header-cell *matHeaderCellDef colspan="1">
          <mat-select
            (selectionChange)="changeItemsPerPage($event.value)"
            class="rounded input--width-sm m-md"
            [(ngModel)]="pageSize"
          >
            <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
              {{ option }} itens por página
            </mat-option>
          </mat-select>
        </td>
      </ng-container>
      <tr mat-footer-row *matHeaderRowDef="['disclaimer']"></tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <app-paginator
      *ngIf="countDataSource > filter.take"
      [length]="countDataSource"
      [pageSize]="filter.take"
      [currentPage]="currentPage"
      (skipPage)="skipPage($event)"
    ></app-paginator>
  </div>
</div>
