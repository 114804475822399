import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manage-allocation-project',
  templateUrl: './manage-allocation-project.component.html',
  styles: [],
})
export class ManageAllocationProjectComponent implements OnInit {
  currentRoute!: string;

  constructor(private _router: Router, private _route: ActivatedRoute) {}

  ngOnInit(): void {}
}
