import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ITeamTower } from 'src/app/pages/tower-teams/interface/team.inteface';
import { TeamManageModalComponent } from 'src/app/pages/tower-teams/modal/team-manage-modal/team-manage-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TeamService } from 'src/app/pages/tower-teams/repository/team.service';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';
import {
  EProcess,
  EPermissionType,
} from 'src/app/shared/interfaces/roles.interface';
import { TowerService } from 'src/app/pages/tower-teams/repository/tower.service';
import { ITower } from 'src/app/pages/tower-teams/interface/tower.interface';

@Component({
  selector: 'app-teams-detail',
  templateUrl: './teams-detail.component.html',
  styleUrls: [],
})
export class TeamsDetailComponent implements OnInit {
  towers: ITower[] = [];
  team!: ITeamTower;
  teamId!: string;
  EProcess = EProcess;
  EPermissionType = EPermissionType;

  private _subs = new SubSink();

  constructor(
    public dialog: MatDialog,
    private _location: Location,
    private _teamService: TeamService,
    private _route: ActivatedRoute,
    private _towerService: TowerService
  ) {}

  ngOnInit() {
    this.teamId = this._route.snapshot.paramMap.get('teamId')!;
    this.getInfoTeam();
    this.getTowerOptions();
  }

  getInfoTeam() {
    this._subs.add(
      this._teamService.getTeamById(this.teamId).subscribe((response) => {
        this.team = response;
      })
    );
  }

  getNamesClientstooltip(): string {
    if (this.team && this.team.clients) {
      return this.team.clients.map((client) => client).join(', ');
    }
    return '';
  }

  getNamesClients(): string {
    if (this.team && this.team.clients) {
      const firstTwoClients = this.team.clients.slice(0, 2);
      return firstTwoClients.map((client) => client).join(', ');
    }
    return '';
  }

  getTowerOptions() {
    this._subs.add(
      this._towerService.getAllTowerOptions().subscribe((res: any) => {
        if (res.success) {
          this.towers = res.result ?? [];
        }
      })
    );
  }

  openDialogTeam(team?: ITeamTower) {
    let dialogRef;
    if (team) {
      dialogRef = this.dialog.open(TeamManageModalComponent, {
        data: {
          team: {
            id: team.id,
            description: team.teamName,
            towerId: team.towerId,
            leaderId: team.leaderId,
          },
          towers: this.towers,
        },
        panelClass: 'modal-dialog-close-type',
      });
    } else {
      dialogRef = this.dialog.open(TeamManageModalComponent, {
        data: { towers: this.towers },
        panelClass: 'modal-dialog-close-type',
      });
    }

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.reload === true) this.getInfoTeam();
    });
  }

  navigateBack() {
    this._location.back();
  }
}
