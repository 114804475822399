<div
  [ngStyle]="{ 'background-color': colors[color] }"
  class="percentage-bar flex align-center"
  (click)="viewCollaborator.emit()"
>
  <span>{{
    percentage != null ? "Neste projeto: " + percentage + "%" : "Indisponível"
  }}</span>

  <div class="flex align-center">
    <ng-container *ngIf="isReservation">
      <span>
        <mat-icon
          matTooltipClass="warning-tooltip"
          matTooltip="Este colaborador tem uma ausência neste projeto. Para saber mais, clique no ícone de visualizar no lado direito."
        >
          <img src="./assets/svg/warning.svg" alt="" />
        </mat-icon>
      </span>
    </ng-container>
    <img src="./assets/svg/visibility.svg" alt="Visualizar" />
  </div>
</div>
