import { Injectable } from '@angular/core';
import { FilterProjectsAllocation } from 'src/app/shared/models/filter-projects.model';
import { FilterCollaboratorAllocation } from 'src/app/shared/models/filter.model';

export enum EFilterType {
  COLLABORATOR = 'COLLABORATOR',
  PROJECT = 'PROJECT'
}

type chip = {
  controlName: string;
  description: string;
};

@Injectable({
  providedIn: 'root'
})
export class AllocationFilterService {
  private filterAllocationCollaborator: FilterCollaboratorAllocation = new FilterCollaboratorAllocation();
  private filterAllocationProject: FilterProjectsAllocation = new FilterProjectsAllocation();

  private chipsListCollaborator: Array<chip> = [];
  private chipsFormControlCollaborator: Array<string> = [];

  private chipsListProject: Array<chip> = [];
  private chipsFormControlProject: Array<string> = [];

  constructor() { }

  // Métodos genéricos para filtros
  public setFilter<T>(filter: T, type: EFilterType) {
    if (type === EFilterType.COLLABORATOR) {
      this.filterAllocationCollaborator = filter as unknown as FilterCollaboratorAllocation;
    } else {
      this.filterAllocationProject = filter as unknown as FilterProjectsAllocation;
    }
  }

  public getFilter<T>(type: EFilterType): T {
    return type === EFilterType.COLLABORATOR ? this.filterAllocationCollaborator as unknown as T : this.filterAllocationProject as unknown as T;
  }

  // Métodos genéricos para chips
  public setChipsList(chipsList: Array<chip>, type: EFilterType) {
    if (type === EFilterType.COLLABORATOR) {
      this.chipsListCollaborator = chipsList;
    } else {
      this.chipsListProject = chipsList;
    }
  }

  public getChipsList(type: EFilterType): Array<chip> {
    return type === EFilterType.COLLABORATOR ? this.chipsListCollaborator : this.chipsListProject;
  }

  public getChipsFormControl(type: EFilterType): Array<string> {
    return type === EFilterType.COLLABORATOR ? this.chipsFormControlCollaborator : this.chipsFormControlProject;
  }

  public setChipsFormControl(chipsFormControl: Array<string>, type: EFilterType) {
    if (type === EFilterType.COLLABORATOR) {
      this.chipsFormControlCollaborator = chipsFormControl;
    } else {
      this.chipsFormControlProject = chipsFormControl;
    }
  }

  public resetChipsList(type: EFilterType) {
    if (type === EFilterType.COLLABORATOR) {
      this.chipsListCollaborator = [];
    } else {
      this.chipsListProject = [];
    }
  }

  public resetFilter(type: EFilterType) {
    if (type === EFilterType.COLLABORATOR) {
      this.filterAllocationCollaborator = new FilterCollaboratorAllocation();
    } else {
      this.filterAllocationProject = new FilterProjectsAllocation();
    }
  }

  public resetChipsFormControl(type: EFilterType) {
    if (type === EFilterType.COLLABORATOR) {
      this.chipsFormControlCollaborator = [];
    } else {
      this.chipsFormControlProject = [];
    }
  }
}
