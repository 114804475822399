import { IteamCollaborator } from '../../tower-teams/interface/team-collaborator.interface';

export interface IDataSourceProject {
  id: string;
  projectPIP: string;
  name: string;
  client: string;
  projectStatus: string;
  fullName: string;
  teams: IteamCollaborator[];
  projectInitiation: string;
  projectConclusion: string;
}

export interface IDataSourceProjectList {
  id?: string;
  clientId: string;
  clientName: string;
  descriptionStatusAllocation: string;
  statusAllocationId: string;
  projectId: string;
  projectManager: string;
  projectName: string;
  projectPIP: string;
  startDate: string;
  endDate: string;
}

export interface IProjectFilter {
  searchString: string | null;
  projectStatusId: string | null;
  projectPIP: string | null;
  clientId: string | null;
  projectInitiation: string | null;
  projectConclusion: string | null;
  page: number;
  take: number;
  orderByParam: { orderBy: number; asc: boolean };
}

export interface IProjectMinified {
  name: string;
  projectPIP: string;
  projectInitiation: string;
  projectConclusion: string;
  towerId: string;
  teamId: string;
  clientId: string;
  clientName: string;
  projectManagerId: string;
  projectManagerName: string;
  projectStatusId: string;
  projectStatusDescription: string;
}

export enum OrderByProject {
  NAME = 1,
  PROJECT_PIP = 2,
  CLIENT_NAME = 3,
  PROJECT_STATUS = 4,
  PROJECT_MANAGER = 5,
  INITIATION = 6,
  CONCLUSION = 7,
}