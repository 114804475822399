<form [formGroup]="resetForm" (ngSubmit)="onSubmitChangePassword()">
    <div class="col align-center">
      <div class="logo p-xl m-vertical-xl">
        <img src="/assets/img/Logo1_color_cinza.png" alt="" />
      </div>
      <span class="p-vertical-md"> Informe uma nova senha. </span>
      <mat-form-field
        class="input-outline input--width-md m-vertical-lg"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Digite sua nova senha</mat-label>
        <input
            type="{{(showPassword === false)?'password':'text'}}" 
          matInput
          formControlName="newPassword"
        />
        <mat-icon (click)="toggleShowPassword(showPassword)" 
        matSuffix>{{!showPassword?'visibility':'visibility_off'}}</mat-icon>
       
      </mat-form-field>
      <mat-form-field
      class="input-outline input--width-md m-vertical-lg"
      appearance="outline"
      floatLabel="always"
    >
      <mat-label>Repita a nova senha</mat-label>
      <input
        type="password"
        matInput
        formControlName="repeatPassword"
      />
    </mat-form-field>
    <mat-error *ngIf="resetForm.controls['repeatPassword'].hasError('notEqual')">
      As senhas não são iguais.
    </mat-error>
    </div>
    <div class="col align-center">
        <button
        class="btn secondary btn--width-md m-lg"
        type="submit"
        [disabled]="resetForm.invalid"
      >
        Enviar
      </button>
    </div>
    <div class="col">
        <button class="btn-no-border m-left-xl" (click)="returnTologin()">
          Login
        </button>
      </div>
  </form>