import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ITeam } from '../../interface/team.inteface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { TeamService } from '../../repository/team.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/core/services/message.service';
import { ITower } from '../../interface/tower.interface';
import { StringUtil } from 'src/app/shared/utils/string';

@Component({
  selector: 'app-team-manage-modal',
  templateUrl: './team-manage-modal.component.html',
  styles: [],
})
export class TeamManageModalComponent implements OnInit, OnDestroy {
  teamForm!: FormGroup;

  optionsTower: ITower[] = [];
  optionsLeader: Array<{}> = [];
  optionsCollaborator: Array<{
    fullName: string;
    imageURL: string;
    id: string;
    isLeader: boolean;
  }> = [];
  isEdit: boolean = false;
  private _id: string | undefined;
  private _subs = new SubSink();

  constructor(
    private _fb: FormBuilder,
    private _teamService: TeamService,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<TeamManageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.optionsTower = this.data.towers;
    this.createFormGroup();

    try {
      this.optionsCollaborator = this.data.team.collaborators;

      const team = this.data.team;
      this._id = team.id;
      this.isEdit = true;
      this.teamForm.patchValue({
        description: team.description,
        id: team.id,
        towerId: team.towerId,
        leaderId: team.leaderId,
      });
      this.getLeaderOptions();
    } catch (error) {
      this.isEdit = false;
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  getLeaderOptions() {
    const towerid = this.teamForm.controls['towerId'].value;
    const tower = this.optionsTower.find((tower) => tower.id == towerid);
    this.optionsCollaborator = [];
    if (tower && tower.collaborators)
      this.optionsCollaborator = [...tower.collaborators];
  }

  createFormGroup() {
    this.teamForm = this._fb.group({
      towerId: ['', Validators.required],
      description: ['', Validators.required],
      leaderId: [''],
      id: '',
    });
  }

  onSubmitTeam() {
    const newTeam = this.teamForm.value;
    newTeam.description = StringUtil.capitalize(newTeam.description);
    if (!this.isEdit) {
      delete newTeam.id;
      this._teamService.postTeam(newTeam).subscribe((response) => {
        if (response.success === true) {
          this._messageService.openSuccessMessage(
            'Cadastro realizado com sucesso.'
          );
        } else if (response.errors.length > 0) {
          this._messageService.openErrorMessage(response.errors);
        }
        this.close({ reload: true });
      });
    } else {
      this._subs.add(
        this._teamService.putTeam(newTeam).subscribe((response) => {
          if (response.success === true) {
            this._messageService.openSuccessMessage(
              'Cadastro alterado com sucesso.'
            );
          } else if (response.errors.length > 0) {
            this._messageService.openErrorMessage(response.errors);
          }
          this.close({ reload: true });
        })
      );
    }
  }

  close(emit = { reload: false }): void {
    this.dialogRef.close(emit);
  }
}
