<div class="content">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="filterForm">
        <div class="row space-start">
          <div class="row align-center">
            <mat-label class="input-label--custom">Ano: </mat-label>
            <mat-form-field
              class="rounded light-bg input--width-xs-secondary m-sm"
              appearance="fill"
              floatLabel="always"
            >
              <mat-select class="custom-select" formControlName="yearId">
                <mat-option
                  *ngFor="let option of optionsYear"
                  [value]="option.description"
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row align-center">
            <mat-label class="input-label--custom">Status: </mat-label>
            <mat-form-field
              class="rounded light-bg input--width-xs-secondary m-sm"
              appearance="fill"
              floatLabel="always"
            >
              <mat-select formControlName="statusRestId" class="custom-select">
                <mat-select-trigger>
                  <span
                    [style.color]="
                      getStatusColor(filterForm.get('statusRestId')?.value)
                    "
                    >{{
                      getStatusEnumName(filterForm.get("statusRestId")?.value)
                    }}</span
                  >
                </mat-select-trigger>
                <mat-option
                  *ngFor="let option of optionsStatus | keyvalue"
                  [value]="option.key"
                  [style.color]="getStatusColor(option.key)"
                     >
                  {{ option.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
      <div class="p-horizontal-xl p-top-xl">
        <table class="bordered-table">
          <thead>
            <tr class="custom-thead-rest">
              <th>Data início:</th>
              <th>Data fim:</th>
              <th>Responsável</th>
              <th>Status do descanso</th>
            </tr>
          </thead>
          <tbody>
            <tr class="custom-tbody-rest" *ngFor="let item of rests">
              <td>{{ item.startDate }}</td>
              <td>{{ item.endDate }}</td>
              <td>{{ item.manager }}</td>
              <td class="bold" width="20%" [ngClass]="getStatusClass(item.statusRest)">{{ item.statusRest }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
