import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { MessageService } from '../services/message.service';
import { CollaboratorService } from 'src/app/pages/collaborator/repository/collaborator.service';

@Injectable({
  providedIn: 'root',
})
export class RouterInative implements CanActivate {
  collaboratorInative!: boolean;

  constructor(
    private router: Router,
    private _messageService: MessageService,
    private _collaboratorService: CollaboratorService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const collaboratorId = next.params['collaboratorId'];

    if (collaboratorId) {
      return this._collaboratorService
        .getCollaboratorById(collaboratorId)
        .toPromise()
        .then((response) => {
          this.collaboratorInative = response?.inactive ?? false;
          if (this.collaboratorInative === true) {
            this._messageService.openErrorMessage(['Acesso não autorizado']);
            setTimeout(() => {
              this.router.navigate([
                'colaboradores',
              ]);
            }, 2000);
            return false;
          }
          return true;
        })
    }
    return true;
  }
}
