import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { MessageService } from 'src/app/core/services/message.service';
import { LevelSkillsEnum } from 'src/app/pages/collaborator/enum/level-skills.enum';
import { ICollaboratorSkillFilter } from 'src/app/pages/collaborator/interface/collaborator-skill-filter.interface';
import {
  ICollaboratorSkill,
  ICollaboratorSkills,
  ILevelSkill,
} from 'src/app/pages/collaborator/interface/collaborator-skills.interface';
import { IOptionsCollaborator } from 'src/app/pages/collaborator/interface/options-collaborator.interface';
import { AddCollaboratorSkillComponent } from 'src/app/pages/collaborator/modal/add-collaborator-skill/add-collaborator-skill.component';
import { CollaboratorSkillService } from 'src/app/pages/collaborator/repository/collaborator-skill.service';
import { CollaboratorService } from 'src/app/pages/collaborator/repository/collaborator.service';
import { ISkillType } from 'src/app/pages/skills/interface/skill-type.interface';
import { SkillTypeService } from 'src/app/pages/skills/repository/skill-type.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Collaborator } from 'src/app/shared/models/Collaborator.model';
import { SubSink } from 'subsink';
import { SkillManager } from './skills-manager';
import { InactivateCollaboratorSkillModalComponent } from 'src/app/pages/collaborator/modal/inactivate-collaborator-skill-modal/inactivate-collaborator-skill-modal.component';
import {
  EPermissionType,
  EProcess,
} from 'src/app/shared/interfaces/roles.interface';
import { SkillTypeManager } from './skilltype-manager';

@Component({
  selector: 'app-collaborator-skills',
  templateUrl: './collaborator-skills.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class CollaboratorSkillsComponent implements OnInit, OnDestroy {
  @Input() collaborator!: Collaborator;
  @Input() collaboratorId!: string;
  EPermissionType = EPermissionType;
  EProcess = EProcess;
  expandedElement: ISkillType | null | undefined;
  pageSizeOptions: Array<number> = [5, 10, 20];
  countDataSource: number = 0;
  filterForm!: FormGroup;
  skillForm!: FormGroup;
  options!: IOptionsCollaborator;
  options$!: Observable<IOptionsCollaborator>;
  optionsSkillType!: any;
  optionsSkillType$!: Observable<any>;
  skillArray: ILevelSkill[] = [];

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = ['icon', 'description', 'status'];
  filter: ICollaboratorSkillFilter = {
    page: 1,
    take: 5,
    searchString: '',
    inactive: null,
    orderByParam: {
      orderBy: 0,
      asc: true,
    },
  };
  private _subs = new SubSink();
  levelSelectOptions: string[] = [];

  showForm: boolean = false;

  chipsList: Array<{ controlName: string; description: string }> = [];
  skillToUpdateArray: Array<ICollaboratorSkills> = [];
  skillToAddArray: ICollaboratorSkill = {
    collaboratorId: '',
    skillsAdd: [],
  };

  optionsDisplay: Array<any> = [];
  optionsNewSkillDisplay: Array<any> = [];

  skillManager!: SkillManager;
  skillTypeManager!: SkillTypeManager;

  constructor(
    private _collaboratorSkillService: CollaboratorSkillService,
    private _skillsTypeService: SkillTypeService,
    private _fb: FormBuilder,
    private _collaboratorService: CollaboratorService,
    private _messageService: MessageService,
    public dialog: MatDialog
  ) {
    this.levelSelectOptions = Object.keys(LevelSkillsEnum).filter((f) =>
      isNaN(Number(f))
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  ngOnInit(): void {
    this.createFormGroup();
    this.createSkillForm();
    this.getSkillTypeOptions();
    this.getSelectOptions();
    this.listenFormChanges();
  }

  listenFormChanges() {
    this.filterForm.valueChanges.subscribe((value) => {
      this.filter = {
        ...this.filter,
        ...value,
      };
      this.getSkillTypeOptions();
      if (value.skillTypeId) {
        this.optionsDisplay = this.options.skills.filter(
          (skill) => skill.skillTypeId === value.skillTypeId
        );
      } else {
        this.optionsDisplay = this.options.skills;
      }
    });
  }

  createFormGroup() {
    this.filterForm = this._fb.group({
      skillTypeId: [null],
      skillsId: [null],
      skillLevel: [null],
    });
  }

  createSkillForm() {
    this.skillForm = this._fb.group({
      skillType: [''],
      skills: [''],
      skillLevel: [''],
    });
  }

  getSelectOptions() {
    this.options$ = this._collaboratorService.getCollaboratorSelect();
    this.optionsSkillType$ = this._skillsTypeService.getAllSkillTypeOptions();
    this._subs.add(
      this.optionsSkillType$.subscribe((options: any) => {
        this.optionsSkillType = options.result;
      }),
      this.options$.subscribe((options: IOptionsCollaborator) => {
        this.options = options;
        this.optionsDisplay = this.options.skills;
      })
    );
  }

  getSkillTypeOptions(resetPage: boolean = true) {
    if (resetPage) {
      this.filter.page = 1;
      this.countDataSource = 0;
    }
    this._subs.add(
      this._collaboratorSkillService
        .getAllbyCollaborator(this.collaboratorId, this.filter)
        .subscribe((res) => {
          this.getDataSource(res.result);
          this.countDataSource = res.count;
        })
    );
  }

  getDataSource(res: any) {
    let arr: any[] = [];
    if (res) {
      res.map((skillType: any) => {
        const prepareDataSource = {
          id: skillType.skillTypeId,
          description: skillType.skillTypeName,
          status: skillType.inactive,
          skills: skillType.skillsProfile,
        };
        arr.push(prepareDataSource);
      });

      this.dataSource.data = arr;
      this.skillManager = new SkillManager(
        this.collaboratorId,
        this.dataSource
      );
      this.skillTypeManager = new SkillTypeManager(
        this.collaboratorId,
        this.dataSource
      );
    }
  }

  changeChipList(
    controlName: string,
    description: string,
    value?: string
  ): void {
    let valueForm = this.filterForm.get(controlName)?.value;

    if (valueForm === value) return;

    const hasChipControl = this.chipsList.findIndex(
      (item) => item.controlName === controlName
    );

    let descriptionValue = '';

    if (hasChipControl >= 0) {
      descriptionValue = this.chipsList.splice(hasChipControl, 1)[0]
        .description;
    }

    if (description && description !== descriptionValue) {
      this.chipsList.push({
        controlName: controlName,
        description: description,
      });
    }
  }

  removeFilterValueChips(chip: any) {
    this.filterForm.get(chip.controlName)?.reset();
  }

  removeAllChips() {
    this.filterForm.reset();
  }

  enableFormSkill() {
    this.createSkillForm();
    this.showForm = true;

    const allSkillsId = this.skillManager.getAllSkillId(this.dataSource);
    this.options.skills = this.skillManager.filterSkill(
      this.options,
      allSkillsId
    );

    this.skillForm.valueChanges.subscribe((value) => {
      if (value.skillType) {
        this.optionsNewSkillDisplay = this.options.skills.filter(
          (skill) => skill.skillTypeId === value.skillType.id
        );
      } else {
        this.optionsNewSkillDisplay = this.options.skills;
      }
    });
  }

  openDialogCollaboratorSkill() {
    let dialogRef;
    dialogRef = this.dialog.open(AddCollaboratorSkillComponent, {
      panelClass: 'modal-dialog-close-type',
      height: '589px',
      width: '520px',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.reload === true) {
        this.submitUpdate();
      } else {
        this.ngOnInit();
      }
    });
  }

  prepareNewSkill() {
    const newSkills = this.skillManager.extractFormValues(this.skillForm);

    this.skillToAddArray = this.skillManager.addSkillToCollaborator(
      this.skillToAddArray,
      newSkills
    );

    this.optionsNewSkillDisplay = this.optionsNewSkillDisplay.filter(
      (skill) => skill.id != newSkills.skillId
    );

    const skillTypeIndex = this.skillTypeManager.findSkillTypeIndex(
      this.skillForm
    );

    if (skillTypeIndex === -1) {
      const skill = this.skillManager.createSkill(this.skillForm);
      const skillType = this.skillTypeManager.createSkillType(
        this.skillForm,
        skill
      );

      let dataSource: any[] = this.dataSource.data;
      dataSource.push(skillType);
      this.dataSource.data = dataSource;
      const newSkillType = this.skillTypeManager.findSkillTypeIndex(
        this.skillForm
      );
      this.expandedElement = this.dataSource.data[newSkillType];
    } else {
      const skill = this.skillManager.createSkill(this.skillForm);

      if (
        this.dataSource.data[skillTypeIndex].skills.findIndex(
          (skillData: any) => skillData.skillId === skill.skillId
        ) === -1
      ) {
        this.dataSource.data[skillTypeIndex].skills.push(skill);
        this.expandedElement = this.dataSource.data[skillTypeIndex];
      }
    }

    this.skillForm.reset();
    this.skillForm.updateValueAndValidity();
  }

  updateSkill(skill: any, event: any) {
    const skillToUpdate: ICollaboratorSkills = {
      skillsId: skill.skillId,
      collaboratorId: this.collaboratorId,
      level: LevelSkillsEnum[event.value],
    };
    let index = this.skillToUpdateArray.findIndex(
      (obj) => obj.skillsId === skillToUpdate.skillsId
    );
    let indexSkillToAdd = this.skillToAddArray.skillsAdd.findIndex(
      (obj) => obj.skillId === skillToUpdate.skillsId
    );

    if (indexSkillToAdd !== -1) {
      this.skillToAddArray.skillsAdd[indexSkillToAdd] = {
        skillId: skill.skillId,
        skillTypeId: skill.skillTypeId,
        level: Number(LevelSkillsEnum[event.value]),
      };
    } else {
      index !== -1
        ? (this.skillToUpdateArray[index] = skillToUpdate)
        : this.skillToUpdateArray.push(skillToUpdate);
    }
  }

  submitUpdate() {
    if (this.skillToAddArray.skillsAdd.length > 0) this.postSkills();
    if (this.skillToUpdateArray.length > 0) this.putSkills();
  }

  postSkills() {
    this._subs.add(
      this._collaboratorSkillService
        .postCollaboratorSkill(this.skillToAddArray)
        .subscribe((res) => {
          if (res.success) {
            this._messageService.openSuccessMessage(
              'Alteração realizada com sucesso'
            );
            this.skillToAddArray.skillsAdd.splice(
              0,
              this.skillToAddArray.skillsAdd.length
            );
            this.getSkillTypeOptions();
            this.getSelectOptions();
          } else if (res.errors && res.errors.length > 0) {
            this._messageService.openErrorMessage(res.errors);
          }
        })
    );
  }

  putSkills() {
    this._subs.add(
      this._collaboratorSkillService
        .putCollaboratorSkill(this.skillToUpdateArray)
        .subscribe((res) => {
          if (res.success) {
            this._messageService.openSuccessMessage(
              'Alteração realizada com sucesso'
            );
            this.skillToUpdateArray.splice(0, this.skillToUpdateArray.length);
          } else if (res.errors && res.errors.length > 0) {
            this._messageService.openErrorMessage(res.errors);
          }
        })
    );
  }

  handleCheckboxChange(
    event: any,
    skill: any,
    skillTypeIndex: number,
    skillIndex: number
  ) {
    event.source.checked = !event.checked;
    event.checked = event.source.checked;
    this.dataSource.data[skillTypeIndex].skills[skillIndex].inactive =
      event.checked;
    skill.inactive = !skill.inactive;
  }

  inactivateSkill(
    event: any,
    skill: any,
    skillIndex: any,
    skillTypeId: string
  ) {
    if (event.checked === true) {
      const skillToInactivate = this.skillManager.inactivateSkill(skill);
      this.inactivateSkillRequest(skillToInactivate);
    } else {
      const dialogRef = this.dialog.open(
        InactivateCollaboratorSkillModalComponent,
        {
          panelClass: 'modal-dialog-close-type',
          height: '589px',
          width: '520px',
        }
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.reload === true) {
          const skillToInactivate = this.skillManager.inactivateSkill(
            skill,
            result.reason
          );
          this.inactivateSkillRequest(skillToInactivate);
        } else {
          const skillTypeIndex =
            this.skillTypeManager.findSkillTypeIndexById(skillTypeId);
          this.handleCheckboxChange(event, skill, skillIndex, skillTypeIndex);
        }
      });
    }
  }

  inactivateSkillRequest(skillToInactivate: any) {
    this._subs.add(
      this._collaboratorSkillService
        .inactivateCollaboratorSkill(skillToInactivate)
        .subscribe((res) => {
          if (res.success) {
            this._messageService.openSuccessMessage(
              'Alteração realizada com sucesso'
            );
            this.getSkillTypeOptions();
            this.getSelectOptions();
            
          } else if (res.errors && res.errors.length > 0) {
            this._messageService.openErrorMessage(res.errors);
          }
        })
    );
  }

  removeNewSkill(skill: any, skillTypeId: string) {
    let index = this.dataSource.data.findIndex(
      (obj: any) => obj.id === skillTypeId
    );
    let indexSkill = this.dataSource.data[index].skills.findIndex(
      (obj: any) => obj.skillId === skill.skillId
    );

    this.dataSource.data[index].skills.splice(indexSkill, 1);

    this.skillToAddArray = this.skillManager.removeSkill(
      this.skillToAddArray,
      skill.skillId
    );

    if (this.dataSource.data[index].skills.length === 0) {
      let dataSource = this.dataSource.data;
      dataSource.splice(index, 1);
      this.dataSource.data = dataSource;
    }
  }

  deleteSkill(skill: any) {
    const message =
      'Tem certeza de que gostaria de deletar a habilidade do colaborador?';
    const buttonTxt = 'Deletar';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._subs.add(
          this._collaboratorSkillService
            .deleteCollaboratorSkill(skill.skillId, this.collaboratorId)
            .subscribe((res) => {
              if (res.success) {
                this._messageService.openSuccessMessage(
                  'Alteração realizada com sucesso'
                );
                this.ngOnInit();
              } else if (res.errors && res.errors.length > 0) {
                this._messageService.openErrorMessage(res.errors);
              }
            })
        );
      }
    });
  }
  skipPage(event: { skip: number }) {
    this.filter.page = event.skip + 1;
    this.getSkillTypeOptions(false);
  }
}
