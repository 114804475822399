<div class="card--border p-xl m-xl">
  <button
    class="btn-no-border btn--remove-card"
    (click)="removeAllocation()"
    type="button"
  >
    <mat-icon>close</mat-icon>
  </button>
  <form [formGroup]="allocationForm" (ngSubmit)="onSubmitAllocation()">
    <div class="section-form p-top-xl">
      <div class="row p-vertical-lg">
        <div class="col min-content">
          <mat-label class="input-label--custom-create">Colaborador</mat-label>
          <mat-form-field
            class="jobFunction-center input-outline input--width-lgs-secondary autocomplete--prefix-image"
            [ngClass]="{ 'warn-outline': overAllocate || overAbsence }"
            appearance="outline"
            floatLabel="always"
          >
            <div
              class="autocomplete--prefix-container"
              *ngIf="imageSrc"
              matPrefix
            >
              <img [src]="imageSrc | safeResourceUrl" alt="" />
            </div>

            <input
              type="text"
              matInput
              formControlName="collaborator"
              (ngModelChange)="validateAllocation()"
              [matAutocomplete]="autoCollaborator"
            />
            <!-- <span matHint *ngIf="imageSrc">{{allocationForm.controls['collaborator'].value.jobFunctionId}}</span> -->

            <mat-autocomplete
              #autoCollaboratorImage
              #autoCollaborator="matAutocomplete"
              [displayWith]="displayFnCollaborator"
            >
              <mat-option
                *ngFor="let option of filteredOptionsCollaborator | async"
                [value]="option"
              >
                <span> {{ option.collaboratorName }}</span>
              </mat-option>
            </mat-autocomplete>

            <ng-container
              *ngIf="allocationForm.controls['collaborator']?.value"
            >
              <small class="show-jobFunction">{{ jobFunction }}</small>
              <ng-template #tooltipTemplate>
                <strong>Papel:</strong> {{ jobFunction || "-" }}<br />
                <strong>Habilidades:</strong> {{ skills || "-" }}<br />
                <strong>Torre:</strong> {{ tower || "-" }}<br />
                <strong>Líder:</strong> {{ leader || "-" }}<br />
                <strong>Senioridade:</strong> {{ seniority || "-" }}
              </ng-template>

              <mat-icon
                class="tooltip-allocation"
                [tooltip]="tooltipTemplate"
                [adaptivePosition]="false"
                placement="top"
                >info
              </mat-icon>
            </ng-container>
          </mat-form-field>
          <span class="hint" style="max-width: 300px" *ngIf="overAllocate || overAbsence && overAllocateMessage">{{
            overAllocateMessage
          }}</span>
        </div>
        <div class="col min-content" *ngIf="!isReservationOrAbsence">
          <mat-label class="input-label--custom-create">Pip/Projeto</mat-label>
          <mat-form-field
            class="input-outline input--width-lg-secondary"
            appearance="outline"
            floatLabel="always"
            [matTooltip]="allocationForm.controls['project'].value?.name"
          >
            <input
              type="text"
              matInput
              formControlName="project"
              [matAutocomplete]="autoProject"
              (ngModelChange)="validateAllocation()"
            />
            <mat-autocomplete
              #autoProject="matAutocomplete"
              [displayWith]="displayFnProject"
            >
              <mat-option
                *ngFor="let option of filteredOptionsProject | async"
                [value]="option"
              >
                {{ option.projectPIP }} - {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div *ngIf="isReservationOrAbsence" class="col min-content">
          <mat-label class="input-label--custom-create">Cliente</mat-label>
          <mat-form-field
            class="input-outline input--width-sm-secondary"
            appearance="outline"
            floatLabel="always"
          >
            <input
              type="text"
              matInput
              formControlName="client"
              [matAutocomplete]="autoClient"
            />
            <mat-autocomplete
              #autoClient="matAutocomplete"
              [displayWith]="displayFnClient"
            >
              <ng-container *ngIf="isAbsence">
                <mat-option
                  *ngFor="let option of filteredOptionsClients | async"
                  [value]="option"
                  [disabled]="option.forAbsence === false"
                >
                  {{ option.name }}
                </mat-option>
              </ng-container>
              <ng-container *ngIf="!isAbsence">
                <mat-option
                  *ngFor="let option of filteredOptionsClients | async"
                  [value]="option"
                  [disabled]="option.forAbsence === true"
                >
                  {{ option.name }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col min-content">
          <mat-label class="input-label--custom-create"
            >Data de início</mat-label
          >

          <mat-form-field
            class="input-outline input--width-xs-tertiary"
            appearance="outline"
            floatLabel="always"
          >
            <input
              [matDatepicker]="pickerInitial"
              [min]="minDate"
              [max]="maxDate"
              matInput
              formControlName="startDate"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerInitial"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerInitial></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col min-content">
          <mat-label class="input-label--custom-create">Data de fim</mat-label>

          <mat-form-field
            class="input-outline input--width-xs-tertiary"
            appearance="outline"
            floatLabel="always"
          >
            <input
              [matDatepicker]="pickerEnd"
              [min]="allocationForm.controls['startDate'].value"
              [max]="maxDate"
              matInput
              formControlName="endDate"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerEnd"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col min-content position-relative" >
          <mat-label class="input-label--custom-create"
            >Horas ou percentual da alocação
            <mat-icon
              class="tooltip-allocation-period"
              [tooltip]="tooltipTemplateCalculationPeriod"
              [adaptivePosition]="false"
              placement="top"
              >info
            </mat-icon>
          </mat-label>
          <ng-container>
            <ng-template #tooltipTemplateCalculationPeriod>
              O tempo que um colaborador é designado para um
              <strong>projeto é calculado pelo período escolhido</strong> .
              Dizer que um colaborador está alocado por 80 horas não significa
              que são horas semanais ou mensais, mas sim 80 horas durante o
              projeto. <br />
              <br />
              Quando mostramos um <strong>percentual</strong>, indicamos as
              horas relacionadas ao período escolhido para o colaborador.
            </ng-template>
          </ng-container>
          <div class="row nowrap">
            <div class="col min-content">
              <mat-form-field
                class="input-outline input--width-xx-tertiary"
                appearance="outline"
                floatLabel="always"
              >
                <input
                  type="text"
                  mask="999"
                  [suffix]="suffixPeriod"
                  matInput
                  formControlName="allocationPeriod"
                  required
           />
              </mat-form-field>

            </div>
            <div class="col min-content">
              <mat-form-field
                class="input-outline input--width-xxs-tertiary"
                appearance="outline"
                floatLabel="always"
              >
                <mat-select
                  formControlName="isPercentage"
                  (selectionChange)="changePeriod($event)"

                >
                  <mat-option [value]="true"> Percentual </mat-option>
                  <mat-option [value]="false"> Horas </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <ng-container>
            <span>{{ hoursSelected }}</span>
          </ng-container>
        </div>
      </div>
      <div class="row box-spacing">
        <mat-checkbox
          class="checkbox "
          formControlName="isReservation"
          [value]="occurrence[0].id"
          (change)="onCheckboxChangeReservation($event)"
        >
          {{ occurrence[0].name }}
        </mat-checkbox>

        <mat-checkbox
          class="checkbox"
          formControlName="isAbsence"
          [value]="occurrence[1].id"
          (change)="onCheckboxChangeReservation($event)"
        >
          {{ occurrence[1].name }}
        </mat-checkbox>
      </div>
      <div class="row">
        <mat-form-field
          *ngIf="
            allocationForm.get('isReservation')?.value ||
            allocationForm.get('isAbsence')?.value
          "
          class="input-outline input--width-xl"
          appearance="outline"
          floatLabel="always"
        >
          <textarea
            maxlength="120"
            matInput
            [placeholder]="
              isAbsence
                ? textAreaPlaceholderAusencia
                : textAreaPlaceholderPreVenda
            "
            formControlName="observation"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<ng-template #profileImage> </ng-template>
