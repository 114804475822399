import { Injectable } from '@angular/core';
import { ICollaboratorFilter } from 'src/app/pages/collaborator/interface/collaborator.interface';

type chip = {
  controlName: string;
  description: string;
};

@Injectable({
  providedIn: 'root',
})

// Service criado para salvar o estado do filtro e dos chips que o usuário selecionou para que ele não suma quando mudar de página
export class CollaboratorFilterService {
  private filter: ICollaboratorFilter = {
    searchString: null,
    teamId: null,
    jobFunctionId: null,
    towerId: null,
    skillsId: null,
    skillLevel: null,
    city: null,
    inactive: null,
    page: 1,
    take: 20,
    orderByParam: { orderBy: 1, asc: true },
  };

  private chipsList: Array<chip> = [];

  constructor() {}

  setFilter(filter: ICollaboratorFilter) {
    this.filter = filter;
  }

  getFilter() {
    return this.filter;
  }
  
  setChipsList(chipsList: Array<chip>) {
    this.chipsList = [...chipsList];
  }

  getChipsList() {
    return this.chipsList;
  }

  resetChipsList() {
    this.chipsList = [];
  }

  resetFilter() {
    this.filter = {
      searchString: null,
      teamId: null,
      jobFunctionId: null,
      towerId: null,
      skillsId: null,
      skillLevel: null,
      city: null,
      inactive: null,
      page: 1,
      take: 20,
      orderByParam: { orderBy: 1, asc: true },
    };
  }
}

export interface IFilter {
  orderBy?: string;
  top: number;
  skip?: number;
  filterBy?: Array<IFilterByValue>;
}
export interface IFilterByValue {
  key: string;
  value: string;
  collection?: string;
  type: 'contains' | 'eq' | 'ge' | 'le';
  searchBy?: 'string' | 'id';
}
