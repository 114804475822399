  <button class="btn-backpage" [routerLink]="['/clientes-e-projetos/projetos']">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <div class="form-ctn p-xl">
    <form [formGroup]="projectForm" (ngSubmit)="onSubmitProject()">
      <div class="section p-top-3xl">
        <div class="row align-center p-top-lg">
          <h2>{{ !isEdit ? "Cadastrar Projeto" : "Editar Projeto" }}</h2>
        </div>
      </div>
      <hr />
      <div class="row p-top-3xl">
        <div class="col-md-3 form-division p-top-lg">
         <h3>Cadastrar Projeto</h3>
        </div>
        <div class="col-md-9" *ngIf="optionsProject">
          <div class="row divisor p-bottom-xl">
            <div class="col-md-8">
              <mat-form-field
                class="input-outline input--width-full"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Nome do projeto</mat-label>
                <input maxlength="60" type="text" matInput formControlName="name" required />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field
                class="input-outline input--width-full"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Cliente</mat-label>
                <mat-select formControlName="clientId" class="custom-select">
                  <mat-option
                    *ngFor="let option of optionsProject.clients"
                    [value]="option.id"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row divisor p-bottom-xl">
            <div class="col-md-2">
            <mat-form-field
              class="input-outline input--width-full "
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>PIP</mat-label>
              <input
                maxlength="5"
                type="text"
                matInput
                formControlName="projectPIP"
                required
                placeholder="Ex: 1234"
              />

            </mat-form-field>
            <span
            class="message-error"
            style="max-width: 300px"
            *ngIf="projectForm.get('projectPIP')?.hasError('pattern')"
          >
            Apenas números.
          </span>
          </div>
            <div class="col-md-3">
            <mat-form-field
              class="input-outline input--width-full"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Data de início</mat-label>
              <input
                [matDatepicker]="pickerInitial"
                matInput
                formControlName="projectInitiation"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerInitial"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickerInitial></mat-datepicker>
            </mat-form-field>
          </div>
            <div class="col-md-3">
            <mat-form-field
              class="input-outline input--width-full"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Previsão de conclusão</mat-label>
              <input
                [matDatepicker]="pickerEnd"
                matInput
                formControlName="projectConclusion"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerEnd"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickerEnd></mat-datepicker>
            </mat-form-field>
            <span
            class="message-error"
            style="max-width: 300px"
            *ngIf="projectForm.get('projectConclusion')?.hasError('dateError')"
          >
            A data de conclusão não pode ser inferior à data de início.
          </span>

          </div>
            <div class="col-md-4">
            <mat-form-field
              class="input-outline input--width-full"
              appearance="outline"
              floatLabel="always"
              required
            >
              <mat-label>Status</mat-label>
              <mat-select
                formControlName="projectStatusId"
                class="custom-select"
              >
                <mat-option
                  *ngFor="let option of optionsProject.status"
                  [value]="option.id"
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          </div>
          <div class="row divisor --three p-bottom-xl">
            <mat-form-field
              class="input-outline "
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Torre</mat-label>
              <mat-select
                (selectionChange)="selectTower()"
                formControlName="towerId"
                class="custom-select"
              >
                <mat-option
                  *ngFor="let option of optionsProject.towers"
                  [value]="option.id"
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>
              <span
                *ngIf="!projectForm.controls['towerId'].value"
                class="input-hint"
              >
                Selecione a torre para carregar as equipes do projeto.
              </span>
            </mat-form-field>
            <mat-form-field
              *ngIf="optionsProject.teams"
              class="input-outline "
              appearance="outline"
              required
              floatLabel="always"
            >
              <mat-label>Equipe</mat-label>
              <mat-select
                formControlName="teamId"
                class="custom-select"
                (selectionChange)="selectTeam()"
              >
                <mat-option
                  *ngFor="let option of optionsProject.teams"
                  [value]="option.id"
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>
              <span
                *ngIf="!projectForm.controls['teamId'].value"
                class="input-hint"
              >
                Selecione a equipe para carregar os responsáveis do projeto.
              </span>
              <span
                *ngIf="
                  projectForm.controls['teamId'].value &&
                  optionsProject.collaborators &&
                  optionsProject.collaborators.length === 0
                "
                class="input-error"
              >
                Essa equipe não possui colaboradores cadastrados
              </span>
            </mat-form-field>
            <mat-form-field
              *ngIf="
                optionsProject.collaborators &&
                optionsProject.collaborators.length > 0
              "
              class="input-outline "
              appearance="outline"
              floatLabel="always"
              required
            >
              <mat-label>Responsável do projeto</mat-label>
              <mat-select
                formControlName="projectManagerId"
                class="custom-select"
              >
                <mat-option
                  *ngFor="let option of optionsProject.collaborators"
                  [value]="option.id"
                >
                  {{ option.fullName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row justify-end m-vertical-lg p-top-3xl">
        <button
          class="btn secondary"
          type="button"
          (click)="returnToProjectsList()"
        >
          Cancelar
        </button>

        <button
          class="btn primary m-horizontal-xl"
          [disabled]="projectForm.invalid"
          type="submit"
        >
          {{ isEdit ? "Salvar" : "Cadastrar" }}
        </button>
      </div>
    </form>
  </div>
