import * as moment from 'moment';

export abstract class FilterConfigBase {
  page!: number;
  take!: number;
  orderByParam!: {
    orderBy: number;
    asc: boolean;
  };
}
export class FilterProjectsAllocation extends FilterConfigBase {
  searchString?: string;
  descriptionStatusAllocation!: string;
  projectPIP!: string;
  projectId!: string;
  clientId!: string;
  startDate?: string | null;
  endDate?: string | null;
  override page!: number;
  override take!: number;
  override orderByParam!: {
    orderBy: number;
    asc: boolean;
  };

  constructor() {
    super();
    this.page = 1;
    this.take = 10;
    this.orderByParam = {
      asc: true,
      orderBy: 1,
    };
    this.searchString = '';

   }

  getWeek(week: Date) {
    this.startDate = moment(week).weekday(1).format();
    this.endDate = moment(week).weekday(5).format();
  }

  removeAll() {
    this.descriptionStatusAllocation = '';
    this.projectPIP = '';
    this.projectId = '';
    this.clientId = '';
  }

  filterChange(key: string, filter: any) {
    switch (key) {
      case 'descriptionStatusAllocation':
        this._checkValueStatusn(filter.name);
        break;
        case 'projectPIP':
        this._checkValuePip(filter);
        break;
      case 'projectId':
        this._checkValueProject(filter.id);
        break;
      case 'clientId':
        this._checkValueClient(filter.id);
        break;
        }
  }

  private _checkValueStatusn(filterId: string) {
   this.descriptionStatusAllocation = filterId;
  }

  private _checkValuePip(filterId: string) {
    this.projectPIP = filterId;
  }

  private _checkValueProject(filterId: string) {
    this.projectId = filterId;
  }

  private _checkValueClient(filterId: string) {
    this.clientId = filterId;
  }

  cleanFilter(key: string) {
        switch (key) {
        case 'descriptionStatusAllocation':
          this._removeStatus();
          break;
          case 'projectPIP':
          this._removePip();
          break;
        case 'projectId':
          this._removeProject();
          break;
        case 'clientId':
          this._removeClient();
          break;

    }
    const currentDate: Date = new Date();
    this.getWeek(currentDate);
  }

  private _removeStatus() {
    this.descriptionStatusAllocation = '';
  }

  private _removePip() {
       this.projectPIP = '';
  }

  private _removeProject() {
     this.projectId = '';
  }

  private _removeClient() {
       this.clientId = '';
       this._removeProject();
       this._removePip();
  }

  changeTake(take: number){
    this.take = take;
  }

  skipPage(event: { skip: number }) {
    this.page = event.skip + 1;
  }
}
