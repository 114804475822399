import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { ITeamTower } from '../interface/team.inteface';
import { ITeamsFilter } from '../interface/team-collaborator.interface';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  endpoint: string = 'Team';

  constructor(public http: HttpClient) {}

  postTeam(tower: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;

    return this.http.post<any>(url, tower);
  }

  putTeam(tower: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.put<any>(url, tower);
  }

  inactivateTeam(idTeam: string): Observable<any> {
    const path = 'inactivate';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}/${idTeam}`;
    return this.http.delete<any>(url);
  }

  getTeamById(idTeam: string): Observable<ITeamTower> {
    const path = 'GetTeamInfos';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}/${idTeam}`;
    return this.http
      .get<IApiResponse>(url)
      .pipe(map((response) => response.result));
  }

  getAllocationTeamById(filter: ITeamsFilter): Observable<any> {
    const path = 'ListTeamCollaboratorsAllocationInfo';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    return this.http.post<any>(url, filter);
  }

  getProjectTeamById(filter: ITeamsFilter): Observable<any> {
    const path = 'GetActiveProjectsTeam';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    return this.http.post<any>(url, filter);
  }

}
