import { NgModule } from '@angular/core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';

import { AllocationRoutingModule } from './allocation-routing.module';
import { AllocationComponent } from './pages/container/allocation/allocation.component';
import { ManageAllocationComponent } from './pages/container/manage-allocation/manage-allocation.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMaskModule } from 'ngx-mask';

import { ComponentsModule } from 'src/app/shared/components/components.module';
import { ModalSelectAllocationComponent } from './modal/modal-select-allocation/modal-select-allocation.component';
import { PageCreateCollaboratorAllocationComponent } from './pages/components/collaborator/page-create-collaborator-allocation/page-create-collaborator-allocation.component';
import { PageAllocationCollaboratorListComponent } from './pages/components/collaborator/page-allocation-collaborator-list/page-allocation-collaborator-list.component';
import { PageAllocationProjectListComponent } from './pages/components/projects/page-allocation-project-list/page-allocation-project-list.component';
import { FormCollaboratorAllocationComponent } from './pages/components/collaborator/form-collaborator-allocation/form-collaborator-allocation.component';
import { ModalReplyAllocationComponent } from './modal/modal-reply-allocation/modal-reply-allocation.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { MAT_DATE_RANGE_SELECTION_STRATEGY, MatDatepickerModule } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { PageUpdateCollaboratorAllocationComponent } from './pages/components/collaborator/page-update-collaborator-allocation/page-update-collaborator-allocation.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MY_DATE_FORMATS } from './interface/my-date-formats';
import { FormAllocationTemplateComponent } from './pages/components/collaborator/form-allocation-template/form-allocation-template.component';
import { ManageAllocationProjectComponent } from './pages/container/manage-allocation-project/manage-allocation-project.component';
import { PageCreateProjectAllocationComponent } from './pages/components/projects/page-create-project-allocation/page-create-project-allocation.component';
import { PageUpdateProjectAllocationComponent } from './pages/components/projects/page-update-project-allocation/page-update-project-allocation.component';
import { DateFormatSelectDay } from 'src/app/shared/validators/date-format';
import { ViewAllocationComponent } from './pages/container/view-allocation/view-allocation.component';
import { PageViewProjectAllocationComponent } from './pages/components/projects/page-view-project-allocation/page-view-project-allocation.component';
import { HeaderViewProjectAllocationComponent } from './pages/components/projects/page-view-project-allocation/components/header-view-project-allocation/header-view-project-allocation.component';
import { BodyViewProjectAllocationComponent } from './pages/components/projects/page-view-project-allocation/components/body-view-project-allocation/body-view-project-allocation.component';
import { PeriodViewProjectAllocationComponent } from './pages/components/projects/page-view-project-allocation/components/body-view-project-allocation/period-view-project-allocation/period-view-project-allocation.component';
import { CollaboratorViewAllocationComponent } from './pages/components/projects/page-view-project-allocation/components/body-view-project-allocation/collaborator-view-allocation/collaborator-view-allocation.component';
import { DirectiveModule } from 'src/app/core/directives/directives.module';
import { LendingViewComponent } from './pages/components/lending/lending-view/lending-view.component';

@NgModule({
  declarations: [
    AllocationComponent,
    ManageAllocationComponent,
    ModalSelectAllocationComponent,
    PageCreateCollaboratorAllocationComponent,
    PageAllocationCollaboratorListComponent,
    FormCollaboratorAllocationComponent,
    PageAllocationProjectListComponent,
    ModalReplyAllocationComponent,
    PageUpdateCollaboratorAllocationComponent,
    FormAllocationTemplateComponent,
    ManageAllocationProjectComponent,
    PageCreateProjectAllocationComponent,
    PageUpdateProjectAllocationComponent,
    ViewAllocationComponent,
    PageViewProjectAllocationComponent,
    HeaderViewProjectAllocationComponent,
    BodyViewProjectAllocationComponent,
    PeriodViewProjectAllocationComponent,
    CollaboratorViewAllocationComponent,
    LendingViewComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AllocationRoutingModule,
    ComponentsModule,
    NgxMaskModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    PipesModule,
    NgTemplateOutlet,
    DirectiveModule,
    TooltipModule.forRoot()
  ],
  providers: [
   {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DateFormatSelectDay,
    },
  ],
 })

export class AllocationModule {
}
