import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ICollaboratorTeam,
  ITeamsFilter,
  OrderByCollaboratorTeam,
} from 'src/app/pages/tower-teams/interface/team-collaborator.interface';
import { TeamService } from 'src/app/pages/tower-teams/repository/team.service';
import {
  EProcess,
  EPermissionType,
} from 'src/app/shared/interfaces/roles.interface';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-team-collaborators',
  templateUrl: './team-collaborators.component.html',
  styleUrls: [],
})
export class TeamCollaboratorsComponent implements OnInit {
  columns = {
    collaboratorName: 'Nome',
    seniority: 'Senioridade',
    jobFunction: 'Papel',
    skills: 'Habilidades',
    hours: '% Alocação',
    redirect: '',
  };
  hasAllocationCollaborator = false;
  pageSizeOptions: Array<number> = [5, 10, 20];
  displayedColumns: string[] = [];
  pageSize: number = 10;
  teamId!: string;
  EProcess = EProcess;
  EPermissionType = EPermissionType;
  private _subs = new SubSink();

  dataSource: MatTableDataSource<ICollaboratorTeam[]> = new MatTableDataSource<
    ICollaboratorTeam[]
  >();
  countDataSource: number = 0;
  orderOptions: { [key: string]: number } = {
    collaboratorName: OrderByCollaboratorTeam.NAME,
    hours: OrderByCollaboratorTeam.HOURS,
  };

  filter: ITeamsFilter = {
    page: 1,
    take: 10,
    teamId: this.teamId,
    orderByParam: {
      orderBy: OrderByCollaboratorTeam.NAME,
      asc: true,
    },
  };

  constructor(
    private _teamService: TeamService,
    private _router: Router,
    private _route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.teamId = this._route.snapshot.paramMap.get('teamId')!;
    this.displayedColumns = Object.keys(this.columns);
    this.filter = { ...this.filter, teamId: this.teamId };
    this.getCollaboratorsAllocationTeams();
  }

  getCollaboratorsAllocationTeams() {
    this._subs.add(
      this._teamService
        .getAllocationTeamById(this.filter)
        .subscribe((response) => {
          this.getDataSource(response.result);
          this.countDataSource = response.count;
        })
    );
  }

  getSkills(skill: string[]) {
    if (skill.length) {
      const firstTwo = skill.slice(0, 3);
      return firstTwo.join(', ');
    } else {
      return 'Não existe habilidades';
    }
  }

  getSkillsTooltip(skill: string[]) {
    return skill.join(', ');
  }

  getDataSource(res: ICollaboratorTeam[]) {
    let arr: any[] = [];
    try {
      res.map((val: ICollaboratorTeam) => {
        const workingPercentageNumber = parseFloat(val.allocationPercentage);
        const prepareDataSource = {
          collaboratorId: val.collaboratorId,
          collaboratorName: val.collaboratorName,
          collaboratorImage: val.collaboratorImage,
          jobFunction: val?.jobFunction,
          seniority: val?.seniority,
          skills: val?.skills,
          hours: `(${val.allocationPercentage}%) ${val.totalHours}h / `,
          workingHoursPerPeriod: val.journey,
          allocationPercentage: workingPercentageNumber,
        };
        this.hasAllocationCollaborator = true;
        arr.push(prepareDataSource);
      });
    } catch (error) {
      console.error(error);
      this.hasAllocationCollaborator = false;
    }
    this.dataSource.data = arr;
  }

  skipPage(event: { skip: number }) {
    this.filter.page = event.skip + 1;
    this.getCollaboratorsAllocationTeams();
  }

  changeItemsPerPage(items: number) {
    this.filter.page = 1;
    this.filter.take = items;
    this.getCollaboratorsAllocationTeams();
  }

  changeOrder(orderBy: OrderByCollaboratorTeam) {
    if (this.filter.orderByParam.orderBy === orderBy) {
      this.filter.orderByParam.asc = !this.filter.orderByParam.asc;
    } else {
      this.filter.orderByParam.orderBy = orderBy;
      this.filter.orderByParam.asc = true;
    }
    this.getCollaboratorsAllocationTeams();
  }

  getStatusClass(allocationsValue: number | null): object {
    return {
      'status-low': allocationsValue !== null && allocationsValue <= 50,
      'status-possibility':
        allocationsValue !== null &&
        allocationsValue > 50 &&
        allocationsValue <= 99,
      'status-ideal': allocationsValue === 100,
      'status-exceeded': allocationsValue !== null && allocationsValue > 100,
    };
  }

  updateCollaboratorAllocation(collaboratorId: string, allocable: boolean) {
    this._router.navigate([
      '/alocacao/gerenciar-alocacao/colaborador/',
      collaboratorId,
      true,
    ]);
  }
}
