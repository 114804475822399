import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-reply-allocation',
  templateUrl: './modal-reply-allocation.component.html',
  styles: [],
})
export class ModalReplyAllocationComponent implements OnInit {

  message!: string;

  constructor(public dialogRef: MatDialogRef<ModalReplyAllocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
      this.message = data;
    }

  ngOnInit(): void {}

  close(emit =  false ): void {
    this.dialogRef.close(emit);
  }
}
