import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/pages/clients-projects/repository/project.service';
import { SubSink } from 'subsink';
import { COLUMNS_PROJECT } from 'src/app/shared/models/table-columns/columns-project';
import { IteamCollaborator } from 'src/app/pages/tower-teams/interface/team-collaborator.interface';
import { COLUMNS_PROJECT_TEAM } from 'src/app/shared/models/table-columns/columns-project-teams';
import { Observable } from 'rxjs';
import { IOptionsProject } from 'src/app/pages/clients-projects/interface/options-project.interface';
import {
  IProjectFilter,
  IProjectMinified,
  OrderByProject,
} from 'src/app/pages/clients-projects/interface/datasource-project.interface';
import { ProjectFilterService } from 'src/app/core/services/filters/project-filter.service';
import { IGenericData } from 'src/app/pages/collaborator/interface/options-collaborator.interface';
import { IClient } from 'src/app/pages/clients-projects/interface/client.interface';

type chip = {
  controlName: string;
  description: string;
};

@Component({
  selector: 'app-page-projects-list',
  templateUrl: './page-projects-list.component.html',
})
export class PageProjectsListComponent implements OnInit {
  private _subs = new SubSink();
  dataSource: MatTableDataSource<IProjectMinified> =
    new MatTableDataSource<IProjectMinified>();
  searchByNameCtrl: FormControl = new FormControl();

  pageTitle: string = 'Projetos';
  btnText: string = 'Cadastrar Projeto';

  pageSizeOptions: Array<number> = [5, 10, 20];
  currentPage: number = 1;
  countDataSource: number = 0;

  columns = COLUMNS_PROJECT;
  projectTeamColumn = COLUMNS_PROJECT_TEAM;

  projects: IProjectMinified[] = [];

  displayedColumns: string[] = [
    'name',
    'projectPIP',
    'clientName',
    'projectStatusDescription',
    'projectManagerName',
    'projectInitiation',
    'projectConclusion',
    'action',
  ];
  displayedTeamColumns: string[] = [
    'icon',
    'fullName',
    'seniorityDescription',
    'jobFunctionDescription',
    'skillsDescriptions',
    'totalPlannedHours',
  ];
  filterForm!: FormGroup;
  filter: IProjectFilter = this.filterService.getFilter();
  isResetting: boolean = false;

  pageSize: number = 20;

  chipsList: Array<any> = this.filterService.getChipsList();

  optionsProject!: IOptionsProject;
  optionsProject$!: Observable<IOptionsProject>;

  selectedFilter!: IOptionsProject;
  expandedElement: IteamCollaborator | null | undefined;

  orderOptions: { [key: string]: number } = {
    name: OrderByProject.NAME,
    projectPIP: OrderByProject.PROJECT_PIP,
    clientName: OrderByProject.CLIENT_NAME,
    projectStatusDescription: OrderByProject.PROJECT_STATUS,
    projectManagerName: OrderByProject.PROJECT_MANAGER,
    projectInitiation: OrderByProject.INITIATION,
    projectConclusion: OrderByProject.CONCLUSION,
  };

  clients: IClient[] = [];
  pips: string[] = [];

  constructor(
    private _router: Router,
    private _projectService: ProjectService,
    private _fb: FormBuilder,
    private filterService: ProjectFilterService
  ) {}

  ngOnInit(): void {
    this.createFormGroup();
    this.displayedColumns = Object.keys(this.columns);
    this.currentPage = this.filter.page;
    this.searchByNameCtrl.setValue(this.filter.searchString);
    
    this.getSelectOptions();
    this.getProjects(false);
    this.filterAutocomplete();
  }

  setFilterValue(): void {
    setTimeout(() => {
      // Uso da variável para evitar que faça chamadas get de colaboradores a cada mudança de valor quando estiver limpando o formulário
      if (this.isResetting) return;
  
      this.filter = {
        ...this.filter,
        ...this.filterForm.value,
      };
      this.filterService.setFilter(this.filter);
      this.getProjects();
    }, 200);
  }

  getProjects(resetPage: boolean = true): void {
    if (resetPage) {
      this.filter.page = 1;
      this.currentPage = 1;
      this.countDataSource = 0;
    }

    this._projectService
      .getAllProjectsByFilter(this.filter)
      .subscribe((response) => {
        if (response.success === true) {
          this.projects = response.result;
          this.countDataSource = response.count;

          this.dataSource.data = this.projects;
        }
      });
  }

  searchBy() {
    this.filter.searchString = this.searchByNameCtrl.value;
    this.filterService.setFilter(this.filter);
    this.getProjects();
  }

  clearFilter() {
    this.searchByNameCtrl.reset();
    this.filter.searchString = null;
    this.filterService.setFilter(this.filter);
    this.getProjects();
  }

  skipPage(event: { skip: number }) {
    this.filter.page = event.skip + 1;
    this.filterService.setFilter(this.filter);
    this.getProjects(false);
  }

  changeChipList(
    event: any = null,
    controlName: string,
    description: string,
    value?: string
  ): void {
    if(!event?.isUserInput) {
      return;
    }

    this.setFilterValue();
    
    let valueForm = this.filterForm.get(controlName)?.value;
    
    if (valueForm === value) return;

    const hasChipControl = this.chipsList.findIndex(
      (item) => item.controlName === controlName
    );
    let descriptionValue = '';

    if (hasChipControl >= 0) {
      descriptionValue = this.chipsList.splice(hasChipControl, 1)[0]
        .description;
    }

    if (description) {
      this.chipsList.push({
        controlName: controlName,
        description: description,
      });

      this.filterService.setChipsList(this.chipsList);
    }
  }

  changeChipListDate(
    controlName: string,
    description: string,
    value?: string
  ): void {
    const hasChipControl = this.chipsList.findIndex(
      (item) => item.controlName === controlName
    );
    let descriptionValue = '';

    if (hasChipControl >= 0) {
      descriptionValue = this.chipsList.splice(hasChipControl, 1)[0]
        .description;

      if (controlName === 'projectInitiation') {
        descriptionValue = `Início: ${descriptionValue}`;
      } else {
        descriptionValue = `Fim: ${descriptionValue}`;
      }
    }

    if (description && description !== descriptionValue && value) {
      this.chipsList.push({
        controlName: controlName,
        description: description,
      });

      this.filterService.setChipsList(this.chipsList);
    }
  }

  removeFilterValueChips(chip: chip) {
    this.chipsList = this.chipsList.filter(
      (item) => item.controlName !== chip.controlName
    );
    this.filterForm.get(chip.controlName)?.reset();
    this.filterService.setChipsList(this.chipsList);
    this.setFilterValue();
    this.resetAutocompleteFilters();
  }

  removeAllChips() {
    this.isResetting = true;
    this.filterForm.reset();
    this.isResetting = false;
    this.filterService.resetFilter();
    this.searchByNameCtrl.reset();
    this.filter = this.filterService.getFilter();
    this.getProjects();
    this.chipsList = [];
    this.filterService.resetChipsList();
    this.resetAutocompleteFilters();
  }

  resetAutocompleteFilters() {
    this.clients = [...this.optionsProject.clients];
    this.pips = [...this.optionsProject.pip];
  }

  removeInactiveChip(): void {
    let index = this.chipsList.findIndex(
      (item) => item.controlName === 'inactive'
    );
    if (index >= 0) {
      this.chipsList.splice(index, 1);
      this.filterService.setChipsList(this.chipsList);
    }
  }

  changeItemsPerPage(items: number) {
    this.filter.page = 1;
    this.filter.take = items;
    this.filterService.setFilter(this.filter);

    this.getProjects();
  }

  createCollaborator() {
    this._router.navigate(['cadastrar-colaborador/']);
  }

  editCollaborator(collaboratorId: string, column: string) {
    if (column !== 'status')
      this._router.navigate(['detalhes-colaborador/', collaboratorId]);
  }

  changeOrder(orderBy: OrderByProject) {
    if (this.filter.orderByParam.orderBy === orderBy) {
      this.filter.orderByParam.asc = !this.filter.orderByParam.asc;
    } else {
      this.filter.orderByParam.orderBy = orderBy;
      this.filter.orderByParam.asc = true;
    }

    this.getProjects(false);
  }

  formatDate(dateString: string): string | null {
    if (!dateString) return null;
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    const formattedDay = String(date.getDate()).padStart(2, '0');
    const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
    const formattedYear = date.getFullYear();

    return `${formattedDay}-${formattedMonth}-${formattedYear}`;
  }

  manageProject(projectId: string = '') {
    this._router.navigate([`gerenciar-projeto/${projectId}`]);
  }

  getSelectOptions() {
    this.optionsProject$ = this._projectService.getListSelectProject();
    this._subs.add(
      this.optionsProject$.subscribe((options: IOptionsProject) => {
        this.optionsProject = options;
        this.sortOptions();
        this.clients = [...this.optionsProject.clients];
        this.pips = [...this.optionsProject.pip];
      })
    );
  }

  private sortOptions() {
    if (this.optionsProject && typeof this.optionsProject === 'object') {
      const propertyMap: { [key: string]: string } = {
        clients: 'name',
        towers: 'description',
        teams: 'description',
        collaborators: 'fullName',
        status: 'description',
        pip: ''
      };
  
      Object.keys(this.optionsProject).forEach((key) => {
        const value = this.optionsProject[key];
        if (Array.isArray(value) && propertyMap[key]) {
          this.sortByProperty(value, propertyMap[key]);
        }
      });
    }
  }
  
  private sortByProperty(array: any[], property: string) {
    array.sort((a, b) => {
      const propA = a[property] ? a[property].toString() : '';
      const propB = b[property] ? b[property].toString() : '';
      return propA.localeCompare(propB);
    });
  }

  createFormGroup() {
    this.filterForm = this._fb.group({
      projectStatusId: [null],
      projectInitiation: [null],
      projectConclusion: [null],
      projectPIP: [null],
      clientId: [null],
    });

    this.filterForm.patchValue(this.filter);
  }

  displayFn(control: string, optionId: string): string {    
    let option: any;

    if(!this.optionsProject) {
      return '';
    }

    switch (control) {
      case 'clients':
        option = this.optionsProject.clients.find((option) => option.id === optionId);
        break;
      case 'pip':
        option = this.optionsProject.pip.find((option) => option === optionId);
        break;
    }

    return option?.name ? option?.name : option;
  }

  filterAutocomplete() {
    this.filterForm.controls['clientId'].valueChanges.subscribe((value) => {
      this.clients = this.optionsProject.clients.filter((option) => option.name?.toLowerCase().includes(value?.toLowerCase()));
    });

    this.filterForm.controls['projectPIP'].valueChanges.subscribe((value) => {
      this.pips = this.optionsProject.pip.filter((option) => option.toLowerCase().includes(value?.toLowerCase()));
    });
  }
}
