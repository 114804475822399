<div class="content">
  <div class="row page-header space-between p-vertical-lg">
    <div class="col page-title">
      <h1>{{ pageTitle }}</h1>
    </div>
    <ng-template permissions [role]="'Create/Update'" [process]="'Client'">
      <button class="btn secondary btn--width-sm" (click)="openDialogClient()">
        {{ btnText }}
      </button>
    </ng-template>
  </div>

  <form [formGroup]="filterForm">
    <div class="flex justify-between align-center filter-ctn">
      <div class="row align-center flex-nowrap">
        <mat-label class="input-label--project">Status: </mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-md"
          appearance="fill"
          floatLabel="always"
        >
          <mat-select
            formControlName="inactive"
            class="custom-select"
            (ngModelChange)="
              $event !== null &&
                changeChipList('inactive', !$event ? 'Ativo' : 'Inativo')
            "
          >
            <mat-option [value]="false"> Ativo </mat-option>
            <mat-option [value]="true"> Inativo </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row align-center input-filter-collaborator">
        <mat-form-field
          class="rounded input--width-xl-secondary search-button"
          appearance="fill"
          floatLabel="always"
        >
          <input
            placeholder="Faça uma busca"
            type="text"
            matInput
            [formControl]="searchByNameCtrl"
          />
          <mat-icon matPrefix>search</mat-icon>
          <mat-icon
            title="Limpar pesquisa"
            *ngIf="searchByNameCtrl?.value?.length >= 1"
            (click)="clearFilter()"
            matSuffix
            >backspace</mat-icon
          >
          <button
            matSuffix
            class="btn primary btn--width-xxs"
            (click)="searchBy()"
          >
            Buscar
          </button>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="row m-md">
    <mat-chip-list>
      <ng-container *ngFor="let chip of chipsList">
        <mat-chip *ngIf="chip" (click)="removeFilterValueChips(chip)">
          <span *ngIf="chip.description">{{ chip.description }}</span>
          <mat-icon>clear</mat-icon>
        </mat-chip>
      </ng-container>
    </mat-chip-list>
  </div>

  <div class="p-bottom-lg">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="table-primary table-clickable"
    >
      <ng-container
        matColumnDef="{{ column.key }}"
        *ngFor="let column of columns | keyvalue"
      >
        <th class="division" mat-header-cell mat-sort-header *matHeaderCellDef>
          <ng-container
            *ngIf="column.key != 'action' && column.key != 'inactive'"
          >
            <span>{{ column.value }}</span>
          </ng-container>
          <ng-container
            *ngIf="column.key !== 'action' && column.key !== 'inactive'"
          >
            <mat-icon (click)="changeOrder(orderOptions[column.key])"
              ><img src="./assets/svg/orderBy.svg" alt=""
            /></mat-icon>
          </ng-container>
          <ng-template
            *ngIf="column.key === 'inactive'"
            permissions
            [role]="'Delete'"
            [process]="'Client'"
          >
            {{ column.value }}
          </ng-template>
        </th>

        <td  class="truncate"  mat-cell *matCellDef="let element; let index = index" #elRow>
          <ng-template permissions [role]="'Delete'" [process]="'Client'">
            <ng-container *ngIf="column.key == 'inactive'">
              <mat-slide-toggle
                class="switch"
                [(ngModel)]="!element.inactive"
                (change)="inactivateClient($event, element.id)"
              ></mat-slide-toggle>
            </ng-container>
          </ng-template>
          <ng-template
            permissions
            [role]="'Create/Update'"
            [process]="'Client'"
          >
            <div *ngIf="column.key == 'action'" class="mat-column-status">
              <mat-icon (click)="openDialogClient(element)"
                ><img src="./assets/svg/edit-black.svg" alt=""
              /></mat-icon>
            </div>
          </ng-template>
          <div
            *ngIf="column.key != 'inactive' && column.key != 'action'"
            class="mat-column-status"
          >
            {{ element[column.key] }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="disclaimer">
        <td mat-header-cell *matHeaderCellDef colspan="1">
          <mat-select
            (selectionChange)="changeItemsPerPage($event.value)"
            class="select-disclaimer rounded input--width-xs m-md"
            [(ngModel)]="pageSize"
          >
            <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
              {{ option }} itens por página
            </mat-option>
          </mat-select>
        </td>
      </ng-container>
      <tr mat-footer-row *matHeaderRowDef="['disclaimer']"></tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <app-paginator
      *ngIf="countDataSource > filter.take"
      [length]="countDataSource"
      [pageSize]="filter.take"
      (skipPage)="skipPage($event)"
    ></app-paginator>
  </div>
</div>
