import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-add-collaborator-skill',
  templateUrl: './add-collaborator-skill.component.html',
  styles: [],
})
export class AddCollaboratorSkillComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AddCollaboratorSkillComponent>) {}

  ngOnInit(): void {}

  confirmSubmit() {
    this.close({ reload: true });
  }

  close(emit = { reload: false }): void {
    this.dialogRef.close(emit);
  }
}
