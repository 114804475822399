import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { IGenericData, IOptionsCollaborator, ISkillOption, ITower } from 'src/app/pages/collaborator/interface/options-collaborator.interface';
import { CollaboratorService } from 'src/app/pages/collaborator/repository/collaborator.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MessageService } from 'src/app/core/services/message.service';
import { COLUMNS_COLLABORATOR } from 'src/app/shared/models/table-columns/columns-collaborator';
import { IDateFormats } from 'src/app/shared/interfaces/date-format.interface';
import {
  ICollaboratorFilter,
  ICollaboratorMinified,
  OrderByCollaborator,
} from 'src/app/pages/collaborator/interface/collaborator.interface';
import {
  EPermissionType,
  EProcess,
} from 'src/app/shared/interfaces/roles.interface';
import { UserRoleService } from 'src/app/pages/account/repository/user-role.service';
import { ConfirmDialogInactivationComponent } from 'src/app/shared/components/confirm-dialog-inactivation/confirm-dialog-inactivation.component';
import { CollaboratorFilterService } from 'src/app/core/services/filters/collaborator-filter.service';

type chip = {
  controlName: string;
  description: string;
};
@Component({
  selector: 'app-collaborator-list',
  templateUrl: './collaborator-list.component.html',
  styles: [],
})
export class CollaboratorListComponent implements OnInit {
  columns = COLUMNS_COLLABORATOR;
  pageTitle: string = 'Colaboradores';
  btnText: string = 'Cadastrar Colaborador';
  pageSizeOptions: Array<number> = [5, 10, 20];
  currentPage: number = 1;
  displayedColumns: string[] = [];
  pageSize: number = 20;
  isEditable: boolean = false;

  EProcess = EProcess;
  EPermissionType = EPermissionType;

  private _subs = new SubSink();

  dataSource: MatTableDataSource<ICollaboratorMinified> =
    new MatTableDataSource<ICollaboratorMinified>();
  filterForm!: FormGroup;
  searchByNameCtrl: FormControl = new FormControl();
  chipsList: Array<chip> = [];
  countDataSource: number = 0;

  options!: IOptionsCollaborator;
  options$!: Observable<IOptionsCollaborator>;

  collaborators: ICollaboratorMinified[] = [];
  filter: ICollaboratorFilter = this.filterService.getFilter(); // Pega o filtro mais recente salvo no service

  orderOptions: { [key: string]: number } = {
    fullName: OrderByCollaborator.NAME,
    jobFunctionDescription: OrderByCollaborator.JOB_FUNCTION,
    collaboratorLeaderName: OrderByCollaborator.LEADER,
    teamDescription: OrderByCollaborator.TEAM,
    towerDescription: OrderByCollaborator.TOWER,
  };

  isResetting: boolean = false;

  jobFunctions: IGenericData[] = [];
  skills: IGenericData[] = [];
  cities: IGenericData[] = [];
  towers: IGenericData[] = [];

  constructor(
    private _fb: FormBuilder,
    private _collaboratorService: CollaboratorService,
    private _router: Router,
    public dialog: MatDialog,
    private _messageService: MessageService,
    private _userRoleService: UserRoleService,
    private filterService: CollaboratorFilterService
  ) { }

  ngOnInit(): void {
    this.createFormGroup();
    this.currentPage = this.filter.page;
    this.searchByNameCtrl.setValue(this.filter.searchString);
    this.displayedColumns = Object.keys(this.columns);
    this.getSelectOptions();
    this.getCollaborators(false);
    this.hasPermission();
    this.chipsList = this.filterService.getChipsList();
    this.filterAutocomplete();
  }

  setFilterValue(): void {
    setTimeout(() => {
      // Uso da variável para evitar que faça chamadas get de colaboradores a cada mudança de valor quando estiver limpando o formulário
      if (this.isResetting) return;
  
      this.filter = {
        ...this.filter,
        ...this.filterForm.value,
      };
      this.filterService.setFilter(this.filter);
      this.getCollaborators();
    }, 200);
  }

  getCollaborators(resetPage: boolean = true): void {
    if (resetPage) {
      this.filter.page = 1;
      this.currentPage = 1;
      this.countDataSource = 0;
    }
    
    this._collaboratorService
      .getAllCollaboratorsByFilter(this.filter)
      .subscribe((response) => {
        if (response.success === true) {
          this.collaborators = response.result;
          this.countDataSource = response.count;

          this.dataSource.data = this.collaborators;
        }
      });
  }

  searchBy() {
    this.filter.searchString = this.searchByNameCtrl.value;
    this.filterService.setFilter(this.filter);
    this.getCollaborators();
  }

  clearFilter() {
    this.searchByNameCtrl.reset();
    this.filter.searchString = null;
    this.filterService.setFilter(this.filter);
    this.getCollaborators();
  }

  createFormGroup() {
    this.filterForm = this._fb.group({
      towerId: [null],
      teamId: [null],
      jobFunctionId: [null],
      skillsId: [null],
      skillLevel: [{ value: null, disabled: true }],
      inactive: [null],
      city: [null],
    });

    this.filterForm.patchValue(this.filter);

    const skillsControl = this.filterForm.controls['skillsId'];
    const levelControl = this.filterForm.controls['skillLevel'];
    if (skillsControl && levelControl) {
      skillsControl.valueChanges.subscribe((skillIdValue) => {
        if (skillIdValue) {
          levelControl.enable();
          levelControl.reset();
        } else {
          levelControl.disable();
          levelControl.reset();
        }
      });
    }
  }

  getSelectOptions() {
    this.options$ = this._collaboratorService.getCollaboratorSelect();

    this._subs.add(
      this.options$.subscribe((options: IOptionsCollaborator) => {
        this.options = options;
        this.sortOptions();
        this.jobFunctions = [...this.options.jobFunctions];
        this.skills = [...this.options.skills];
        this.cities = [...this.options.cities] as IGenericData[];
        this.towers = [...this.options.towers];        
      })
    );
  }
  
  private sortOptions() {
    if (this.options && typeof this.options === 'object') {
      const propertyMap: { [key: string]: string } = {
        contractTypes: 'description',
        jobFunctions: 'description',
        teams: 'description',
        senioritys: 'description',
        towers: 'description',
        skills: 'description',
        skillLevel: 'description',
        accessProfile: 'name',
        leaders: 'name',
        cities: 'description'
      };
  
      Object.keys(this.options).forEach((key) => {
        const value = this.options[key];
        if (Array.isArray(value) && propertyMap[key]) {
          this.sortByProperty(value, propertyMap[key]);
        }
      });
    }
  }
  
  private sortByProperty(array: any[], property: string) {
    array.sort((a, b) => {
      const propA = a[property] ? a[property].toString() : '';
      const propB = b[property] ? b[property].toString() : '';
      return propA.localeCompare(propB);
    });
  }

  skipPage(event: { skip: number }) {
    this.filter.page = event.skip + 1;
    this.filterService.setFilter(this.filter);
    this.getCollaborators(false);
  }

  changeChipList(
    event: any = null,
    controlName: string,
    description: string,
    value?: string
  ): void {    
    if(!event?.isUserInput && controlName !== 'inactive') {
      return;
    }

    let valueForm = this.filterForm.get(controlName)?.value;
    
    if (valueForm === value) return;

    const hasChipControl = this.chipsList.findIndex(
      (item) => item.controlName === controlName
    );
    let descriptionValue = '';
    if (hasChipControl >= 0) {
      descriptionValue = this.chipsList.splice(hasChipControl, 1)[0]
        .description;
    }

    if (description) {
      this.chipsList.push({
        controlName: controlName,
        description: description,
      });

      this.filterService.setChipsList(this.chipsList);
    }

    this.setFilterValue();
  }

  removeFilterValueChips(chip: chip) {
    this.chipsList = this.chipsList.filter(
      (item) => item.controlName !== chip.controlName
    );
    this.filterForm.get(chip.controlName)?.reset();
    this.filterService.setChipsList(this.chipsList);
    this.setFilterValue();
    this.resetAutocompleteFilters();
  }

  removeAllChips() {
    this.filterService.resetFilter();
    this.filter = this.filterService.getFilter();
    this.isResetting = true;
    this.filterForm.reset();
    this.searchByNameCtrl.reset();
    this.isResetting = false;
    this.getCollaborators();
    this.chipsList = [];
    this.filterService.resetChipsList();
    this.resetAutocompleteFilters();
  }

  resetAutocompleteFilters() {
    this.jobFunctions = [...this.options.jobFunctions];
    this.skills = [...this.options.skills];
    this.cities = [...this.options.cities] as IGenericData[];
    this.towers = [...this.options.towers];
  }

  removeInactiveChip(): void {
    let index = this.chipsList.findIndex(
      (item) => item.controlName === 'inactive'
    );
    if (index >= 0) {
      this.chipsList.splice(index, 1);
      this.filterService.setChipsList(this.chipsList);
    }
  }

  changeItemsPerPage(items: number) {
    this.filter.page = 1;
    this.filter.take = items;
    this.filterService.setFilter(this.filter);

    this.getCollaborators();
  }

  createCollaborator() {
    this._router.navigate(['cadastrar-colaborador/']);
  }

  editCollaborator(collaboratorId: string, column: string) {
    if (column !== 'status')
      this._router.navigate(['detalhes-colaborador/', collaboratorId]);
  }

  collaboratorInactivation(
    event: any,
    element: ICollaboratorMinified,
    index: number
  ) {
    let message: string = '';
    let buttonTxt: string = '';
    if (element.inactive === false) {
      message = 'Deseja inativar o colaborador?';
      buttonTxt = 'Sim';
    } else {
      message = 'Deseja reativar o colaborador?';
      buttonTxt = 'Sim';
    }
    if (element.collaboratorId) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { message: message, buttonTxt: buttonTxt, confirm: false },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (element.inactive === false) {
            const messageInativacao = 'Digite o motivo do desligamento.';
            message = messageInativacao;
            buttonTxt = 'Confirmar';
            const dialogRefs = this.dialog.open(
              ConfirmDialogInactivationComponent,
              {
                data: {
                  message: message,
                  buttonTxt: 'Confirmar',
                  confirm: false,
                },
              }
            );
            dialogRefs.afterClosed().subscribe((result) => {
              if (result) {
                const { reason, exclusionDate } = result;
                this.handleInactivation(element, reason, index, event, exclusionDate);
              } else {
                this.handleCheckboxChange(event, element, index);
              }
            });
          } else {
            this.handleReactivation(element);
          }
        } else {
          this.handleCheckboxChange(event, element, index);
        }
      });
    }
  }

  handleInactivation(
    element: ICollaboratorMinified,
    reason: string,
    index: number,
    event: any,
    exclusionDate: Date
  ) {
    const collaboratorId = element.collaboratorId;
    this._subs.add(
      this._collaboratorService
        .collaboratorInactivate(collaboratorId, reason, exclusionDate)
        .subscribe(
          (response) => {
            if (response.success === true) {
              this._messageService.openSuccessMessage(
                'Inativação realizada com sucesso'
              );
              this.getCollaborators();
            } else if (response.errors.length > 0) {
              this._messageService.openErrorMessage(response.errors);
              this.getCollaborators();
              event.source.checked = !event.checked;
              event.checked = event.source.checked;
              this.dataSource.data[index].inactive = event.checked;
            }
          },
          () => {
            this._messageService.openErrorMessage([
              'Erro encontrado, tente novamente',
            ]);
            this.getCollaborators();
            event.source.checked = !event.checked;
            event.checked = event.source.checked;
            this.dataSource.data[index].inactive = event.checked;
          }
        )
    );
  }

  handleReactivation(element: ICollaboratorMinified) {
    const collaboratorId = element.collaboratorId;
    this._subs.add(
      this._collaboratorService
        .collaboratorInactivate(
          collaboratorId,
          'Reativação realizada',
          new Date()
        )
        .subscribe(
          (response) => {
            if (response.success === true) {
              this._messageService.openSuccessMessage(
                'Reativação realizada com sucesso'
              );
              this.getCollaborators();
            } else if (response.errors.length > 0) {
              this._messageService.openErrorMessage(response.errors);
              this.getCollaborators();
            }
          },
          () => {
            this._messageService.openErrorMessage([
              'Erro encontrado, tente novamente',
            ]);
            this.getCollaborators();
          }
        )
    );
  }

  handleCheckboxChange(event: any, element: ICollaboratorMinified, index: number) {
    event.source.checked = !event.checked;
    event.checked = event.source.checked;
    this.dataSource.data[index].inactive = event.checked;
    element.inactive = !element.inactive;
  }

  getinitialsNameCollaborator(name: string): string {
    const initialsArray = name.split(' ').filter((name) => {
      if (name) return name;
      return '';
    });
    const firstLetter = initialsArray[0][0];
    const secondLetter =
      initialsArray.length > 1
        ? initialsArray[initialsArray.length - 1][0]
        : '';
    return `${firstLetter}${secondLetter}`;
  }

  changeOrder(orderBy: OrderByCollaborator) {
    if (this.filter.orderByParam.orderBy === orderBy) {
      this.filter.orderByParam.asc = !this.filter.orderByParam.asc;
    } else {
      this.filter.orderByParam.orderBy = orderBy;
      this.filter.orderByParam.asc = true;
    }

    this.getCollaborators(false);
  }

  hasPermission() {
    [
      EProcess.CollaboratorPersonalData,
      EProcess.CollaboratorAddress,
      EProcess.CollaboratorContractualinformation,
      EProcess.CollaboratorSkills,
    ].forEach((process) => {
      if (
        this._userRoleService.hasRole(process, EPermissionType.CreateUpdate)
      ) {
        this.isEditable = true;
      }
    });
  }

  isEmptyObject(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  displayFn(control: string, optionId: string): string {    
    let option: any;
    
    if(!this.options) {
      return '';
    }

    if(control === 'cities') {
      option = (this.options[control as keyof IOptionsCollaborator] as any)?.find((opt: any) => opt.description === optionId);
    } else {
      option = (this.options[control as keyof IOptionsCollaborator] as any)?.find((opt: any) => opt.id === optionId);
    }

    return option ? option?.description : '';
  }

  filterAutocomplete() {
    this.filterForm.controls['jobFunctionId'].valueChanges.subscribe((value) => {
      this.jobFunctions = this.options.jobFunctions.filter((option) => option.description?.toLowerCase().includes(value?.toLowerCase()));
    });

    this.filterForm.controls['skillsId'].valueChanges.subscribe((value) => {
      this.skills = this.options.skills.filter((option) => option.description?.toLowerCase().includes(value?.toLowerCase()));
    });

    this.filterForm.controls['city'].valueChanges.subscribe((value) => {
      this.cities = this.options.cities.filter((option) => option.description?.toLowerCase().includes(value?.toLowerCase())) as IGenericData[];
    });

    this.filterForm.controls['towerId'].valueChanges.subscribe((value) => {
      this.towers = this.options.towers.filter((option) => option.description?.toLowerCase().includes(value?.toLowerCase()));
    });
  }
}
