<div class="content">
  <form [formGroup]="filterForm">
    <div class="row align-center p-md">
      <mat-form-field
        class="rounded input--width-full search-button"
        appearance="fill"
        floatLabel="always"
        placeholder="Buscar"
      >
        <input
          placeholder="Faça uma busca"
          type="text"
          matInput
          [formControl]="searchByNameCtrl"
        />
        <mat-icon matPrefix>search</mat-icon>
        <mat-icon
        title="Limpar pesquisa"
          *ngIf="searchByNameCtrl?.value?.length >= 1"
          (click)="clearFilter()"
          matSuffix
          >backspace</mat-icon
        >
        <button
          matSuffix
          class="btn primary btn--width-xxs"
          (click)="searchBy()"
        >
    Buscar
        </button>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="row align-center">
        <mat-label class="input-label--custom">Função : </mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <input
            type="text"
            matInput
            [matAutocomplete]="jobFunction"
            formControlName="jobFunctionId"
            (keyup)="filterAutocomplete('jobFunctionId', $event.target)"
          />
          <mat-autocomplete #jobFunction="matAutocomplete" class="custom-select" [displayWith]="displayFn.bind(this, 'jobFunctions')"  (selectionChange)="onFilterSelected()">
            <mat-option
              *ngFor="let option of jobFunctions"
              [value]="option"
              (onSelectionChange)="
                changeChipList($event, 'jobFunctionId', option)
              "
            >
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="row align-center">
        <mat-label class="input-label--custom">Senioridade: </mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <mat-select formControlName="seniorityId" class="custom-select" (selectionChange)="onFilterSelected()">
            <mat-option
              *ngFor="let option of optionsFilter?.senioritys"
              [value]="option"
            >
              {{ option.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row align-center">
        <mat-label class="input-label--custom">Habilidade: </mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <input
            type="text"
            matInput
            [matAutocomplete]="skill"
            formControlName="skillId"
            (keyup)="filterAutocomplete('skillId', $event.target)"
          />
          <mat-autocomplete #skill="matAutocomplete" class="custom-select" [displayWith]="displayFn.bind(this, 'skills')" (selectionChange)="onFilterSelected()">
            <mat-option
              *ngFor="let option of skills"
              [value]="option"
              (onSelectionChange)="
                changeChipList($event, 'skillId', option)
              "
            >
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="row align-center">
        <mat-label class="input-label--custom">Nível: </mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <mat-select
          [disabled]="!filterForm.controls['skillId'].value"
           formControlName="skillLevel" class="custom-select" (selectionChange)="onFilterSelected()">
            <mat-option
              *ngFor="let option of levelSelectOptions"
              [value]="option"
            >
              {{  option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row align-center">
        <mat-label class="input-label--custom">Status: </mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
            <mat-select formControlName="statusAllocation" class="custom-select"
            [matTooltip]="getStatusEnumName(filterForm.controls['statusAllocation'].value)" (selectionChange)="onFilterSelected()">
            <mat-select-trigger>
              <span [style.color]="getStatusColor(filterForm.get('statusAllocation')?.value)">{{ getStatusEnumName(filterForm.get('statusAllocation')?.value) }}</span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let option of statusSelectOptions"
              [value]="option.key"
              [style.color]="getStatusColor(option.key)"
            >
              {{ option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row align-center">
        <mat-label class="input-label--custom">Torre:</mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <input
            type="text"
            matInput
            [matAutocomplete]="tower"
            formControlName="towerId"
            (keyup)="filterAutocomplete('towerId', $event.target)"
          />
          <mat-autocomplete #tower="matAutocomplete" class="custom-select" [displayWith]="displayFn.bind(this, 'towers')" (selectionChange)="onFilterSelected()">
            <mat-option
              *ngFor="let option of towers"
              [value]="option"
              (onSelectionChange)="
                changeChipList($event, 'towerId', option)
              "
            >
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="row align-center">
        <mat-label class="input-label--custom">Liderança:</mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <input
            type="text"
            matInput
            [matAutocomplete]="leader"
            formControlName="leaderId"
            (keyup)="filterAutocomplete('leaderId', $event.target)"
          />
          <mat-autocomplete #leader="matAutocomplete" class="custom-select" [displayWith]="displayFn.bind(this, 'leaders')" (selectionChange)="onFilterSelected()">
            <mat-option
              *ngFor="let option of leaders"
              [value]="option"
              (onSelectionChange)="
                changeChipList($event, 'leaderId', option)
              "
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="row align-center">
        <mat-label class="input-label--custom">Período: </mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <mat-select
            [formControl]="period"
            class="custom-select"
            (selectionChange)="onPeriodChange($event)"
          >
            <mat-option
              *ngFor="let option of periodSelectOptions"
              [value]="option.name"
            >
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row align-center">
        <mat-label class="input-label--custom">Início/fim:</mat-label>
        <mat-form-field
          class="rounded input--width-sm m-sm "
          appearance="fill"
         floatLabel="always"
        >
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="startDate" />
            <input
              matEndDate
              formControlName="endDate"
              [value]="filterConfig.endDate"
              (dateChange)="onFilterSelected()"
            />
          </mat-date-range-input>
           <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="row m-md">
    <mat-chip-list>
      <span class="icon-info" *ngIf="chipsList.length > 0">
        <mat-icon
        matTooltipClass='info-tooltip'
        [matTooltipPosition]="'above'"
        matTooltip="Nos campos do filtro, você pode escolher filtrar mais de um em cada categoria."
          >info</mat-icon
        >
        Filtros:</span
      >
      <ng-container *ngFor="let chip of chipsList">
        <mat-chip *ngIf="chip" (click)="removeFilterValueChips(chip)">
          <span *ngIf="chip.description">{{ chip.description }}</span>
          <span *ngIf="!chip.description">{{ chip.name }}</span>
          <span *ngIf="!chip.description && !chip.name">{{ chip.value }}</span>
          <mat-icon>clear</mat-icon>
        </mat-chip>
      </ng-container>
      <mat-chip
        class="remove-chip"
        *ngIf="chipsList.length > 0"
        (click)="removeAllChips()"
      >
        <span class="error">Excluir todos</span>
        <mat-icon>clear</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <div>
    <table
      matSort
      mat-table
      [dataSource]="dataSource"
      class="table-primary table-clickable"
    >
      <ng-container
        matColumnDef="{{ column.key }}"
        *ngFor="let column of columns | keyvalue"
      >
        <ng-container *ngIf="column.key === 'select'">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              class="checkbox m-horizontal-xs"
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
        </ng-container>
        <ng-container *ngIf="column.key === 'select'">
          <td mat-cell *matCellDef="let row">
            <ng-container>
              <mat-checkbox
                class="checkbox m-horizontal-xs "
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </ng-container>
          </td>
        </ng-container>
        <ng-container *ngIf="column.key === 'info'">
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="!row.allocable">
              <mat-icon
                class="inactive p-top-xs"
                matTooltip="A alocação deste colaborador é um empréstimo e pode ser recusado pela liderança."
                >info</mat-icon
              >
            </ng-container>
          </td>
        </ng-container>
        <ng-container *ngIf="column.key !== 'select'">
          <th mat-header-cell *matHeaderCellDef>
            <div class="alinhar-icon">
              <ng-container *ngIf="column.key !== 'edition'">
                {{ column.value }}
              </ng-container>
              <ng-container
                *ngIf="
                  column.key !== 'hours' &&
                  column.key !== 'edition' &&
                  column.key !== 'absenteeCollaborator'&& column.key !== 'info'
                "
              >
                <mat-icon (click)="ordenar(getOrderByValue(column.key))"
                  ><img src="./assets/svg/orderBy.svg" alt=""
                /></mat-icon>
              </ng-container>
              <ng-container *ngIf="column.key === 'edition'">
                <button
                  style="border: none"
                  [disabled]="!selection.hasValue()"
                  class="btn primary-no-border min-height"
                  (click)="editMultipleAllocation()"
                >
                  Alocar
                </button>
              </ng-container>
            </div>
          </th>
        </ng-container>
        <ng-container *ngIf="column.key !== 'select'">
          <td mat-cell *matCellDef="let element; let index = index" #elRow>
            <div
              *ngIf="column.key !== 'hours' && column.key !== 'edition'"
              [class]="'row-' + index"
            >
              {{ element[column.key] }}
            </div>
            <div *ngIf="column.key === 'edition'">
              <span class="warning">
                <mat-icon>
                  <button
                    title="Visualizar"
                    class="btn-icon-visuality"
                    (click)="
                      updateCollaboratorAllocation(
                        element['collaboratorId'],
                        element['allocable']
                      )
                    "
                  >
                    <img src="./assets/svg/visibility.svg" alt="Visualizar" />
                  </button>
                </mat-icon>
              </span>
            </div>
            <div *ngIf="column.key === 'hours'">
              <span [ngClass]="getStatusClass(element.workingPercentage)">
                {{ element[column.key] }}</span
              >
              <span class="status-disabled">
                {{ element.workingHoursPerPeriod }}h
              </span>
            </div>
            <div *ngIf="column.key === 'absenteeCollaborator'">
              <ng-container *ngIf="element.absence">
                <span class="warning">
                  <mat-icon
                  matTooltipClass='warning-tooltip'
                  [matTooltipPosition]="'above'"
                  matTooltip="Este colaborador tem uma ausência no período selecionado. Para saber  mais, clique no ícone de visualizar no lado direito."
                  >
                    <img src="./assets/svg/warning.svg" alt="" />
                  </mat-icon>
                </span>
              </ng-container>
            </div>
          </td>
        </ng-container>
      </ng-container>
      <ng-container matColumnDef="disclaimer">
        <td mat-header-cell *matHeaderCellDef colspan="2">
          <mat-select
              (selectionChange)="changeItemsPerPage($event.value)"
              [(value)]="filterConfig.take"
              class="rounded input--width-xs-secondary m-md"
            >
              <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
                {{ option }} itens por página
              </mat-option>
            </mat-select>
        </td>
      </ng-container>
      <tr mat-footer-row *matHeaderRowDef="['disclaimer']"></tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr> -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <app-paginator
    *ngIf="countDataSource > filterConfig.take "
    [length]="countDataSource"
    [pageSize]="filterConfig.take"
    [currentPage]="filterConfig.page"
    (skipPage)="skipPage($event)"
  ></app-paginator>
  </div>
</div>
