export abstract class FilterConfigBase{
  page!: number;
  take!: number;
}

export class FilterPermission extends FilterConfigBase{
  override page!: number;
  override take!: number;
  constructor() {
    super();
    this.page = 1;
    this.take = 10;
  }
  changeTake(event: number) {
    this.page = 1;
    this.take = event;
    console.log(`Page: ${this.page}, Take: ${this.take}`);
  }

  skipPage(event: { skip: number }) {
    this.page = event.skip + 1;
    console.log(`Page: ${this.page}, Take: ${this.take}`);
  }
}


