<div class="content">
  <div class="row align-center p-vertical-xxl input-filter-collaborator">
    <mat-form-field
      class="rounded input--width-full search-button"
      appearance="fill"
      floatLabel="always"
    >
      <input
        placeholder="Faça uma busca"
        type="text"
        matInput
        [formControl]="searchByNameCtrl"
      />
      <mat-icon matPrefix>search</mat-icon>
      <mat-icon
        title="Limpar pesquisa"
        *ngIf="searchByNameCtrl?.value?.length >= 1"
        (click)="clearFilter()"
        matSuffix
        >backspace</mat-icon
      >
      <button matSuffix class="btn primary btn--width-xxs" (click)="searchBy()">
        Buscar
      </button>
    </mat-form-field>
  </div>
  <ng-container *ngFor="let item of loans">
    <div class="card--border p-xl m-lg">
      <div class="row justify-end position-absolut right top">
        <button
          (click)="refusLoan(item)"
          class="btn-border-red btn--width-xxxs p-horizontal-md m-horizontal-md s-vertical-xs">
          Recusar empréstimo
        </button>
      </div>
      <div class="section-form">
        <div class="flex justify-between align-end filter-ctn">
          <div class="flex-1">
            <div>
              <mat-label class="label--custom-project">Colaborador:</mat-label>
              <span>{{ item.collaboratorName }} </span>
            </div>
            <div>
              <mat-label class="label--custom-project"
                >Responsável empréstimo:</mat-label
              >
              <span >{{ item.loanResponsible == null  ?  "Não informado"   : item.loanResponsible }}</span>
            </div>
          </div>
          <div class="flex-1">
            <div>
              <mat-label class="label--custom-project"
                >Tribo pertencente:</mat-label
              >
              <span>{{ item.collaboratorTeam == null || item.collaboratorTeam == "" ? "Não informado" : item.collaboratorTeam}} </span>
            </div>
            <div>
              <mat-label class="label--custom-project"
                >Tribo a migrar:</mat-label
              >
              <span>{{ item.loanResponsibleTeam == null || item.loanResponsibleTeam == "" ? "Não informado"  : item.loanResponsibleTeam }}</span>
            </div>
          </div>
          <div class="flex-1">
            <div>
              <mat-label class="label--custom-project">Projeto:</mat-label>
              <span>{{ item.projectNameLoan == null || item.projectNameLoan == ""? "Não informado" : item.projectNameLoan }}</span>
            </div>
            <div>
              <mat-label class="label--custom-project">Cliente:</mat-label>
              <span>{{ item.clientNameLoan == null || item.clientNameLoan == "" ? "Não informado" : item.clientNameLoan }}</span>
            </div>
          </div>
          <div class="flex-1">
            <div>
              <mat-label class="label--custom-project">% alocado:</mat-label>
              <span>{{ item.percentageLoan + '% alocado'}}</span>
            </div>
            <div>
              <mat-label class="label--custom-project"
                >Data de início
              </mat-label>
              <span>{{ item.startDateLoan }}</span>
            </div>
          </div>
          <div class="flex ">
            <div>
              <mat-label class="label--custom-project">Data de fim </mat-label>
              <span>{{ item.endDateLoan }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
