import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { environment } from 'src/environments/environment.development';
import { ICollaboratorSkill, ICollaboratorSkills } from '../interface/collaborator-skills.interface';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorSkillService extends BaseApiService{
  endpoint: string = 'CollaboratorSkills';
  
  constructor(protected override http: HttpClient) {
    super(http);
  }
  getAllbyCollaborator(collaboratorId: string, filter: any) {
    const path = 'ListByFiltersAsync'
    const url = `${environment.apiUrl}/${this.endpoint}/${path}/${collaboratorId}`;
    return this.http
      .post<IApiResponse>(url, filter)
  }

  postCollaboratorSkill(collaborator: ICollaboratorSkill): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;

    return this.http.post<ICollaboratorSkill>(url, collaborator);
  }

  putCollaboratorSkill(collaborator: ICollaboratorSkills[]): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.put<ICollaboratorSkill>(url, collaborator);
  }

  inactivateCollaboratorSkill(skillToInactivate: any): Observable<any> {
    const path = 'inactivate';
    const body = skillToInactivate;
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    return this.http.delete<ICollaboratorSkill>(url,{body});
  }


  deleteCollaboratorSkill(skillId: string, collaboratorId: string): Observable<any> {
    const path = 'Remove';
    const body = {skillId : skillId, collaboratorId: collaboratorId}
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    return this.http.delete<any>(url,{body});
  }

}