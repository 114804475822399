<div class="row align-center header-allocation">
  <div class="flex justify-between align-center p-bottom-10px">
    <span class="project-name">{{ allocation?.projectName }}</span>

    <div class="wall"></div>

    <div class="row">
      <div class="col space-between">
        <span
          ><strong>Data de início: </strong>
          {{ allocation?.startDate | date : "dd/MM/yyyy" }}</span
        >
        <span
          ><strong>Data de fim: </strong>
          {{ allocation?.endDate | date : "dd/MM/yyyy" }}</span
        >
      </div>
      <div class="col">
        <span
          ><strong>Responsável pelo projeto: </strong>
          {{ allocation?.projectResponsible }}</span
        >
        <span><strong>Cliente: </strong> {{ allocation?.clientName }}</span>
      </div>
    </div>

    <div class="wall"></div>

    <div>
      <span class="text-filter">FILTROS</span>
      <div class="box-filter row align-center">
        <mat-label class="input-label--custom">Período: </mat-label>
        <mat-form-field
          class="rounded input--width-xs-secondary m-sm"
          appearance="fill"
          floatLabel="always"
        >
          <mat-select
            class="custom-select"
            [(ngModel)]="filterSelected"
            (ngModelChange)="changeFilter.emit(filterSelected)"
          >
            <mat-option
              *ngFor="let option of filterOptions"
              [value]="option.id"
            >
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div>
      <ng-container *ngFor="let item of legendItems">
        <app-legend-item
          [color]="item.color"
          [text]="item.text"
        ></app-legend-item>
      </ng-container>
    </div>
  </div>
</div>
