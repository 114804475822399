<div class="page-bg-secondary">
  <div class="form-ctn">
    <form
      [formGroup]="profileForm"
      #formDirective="ngForm"
      (ngSubmit)="onSubmitCollaborator(formDirective)"
    >
      <div class="section p-top-lg">
        <div class="row align-center p-top-lg">
          <h2>Cadastrar colaborador</h2>
        </div>
      </div>
      <hr />
      <div class="section-form">
        <!-- PERSONAL DATA-->
        <div class="row p-top-3xl">
          <div class="col-md-3 form-division"><h3>Dados pessoais</h3></div>
          <div class="col-md-9">
            <div class="row divisor --three p-vertical-lg">
              <div class="input-text-col">
                <mat-form-field
                  class="input-outline"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>Nome completo</mat-label>
                  <input
                    type="text"
                    matInput
                    maxlength="100"
                    formControlName="fullName"
                    required
                  />
                  <mat-icon *ngIf="false" matSuffix class="error"
                    >info</mat-icon
                  >
                </mat-form-field>
                <!-- <span
                    class="message-error"
                    style="max-width: 300px"
                    *ngIf="false"
                    >Este usuário já existe em nosso sistema</span
                  > -->
              </div>
              <div class="input-text-col">
                <mat-form-field
                  class="input-outline"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>Email</mat-label>
                  <input
                    type="email"
                    matInput
                    maxlength="100"
                    formControlName="email"
                    placeholder="Ex. email@performait.com"
                  />
                  <mat-icon
                    *ngIf="profileForm.get('email')?.hasError('invalidEmail')"
                    matSuffix
                    class="error"
                    >info</mat-icon
                  >
                </mat-form-field>
                <span
                  class="message-error"
                  style="max-width: 300px"
                  *ngIf="profileForm.get('email')?.hasError('invalidEmail')"
                  >O e-mail cadastrado deve ser o da Performa It</span
                >
              </div>
              <div class="input-text-col">
                <mat-form-field
                  class="input-outline"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>Email pessoal</mat-label>
                  <input
                    type="email"
                    matInput
                    formControlName="personalEmail"
                    placeholder="Ex. email@email.com"
                  />
                            </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <!-- CONTRACT INFO  -->
        <div class="row p-top-3xl">
          <div class="col-md-3 form-division"><h3>Info. contratuais</h3></div>
          <div class="col-md-9">
            <div class="row divisor --three p-vertical-lg">
              <div class="input-text-col">
                <mat-form-field
                  class="input-outline"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>Admissão</mat-label>
                  <input
                  [matDatepicker]="pickerAdmission"
                    matInput
                    [max]="maxAdmissionDate"
                    formControlName="admission"
                    required
                  />
                  <mat-datepicker-toggle
                  matSuffix
                  [for]="pickerAdmission"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerAdmission></mat-datepicker>
                </mat-form-field>
                <span
                  class="message-error"
                  style="max-width: 300px"
                  *ngIf="
                    profileForm
                      .get('admission')
                      ?.hasError('invalidAdmission') &&
                    profileForm.get('admission')?.value !== null &&
                    profileForm.get('admission')?.value !== ''
                  "
                  >Campo inválido</span
                >
              </div>
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Tipo de Contratação</mat-label>
                <mat-select formControlName="contractTypeId">
                  <mat-option
                    *ngFor="let option of options?.contractTypes"
                    [value]="option.id"
                  >
                    {{ option.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Dias de trabalho</mat-label>
                <mat-select formControlName="daysOfWeek" multiple>
                  <mat-select-trigger>
                    <span *ngIf="allSelected.selected"> Dias úteis </span>
                    <span *ngIf="!allSelected.selected">
                      {{ profileForm.controls["daysOfWeek"].value }}
                    </span>
                  </mat-select-trigger>
                  <mat-option
                    #allSelected
                    (click)="toggleAllWorkingDaysSelection()"
                    [value]="0"
                    >Dias úteis</mat-option
                  >
                  <mat-option
                    class="checkbox"
                    *ngFor="let enum of workingDaysSelectOptions | keyvalue"
                    [value]="enum.value"
                    (click)="togglePerOne()"
                  >
                    {{ enum.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row divisor --three p-vertical-lg">
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Horas de trabalho diária</mat-label>
                <input
                  type="text"
                  matInput
                  suffix="h"
                  mask="0"
                  [dropSpecialCharacters]="true"
                  thousandSeparator="."
                  formControlName="workingHours"
                  required
                />
              </mat-form-field>
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Torre</mat-label>
                <mat-select
                  (selectionChange)="selectTower()"
                  formControlName="towerId"
                >
                  <mat-option
                    *ngFor="let option of options?.towers"
                    [value]="option.id"
                  >
                    {{ option.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Equipe</mat-label>
                <mat-select formControlName="teamId">
                  <mat-option
                    *ngFor="let option of options?.teams"
                    [value]="option.id"
                  >
                    {{ option.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row divisor --three p-vertical-lg">
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Líder do Colaborador</mat-label>
                <mat-select formControlName="collaboratorLeaderId">
                  <mat-option
                    *ngFor="let option of options?.leaders"
                    [value]="option.id"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Função</mat-label>
                <mat-select formControlName="jobFunctionId">
                  <mat-option
                    *ngFor="let option of options?.jobFunctions"
                    [value]="option.id"
                  >
                    {{ option.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="input-outline"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Senioridade</mat-label>
                <mat-select formControlName="seniorityId">
                  <mat-option
                    *ngFor="let option of options?.senioritys"
                    [value]="option.id"
                  >
                    {{ option.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-checkbox class="checkbox" formControlName="isLeader"
                >Líder</mat-checkbox
              >
            </div>
          </div>
        </div>
      </div>
      <!-- COLLABORATOR PROFILE-->
      <div class="row p-top-3xl">
        <div class="col-md-3 form-division">
          <h3>Permissões de perfil</h3>
        </div>
        <div class="col-md-9">
          <div class="row divisor --three p-vertical-lg">
            <mat-form-field
              class="input-outline"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Perfil</mat-label>
              <mat-select formControlName="accessProfileId">
                <mat-option
                  *ngFor="let option of options?.accessProfile"
                  [value]="option.id"
                >
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row justify-end p-lg">
        <button
          class="btn secondary m-horizontal-xl"
          type="button"
          (click)="returnToCollaboratorList()"
        >
          Cancelar
        </button>
        <ng-template
          permissions
          [role]="EPermissionType.CreateUpdate"
          [process]="EProcess.Collaborator"
        >
          <button
            [disabled]="profileForm.invalid"
            class="btn primary"
            type="submit"
          >
            Salvar
          </button>
        </ng-template>
      </div>
    </form>
  </div>
</div>
