import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { IChangePassword, IVerificationCode } from '../interface/reset-password.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  endpoint: string = 'User';

  constructor(public http: HttpClient) { }

  generateValidation(email: string): Observable<any>{
    const path: string = 'GenerateValidationCode';
    const response = this.http
    .post( `${environment.apiUrl}/${this.endpoint}/${path}`, email)
    return response;
  }

  validateVerificationCode(verification: IVerificationCode): Observable<any>{
    const path: string = 'ValidateVerificationCode';
    const response = this.http
    .post( `${environment.apiUrl}/${this.endpoint}/${path}`, verification)
    return response;
  }

  changePassword(newPassword: IChangePassword): Observable<any>{
    const path: string = 'ChangePassword';
    const response = this.http
    .put( `${environment.apiUrl}/${this.endpoint}/${path}`, newPassword)
    return response;
  }

}
