import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/core/services/message.service';
import { SubSink } from 'subsink';
import { ISkillType } from '../../interface/skill-type.interface';
import { SkillService } from '../../repository/skill.service';
import { SkillTypeManageModalComponent } from '../skill-type-manage-modal/skill-type-manage-modal.component';
import { StringUtil } from 'src/app/shared/utils/string';

@Component({
  selector: 'app-skill-manage-modal',
  templateUrl: './skill-manage-modal.component.html',
  styles: [],
})
export class SkillManageModalComponent implements OnInit, OnDestroy {
  skillForm!: FormGroup;

  options: ISkillType[] = [];
  isEdit: boolean = false;
  private _id: string | undefined;
  private _subs = new SubSink();

  constructor(
    private _fb: FormBuilder,
    private _skillService: SkillService,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<SkillTypeManageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.options = this.data.skillType;
    this.createFormGroup();

    try {
      const skill = this.data.skill;
      this._id = skill.id;
      this.isEdit = true;
      this.skillForm.patchValue({
        description: skill.description,
        id: skill.id,
        skillTypeId: skill.skillTypeId,
      });
    } catch (error) {
      this.isEdit = false;
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  createFormGroup() {
    this.skillForm = this._fb.group({
      skillTypeId: '',
      description: ['', Validators.required],
      id: '',
    });
  }

  onSubmitSkill() {
    const newSkillType = this.skillForm.value;
    newSkillType.description = StringUtil.capitalize(newSkillType.description ?? '');

    if (!this.isEdit) {
      delete newSkillType.id;
      this._skillService.postSkill(newSkillType).subscribe((response) => {
        if (response.success === true) {
          this._messageService.openSuccessMessage(
            'Cadastro realizado com sucesso.'
          );
        } else if (response.errors.length > 0) {
          this._messageService.openErrorMessage(response.errors);
        }
        this.close({reload: true});
      });
    } else {
      this._subs.add(
        this._skillService.putSkill(newSkillType).subscribe((response) => {
          if (response.success === true) {
            this._messageService.openSuccessMessage(
              'Cadastro alterado com sucesso.'
            );
          } else if (response.errors.length > 0) {
            this._messageService.openErrorMessage(response.errors);
          }
          this.close({reload: true});
        })
      );
    }
  }

  close(emit = { reload: false }): void {
    this.dialogRef.close(emit);
  }
}
