import { AbstractControl, ValidatorFn } from '@angular/forms';

export class DateValidators {
    public static dateMaxValidator(dateMaxLimit: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + dateMaxLimit);
      const forbidden = new Date(control.value) > currentDate;
      return forbidden ? { dateInvalid: { value: control.value } } : null;
    };
  }
  public static dateMinValidator(dateMinLimit: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - dateMinLimit);
      const forbidden = new Date(control.value) < currentDate;
      return forbidden ? { dateInvalid: { value: control.value } } : null;
    };
  }
}
