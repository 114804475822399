<div class="content">
  <div class="row page-header space-between p-vertical-lg">
    <div class="col page-title">
      <h1>{{ pageTitle }}</h1>
    </div>
    <ng-template permissions [role]="'Create/Update'" [process]="'Project'">
      <button class="btn secondary btn--width-sm" (click)="manageProject()">
        {{ btnText }}
      </button>
    </ng-template>
  </div>

  <form [formGroup]="filterForm">
    <div class="flex justify-between align-center filter-ctn" *ngIf="!!optionsProject">
      <div class="flex-1">
        <div class="row align-center flex-nowrap">
          <mat-label class="input-label--project">Status:</mat-label>
          <mat-form-field
            class="rounded input--width-xs-secondary m-sm"
            appearance="fill"
            floatLabel="always"
          >
            <mat-select formControlName="projectStatusId" class="custom-select">
              <mat-option
                *ngFor="let option of optionsProject?.status"
                [value]="option.id"
                (onSelectionChange)="
                  changeChipList(
                    $event,
                    'projectStatusId',
                    option.description,
                    option.id
                  )
                "
              >
                {{ option.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row align-center flex-nowrap">
          <mat-label class="input-label--project">PIP:</mat-label>
          <mat-form-field
            class="rounded input--width-xs-secondary m-sm"
            appearance="fill"
            floatLabel="always"
          >
          <input
              type="text"
              matInput
              [matAutocomplete]="projectPIP"
              formControlName="projectPIP"
            />
            <mat-autocomplete #projectPIP="matAutocomplete" class="custom-select" [displayWith]="displayFn.bind(this, 'pip')">
              <mat-option
                *ngFor="let option of pips"
                [value]="option"
                (onSelectionChange)="
                  changeChipList($event, 'projectPIP', option, option)
                "
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div class="flex-1">
        <div class="row align-center flex-nowrap">
          <mat-label class="input-label--project">Início: </mat-label>
          <mat-form-field
            class="rounded input--width-xs-secondary m-sm"
            appearance="fill"
            floatLabel="always"
          >
            <input
            [matDatepicker]="pickerInitial"
              matInput
              formControlName="projectInitiation"
              required
              (ngModelChange)="
                changeChipListDate(
                  'projectInitiation',
                  'Início: ' +
                    formatDate(filterForm.controls['projectInitiation'].value),
                  filterForm.controls['projectInitiation'].value
                )
              "
            />
            <mat-datepicker-toggle
            matSuffix
            [for]="pickerInitial"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerInitial></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="row align-center flex-nowrap">
          <mat-label class="input-label--project">Cliente:</mat-label>
          <mat-form-field
            class="rounded input--width-xs-secondary m-sm"
            appearance="fill"
            floatLabel="always"
          >
            <input
              type="text"
              matInput
              [matAutocomplete]="client"
              formControlName="clientId"
            />
            <mat-autocomplete #client="matAutocomplete" class="custom-select" [displayWith]="displayFn.bind(this, 'clients')">
              <mat-option
                *ngFor="let option of clients"
                [value]="option.id"
                (onSelectionChange)="
                  changeChipList($event, 'clientId', option.name, option.id)
                "
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="flex-2">
        <div class="row align-center flex-nowrap">
          <mat-label class="input-label--project">Fim: </mat-label>
          <mat-form-field
            class="rounded input--width-xs-secondary m-sm"
            appearance="fill"
            floatLabel="always"
          >
            <input
            [matDatepicker]="pickerEnd"
              matInput
              formControlName="projectConclusion"
              (ngModelChange)="
                changeChipListDate(
                  'projectConclusion',
                  'Fim: ' +
                    formatDate(filterForm.controls['projectConclusion'].value),
                  filterForm.controls['projectConclusion'].value
                )
              "
              required
            />
            <mat-datepicker-toggle
            matSuffix
            [for]="pickerEnd"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="row align-center input-filter-collaborator">
          <mat-form-field
            class="rounded search-button flex-1"
            appearance="fill"
            floatLabel="always"
          >
            <input
              placeholder="Faça uma busca"
              type="text"
              matInput
              [formControl]="searchByNameCtrl"
            />
            <mat-icon matPrefix>search</mat-icon>
            <mat-icon
              title="Limpar pesquisa"
              *ngIf="searchByNameCtrl?.value?.length >= 1"
              (click)="clearFilter()"
              matSuffix
              >backspace</mat-icon
            >
            <button
              matSuffix
              class="btn primary btn--width-xxs"
              (click)="searchBy()"
            >
              Buscar
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>

  <div class="row p-vertical-md">
    <mat-chip-list>




      <span class="icon-info p-horizontal-xs" *ngIf="chipsList.length > 0">
        <mat-icon
        matTooltipClass='info-tooltip'
        [matTooltipPosition]="'above'"
        matTooltip="Nos campos do filtro, você pode escolher filtrar mais de um em cada categoria."
          >info</mat-icon
        >
        Filtros:</span
      >
      <ng-container *ngFor="let chip of chipsList">
        <mat-chip (click)="removeFilterValueChips(chip)">
          <span>{{ chip.description }}</span>
          <mat-icon>clear</mat-icon>
        </mat-chip>
      </ng-container>
      <mat-chip
        class="remove-chip"
        *ngIf="chipsList.length > 0"
        (click)="removeAllChips()"
      >
        <span class="error">Excluir todos</span>
        <mat-icon>clear</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div class="table-expansive p-bottom-lg">
    <table mat-table [dataSource]="dataSource" matSort class="table-primary">
      <ng-container
        matColumnDef="{{ column.key }}"
        *ngFor="let column of columns | keyvalue; let last = last"
      >
        <th mat-header-cell mat-sort-header *matHeaderCellDef [ngClass]="{ 'p-th-none': last }">
          <ng-container *ngIf="column.key != 'action' && column.key != 'icon'">
            {{ column.value }}
          </ng-container>
          <ng-container
            *ngIf="
              column.key !== 'icon' &&
              column.key !== 'action' &&
              column.key !== 'inactive'
            "
          >
            <mat-icon (click)="changeOrder(orderOptions[column.key])"
              ><img src="./assets/svg/orderBy.svg" alt=""
            /></mat-icon>
          </ng-container>
        </th>

        <td mat-cell *matCellDef="let element; let index = index" #elRow>
          <div *ngIf="column.key == 'action'" class="mat-column-status cursor-pointer">
            <ng-template
              permissions
              [role]="'Create/Update'"
              [process]="'Project'"
            >
              <mat-icon (click)="manageProject(element.id)"
                ><img src="./assets/svg/edit-black.svg" alt=""
              /></mat-icon>
            </ng-template>
          </div>
          <div
            *ngIf="column.key != 'action'"
            [ngClass]="{
              pill: column.key === 'projectStatusDescription',
              'pill-status-1': element[column.key] === 'Pausado',
              'pill-status-2': element[column.key] === 'Encerrado',
              'pill-status-3': element[column.key] === 'Para iniciar',
              'pill-status-4': element[column.key] === 'Em Andamento',
              'pill-status-5': element[column.key] === 'Cancelado'
            }"
          >
            <span
              *ngIf="
                column.key !== 'projectInitiation' &&
                  column.key !== 'projectConclusion';
                else date
              "
              >{{ element[column.key] }}</span
            >
            <ng-template #date>
              <span>{{ element[column.key] | date : "dd/MM/yyyy" }}</span>
            </ng-template>
          </div>
          <ng-container *ngIf="column.key == 'icon'">
            <div *ngIf="element.teams">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="disclaimer">
        <td mat-header-cell *matHeaderCellDef colspan="1">
          <mat-select
            (selectionChange)="changeItemsPerPage($event.value)"
            class="rounded input--width-sm m-md"
            [(ngModel)]="pageSize"
          >
            <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
              {{ option }} itens por página
            </mat-option>
          </mat-select>
        </td>
      </ng-container>
      <tr mat-footer-row *matHeaderRowDef="['disclaimer']"></tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <app-paginator
      *ngIf="countDataSource > filter.take"
      [length]="countDataSource"
      [pageSize]="filter.take"
      [currentPage]="currentPage"
      (skipPage)="skipPage($event)"
    ></app-paginator>
  </div>
</div>
