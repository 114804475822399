import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IProjectAllocation } from 'src/app/pages/allocation/interface/project-allocation.interface';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import { EFilterAllocationOption } from 'src/app/pages/collaborator/enum/period-alocation.enum';

@Component({
  selector: 'app-page-view-project-allocation',
  templateUrl: './page-view-project-allocation.component.html',
})
export class PageViewProjectAllocationComponent implements OnInit, OnDestroy {
  filterSelected: EFilterAllocationOption = EFilterAllocationOption.Total;
  allocation!: IProjectAllocation;
  projectId!: string;
  changeSubmit!: Subscription;

  constructor(
    private _allocationService: AllocationService,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.projectId = this._activatedRoute.snapshot.params['projectId'];
    this.getAllocationTotal();

    this.changeSubmit =
      this._allocationService.changePeriodFilterEvent.subscribe({
        next: (response) => {
          this.filterSelected = response;
        },
      });
  }

  ngOnDestroy(): void {
    if (this.changeSubmit) this.changeSubmit.unsubscribe();
  }

  getAllocationTotal(): void {
    this._allocationService.getAllocationByProject(this.projectId).subscribe({
      next: (response) => {
        if (response) {
          this.allocation = response[0];
        }
      },
    });
  }
}
