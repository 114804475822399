<app-title [title]="pageTitle"></app-title>
<div class="m-xl">
  <app-form-allocation-template
    *ngIf="optionsProject"
    (submitEmitter)="receiveProjectSubmit($event)"
    (formStatusValid)="receiveProjectStatusForm($event)"
    [formEnabledList]="formProjectList"
    [allocation]="allocation[0]"
    [allocable]="true"
    [isRemovable]="false"
  >
  </app-form-allocation-template>
  <ng-container *ngFor="let item of allocation; let formIndex = index">
    <app-form-allocation-template
      *ngIf="projectSelected"
      (submitEmitter)="receiveAllocationSubmit($event, formIndex)"
      (removeEmitter)="removeAllocation(formIndex)"
      [formEnabledList]="formAllocationList"
      [allocation]="item"
      [allocationByCollaborator]="false"
      [collaboratorIdSelected]="collaboratorIdSelected"
      [allocable]="true"
      [minDate]="projectSelected.projectInitiation"
      [maxDate]="projectSelected.projectConclusion"
    >
    </app-form-allocation-template>
    <div class="row justify-end">
      <button
        class="btn-no-border m-horizontal-xl hint"
       [disabled]="disableButtonReplyAllocation[formIndex] === true"
        (click)="addAllocation(true, item, formIndex)"
      >
        Replicar alocação
        <img src="./assets/svg/add.svg" alt="add">
      </button>

      <button
        *ngIf="formIndex === allocation.length - 1"
        class="btn-no-border m-horizontal-xl hint"
        [disabled]="!projectSelected"
        (click)="addAllocation()"
      >
        Nova alocação
        <img src="./assets/svg/add.svg" alt="add">
         </button>

    </div>
  </ng-container>

  <div class="row m-xl p-xl justify-end">
    <button
      class="btn secondary m-md"
      (click)="cancelAllocation()"
      type="button"
    >
      Cancelar
    </button>
    <button
      class="btn primary m-md"
      matTooltip="Finalize o cadastro para habilitar o envio"
      [matTooltipDisabled]="!disableButton"
      [disabled]="disableButton"
      (click)="postAllocation()"
    >
      Confirmar alterações
    </button>
  </div>
</div>
