import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { AccountService } from '../../../repository/account.service';
import { UserService } from '../../../repository/user.service';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { NavigationExtras, Router } from '@angular/router';
import { MessageService } from 'src/app/core/services/message.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styles: [],
})
export class VerifyEmailComponent implements OnInit {
  private _subs = new SubSink();
  emailForm!: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _userService: UserService,
    private _router: Router,
    private _messageService: MessageService,
  ) {
    this.createFormGroup();
  }

  ngOnInit(): void {}

  createFormGroup() {
    this.emailForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmitGenerateCode() {
    if (this.emailForm.dirty && this.emailForm.valid) {
      this._subs.sink = this._userService
        .generateValidation(this.emailForm.value)
        .subscribe(
          (response) => {
            this.generateCodeSucess(response);
          },
          (error) => {
            this.generateCodeFailed(error.error);
          }
        );
    } else {
      this.emailForm.markAllAsTouched();
    }
  }

  generateCodeSucess(response: IApiResponse) {
    if (response.success === true) {
      const navigationExtras: NavigationExtras = {state: this.emailForm.value};
      this._router.navigate(['account/verificacao'], navigationExtras );
    }
  }

  generateCodeFailed(errors: Array<string>) {
    this._messageService.openErrorMessage(errors);
    this.returnTologin();
  }

  returnTologin(){
    this._router.navigate(['account/login']);
  }
}
