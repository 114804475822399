import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-allocation-bar',
  templateUrl: './allocation-bar.component.html',
})
export class AllocationBarComponent implements OnInit {
  @Input() color!: 'green' | 'yellow' | 'red' | 'gray' | 'purple';
  @Input() text: string = '';
  @Input() percentage!: number | null;
  @Input() isReservation: boolean = false;

  @Output() viewCollaborator = new EventEmitter<void>();

  colors: { [key: string]: string } = {
    green: '#2ECC71',
    yellow: '#FAA831',
    red: '#E74C3C',
    gray: '#BDC3C7',
    purple: '#8E44AD',
  };

  ngOnInit(): void {
    this.setColorByPercentage();
  }

  setColorByPercentage(): void {
    if (this.percentage == null) {
      this.color = 'gray';
      return;
    }

    if (this.percentage > 100) {
      this.color = 'purple';
    } else if (this.percentage === 100) {
      this.color = 'green';
    } else if (this.percentage > 50 && this.percentage < 100) {
      this.color = 'yellow';
    } else if (this.percentage <= 50) {
      this.color = 'red';
    }
  }
}
