export const COLUMNS_PROJECT = {
  name: 'Projeto',
  projectPIP: 'PIP',
  clientName: 'Cliente',
  projectStatusDescription: 'Status',
  projectManagerName: 'Responsável',
  projectInitiation: 'Início',
  projectConclusion: 'Fim',
  action: 'Editar',
};
