import { ValidatorFn, AbstractControl, FormControl } from "@angular/forms";

export class StringValidators {
    public static maxNumber(max: number): ValidatorFn | any {
        return (control: AbstractControl[]) => {
            if (!(control instanceof FormControl)) return;
            return Number(control.value) > max ? { maxNumber: true } : null;
        }
    }

    // min length
    public static minNumber(min: number): ValidatorFn | any {
        return (control: AbstractControl[]) => {
            if (!(control instanceof FormControl)) return;
            return Number(control.value) < min ? { minNumber: true } : null;
        }
    }
}