<form [formGroup]="codeForm" (ngSubmit)="onSubmitVerifyCode()">
  <div class="col align-center">
    <div class="logo p-xl m-vertical-xl">
      <img src="/assets/img/Logo1_color_cinza.png" alt="" />
    </div>
    <span class="p-vertical-md">
      Informe o código que foi enviado ao email cadastrado.
    </span>
    <mat-form-field
      class="input-outline input--width-md m-vertical-lg"
      appearance="outline"
      floatLabel="always"
    >
      <mat-label>Código</mat-label>
      <input type="text" matInput formControlName="verificationCode" />
    </mat-form-field>
  </div>
  <div class="col align-center">
    <button
      class="btn secondary btn--width-md m-lg"
      type="submit"
      [disabled]="codeForm.invalid"
    >
      Verificar
    </button>
  </div>
  <div class="col">
    <button class="btn-no-border m-left-xl" (click)="returnTologin()">
      Login
    </button>
  </div>
</form>
