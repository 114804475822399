import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { AccountService } from '../../account/repository/account.service';
import { NavigationEnd, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { FileService } from 'src/app/core/services/file.service';
import { ModulePermission } from 'src/app/core/guards/permission.guard';
import { SidenavItem } from 'src/app/shared/components/sidenav/sidenav.component';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment.development';
import { Subscription, Observable, fromEvent, startWith, map } from 'rxjs';
import { UserRoleService } from '../../account/repository/user-role.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styles: [],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  @HostBinding('class.min-device') isMinDevice: boolean = false;

  menu: SidenavItem[] = [
    {
      name: 'Minha conta',
      process: 'Profile',
      permission: 'Create/Update',
      children: [
        {
          name: 'meu perfil',
          path: 'meu-perfil-detalhes',
          process: 'Profile',
          permission: 'Create/Update',
        },
      ],
    },
    {
      name: 'Colaboradores',
      process: 'Collaborator',
      permission: 'Create/Update',
      children: [
        {
          name: 'Cadastrar Colaborador',
          path: 'cadastrar-colaborador',
          process: 'Collaborator',
          permission: 'Create/Update',
        },
      ],
    },
    {
      name: 'ADM',
      process: 'Permission',
      permission: 'Create/Update',
      children: [
        {
          name: 'Cadastrar perfis de acesso',
          path: 'permissoes/cadastrar',
          process: 'Permission',
          permission: 'Create/Update',
        },
        {
          name: 'Gerenciar perfis de acesso',
          path: 'permissoes/gerenciar',
          process: 'Permission',
          permission: 'Create/Update',
        },
      ],
    },
  ];
  menuMode: 'over' | 'side' = 'side';
  menuOpened: boolean = true;

  roles!: ModulePermission[];
  nameUser!: string;
  imageSrc!: string;
  collaboratorId!: string;
  pathControl: FormControl = new FormControl('');
  currentRoute: string = '';
  changeNameSubscription!: Subscription;

  constructor(
    private _accountService: AccountService,
    private _router: Router,
    private _fileService: FileService,
    private authService: MsalService,
    private _userRoleService: UserRoleService
  ) {
    this.currentRoute = this._router.url;
    this.pathControl.setValue(this.currentRoute.slice(1));
  }

  ngOnInit(): void {
    if (location.pathname === '/') {
      this._router.navigate([
        'meu-perfil-detalhes',
        this._accountService.localStorage.collaboratorId,
      ]);
    }
    this.getRoles();
    this.setPathControl();

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;

        if (!this.currentRoute.includes(this.pathControl.value)) {
          this.pathControl.setValue('');
        }
      }
    });

    this.menuMode = 'over';
    this.menuOpened = false;

    this.nameUser = this._accountService.localStorage.getNameUser();
    this.collaboratorId = this._accountService.localStorage.collaboratorId;

    this.getProfileImage();

    this.pathControl.valueChanges.subscribe((value) => {
      this.buttonToggleClick(value);
    });

    this.changeNameSubscription =
      this._accountService.localStorage.changeNameUser.subscribe((name) => {
        this.nameUser = name;
      });
  }

  ngOnDestroy(): void {
    if (this.changeNameSubscription) {
      this.changeNameSubscription.unsubscribe();
    }
  }

  getRoles() {
    let roles = this._userRoleService.roles;

    if (!roles || roles.length == 0) {
      this._userRoleService.getPermissionsByUser().subscribe((data) => {
        let roles = data.profileProcessPermissions ?? [];
        let tokenData = data.token;

        if (roles) {
          this._userRoleService.roles = roles;
          this.roles = roles;

          if (tokenData) window.localStorage.setItem('user.token', tokenData);

          this._userRoleService.changeUserRoles.next(true);
        }
      });
    } else {
      this.roles = roles;
    }
  }

  setPathControl() {
    if (
      this.currentRoute.includes('projetos') ||
      this.currentRoute.includes('alocacao')
    ) {
      let path = this.currentRoute.split('/');

      this.pathControl.setValue(path[1]);
    }
  }

  buttonToggleClick(path: any) {
    if (path) {
      this._router.navigate([path]);
    }
  }

  getProfileImage() {
    this._fileService
      .getFileByCollaboratorId(this.collaboratorId)
      .then((response) => response.blob())
      .then((blob) => {
        const urlCreator = window.URL || window.webkitURL;
        if (blob.type === 'image/png') {
          this.imageSrc = urlCreator.createObjectURL(blob);
        } else {
          this.imageSrc = './assets/img/account_circle.png';
        }
      })
      .catch((error) => console.error(error));
  }

  logout() {
    const logoutRequest = {
      postLogoutRedirectUri: environment.azureConfig.logoutRedirectUri,
    };

    this._accountService.localStorage.clearLocalDataUser();
    this.authService.logoutRedirect(logoutRequest);
  }

  getScreenSize(): Observable<{ width: number; height: number }> {
    return fromEvent(window, 'resize').pipe(
      startWith(null), // to get the size immediately
      map(() => {
        return { width: window.innerWidth, height: window.innerHeight };
      })
    );
  }
}
