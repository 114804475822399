<div class="flex column-ctn">
  <div class="flex first-column justify-center align-start">
    <div class="flex justify-center align-center">
      <span>{{
        searchType === "month"
          ? months[date.month] + " - " + date.year
          : activeYears
      }}</span>
    </div>
  </div>

  <div class="flex main-column flex-1">
    <div class="paginator">
      <button
        [disabled]="
          (searchType === 'total' && activeIndex === minIndex) ||
          (searchType === 'month' && limitReachedLeft)
        "
        (click)="changePeriod(-1)"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>

      <button
        [disabled]="
          (searchType === 'total' && activeIndex === maxIndex) ||
          (searchType === 'month' && limitReachedRight)
        "
        (click)="changePeriod(1)"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="searchType === 'month'">
      <ng-container *ngFor="let week of weeksPerMonth; let index = index; let last = last">
        <div
          class="flex flex-{{ week.days.length }} week align-center"
          [ngClass]="{ 'current-block': week.current, 'border-none': last }"
        >
          <span
            class="week-text flex align-center justify-center"
            [ngClass]="{ 'current-week': week.current }"
            >{{ months[date.month] + " - " + (index + 1) }}</span
          >
          <div class="flex day-ctn">
            <ng-container *ngFor="let day of week.days">
              <div class="day flex flex-1 justify-center">
                <span
                  class="day-text flex justify-center align-center"
                  [ngClass]="{
                    weekend: day.weekendDay && !day.current,
                    'current-day': day.current
                  }"
                  >{{ day.day }}</span
                >
              </div>
            </ng-container>
          </div>
          <div
            class="continuous"
            [ngClass]="{ 'current-block': week.current }"
          ></div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="searchType === 'total'">
      <ng-container
        *ngFor="let item of groupedMonths[activeIndex]; let index = index"
      >
        <div class="month-ctn flex flex-1 align-center justify-center">
          <div
            class="flex month align-center"
            [ngClass]="{ 'current-month': item.current }"
            (click)="selectMonth(item.month, item.year)"
          >
            <span class="month-text flex align-center justify-center">{{
              item.month | titlecase
            }}</span>
            <small>{{ item.year }}</small>
          </div>

          <div class="continuous"></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
