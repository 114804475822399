import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SubSink } from 'subsink';
import { AccountService } from '../../repository/account.service';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { User } from '../../../../shared/models/user.model';
import { MessageService } from 'src/app/core/services/message.service';

import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [],
})
export class LoginComponent implements OnInit, OnDestroy {
  private _subs = new SubSink();
  loginForm!: FormGroup;
  user!: User;
  returnUrl!: string;
  keepSignIn!: FormControl;
  showPassword: boolean = false;
  idLog: string = '';
  buttonDisabled: boolean = false;
  changeDisableLoginSubscription!: Subscription;

  constructor(
    private _fb: FormBuilder,
    private _accountService: AccountService,
    private _messageService: MessageService,
    private _route: ActivatedRoute,
    private _router: Router,
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
    this.createFormGroup();
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'];
  }

  ngOnInit(): void {
    this.changeDisableLoginSubscription =
      this._accountService.changeDisableLogin.subscribe((response) => {
        this.buttonDisabled = response;
      });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    if (this.changeDisableLoginSubscription) {
      this.changeDisableLoginSubscription.unsubscribe();
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  toggleShowPassword(showPassword: boolean) {
    this.showPassword = !showPassword;
  }

  createFormGroup() {
    this.keepSignIn = new FormControl(true, []);
    this.loginForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  onSubmitLogin() {
    if (this.loginForm.dirty && this.loginForm.valid) {
      this.user = Object.assign({}, this.user, this.loginForm.value);

      this._subs.add(
        this._accountService.login(this.user).subscribe(
          (response) => {
            this.login(response);
          },
          (failed) => {
            this.loginFailed(failed.error.errors);
          }
        )
      );
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  resetPassword() {
    this._router.navigate(['account/gerar-codigo']);
  }

  login(response: IApiResponse) {
    if (response.success === true) {
      if (this.keepSignIn.value === true) {
        this._accountService.localStorage.saveTokenUser(
          response.result.accessToken,
          response.result.user.name,
          response.result.user.collaboratorID,
        );
        } else {
        this._accountService.localStorage.saveTokenUserSession(
          response.result.accessToken,
          response.result.user.name,
          response.result.user.collaboratorID,
        );
      }

      this._messageService.openSuccessMessage(
        'Olá, ' + response.result.user.name
      );
      if (this.returnUrl) {
        window.location.href = `${this.returnUrl}`;
      } else {
        this._router.navigate([
          'meu-perfil',
          response.result.user.collaboratorID,
        ]);
      }
    }
  }

  loginFailed(errors: Array<string>) {
    this._messageService.openErrorMessage(errors);
  }
}
