import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { HttpClient } from '@angular/common/http';
import { Allocation } from '../../../shared/models/allocation.model';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { FilterCollaboratorAllocation } from 'src/app/shared/models/filter.model';
import { IOverallocate } from '../interface/collaborator-allocation.interface';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import {
  IMonthYearPeriod,
  IProjectAllocation,
  IRequestAllocationByMonth,
} from '../interface/project-allocation.interface';
import { EFilterAllocationOption } from '../../collaborator/enum/period-alocation.enum';
import { IFilterCollaboratorLoan } from '../interface/filter-collaborator-loan';


@Injectable({
  providedIn: 'root',
})
export class AllocationService extends BaseApiService {
  endpoint: string = 'Allocation';

  changeOverallocationEvent = new Subject<boolean>();
  changePeriodFilterEvent = new Subject<EFilterAllocationOption>();
  getAllocationPercentageEvent = new BehaviorSubject<{
    period: IMonthYearPeriod[] | { month: number; year: number };
    type: EFilterAllocationOption;
  } | null>(null);

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getAllbyCollaborator(collaboratorId: string) {
    const path = 'ByCollaborator';
    return this.get<any>(this.endpoint, path, collaboratorId);
  }

  getAllByCollaboratorFilter(filter: FilterCollaboratorAllocation) {
    const path = 'ListAllByFilter';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    return this.http.post<Allocation>(url, filter);
  }

  postAllocation(allocation: Allocation[]): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.post<Allocation>(url, allocation);
  }

  postOverallocate(overallocated: IOverallocate) {
    const path = 'Overallocate';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    return this.http
      .post<IApiResponse>(url, overallocated)
      .pipe(map((response: IApiResponse) => response.result));
  }

  putAllocation(allocation: Allocation[]): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.put<Allocation>(url, allocation);
  }

  inactivateAllocation(idAllocation: string) {
    const path = 'inactivate';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}/${idAllocation}`;
    return this.http.delete<any>(url);
  }

  getAllByProjectsFilter(filter: any) {
    const path = 'ListByFilterProjects';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    return this.http.post<any>(url, filter);
  }

  getAllocationByProject(projectId: string): Observable<IProjectAllocation[]> {
    const path = 'ByProject';
    return this.http
      .get<IApiResponse>(
        `${environment.apiUrl}/${this.endpoint}/${path}/${projectId}`
      )
      .pipe(map((response: IApiResponse) => response.result));
  }

  getAllocationProjectByMonth(
    param: IRequestAllocationByMonth
  ): Observable<IProjectAllocation[]> {
    const path = 'ByProjectMonth';
    return this.http
      .post<IApiResponse>(
        `${environment.apiUrl}/${this.endpoint}/${path}`,
        param
      )
      .pipe(map((response: IApiResponse) => response.result));
  }

  postHoursPeriod(body: any) {
    const path = 'CalculateHoursPeriod';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    return this.http.post<any>(url, body);
  }

  refuseLoan(allocationId: string,  reason: string) {
    const path = 'RefuseLoan';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    const body= { reason, allocationId };
    return this.http.post<any>(url, body);
  }

  getAllLoan(filter: IFilterCollaboratorLoan) : Observable<any>{
    const path = 'GetLoans';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    return this.http.post<any>(url, filter);
  }

}
