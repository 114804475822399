import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { LocalStorage } from '../guards/localstorage';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  endpoint: string = 'File';
  private localStorage = new LocalStorage();
  constructor(public http: HttpClient) {}

  getFileByCollaboratorId(collaboratorId: string) {
    let headers = new HttpHeaders();
    const token = this.localStorage.getTokenUser();

    headers.set('responseType', 'image/png');
    headers.set('Authorization', `Bearer ${token}`);

    return fetch(`${environment.apiUrl}/${this.endpoint}/${collaboratorId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
  }

  postProfileImage(collaboratorId: string, file: FormData): Observable<any> {
    const path: string = '?collaboratorId=';
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    const response = this.http.post(
      `${environment.apiUrl}/${this.endpoint}${path}${collaboratorId}`,
      file,
      { headers }
    );
    return response;
  }

  getFileByUrl(url:string){
    let headers = new HttpHeaders();
    const token = this.localStorage.getTokenUser();

    headers.set('responseType', 'image/png');
    headers.set('Authorization', `Bearer ${token}`);

    return fetch(`${url}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
  }
}
