import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { IProjectAllocation } from 'src/app/pages/allocation/interface/project-allocation.interface';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import { EFilterAllocationOption } from 'src/app/pages/collaborator/enum/period-alocation.enum';
import { IFilterAllocationOptions } from 'src/app/pages/collaborator/interface/project-options.interface';

@Component({
  selector: 'app-header-view-project-allocation',
  templateUrl: './header-view-project-allocation.component.html',
})
export class HeaderViewProjectAllocationComponent implements OnInit, OnDestroy {
  @Input() allocation!: IProjectAllocation | null;
  @Output() changeFilter = new EventEmitter<EFilterAllocationOption>();

  filterOptions: IFilterAllocationOptions[] = [
    { id: EFilterAllocationOption.Mes, name: 'Mês' },
    { id: EFilterAllocationOption.Total, name: 'Período Total' },
  ];

  filterSelected: EFilterAllocationOption = EFilterAllocationOption.Total;

  legendItems: {
    text: string;
    color: 'green' | 'yellow' | 'red' | 'gray' | 'purple';
  }[] = [
    { color: 'green', text: 'Alocado (100%)' },
    { color: 'yellow', text: 'Alocável (51% a 99%)' },
    { color: 'red', text: 'Desalocado (0% a 50%)' },
    // { color: 'gray', text: 'Afastamento, férias, licenças...' },
    { color: 'purple', text: 'Super alocado (>100%)' },
  ];

  changeSubmit!: Subscription;

  constructor(private _allocationService: AllocationService) {}

  ngOnInit(): void {
    this.changeSubmit =
      this._allocationService.changePeriodFilterEvent.subscribe({
        next: (response) => {
          this.filterSelected = response;
        },
      });
  }

  ngOnDestroy(): void {
    if (this.changeSubmit) this.changeSubmit.unsubscribe();
  }
}
