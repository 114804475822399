import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { IDefaultFilter } from 'src/app/shared/models/filter.model';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class TowerService {
  endpoint: string = 'Tower';

  constructor(public http: HttpClient) {}

  getAllTowers(filter: IDefaultFilter): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/${this.endpoint}/listAllByFilters`,
      filter
    );
  }

  getAllTowerOptions(): Observable<IApiResponse<any>> {
    return this.http.get<IApiResponse<any>>(
      `${environment.apiUrl}/${this.endpoint}/Options`
    );
  }

  postTower(tower: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;

    return this.http.post<any>(url, tower);
  }

  putTower(tower: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.put<any>(url, tower);
  }

  inactivateTower(idTower: string): Observable<any> {
    const path = 'inactivate';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}/${idTower}`;
    return this.http.delete<any>(url);
  }
}
