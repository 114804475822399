import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MessageService } from 'src/app/core/services/message.service';
import { Allocation } from 'src/app/shared/models/allocation.model';
import { ModalReplyAllocationComponent } from 'src/app/pages/allocation/modal/modal-reply-allocation/modal-reply-allocation.component';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import { IClient } from 'src/app/pages/clients-projects/interface/client.interface';
import { IProject } from 'src/app/pages/clients-projects/interface/project.interface';
import { ClientService } from 'src/app/pages/clients-projects/repository/client.service';
import { ProjectService } from 'src/app/pages/clients-projects/repository/project.service';
import { CollaboratorService } from 'src/app/pages/collaborator/repository/collaborator.service';
import { SubSink } from 'subsink';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { ICollaboratorAllocation } from 'src/app/pages/allocation/interface/collaborator-allocation.interface';
import { ConfirmDialogNewComponent } from 'src/app/shared/components/confirm-dialog-new/confirm-dialog-new.component';

@Component({
  selector: 'app-page-create-collaborator-allocation',
  templateUrl: './page-create-collaborator-allocation.component.html',
  styles: [],
})
export class PageCreateCollaboratorAllocationComponent
  implements OnInit, OnDestroy
{
  @Input() collaboratorsId: Array<string> = [];
  pageTitle: string = 'Cadastrar alocação por colaborador';
  btnText: string = 'Cadastrar nova alocação';
  allocation: Array<Allocation> = [];
  optionsCollaborator$!: Observable<ICollaboratorAllocation[]>;
  optionsProject$!: Observable<IProject[]>;
  optionsClients$!: Observable<IClient[]>;
  optionsCollaborator!: ICollaboratorAllocation[];
  optionsClients!: IClient[];
  optionsProject!: IProject[];
  disableButton: boolean = true;
  isReservationOrAbsence: boolean = false;
  isAbsence: boolean = false;
  private _subs = new SubSink();
  disableButtonReplyAllocation: Array<boolean> = [true];

  constructor(
    private _projectService: ProjectService,
    private _collaboratorService: CollaboratorService,
    private _clientsService: ClientService,
    private _allocationService: AllocationService,
    private _messageService: MessageService,
    public dialog: MatDialog,
    private _router: Router
  ) {
    this.getSelectOptions();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  getSelectOptions() {
    this.optionsProject$ = this._projectService.getAllbyProjects();
    this.optionsCollaborator$ =
      this._collaboratorService.getAllCollaboratorsAllocation();
    this.optionsClients$ = this._clientsService.getAllClients();
    this._subs.add(
      this.optionsProject$?.subscribe((projects: IProject[]) => {
        this.optionsProject = projects;
      }),
      this.optionsCollaborator$.subscribe(
        (collaborators: ICollaboratorAllocation[]) => {
          this.optionsCollaborator = collaborators;
          this.collaboratorsId
            ? this.initCollaborator()
            : this.addCollaborator();
        }
      ),
      this.optionsClients$.subscribe((clients: IClient[]) => {
        this.optionsClients = clients;
      })
    );
  }

  receiveStatusForm(validForm: boolean, buttonIndex: number) {
    this.disableButtonReplyAllocation[buttonIndex] = !validForm;
    this.disableButton = !validForm;
  }

  receiveSubmit(event: any, formIndex: number) {
    if (event.data.isReservation === false && event.data.isAbsence === false) {
      this.allocation
        .at(formIndex)
        ?.createAllocation(
          event.data,
          event.data.project,
          event.data.collaborator
        );
    } else {
      this.allocation
        .at(formIndex)
        ?.createReservation(
          event.data,
          event.data.client,
          event.data.collaborator
        );
    }
    this.allocation
      .at(formIndex)
      ?.checkIsPercentage(event.data.allocationPeriod);

    this.disableButton = this.hasInvalidAllocation();
  }

  openAllocationModal() {
    let dialogRef;
    dialogRef = this.dialog.open(ModalReplyAllocationComponent);
    dialogRef.afterClosed().subscribe((res) => {
      this.addCollaborator(res);
    });
  }

  addCollaborator(
    reply: boolean = false,
    lastAllocation?: Allocation,
    formIndex: number = 0
  ) {
    const newAllocation = new Allocation();
    if (reply === true) {
      if (lastAllocation)newAllocation.replyAllocation(lastAllocation);
      if (lastAllocation?.isAbsence || lastAllocation?.isReservation) {
        this.isReservationOrAbsence = true;
        this.isAbsence = lastAllocation.isAbsence || false;
      } else {
        this.isReservationOrAbsence = false;
        this.isAbsence = false;
      }
      this.allocation.splice(formIndex + 1, 0, newAllocation);
      this.disableButtonReplyAllocation.splice(formIndex + 1, 0, true);
    } else {
      this.allocation.push(newAllocation);
      this.disableButtonReplyAllocation.push(true);
    }
    this.disableButton = true;
  }

  initCollaborator() {
    this.pageTitle = 'Alocar colaboradores selecionados';
    this.optionsCollaborator.map((collaborator) => {
      this.collaboratorsId.map((collaboratorSelected) => {
        if (collaborator.collaboratorId === collaboratorSelected) {
          const newAllocation = new Allocation();
          newAllocation.createAllocationByCollaboratorId(collaborator);
          this.allocation.push(newAllocation);
        }
      });
    });
  }

  postAllocation() {
    this.removeNullIds();

    const overAllocated = this.checkOverAllocated();
    const overAbsence = this.checkOverAbsence();
    const hasReservation = this.checkHasReservation();

    if (overAllocated && !overAbsence) {
      this.handleOverAllocated(hasReservation);
    } else if (overAbsence && overAllocated) {
      this.handleOverAbsenceWithOverAllocation(hasReservation);
    } else if (overAbsence) {
      this.handleOverAbsence();
    } else if (hasReservation) {
      this.handleHasReservation();
    } else {
      this.allocationRequest();
    }
  }

  removeNullIds() {
    this.allocation.forEach((allocation) => {
      if (allocation.id == null) delete allocation.id;
    });
  }

  checkOverAllocated() {
    return this.allocation.some(
      (allocation) => allocation.overAllocation === true
    );
  }

  checkOverAbsence() {
    return this.allocation.some(
      (allocation) => allocation.overAbsence === true
    );
  }

  checkHasReservation() {
    return this.allocation.some(
      (allocation) => allocation.isReservation && !allocation.id
    );
  }

  handleOverAllocated(hasReservation: boolean) {
    const message =
      'Você selecionou um colaborador que já está super alocado. Tem certeza que deseja continuar com a alocação?';
    const buttonTxt = 'Sim';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.handleReservation(hasReservation);
      }
    });
  }

  handleOverAbsenceWithOverAllocation(hasReservation: boolean) {
    const message =
      'Este colaborador possui uma ausência dentro do período, deseja continuar com a alocação?';
    const buttonTxt = 'Sim';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.handleSecondConfirmation(hasReservation);
      }
    });
  }

  handleOverAbsence() {
    const message =
      'Este colaborador possui uma ausência dentro do período, deseja continuar com a alocação? ';
    const buttonTxt = 'Sim';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.allocationRequest();
    });
  }

  handleHasReservation() {
    const reservationMessage =
      'Sua pré venda vai expirar automaticamente depois de 15 dias corridos, mas te avisaremos quando o dia chegar.';
    const reservationButtonTxt = 'OK';

    const reservationDialogRef = this.dialog.open(
      ConfirmDialogNewComponent,
      {
        data: {
          message: reservationMessage,
          buttonTxt: reservationButtonTxt,
          confirm: false,
        },
      }
    );

    reservationDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.allocationRequest();
      }
    });
  }

  handleReservation(hasReservation: boolean) {
    if (hasReservation) {
      const reservationMessage =
        'Sua pré venda vai expirar automaticamente depois de 15 dias corridos, mas te avisaremos quando o dia chegar.';
      const reservationButtonTxt = 'OK';

      const reservationDialogRef = this.dialog.open(
        ConfirmDialogNewComponent,
        {
          data: {
            message: reservationMessage,
            buttonTxt: reservationButtonTxt,
            confirm: false,
          },
        }
      );

      reservationDialogRef.afterClosed().subscribe((reservationResult) => {
        if (reservationResult) {
          this.allocationRequest();
    }
      });
    } else {
        this.allocationRequest();
      }
  }

  handleSecondConfirmation(hasReservation: boolean) {
    const message =
      'Você selecionou um colaborador que já está super alocado. Tem certeza que deseja continuar com a alocação?';
    const buttonTxt = 'Sim';

    const secondDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });

    secondDialogRef.afterClosed().subscribe((secondResult) => {
      if (secondResult) {
        this.handleReservation(hasReservation);
      }
    });
  }

  private allocationRequest() {
    this._subs.add(
      this._allocationService
        .postAllocation(this.allocation)
        .subscribe((response) => {
          if (response.success === true) {
            this._messageService.openSuccessMessage(
              'Cadastro realizado com sucesso.'
            );
            this._router.navigate(['/alocacao/colaboradores']);
          } else if (response.errors.length > 0) {
            this._messageService.openErrorMessage(response.errors);
          }
        })
    );
  }

  removeAllocation(formIndex: number, item: Allocation) {
    if (formIndex === 0 && this.allocation.length === 1) {
      this.addCollaborator();
    }
    this.allocation.splice(formIndex, 1);
    this.disableButton = this.hasInvalidAllocation();
    this.disableButtonReplyAllocation.splice(formIndex, 1);
    this.allocation = [...this.allocation];
  }

  hasInvalidAllocation() {
    const hasInvalidAllocation = this.allocation.filter(
      (allocation: Allocation) => !allocation.isValid()
    );
    let disableButton: boolean;
    hasInvalidAllocation.length
      ? (disableButton = true)
      : (disableButton = false);
    return disableButton;
  }

  cancelAllocation() {
    const message: string =
      'Você tem certeza que deseja cancelar essa alocação?';
    const buttonTxt: string = 'Sim';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._router.navigate(['/alocacao/colaboradores']);
      }
    });
  }
}
