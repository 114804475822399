import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  endpoint: string = 'Skills';
  constructor(public http: HttpClient) { }

  getSkills(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(map((response: IApiResponse) => response.result));
  }

  postSkill(skillType: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;

    return this.http.post<any>(url, skillType);
  }

  putSkill(skillType: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;

    return this.http.put<any>(url, skillType);
  }

  inactivateSkill(idSkill?: string): Observable<any> {
    const path = 'inactivate'
    const url = `${environment.apiUrl}/${this.endpoint}/${path}/${idSkill}`;
    return this.http.delete<any>(url);
  }

}
