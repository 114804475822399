import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorage } from './localstorage';

@Injectable()
export class LoginGuard implements CanActivate {
  localStorage = new LocalStorage();

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    let collaboratorId = this.localStorage.collaboratorId;

    if (this.localStorage.tokenIsValid()) {
      this.router.navigate(['meu-perfil', collaboratorId]);
      return false;
    }
    return true;
  }
}
