<div class="modal-dialog-close">
  <div>
    <h1 class="m-bottom-md">
      {{ isEdit ? 'Editar perfil' : 'Cadastrar perfil' }}
    </h1>
    <form [formGroup]="profileTypeForm" (ngSubmit)="onSubmitProfileType()">
      <mat-form-field
        class="input-outline input--width-sm m-bottom-lg"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Nome do perfil</mat-label>
        <input
          type="text"
          matInput
          formControlName="name"
          maxlength="50"
        />
      </mat-form-field>
      <button
        [disabled]="!profileTypeForm.controls['name'].value"
        class="btn no-width primary"
        type="submit"
      >
      {{ isEdit ? 'Salvar' : 'Cadastrar' }}
      </button>
       </form>
  </div>
  <div class="position">
    <button class="position btn-icon-close" (click)="close()"><img src="./assets/svg/close-button.svg" alt=""></button>
  </div>
</div>
