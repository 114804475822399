import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { IDefaultFilter } from 'src/app/shared/models/filter.model';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class SkillTypeService {
  endpoint: string = 'SkillType';

  constructor(public http: HttpClient) {}

  getAllSkillType(filter: IDefaultFilter): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/${this.endpoint}/listAllByFilters`,
      filter
    );
  }

  getAllSkillTypeOptions(): Observable<IApiResponse<any>> {
    return this.http.get<IApiResponse<any>>(
      `${environment.apiUrl}/${this.endpoint}/Options`
    );
  }

  postSkillType(skillType: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;

    return this.http.post<any>(url, skillType);
  }

  putSkillType(skillType: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.put<any>(url, skillType);
  }

  inactivateSkillType(idSkillType: string): Observable<any> {
    const path = 'inactivate';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}/${idSkillType}`;
    return this.http.delete<any>(url);
  }
}
