import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderByCollaboratorAllocationEnum } from 'src/app/pages/collaborator/enum/collaborator-alocation-order';

import { CollaboratorAllocationService } from 'src/app/pages/collaborator/repository/collaborator-allocation.service';
import {
  ICollaboratorAllocation,
  ICollaboratorAllocationsRequest,
} from 'src/app/shared/models/collaborator-allocation.model';
import { COLUMNS_ALLOCATION_COLLABORATOR_PROFILE } from 'src/app/shared/models/table-columns/columns-allocation-collaborator-profile';

@Component({
  selector: 'app-collaborator-allocations',
  templateUrl: './collaborator-allocations.component.html',
  styles: [],
})
export class CollaboratorAllocationsComponent implements OnInit, OnDestroy {
  readonly columns = COLUMNS_ALLOCATION_COLLABORATOR_PROFILE;

  displayedColumns: string[] = [];
  collaboratorId!: string;
  allocationPercentage = 0;
  hasAllocation = false;

  subs: Subscription[] = [];

  dataSource: MatTableDataSource<ICollaboratorAllocation> =
    new MatTableDataSource<ICollaboratorAllocation>();

  orderOption: { [key: string]: number } = {
    projectName: OrderByCollaboratorAllocationEnum.PROJECT_NAME,
    clientName: OrderByCollaboratorAllocationEnum.CLIENT_NAME,
    status: OrderByCollaboratorAllocationEnum.STATUS,
    projectResponsible: OrderByCollaboratorAllocationEnum.PROJECT_RESPONSIBLE,
    startDate: OrderByCollaboratorAllocationEnum.START_DATE,
    endDate: OrderByCollaboratorAllocationEnum.END_DATE,
    percentage: OrderByCollaboratorAllocationEnum.PERCENTAGE,
  };

  constructor(
    private _collaboratorAllocationService: CollaboratorAllocationService,
    private readonly _activetedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getCollaboratorID();
    this.setRequestBody();
    this.displayedColumns = Object.keys(this.columns);
    this.getAllCollaboratorAlocations(this.requestBody);
  }

  ngOnDestroy(): void {
    this.subs.forEach (sub => sub.unsubscribe);
  }

  requestBody!: ICollaboratorAllocationsRequest;

  getCollaboratorID(): void {
    this.collaboratorId =
      this._activetedRoute.snapshot.params['collaboratorId'];
  }

  setRequestBody(): void {
    this.requestBody = {
      collaboratorId: this.collaboratorId,
      page: 1,
      take: 10,
      orderByParam: {
        orderBy: 1,
        asc: true,
      },
    };
  }

  getAllCollaboratorAlocations(
    requestBody: ICollaboratorAllocationsRequest
  ): void {
    try {
      const subscription = this._collaboratorAllocationService
        .getCollaboratorAllocations(requestBody)
        .subscribe((res) => {
          this.dataSource.data = res.result.allocationsProfile;
          this.allocationPercentage = res.result.totalPercentage;

          this.hasAllocation = true;

          this.subs.push(subscription);
        });
    } catch {
      (err: Error) => {
        console.error(err);
        this.hasAllocation = false;
      };
    }
  }

  changeOrder(orderOption: OrderByCollaboratorAllocationEnum): void {
    if (this.requestBody.orderByParam.orderBy === orderOption) {
      this.requestBody.orderByParam.asc = !this.requestBody.orderByParam.asc;
    } else {
      this.requestBody.orderByParam.orderBy = orderOption;
      this.requestBody.orderByParam.asc = true;
    }

    this.getAllCollaboratorAlocations(this.requestBody);
  }

  redirectToRestRequest() {
    window.open('https://apps.powerapps.com/play/e/default-97301bc6-aabf-4213-b140-e9574488f252/a/30c7c543-a9f5-4327-9f49-265e2625d296?tenantId=97301bc6-aabf-4213-b140-e9574488f252&sourcetime=1709813579383','_blank');
  }
}
