import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { LocalStorage } from "./localstorage";

@Injectable({
    providedIn: 'root',
})

export class AccountGuard implements CanActivate {
    localStorage = new LocalStorage();

constructor(private router: Router) {
}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ){
        if (this.localStorage.tokenIsValid() === false) {
            this.router.navigate(['account/login']);

            return false;
        }
        return true
    }
}
