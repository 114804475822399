import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav/sidenav.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatIconModule } from '@angular/material/icon';
import { TitleComponent } from './title/title.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { LoadComponent } from './load/load.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { LegendItemComponent } from './legend-item/legend-item.component';
import { AllocationBarComponent } from './allocation-bar/allocation-bar.component';
import { ConfirmDialogNewComponent } from './confirm-dialog-new/confirm-dialog-new.component';


import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { DirectiveModule } from 'src/app/core/directives/directives.module';
import { ConfirmDialogInactivationComponent } from './confirm-dialog-inactivation/confirm-dialog-inactivation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ConfirmDialogLoanComponent } from './confirm-dialog-loan/confirm-dialog-loan.component';

@NgModule({
  declarations: [
    SidenavComponent,
    TitleComponent,
    PaginatorComponent,
    LoadComponent,
    ConfirmDialogComponent,
    MessageDialogComponent,
    LegendItemComponent,
    AllocationBarComponent,
    ConfirmDialogNewComponent,
    ConfirmDialogInactivationComponent,
    ConfirmDialogLoanComponent,
    ModalErrorComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    CdkAccordionModule,
    MatIconModule,
    MatTooltipModule,
    DirectiveModule,
    FormsModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule

  ],
  exports: [
    SidenavComponent,
    TitleComponent,
    PaginatorComponent,
    LoadComponent,
    ConfirmDialogComponent,
    ConfirmDialogInactivationComponent,
    ConfirmDialogLoanComponent,
    LegendItemComponent,
    AllocationBarComponent,
  ],
})
export class ComponentsModule {}
