import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkillRoutingModule } from './skill-routing.module';
import { PageSkillListComponent } from './pages/page-skill-list/page-skill-list.component';
import { MatTableModule } from '@angular/material/table';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatDialogModule} from '@angular/material/dialog';
import { SkillTypeManageModalComponent } from './modal/skill-type-manage-modal/skill-type-manage-modal.component';
import { SkillManageModalComponent } from './modal/skill-manage-modal/skill-manage-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { DirectiveModule } from 'src/app/core/directives/directives.module';

@NgModule({
  declarations: [
    PageSkillListComponent,
    SkillTypeManageModalComponent,
    SkillManageModalComponent
  ],
  imports: [
    CommonModule,
    SkillRoutingModule,
    MatTableModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    FormsModule,
    ComponentsModule,
    DirectiveModule
  ]
})
export class SkillModule { }
