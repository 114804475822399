import { ICollaboratorAllocation } from 'src/app/pages/allocation/interface/collaborator-allocation.interface';
import { IClient } from '../../pages/clients-projects/interface/client.interface';
import { IProject } from '../../pages/clients-projects/interface/project.interface';
import { AllocationProjectCollaborator, IProjectAllocation } from 'src/app/pages/allocation/interface/project-allocation.interface';

export class Allocation implements AllocationProjectCollaborator{
  id?: null | string = null;
  collaboratorId!: string;
  projectId: string | null = null;
  clientId!: string;
  allocationStatusId: string = 'BFF0EC6B-2AF8-4FE1-BD86-FE5206E9C8E5';
  allocationTypeId: string = '8AE24097-C78C-44EC-B1FD-B7F8FCFFC224';
  startDate!: string;
  endDate!: string;
  observation!: string;
  hours!: number;
  percentage!: number;
  isReservation: boolean = false;
  isAbsence: boolean = false;
  isPercentage: boolean = true;
  jobFunctionId?: string;
  overAllocation?: boolean = false;
  overAbsence?: boolean = false;

  constructor() {}

  createAllocation(
    allocation: Allocation,
    project: IProject,
    collaborator: ICollaboratorAllocation
  ) {
    this.collaboratorId = collaborator?.collaboratorId;
    this.projectId = project?.id;
    this.clientId = project?.clientId;
    this.startDate = allocation.startDate;
    this.endDate = allocation.endDate;
    this.isReservation = allocation.isReservation;
    this.isAbsence = allocation.isAbsence;
    this.isPercentage = allocation.isPercentage;
  }

  setAllocation(allocation: Allocation) {
    this.id = allocation.id;
    this.collaboratorId = allocation.collaboratorId;
    this.projectId = allocation.projectId;
    this.clientId = allocation.clientId;
    this.startDate = allocation.startDate;
    this.endDate = allocation.endDate;
    this.isAbsence = allocation.isAbsence;
    this.isReservation = allocation.isReservation;
    this.isPercentage = allocation.isPercentage;
    this.observation = allocation.observation;
    this.percentage = allocation.percentage;
    this.hours = allocation.hours;
  }

  updateAllocation(allocation: Allocation) {
    this.startDate = allocation.startDate;
    this.endDate = allocation.endDate;
    this.isAbsence = allocation.isAbsence;
    this.isReservation = allocation.isReservation;
    this.isPercentage = allocation.isPercentage;
    this.observation = allocation.observation;
   // this.percentage = allocation.percentage;
  }

  createAllocationByProject(project: IProject) {
    this.projectId = project.id;
    this.clientId = project.clientId;
    this.startDate = this.checkCurrentDay(project.projectInitiation);
    this.isReservation = false;
    this.isAbsence = false;

  }

   setProjectToAllocation(project: IProjectAllocation) {
    this.projectId = project.projectId;
    this.clientId = project.clientId;
    this.startDate = this.checkCurrentDay(project.startDate);
    this.isReservation = false;
    this.isAbsence = false;
    this.isPercentage = true;
  }

  setIdDefault(){
    this.id = '00000000-0000-0000-0000-000000000000';
  }
  updateAllocationByProject(
    collaborator: ICollaboratorAllocation,
    allocation: Allocation
  ) {
    this.collaboratorId = collaborator.collaboratorId;
    this.startDate = allocation.startDate;
    this.endDate = allocation.endDate;
    this.isPercentage = allocation.isPercentage;
    }

  createAllocationByCollaboratorId(collaborator: ICollaboratorAllocation) {
    this.collaboratorId = collaborator.collaboratorId;
    this.jobFunctionId = collaborator.jobFunction.id;
  }

  createReservation(
    allocation: any,
    client: IClient,
    collaborator: ICollaboratorAllocation
  ) {
    this.projectId = null;
    this.collaboratorId = collaborator?.collaboratorId;
    this.clientId = client?.id;
    this.startDate = allocation.startDate;
    this.endDate = allocation.endDate;
    this.isReservation = allocation.isReservation;
    this.isAbsence = allocation.isAbsence;
    this.observation = allocation.observation;
    this.isPercentage = allocation.isPercentage;
  }

  createByReservation(allocation: any) {
    if(allocation.project)
    this.projectId = allocation.project.id;
    this.startDate = allocation.startDate;
    this.endDate = allocation.endDate;
    this.isPercentage = allocation.isPercentage;
    this.observation = allocation.observation;
    this.isReservation = allocation.isReservation;
    this.isAbsence = allocation.isAbsence;
    this.checkIsPercentage(allocation.allocationPeriod);
  }

  createAllocationByReservation(){
    this.isReservation = false;
    this.isAbsence = false;
  }

  replyAllocation(allocation: Allocation) {
    if (allocation.isReservation === false) {
      this.projectId = allocation.projectId;
    }
    this.isReservation = allocation.isReservation;
    this.isAbsence = allocation.isAbsence;
    this.clientId = allocation.clientId;
    this.startDate = allocation.startDate;
    this.endDate = allocation.endDate;
    this.isPercentage = allocation.isPercentage;
    this.percentage = allocation.percentage;
    this.hours = allocation.hours;
   }

  isValid(): boolean {
    if (this.isReservation === false && this.isAbsence === false) {
      if (
        this.collaboratorId &&
        this.projectId &&
        this.clientId &&
        this.startDate &&
        this.endDate
      )
        return true;
      return false;
    } else if (this.isReservation || this.isAbsence){
      if (
        this.collaboratorId &&
        this.clientId &&
        this.startDate &&
        this.endDate
        && this.observation
      )
        return true;
      return false;
    }
    return false;
  }

  checkCurrentDay(date: string): string {
    const currentDate = new Date();
    const projectDate = new Date(date);
    let dateString;

    if (projectDate > currentDate) {
      dateString = projectDate.toDateString();
    } else {
      currentDate.setDate(currentDate.getDate() - 10);
      dateString = currentDate.toDateString();
    }
    return dateString;
  }

  checkIsPercentage(value: number) {
    if (this.isPercentage === true) {
      this.percentage = value;
      // this.hours = 0;
    } else {
      this.hours = value;
      // this.percentage = 0;
    }
  }
}
