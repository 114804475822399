import { IPermission } from './../../../../../shared/interfaces/check-select.interface';
import { IProfileType } from './../../../interface/profile-type.interface';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProfileManageModalComponent } from '../../../modal/profile-manage-modal/profile-manage-modal.component';
import { IProfile } from 'src/app/pages/collaborator/interface/collaborator-profile.interface';
import { SubSink } from 'subsink';
import { MessageService } from 'src/app/core/services/message.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ProfileService } from '../../../repository/profile.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProfilePermissionsService } from '../../../repository/profile-permissions.service';
import { EMPTY, catchError, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterPermission } from 'src/app/shared/models/filter-permission.model';
import { ModalErrorComponent } from 'src/app/shared/components/modal-error/modal-error.component';

@Component({
  selector: 'app-create-access-profile',
  templateUrl: './create-access-profile.component.html',
  styles: [],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class CreateAccessProfileComponent implements OnInit {
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = ['icon', 'description', 'edits', 'status'];
  expandedElement: IProfileType | null | undefined;
  profileType: IProfileType[] = [];
  countDataSource: number = 0;
  pemissions: IPermission[] = [];
  formEdit!: FormGroup;
  private _subs = new SubSink();
  isEdit: boolean = false;
  isSelected: any;
  allChecked: boolean = false;
  profileID: string = '';
  idPermission: any[] = [];
  formPermissions = new FormGroup({});
  filterConfig: FilterPermission = new FilterPermission();
  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _messageService: MessageService,
    private _profileService: ProfileService,
    private _profilePermissionsService: ProfilePermissionsService,
    private _fb: FormBuilder
  ) {
    this.filterConfig = new FilterPermission();
  }

  ngOnInit(): void {
    this.getaccessPermission();
  }

  getaccessPermission() {
    this._profileService
      .getProfile()
      .pipe(
        switchMap((res) => {
          if (Array.isArray(res)) {
            this.getDataSource(res);
            this.countDataSource = res.length;
            this.profileType = res;
            this.idPermission = res.map(
              (profile: any) => profile.accessProfilePermission
            );
            return this._profilePermissionsService.getAccessPermission();
          } else {
            console.error('O resultado de getProfile não é um array:', res);
            return EMPTY;
          }
        }),
        catchError((error) => {
          console.error('Erro ao obter perfil:', error);
          return EMPTY;
        })
      )
      .subscribe((result) => {
        this.pemissions = result;
        this.profileType.forEach((profile: any) => {
          const profileForm = new FormGroup({});
          this.pemissions.forEach((permission) => {
            const permissionForm = new FormGroup({});
            permission.permissions?.forEach((subPermission) => {
              permissionForm.addControl(
                subPermission.id,
                this._fb.control(
                  profile.accessProfilePermission.some(
                    (profilePermission: any) =>
                      profilePermission.permissionId === subPermission.id
                  )
                )
              );
            });
            profileForm.addControl(permission.process, permissionForm);
          });
          this.formPermissions.addControl(profile.id, profileForm);
          this.formPermissions.statusChanges.subscribe(() => {});
        });
      });
  }

  getFormControl(formGroug: FormGroup, formControlName: string) {
    return formGroug.get(formControlName) as FormGroup;
  }

  getDataSource(res: any) {
    let arr: any[] = [];
    if (res) {
      res.map((profileType: IProfileType) => {
        const prepareDataSource = {
          id: profileType.id,
          name: profileType.name,
          status: profileType.inactive,
        };
        arr.push(prepareDataSource);
      });
      this.dataSource.data = arr;
    }
  }

  inactivateProfile(event: any, profile: IProfile) {
    let message: string = '';
    let buttonTxt: string = '';
    const profileId = profile.id;
    if (event.source.checked === false) {
      message = 'Tem certeza que deseja inativar este perfil?';
      buttonTxt = 'Sim';
    } else if (event.source.checked === true) {
      message = 'Tem certeza que deseja ativar este perfil?';
      buttonTxt = 'Sim';
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._profileService.inactivateProfile(profileId).subscribe((res) => {
          if (res.success === true) {
            this._messageService.openSuccessMessage(
              'Operação realizada com sucesso'
            );
            this.reloadComponent();
          } else if (res.success === false) {
           const dialogRefErro = this.dialog.open(ModalErrorComponent, {
              data: {
                title: 'Ops, houve um erro!',
                message:
                  'Ainda existem colaboradores com este perfil ativo, altere-os para outros perfis antes de inativá-los.',
              },
            });
            setTimeout(() => {
              dialogRefErro.close();
            }, 6000);
            this.reloadComponent();
          }
        });
      } else {
        this.reloadComponent();
      }
    });
  }

  openEdit(id: string) {
    this.profileID = id;
    let dialogRef;
    dialogRef = this.dialog.open(ProfileManageModalComponent, {
      data: {
        id: id,
        name: this.profileType.find((profile) => profile.id === id)?.name,
      },
      panelClass: 'modal-dialog-close-type',
    });
  }

  onSubmitProfilePemission(profileId: string) {
    const message: string =
      'Tem certeza que deseja confirmar estas alterações?';
    const buttonTxt: string = 'Sim';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.requestOnSubmit(profileId);
      }
    });
  }

  getId(id: string) {
    this.profileID = id;
  }

  setAll(selected: boolean, profile: IPermission, id: any) {
    this.allChecked = selected;
    if (profile.permissions) {
      profile.permissions.forEach((subPermission) => {
        subPermission.permission = selected;
        this.formPermissions
          .get(id)
          ?.get(profile.process)
          ?.get(subPermission.id)
          ?.setValue(subPermission.permission);
      });
    }
    this.isSelected = profile;
  }

  checkAll(permission: IPermission, id: string): boolean {
    let allChecked = true;
    const formValues: { [key: string]: any } =
      this.formPermissions.getRawValue();
    const profileToUpdate = formValues[id];
    Object.values(profileToUpdate[permission.process]).forEach(
      (subPermission: any) => {
        if (subPermission === false) {
          allChecked = false;
        }
      }
    );
    return allChecked;
  }

  private requestOnSubmit(profileId: string) {
    const formValues: { [key: string]: any } =
      this.formPermissions.getRawValue();
    const profileToUpdate = formValues[profileId];
    const permissionsToUpdate = Object.keys(profileToUpdate).map(
      (key: string) => {
        return Object.keys(profileToUpdate[key]).map((subKey: string) => {
          return profileToUpdate[key][subKey] ? subKey : null;
        });
      }
    );
    const filteredPermissions = permissionsToUpdate
      .flat()
      .filter((permissionId) => permissionId !== null);
    const valueAll =
      this.isSelected && this.isSelected.permissions
        ? this.isSelected.permissions.map(
            (permission: any) => permission.permission
          )
        : [];
    const form = {
      accessProfileId: profileId,
      permissionIds: filteredPermissions,
    };
    this._profilePermissionsService.postPermission(form).subscribe(
      (res) => {
        if (res.success === true) {
          this._messageService.openSuccessMessage(
            'Operação realizada com sucesso'
          );
          this.reloadComponent();
        } else {
          this._messageService.openErrorMessage(
            [
              'Erro ao salvar: é necessário incluir pelo menos uma permissão obrigatória.',
            ],
            5000
          );
        }
      },
      (error) => {
        if (error instanceof HttpErrorResponse && error.status === 400) {
          const errorBody = error.error;
          const errorMessage =
            Array.isArray(errorBody) && errorBody.length > 0
              ? 'Erro ao salvar: é necessário incluir pelo menos uma permissão obrigatória.'
              : 'Erro ao salvar: é necessário incluir pelo menos uma permissão obrigatória.';
          this._messageService.openErrorMessage([errorMessage], 5000);
        } else {
          this._messageService.openErrorMessage(
            [
              'Erro ao salvar: é necessário incluir pelo menos uma permissão obrigatória.',
            ],
            5000
          );
        }
      }
    );
  }

  cancelProfileCreation() {
    const message: string = 'Tem certeza que deseja cancelar?';
    const buttonTxt: string = 'Sim';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadComponent();
      }
    });
  }

  openDialogProfile(profile?: IProfileType): void {
    let dialogRef;
    if (profile) {
      dialogRef = this.dialog.open(ProfileManageModalComponent, {
        data: {
          id: profile.id,
          name: profile.name,
          inactive: profile.inactive,
        },
        panelClass: 'modal-dialog-close-type',
      });
    } else {
      dialogRef = this.dialog.open(ProfileManageModalComponent, {
        panelClass: 'modal-dialog-close-type',
      });
    }

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.reload === true) this.getaccessPermission();
    });
  }

  reloadComponent() {
    const url = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([`/${url}`]);
    });
  }

  skipPage(event: { skip: number }) {
    this.filterConfig.skipPage(event);
    this.getaccessPermission();
  }
}
