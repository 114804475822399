import { IInputOptions } from 'src/app/shared/interfaces/input-select.interface';

export const FILTER_PERIOD_PROJECT: IInputOptions[] = [
  {
    id: '0',
    name: 'Semana',
  },
  {
    id: '1',
    name: 'Quinzena',
  },
  {
    id: '2',
    name: 'Mês',
  },
  {
    id: '3',
    name: 'Trimestre',
  },
];

export const FILTER_STATUS: IInputOptions[] = [
  {
    id: '0',
    name: 'Pausado',
  },
  {
    id: '1',
    name: 'Encerrado',
  },
  {
    id: '2',
    name: 'Para iniciar',
  },
  {
    id: '3',
    name: 'Em Andamento',
  },
];
