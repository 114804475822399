// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.production.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://dalva-dev.performait.net/api',
  azureConfig: {
    clientId: '540bb795-8bd4-43fb-b9e1-a2afc7c38c10',
    authority:
      'https://login.microsoftonline.com/97301bc6-aabf-4213-b140-e9574488f252',
    redirectUri: 'https://dalva-dev.performait.net/account/login',
    logoutRedirectUri: 'https://dalva-dev.performait.net/account/login',
    cacheLocation: 'localStorage',
    consentScopes: ['user.read', 'openid', 'profile'],
    protectedResourceMap: [
      ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    ],
    storeAuthStateInCookie: false,
    loginFailedRoute: '/account/login',
  },
  microservices: {
    collaborator: {
      url: 'Collaborator',
      controllers: {
        alocations: 'AllocationByCollaboratorProfile'
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
