<div class="row m-horizontal-xl align-center" *ngIf="optionsCollaborator">
  <div class="profile">
    <div class="profile-photo row align-center justify-center">
      <img
        *ngIf="optionsCollaborator.imageURL"
        [src]="optionsCollaborator.imageURL | safeResourceUrl"
        alt=""
      />
      <div
        class="row align-center justify-center"
        *ngIf="!optionsCollaborator.imageURL"
      >
        {{ getinitialsNameCollaborator(optionsCollaborator.collaboratorName) }}
      </div>
    </div>
  </div>
  <div class="col p-xl min-content">
    <h1>{{ optionsCollaborator.collaboratorName }}</h1>
    <span>{{
      optionsCollaborator.jobFunction.description ||
        "Business/Service Designer "
    }}</span>
  </div>
  <div class="col p-xl line justify-center">
    <span class="bold">Torre:</span>
    <span>{{ optionsCollaborator.tower.description }}</span>
  </div>
  <div class="col p-xl line justify-center">
    <span class="bold">Horas totais alocadas:</span>
    <span style="font-size: 12px">{{ currentMonth }}/{{ currentYear }}</span>
    <span>
      <span
        [ngClass]="{
          'status-ok': overAllocated === false,
          'status-full': overAllocated === true
        }"
        >(%) {{ weeksTotals }}/
      </span>
      <span class="status-disabled"> 40h semanais </span></span
    >
    <span>
      <span
        [ngClass]="{
          'status-ok': overAllocated === false,
          'status-full': overAllocated === true
        }"
        >(%) {{ monthlyHours }}/
      </span>
      <span class="status-disabled"> 160h mensais </span></span
    >
  </div>
</div>
<div>
  <ng-container
    *ngFor="let item of allocation; let formIndex = index; let first = first"
  >
    <ng-container
      *ngIf="(first && !viewByProject) || (viewByProject && formIndex === 1)"
      ><app-title [title]="pageTitle"></app-title
    ></ng-container>
    <ng-container *ngIf="first && viewByProject"
      ><app-title title="Projeto atual"></app-title
    ></ng-container>
    <app-form-allocation-template
      *ngIf="optionsProject && optionsCollaborator && optionsClients"
      (removeEmitter)="removeAllocation(formIndex, item)"
      (submitEmitter)="receiveSubmit($event, formIndex)"
      (formStatusValid)="receiveStatusForm($event, formIndex)"
      [allocation]="item"
      [collaborator]="optionsCollaborator"
      [allocable]="allocable"
      [formEnabledList]="formEnabledList"
    >
    </app-form-allocation-template>
  </ng-container>
  <div
    class="row justify-end"
    *ngIf="optionsCollaborator && allocable === true"
  >
    <button
    class="btn-no-border m-horizontal-xl hint"
    (click)="addAllocation()">
    Adicionar nova alocação para {{ optionsCollaborator.collaboratorName }}
       <img src="./assets/svg/add.svg" alt="add">
  </button>
  </div>
  <div class="row m-xl p-xl justify-end" *ngIf="optionsCollaborator && allocable === true">
    <button
      class="btn secondary m-md"
      (click)="cancelAllocation()"
      type="button"
    >
      Cancelar
    </button>
    <button
      class="btn primary m-md"
      matTooltip="Finalize o cadastro para habilitar o envio"
      [matTooltipDisabled]="!disableButton"
      (click)="putAllocation()"
      [disabled]="disableButton"
    >
      Confirmar alterações
    </button>
  </div>
</div>

