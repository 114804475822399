import { MaritalEnum } from 'src/app/pages/collaborator/enum/marital.enum';
import { DaysOfWeekEnum } from 'src/app/pages/collaborator/enum/working-days.enum';
import { IAddress } from 'src/app/pages/collaborator/interface/address.interface';
import { ICollaboratorSalary } from 'src/app/pages/collaborator/interface/collaborator-salary.interface';
import { ILevelSkill } from 'src/app/pages/collaborator/interface/collaborator-skills.interface';

export class Collaborator {
  private _id: string;
  private _email: string;
//  private _editableEmail: boolean;
  fullName: string;
  personalEmail?: string;
  nickname: string;
  gender: string;
  birthday: string;
  maritalStatus: MaritalEnum;
  children: boolean;
  isLeader: boolean;
  admission: string;
  contractTypeId: string;
  workingHours: number;
  jobFunctionId: string;
  teamId: string;
  seniorityId: string;
  collaboratorLeaderId: string;
  towerId: string;
  collaboratorSalaryData: ICollaboratorSalary;
  collaboratorSkills: ILevelSkill[];
  collaboratorSchedule: {
    daysOfWeek: number;
    workingDays?: Array<DaysOfWeekEnum>;
  };
  address: IAddress[];
  inactive: boolean;
  accessProfileId!: string;
  /**
   *
   */
  constructor(collaborator: Collaborator) {
    this._id = collaborator._id;
    this._email = collaborator.email;
    this.personalEmail = collaborator.personalEmail;
   // this._editableEmail = collaborator.editableEmail;
    this.fullName = collaborator.fullName;
    this.nickname = collaborator.nickname;
    this.gender = collaborator.gender;
    this.personalEmail = collaborator.personalEmail;
    this.birthday = collaborator.birthday;
    this.children = collaborator.children;
    this.maritalStatus = collaborator.maritalStatus;
    this.admission = collaborator.admission;
    this.isLeader = collaborator.isLeader;
    this.contractTypeId = collaborator.contractTypeId;
    this.workingHours = collaborator.workingHours;
    this.jobFunctionId = collaborator.jobFunctionId;
    this.teamId = collaborator.teamId;
    this.seniorityId = collaborator.seniorityId;
    this.collaboratorLeaderId = collaborator.collaboratorLeaderId;
    this.towerId = collaborator.towerId;
    this.collaboratorSalaryData = collaborator.collaboratorSalaryData;
    this.collaboratorSkills = collaborator.collaboratorSkills;
    this.collaboratorSchedule = collaborator.collaboratorSchedule;
    this.address = collaborator.address;
    this.inactive = collaborator.inactive;
    this.accessProfileId = collaborator.accessProfileId;
  }

  get email(){
    return this._email;
  }

  // get editableEmail(){
  //   return this._editableEmail;
  // }

  get idCollaborator(){
    return this._id;
  }

  getMaxAdmissionDate(){
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    const maxDate = new Date(
      currentDate.getFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate()
    );
    return maxDate.toISOString().slice(0, -14);
  }
}
