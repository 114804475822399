import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import {
  IClient,
  IClientFilter,
  OrderByClient,
} from '../../../../interface/client.interface';
import { ClientService } from '../../../../repository/client.service';
import { SubSink } from 'subsink';
import { ClientManageModalComponent } from '../../../../modal/client-manage-modal/client-manage-modal.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MessageService } from 'src/app/core/services/message.service';
import { COLUMNS_CLIENT } from 'src/app/shared/models/table-columns/columns-client';

type chip = {
  controlName: string;
  description: string;
};

@Component({
  selector: 'app-page-client-list',
  templateUrl: './page-client-list.component.html',
  styles: [],
})
export class PageClientListComponent implements OnInit {
  private _subs = new SubSink();
  clients: IClient[] = [];
  columns = COLUMNS_CLIENT;

  filter: IClientFilter = {
    searchString: null,
    inactive: null,
    page: 1,
    take: 20,
    orderByParam: { orderBy: 1, asc: true },
  };

  pageTitle: string = 'Clientes';
  btnText: string = 'Cadastrar Cliente';

  countDataSource: number = 0;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = ['name', 'country' , 'projectsCount', 'status', 'action'];

  pageSizeOptions: Array<number> = [5, 10, 20];
  pageSize: number = 20;
  filterForm!: FormGroup;
  searchByNameCtrl: FormControl = new FormControl();

  orderOptions: { [key: string]: number } = {
    name: OrderByClient.NAME,
    projectsCount: OrderByClient.PROJECT_COUNT,
    country: OrderByClient.COUNTRY,
  };

  chipsList: Array<any> = [];

  constructor(
    private _clientService: ClientService,
    private _fb: FormBuilder,
    public dialog: MatDialog,
    private _messageService: MessageService
  ) {
    this.displayedColumns = Object.keys(this.columns);
  }

  ngOnInit(): void {
    this.createFormGroup();
    this.displayedColumns = Object.keys(this.columns);

    this.getClients();

    this.listenFormChanges();
  }

  listenFormChanges() {
    this.filterForm.valueChanges.subscribe((value) => {
      this.filter = {
        ...this.filter,
        ...value,
      };
      this.getClients();
    });
  }

  getClients(resetPage: boolean = true): void {
    if (resetPage) {
      this.filter.page = 1;
      this.countDataSource = 0;
    }

    this._clientService
      .getAllClientsByFilter(this.filter)
      .subscribe((response) => {
        if (response.success === true) {
          this.clients = response.result;
          this.countDataSource = response.count;
          this.dataSource.data = this.clients;
        }
      });
  }

  searchBy() {
    let value = this.searchByNameCtrl.value;

    if (value) {
      value = value.replace(/\.|-|\//g, '');
    }

    this.filter.searchString = value;

    this.getClients();
  }

  clearFilter() {
    this.searchByNameCtrl.reset();
    this.filter.searchString = null;
    this.getClients();
  }

  createFormGroup() {
    this.filterForm = this._fb.group({
      inactive: [null],
    });
  }

  skipPage(event: { skip: number }) {
    this.filter.page = event.skip + 1;
    this.getClients(false);
  }

  changeChipList(
    controlName: string,
    description: string,
    value?: string
  ): void {
    let valueForm = this.filterForm.get(controlName)?.value;

    if (valueForm === value) return;

    const hasChipControl = this.chipsList.findIndex(
      (item) => item.controlName === controlName
    );
    let descriptionValue = '';

    if (hasChipControl >= 0) {
      descriptionValue = this.chipsList.splice(hasChipControl, 1)[0]
        .description;
    }

    if (description && description !== descriptionValue) {
      this.chipsList.push({
        controlName: controlName,
        description: description,
      });
    }
  }

  removeFilterValueChips(chip: chip) {
    this.filterForm.get(chip.controlName)?.reset();
    this.removeInactiveChip();
  }

  removeAllChips() {
    this.filterForm.reset();
    this.removeInactiveChip();
  }

  removeInactiveChip(): void {
    let index = this.chipsList.findIndex(
      (item) => item.controlName === 'inactive'
    );
    if (index >= 0) {
      this.chipsList.splice(index, 1);
    }
  }

  changeItemsPerPage(items: number) {
    this.filter.page = 1;
    this.filter.take = items;

    this.getClients();
  }

  changeOrder(orderBy: OrderByClient) {
    if (this.filter.orderByParam.orderBy === orderBy) {
      this.filter.orderByParam.asc = !this.filter.orderByParam.asc;
    } else {
      this.filter.orderByParam.orderBy = orderBy;
      this.filter.orderByParam.asc = true;
    }

    this.getClients(false);
  }

  openDialogClient(client?: IClient) {
    let dialogRef;
    if (client) {
      dialogRef = this.dialog.open(ClientManageModalComponent, {
        data: { client: client },
        panelClass: 'modal-dialog-close-type',
      });
    } else {
      dialogRef = this.dialog.open(ClientManageModalComponent, {
        panelClass: 'modal-dialog-close-type',
      });
    }
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.reload === true) this.getClients();
    });
  }

  inactivateClient(event: any, clientId: string) {
    let message: string = '';
    let buttonTxt: string = '';
    if (event.source.checked === false) {
      message = 'Deseja inativar cliente?';
      buttonTxt = 'Sim';
    } else if (event.source.checked === true) {
      message = 'Deseja ativar cliente?';
      buttonTxt = 'Sim';
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._subs.add(
          this._clientService.inactivateClient(clientId).subscribe(
            (response) => {
              if (response.success === true) {
                this.getClients();
                this._messageService.openSuccessMessage(
                  'Operação realizada com sucesso'
                );
              } else if (response.errors.length > 0) {
                this._messageService.openErrorMessage(response.errors);
                event.source.checked = !event.source.checked;
              }
            },
            () => {
              this._messageService.openErrorMessage([
                'Erro encontrado, tente novamente',
              ]);
              event.source.checked = !event.source.checked;
            }
          )
        );
      } else {
        event.source.checked = !event.source.checked;
      }
    });
  }
}
