import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { SubSink } from 'subsink';
import { UserService } from '../../../repository/user.service';
import { IChangePassword } from '../../../interface/reset-password.interface';
import { MessageService } from 'src/app/core/services/message.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: [],
})
export class ResetPasswordComponent implements OnInit {
  private _subs = new SubSink();
  resetForm!: FormGroup;
  showPassword: boolean = false;
  email!: string;

  constructor(
    private _fb: FormBuilder,
    private _userService: UserService,
    private _router: Router,
    private _messageService: MessageService
  ) {
    this.createFormGroup();
    try {
      const navigation = this._router.getCurrentNavigation()?.extras.state as {
        email: string;
      };
      this.email = navigation.email;
    } catch (error) {
      if (!this.email) this.returnTologin();
    }
  }

  ngOnInit(): void {
    this.resetForm.controls['repeatPassword'].valueChanges.subscribe(
      (password: String) => {
        if (password != this.resetForm.controls['newPassword'].value) {
          this.resetForm.controls['newPassword'].setErrors({ notEqual: true });
          this.resetForm.controls['repeatPassword'].setErrors({
            notEqual: true,
          });
        } else {
          this.resetForm.controls['newPassword'].setErrors(null);
          this.resetForm.controls['repeatPassword'].setErrors(null);
        }
      }
    );
  }

  toggleShowPassword(showPassword: boolean) {
    this.showPassword = !showPassword;
  }
  createFormGroup() {
    this.resetForm = this._fb.group({
      newPassword: ['', [Validators.required]],
      repeatPassword: ['', [Validators.required]],
    });
  }
  onSubmitChangePassword() {
    const changePassword: IChangePassword = {
      email: this.email,
      newPassword: this.resetForm.value['repeatPassword'],
    };

    if (this.resetForm.dirty && this.resetForm.valid) {
      this._subs.sink = this._userService
        .changePassword(changePassword)
        .subscribe(
          (response) => {
            this.changePasswordSuccess(response);
          },
          (error) => {
            this.changePasswordFailed(error.error);
          }
        );
    } else {
      this.resetForm.markAllAsTouched();
    }
  }

  changePasswordSuccess(response: IApiResponse) {
    if (!response) {
      this._messageService.openSuccessMessage('Senha cadastrada com sucesso.');
      this.returnTologin();
    }
  }

  changePasswordFailed(errors: Array<string>) {
    this._messageService.openErrorMessage(errors);
    this.returnTologin();
  }

  returnTologin() {
    this._router.navigate(['account/login']);
  }
}
