import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { CollaboratorService } from '../../repository/collaborator.service';
import { SubSink } from 'subsink';
import { EMPTY, Observable, catchError, switchMap } from 'rxjs';
import { AddressService } from '../../repository/address.service';
import { MaritalEnum } from '../../enum/marital.enum';
import { DaysOfWeekEnum } from '../../enum/working-days.enum';
import { MatOption } from '@angular/material/core';
import { LevelSkillsEnum } from '../../enum/level-skills.enum';
import { ILevelSkill } from '../../interface/collaborator-skills.interface';
import { ICollaborator } from '../../interface/collaborator.interface';
import { _isNumberValue } from '@angular/cdk/coercion';
import { ArrayValidators } from 'src/app/shared/validators/array.validator';
import {
  IOptionsCollaborator,
  ISkillOption,
} from '../../interface/options-collaborator.interface';
import { IAddress, IAddressViaCep } from '../../interface/address.interface';
import { MessageService } from 'src/app/core/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';

import { StringValidators } from 'src/app/shared/validators/string.validator';
import { ERoleUser } from '../../enum/role.enum';
import { StringUtil } from 'src/app/shared/utils/string';
import { UserRoleService } from 'src/app/pages/account/repository/user-role.service';
import {
  EPermissionType,
  EProcess,
} from 'src/app/shared/interfaces/roles.interface';
import { AccountService } from 'src/app/pages/account/repository/account.service';

@Component({
  selector: 'app-page-manage-collaborator',
  templateUrl: './page-manage-collaborator.component.html',
  styles: [],
})
export class PageManageCollaboratorComponent implements OnInit, OnDestroy {
  profileForm!: FormGroup;
  maxAdmissionDate!: string;
  maxLenghtString = 100;
  currentRoute!: string;

  options!: IOptionsCollaborator;
  options$!: Observable<IOptionsCollaborator>;

  address!: IAddressViaCep;
  address$!: Observable<IAddressViaCep>;
  EProcess = EProcess;
  EPermissionType = EPermissionType;
  maritalState = MaritalEnum;
  skillLevel = LevelSkillsEnum;
  skillArray: ILevelSkill[] = [];

  workingDaysSelectOptions: string[] = [];
  levelSelectOptions: string[] = [];
  maritalStateOptions: string[] = [];
  private _subs = new SubSink();
  name: string = '';
  role!: number | null;
  eRoleUser = ERoleUser;
  isEdit: boolean = false;
  collaboratorId!: string;
  isEditForm: boolean = false;
  isUserCollaborator: boolean = false;
  isColaboratorLeader!: boolean;
  collaborator: ICollaborator = {
    id: '',
    email: '',
    personalEmail: '',
    fullName: '',
    editableIsLeader: false,
    nickname: '',
    gender: '',
    birthday: '',
    maritalStatus: 0,
    children: false,
    isLeader: false,
    admission: '',
    contractTypeId: '',
    workingHours: 0,
    jobFunctionId: '',
    teamId: '',
    seniorityId: '',
    collaboratorLeaderId: '',
    towerId: '',
    accessProfileId: '',
    collaboratorSalaryData: {
      salary: 0,
      dentalPlan: 0,
      allowance: 0,
      lifeInsurance: 0,
      equipmentSubsidy: 0,
      mealTicket: 0,
      healthInsurance: 0,
      thirteenthBonus: 0,
      billable: false,
    },
    collaboratorSkills: [],
    collaboratorSchedule: {
      daysOfWeek: 0,
      workingDays: [],
    },
    address: [],
  };

  @ViewChild('allSelected')
  private allSelected!: MatOption;

  constructor(
    private _fb: FormBuilder,
    private _collaboratorService: CollaboratorService,
    private _addressService: AddressService,
    private _messageService: MessageService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private _userRoleService: UserRoleService,
    private _accountService: AccountService
  ) {}

  detectChanges() {
    this._cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  ngOnInit(): void {
    this.role = this.getAccessProfile();

    try {
      this.collaboratorId = this._route.snapshot.paramMap.get(
        'collaboratorId'
      ) as string;
      this.currentRoute = this._router.url.split('/')[1];

      this.getSelectOptions();
      this.createFormGroup();
      this.createSelectOptions();
      this.disableInputsForm();

      if (this.collaboratorId) {
        this.isEditForm = true;
      }
    } catch (error) {
      this.isEditForm = false;
    }

    if (
      this.collaboratorId === this._accountService.localStorage.collaboratorId
    ) {
      this.isUserCollaborator = true;
    }
  }

  createFormGroup() {
    this.maxAdmissionDate = this.getMaxAdmissionDate();
    this.profileForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      personalEmail: ['', [Validators.required,Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')] ],
      fullName: ['', Validators.required],
      nickname: [null],
      gender: [null],
      birthday: ['', [Validators.required, this.validateDateBirth]],
      maritalStatus: [null, Validators.required],
      isLeader: [false, Validators.required],
      children: [null, Validators.required],
      admission: ['', [Validators.required, this.validateDate]],
      contractTypeId: ['', Validators.required],
      daysOfWeek: ['', Validators.required],
      workingHours: [
        '',
        [
          Validators.required,
          StringValidators.minNumber(4),
          StringValidators.maxNumber(8),
        ],
      ],
      towerId: ['', Validators.required],
      teamId: ['', Validators.required],
      jobFunctionId: ['', Validators.required],
      seniorityId: ['', Validators.required],
      collaboratorLeaderId: [''],
      accessProfileId: ['', Validators.required],
      skillSelected: [],
      collaboratorSkills: this._fb.array([], ArrayValidators.minLength(1)),
      addresses: this._fb.array(
        [
          this._fb.group({
            addressIdentity: ['', Validators.required],
            publicArea: ['', Validators.required],
            localAddress: ['', Validators.required],
            number: ['', Validators.required],
            complement: [null],
            neighborhood: ['', Validators.required],
            zipCode: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            Country: ['', Validators.required],
            principal: [true, Validators.required],
          }),
        ],
        [ArrayValidators.minLength(1), ArrayValidators.maxLength(2)]
      ),
      collaboratorSalaryData: this._fb.group({
        salary: [''],
        allowance: [''],
        lifeInsurance: [''],
        dentalPlan: [''],
        mealTicket: [''],
        equipmentSubsidy: [''],
        healthInsurance: [''],
        thirteenthBonus: [''],
        billable: [''],
      }),
    });
  }

  createSelectOptions() {
    this.workingDaysSelectOptions = Object.keys(DaysOfWeekEnum).filter((f) =>
      isNaN(Number(f))
    );
    this.levelSelectOptions = Object.keys(LevelSkillsEnum).filter((f) =>
      isNaN(Number(f))
    );
    this.maritalStateOptions = Object.keys(MaritalEnum).filter((f) =>
      isNaN(Number(f))
    );
  }

  get skills() {
    return this.profileForm.controls['collaboratorSkills'] as FormArray;
  }

  addFormSkills() {
    const skillSelected = this.profileForm.controls['skillSelected'].value;
    let canCreateForm: boolean = true;
    this.skills.value.map((skillFormValue: ISkillOption) => {
      if (skillFormValue.skillsId === skillSelected.skillsId)
        canCreateForm = false;
    });
    this.options.skills = this.options.skills.filter((skill: ISkillOption) => {
      return skill != skillSelected;
    });
    let skillForm: FormGroup = new FormGroup({});
    if (skillSelected.skillsId && canCreateForm === true) {
      skillForm = this._fb.group({
        id: [skillSelected.id, Validators.required],
        skillsId: [skillSelected.skillsId, Validators.required],
        description: [skillSelected.description, Validators.required],
        level: ['', Validators.required],
      });
      skillForm = this._fb.group({
        skillsId: [skillSelected.skillsId, Validators.required],
        description: [skillSelected.description, Validators.required],
        level: ['', Validators.required],
      });
      skillForm.markAsTouched();

      this.skills.push(skillForm);
    }
    this.profileForm.controls['skillSelected'].reset();
  }

  addLevel(indexSkillForm: number) {
    const level: ILevelSkill = {
      skillsId: this.skills.at(indexSkillForm).value.skillsId,
      id: this.skills.at(indexSkillForm).value.id,
      level: this.skills.at(indexSkillForm).value.level,
    };
    this.skillArray.map((skillToSubmit: ILevelSkill, index) => {
      if (skillToSubmit.skillsId && skillToSubmit.skillsId === level.skillsId) {
        this.skillArray.splice(index, 1);
      }
    });
    this.skillArray.push(level);
  }

  deleteSkills(skillIndex: number) {
    const removedSkill = this.skills.at(skillIndex).value;
    this.options.skills.unshift(this.skills.at(skillIndex).value);
    this.skillArray.map((removedSkills, formSkillIndex) => {
      if (removedSkills.skillsId === removedSkill.skillsId) {
        this.skillArray.splice(formSkillIndex, 1);
      }
    });
    this.skills.removeAt(skillIndex);
  }

  onSubmitCollaborator(formDirective: FormGroupDirective) {
    this.profileForm.enable();
    const formValue = this.profileForm.value;
    const collaborator: ICollaborator = {
      email: formValue.email,
      personalEmail: formValue.personalEmail,
      fullName: StringUtil.capitalize(formValue.fullName),
      nickname: formValue.nickname,
      gender: formValue.gender,
      birthday: new Date(formValue.birthday).toISOString(),
      maritalStatus: formValue.maritalStatus,
      children: formValue.children,
      admission: new Date(formValue.admission).toISOString(),
      contractTypeId: formValue.contractTypeId,
      workingHours: this.stringToNumber(formValue.workingHours),
      jobFunctionId: formValue.jobFunctionId,
      teamId: formValue.teamId,
      seniorityId: formValue.seniorityId,
      collaboratorLeaderId: formValue.collaboratorLeaderId,
      isLeader: formValue.isLeader,
      towerId: formValue.towerId,
      accessProfileId: formValue.accessProfileId,
      collaboratorSkills: this.skillArray,
      collaboratorSchedule: {
        daysOfWeek: this.getDaysOfWeek(),
        workingDays: [],
      },
      address: formValue.addresses,
      id: this.isEditForm ? this.collaboratorId : undefined,
    };
    if (this.isEditForm) {
      collaborator.id = this.collaboratorId;
      collaborator.collaboratorSkills.map((skillUpdate) => {
        this.collaborator.collaboratorSkills.map((skillCollaborator) => {
          if (skillUpdate.skillsId === skillCollaborator.skillsId) {
            skillUpdate.id = skillCollaborator.id;
          }
        });
      });
      if (this.isEditForm && this.profileForm.valid) {
        const collaboratorId = this.collaboratorId;
        collaborator.id = collaboratorId;

        this._subs.add(
          this._collaboratorService.putCollaborator(collaborator).subscribe(
            (response) => {
              if (response.success === true) {
                this._messageService
                  .openSuccessMessageDefault('Cadastro realizado')
                  .subscribe((response) => {
                    formDirective.resetForm();
                    this.deleteAllSkills();

                    if (this.isUserCollaborator) {
                      this._accountService.localStorage.setNameUser(
                        collaborator.fullName
                      );
                      this._router.navigate([
                        'meu-perfil-detalhes',
                        collaboratorId,
                      ]);
                    } else if (!this.isUserCollaborator) {
                      this._router.navigate([
                        'detalhes-colaborador',
                        collaboratorId,
                      ]);
                    } else {
                      this.reloadComponent();
                    }
                  });
              } else if (response.success === false) {
                if (response.errors.length > 0) {
                  this._messageService.openErrorMessage(response.errors);
                  this.disableInputsForm();
                }
              }
            },
            (error) => {
              this._messageService.openErrorMessage([error.error[0].Message]);
              this.disableInputsForm();
            }
          )
        );
      } else if (this.isEditForm && !this.profileForm.valid) {
        this._messageService.openErrorMessage([
          'Preencha os campos obrigatórios',
        ]);
        this.disableInputsForm();
      }
    } else {
      if (this.profileForm.valid) {
        this._subs.add(
          this._collaboratorService
            .postCollaborator(collaborator)
            .subscribe((response) => {
              if (response.success === true) {
                this._messageService.openSuccessMessage(
                  'Cadastro realizado com sucesso.'
                );

                formDirective.resetForm();
                this.deleteAllSkills();
                this._router.navigate(['/colaboradores']);
              } else if (response.errors.length > 0) {
                this._messageService.openErrorMessage(response.errors);
              }
            })
        );
      } else {
        this._messageService.openErrorMessage(
          this.getSkillsFormValidationErrors()
        );
      }
    }
  }

  returnToCollaboratorList() {
    this._router.navigate(['colaboradores']);
  }

  getMaxAdmissionDate() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    const maxDate = new Date(
      currentDate.getFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate()
    );
    return maxDate.toISOString().slice(0, -14);
  }

  fillDate(dateString: string | null): string {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  validateDate(control: FormControl) {
    const admission = control.value;
    if (typeof admission === 'string') {
      const admissionDate = new Date(admission);
      const currentDate = new Date();
      const referenceDate = new Date(currentDate);
      referenceDate.setFullYear(currentDate.getFullYear() - 20);
      if (admissionDate < referenceDate) {
        return { invalidAdmission: true };
      }
    }
    return null;
  }

  validateDateBirth(control: FormControl) {
    const birthday = control.value;
    if (typeof birthday === 'string') {
      const admissionDate = new Date(birthday);
      const referenceDate = new Date('1900-01-01');
      if (admissionDate < referenceDate) {
        return { invalidBirthday: true };
      }
    }

    return null;
  }

  getSkillsFormValidationErrors(): Array<string> {
    if (this.skills.invalid) {
      if (this.skills.value.length === 0) {
        return ['Você precisa adicionar ao menos uma habilidade'];
      } else {
        return ['Preencha o nível das habilidades selecionadas'];
      }
    }
    return ['Algo deu errado.'];
  }

  deleteAllSkills() {
    let skillLength = this.skills.length - 1;
    for (skillLength; skillLength >= 0; skillLength--) {
      this.deleteSkills(skillLength);
    }
  }

  getSelectOptions() {
    this._collaboratorService
      .getCollaboratorSelect()
      .pipe(
        switchMap((res) => {
          this.options = res;
          this.options.skills.map((skill: ISkillOption) => {
            skill.skillsId = skill.id;
            skill.id = undefined;
          });
          return this._collaboratorService.getCollaboratorById(
            this.collaboratorId
          );
        }),
        catchError((error) => {
          console.error('Erro:', error);
          return EMPTY;
        })
      )
      .subscribe((response) => {
        this.setPathValue(response);
        this.collaborator = response;
      });
  }

  getCollaboratorById() {
    this._subs.add(
      this._collaboratorService
        .getCollaboratorById(this.collaboratorId)
        .subscribe((response) => {
          this.setPathValue(response);
          this.collaborator = response;
        })
    );
  }

  setPathValue(collaborator: ICollaborator) {
    let days: string[] = [];
    this.name = collaborator.fullName;
    this.profileForm.patchValue({
      email: collaborator.email,
      personalEmail: collaborator.personalEmail,
      fullName: collaborator.fullName,
      nickname: collaborator.nickname,
      birthday: this.fillDate(collaborator.birthday),
      isLeader: collaborator.isLeader,
      children: collaborator.children,
      admission: this.fillDate(collaborator.admission),
      contractTypeId: collaborator.contractTypeId,
      workingHours: collaborator.workingHours,
      towerId: collaborator.towerId,
      teamId: collaborator.teamId,
      jobFunctionId: collaborator.jobFunctionId,
      seniorityId: collaborator.seniorityId,
      collaboratorLeaderId: collaborator.collaboratorLeaderId,
      accessProfileId: collaborator.accessProfileId,
    });

    if (collaborator.gender !== null)
      this.profileForm.patchValue({ gender: `${collaborator.gender}` });
    if (collaborator.maritalStatus !== null)
      this.profileForm.patchValue({
        maritalStatus: `${collaborator.maritalStatus}`,
      });

    this.collaboratorSalaryData.patchValue({
      salary: collaborator.collaboratorSalaryData?.salary,
      allowance: collaborator.collaboratorSalaryData?.allowance,
      lifeInsurance: collaborator.collaboratorSalaryData?.lifeInsurance,
      dentalPlan: collaborator.collaboratorSalaryData?.dentalPlan,
      mealTicket: collaborator.collaboratorSalaryData?.mealTicket,
      equipmentSubsidy: collaborator.collaboratorSalaryData?.equipmentSubsidy,
      healthInsurance: collaborator.collaboratorSalaryData?.healthInsurance,
      thirteenthBonus: collaborator.collaboratorSalaryData?.thirteenthBonus,
      billable: `${collaborator.collaboratorSalaryData?.billable}`,
    });

    collaborator.address.map((address: IAddress, index) => {
      this.collaborator.address.push(address);
      if (index === 1) {
        this.addFormControlAddress();
      }
      this.addresses.at(index).patchValue({
        zipCode: address.zipCode,
        number: address.number,
        addressIdentity: address.addressIdentity,
        publicArea: address.publicArea,
        complement: address.complement,
        neighborhood: address.neighborhood,
        localAddress: address.localAddress,
        city: address.city,
        Country: address.country,
        state: address.state,
        id: address.id,
        principal: address.principal,
      });
    });

    const uniqueSkills =
      collaborator.collaboratorSkills?.filter(
        (skill, index, skillArray) =>
          skillArray.findIndex(
            (skillCompare) => skillCompare?.skillsId === skill?.skillsId
          ) === index
      ) || [];

    uniqueSkills.forEach((skillCollaborator: ILevelSkill, index) => {
      const options = this.options?.skills?.find((skillOption) => {
        return skillOption?.skillsId === skillCollaborator?.skillsId;
      });

      if (options) {
        this.profileForm.controls['skillSelected'].setValue(options);
        this.addFormSkills();

        if (this.skills.length > index) {
          this.skills.at(index).patchValue({
            level: `${skillCollaborator.level}`,
          });
          this.addLevel(index);
        }
      }
    });

    if (
      collaborator.collaboratorSchedule.workingDays?.length ===
      this.workingDaysSelectOptions.length
    ) {
      this.allSelected.select();
      this.toggleAllWorkingDaysSelection();
    } else {
      collaborator.collaboratorSchedule.workingDays?.map((day) => {
        days.push(DaysOfWeekEnum[day]);
      });
      this.profileForm.patchValue({
        daysOfWeek: days,
      });
    }

    this.selectTower();

    if (collaborator.editableIsLeader === false) {
      this.profileForm.controls['isLeader']?.disable();
    }
    this.isColaboratorLeader = collaborator.editableIsLeader ?? false;

    this.disableInputsForm();
  }

  get collaboratorSalaryData() {
    return this.profileForm.controls['collaboratorSalaryData'] as FormGroup;
  }

  togglePerOne() {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
    }
    if (
      this.profileForm.controls['daysOfWeek'].value.length ==
      this.workingDaysSelectOptions.length
    )
      this.allSelected.select();
  }

  toggleAllWorkingDaysSelection() {
    if (this.allSelected.selected) {
      this.profileForm.controls['daysOfWeek'].patchValue([
        ...this.workingDaysSelectOptions.map((item) => item),
        0,
      ]);
    } else {
      this.profileForm.controls['daysOfWeek'].patchValue([]);
    }
  }

  selectTower(resetTeam: boolean = false) {
    try {
      this.options.towers.forEach((tower) => {
        if (tower.id === this.profileForm.controls['towerId'].value) {
          this.options.teams = tower.teams;
        }
      });

      if(resetTeam) {
        this.profileForm.controls['teamId'].reset();
      }
    } catch (error) {
      console.error(error);
    }
  }

  get addresses() {
    return this.profileForm.controls['addresses'] as FormArray;
  }

  getAddressByZipCode(zipCode: string, addressIndex: number) {
    this.address$ = this._addressService.getAddressByZipCode(zipCode);
    this._subs.add(
      this.address$.subscribe((options: IAddressViaCep) => {
        if (options.erro) {
          this._messageService.openErrorMessage(['CEP INVÁLIDO']);
        }
        this.fillAddressForm(options, addressIndex);
      })
    );
  }

  addFormControlAddress() {
    if (this.addresses.length === 1) {
      const addressForm = this._fb.group({
        addressIdentity: [''],
        publicArea: [''],
        localAddress: [''],
        number: [''],
        complement: [''],
        neighborhood: [''],
        zipCode: [''],
        city: [''],
        Country: [''],
        state: [''],
        principal: [false],
      });
      this.addresses.push(addressForm);
    }
  }

  fillAddressForm(address: IAddressViaCep, addressIndex: number) {
    this.addresses.at(addressIndex).patchValue({
      localAddress: address.logradouro,
      complement: address.complemento,
      neighborhood: address.bairro,
      city: address.localidade,
      Country: address.pais,
      state: address.uf,
    });
  }

  deleteAddress() {
    this.addresses.at(0).patchValue({ principal: true });
    this.addresses.removeAt(1);
  }

  selectPrincipal(addressIndex: number) {
    if (this.addresses.length === 1)
      this.addresses.at(0).patchValue({ principal: true });
    else {
      this.addresses.value.map((address: IAddress, index: number) => {
        if (index != addressIndex) {
          this.addresses.at(addressIndex).value.principal === true
            ? this.addresses.at(index).patchValue({ principal: false })
            : this.addresses.at(index).patchValue({ principal: true });
        }
      });
    }
  }

  stringToNumber(value: string): number {
    return Number(value);
  }

  getDaysOfWeek() {
    const dayOfWeek = this.profileForm.controls['daysOfWeek'].value;
    const response = dayOfWeek
      .map((day: DaysOfWeekEnum) => {
        const indexOfS = Object.values(DaysOfWeekEnum).indexOf(
          day as unknown as DaysOfWeekEnum
        );
        const key = Object.keys(DaysOfWeekEnum)[indexOfS];
        if (key) return Number(key);
        return 0;
      })
      .reduce((sum: number, current: number) => sum + current, 0);

    return response;
  }

  formDisabled(controlName: string): boolean {
    return (
      (this.isEditForm && this.profileForm.get(controlName)?.disabled) || false
    );
  }

  disableInputsForm() {
    this.profileForm.enable();
    this.profileForm.controls['email'].disable();
    this.profileForm.controls['accessProfileId'].disable();

    if (
      !this._userRoleService.hasRole(
        EProcess.CollaboratorContractualinformation,
        EPermissionType.CreateUpdate
      )
    ) {
      this.profileForm.controls['admission'].disable();
      this.profileForm.controls['contractTypeId'].disable();
      this.profileForm.controls['daysOfWeek'].disable();
      this.profileForm.controls['workingHours'].disable();
      this.profileForm.controls['towerId'].disable();
      this.profileForm.controls['teamId'].disable();
      this.profileForm.controls['collaboratorLeaderId'].disable();
      this.profileForm.controls['seniorityId'].disable();
      this.profileForm.controls['jobFunctionId'].disable();
      this.profileForm.controls['accessProfileId'].disable();
      this.profileForm.controls['isLeader'].disable();
    }

    if (
      !this.isUserCollaborator &&
      !this._userRoleService.hasRole(
        EProcess.CollaboratorPersonalData,
        EPermissionType.CreateUpdate
      )
    ) {
      this.profileForm.controls['fullName'].disable();
      this.profileForm.controls['nickname'].disable();
      this.profileForm.controls['gender'].disable();
      this.profileForm.controls['birthday'].disable();
      this.profileForm.controls['maritalStatus'].disable();
      this.profileForm.controls['children'].disable();
    }

    if (
      !this.isUserCollaborator &&
      !this._userRoleService.hasRole(
        EProcess.CollaboratorAddress,
        EPermissionType.CreateUpdate
      )
    ) {
      this.profileForm.controls['addresses'].disable();
    }

    if (
      !this.isUserCollaborator &&
      !this._userRoleService.hasRole(
        EProcess.CollaboratorSkills,
        EPermissionType.CreateUpdate
      )
    ) {
      this.skills.controls.forEach((skill) => {
        skill.get('level')?.disable();
      });
    }
  }

  reloadComponent() {
    const url = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([`/${url}`]);
    });
  }

  return() {
    this.currentRoute === 'meu-perfil'
      ? this._router.navigate(['meu-perfil-detalhes/', this.collaboratorId])
      : this._router.navigate(['colaboradores/']);
  }

  getAccessProfile() {
    let role = this._accountService.localStorage.getProfile();
    return role;
  }

}
