<div class="login-background">
  <div class="logo-container">
    <img class="logo-img p-md" src="./assets/img/logo-menu.png" alt="" />
  </div>
  <div class="row max-height align-center justify-center">
    <div class="card p-xl">
      <app-load [isLogin]="true"></app-load>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
