import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  DateInputAllocation,
  GroupedMonths,
  IMonthYearPeriod,
  IProjectAllocation,
  IRequestAllocationByMonth,
  IWeeksPerMonthAllocation,
} from 'src/app/pages/allocation/interface/project-allocation.interface';
import {
  getMonthNumber,
  getMonthsInPeriod,
  getWeeksOfMonth,
  getYearString,
  isDateInRange,
  isNextMonthWithinRangeByFilter,
  isNextMonthWithinRangeByFlag,
} from './period.strategy';
import { EFilterAllocationOption } from 'src/app/pages/collaborator/enum/period-alocation.enum';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';

@Component({
  selector: 'app-period-view-project-allocation',
  templateUrl: './period-view-project-allocation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodViewProjectAllocationComponent implements OnInit, OnChanges {
  @Input() searchType!: EFilterAllocationOption;
  @Input() allocation!: IProjectAllocation;

  @Output() changeMonth = new EventEmitter<IRequestAllocationByMonth>();

  date!: DateInputAllocation;
  weeksPerMonth: IWeeksPerMonthAllocation[] = [];
  monthsInPeriod: IMonthYearPeriod[] = [];
  groupedMonths: GroupedMonths = [];
  filterByMonth!: IRequestAllocationByMonth;

  period!: { initialDate: Date; finalDate: Date };

  activeIndex: number = 0;
  minIndex: number = 0;
  maxIndex: number = 0;
  activeYears: string = '';
  limitReachedLeft: boolean = false;
  limitReachedRight: boolean = false;
  monthSelected: boolean = false;

  months: { [key: number]: string } = {
    1: 'JAN',
    2: 'FEV',
    3: 'MAR',
    4: 'ABR',
    5: 'MAI',
    6: 'JUN',
    7: 'JUL',
    8: 'AGO',
    9: 'SET',
    10: 'OUT',
    11: 'NOV',
    12: 'DEZ',
  };

  constructor(private _allocationService: AllocationService) {}

  ngOnInit(): void {
    this.initializeByPeriod();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchType']) {
      this.initializeByPeriod();

      if (this.searchType === EFilterAllocationOption.Total) {
        const currentDate = new Date();

        this.date = {
          day: currentDate.getDate(),
          month: currentDate.getMonth() + 1,
          year: currentDate.getFullYear(),
        };
      }
    }
    if (changes['allocation']) {
      this.setPeriodByProject();
      this.initializeByPeriod();
    }
  }

  setPeriodByProject(): void {
    if (this.allocation) {
      this.period = {
        initialDate: new Date(this.allocation.startDate),
        finalDate: new Date(this.allocation.endDate),
      };
    }
  }

  initializeByPeriod(): void {
    if (!!this.period) {
      if (this.searchType === 'month') {
        let isDateRange = isDateInRange(
          this.period.initialDate,
          this.period.finalDate
        );

        if (!isDateRange && !this.monthSelected) {
          this.date.day = this.period.initialDate.getDate();
          this.date.month = this.period.initialDate.getMonth() + 1;
          this.date.year = this.period.initialDate.getFullYear();
        }

        this.sendAllocationPercentageEvent(EFilterAllocationOption.Mes);
        this.setLimitReachedMonths();
        this.weeksPerMonth = getWeeksOfMonth(this.date);
        this.monthSelected = false;

        return;
      } else if (this.groupedMonths.length < 1 && !!this.period) {
        const { groupedMonths, activeIndex } = getMonthsInPeriod(
          this.period.initialDate,
          this.period.finalDate
        );

        this.groupedMonths = groupedMonths;
        this.activeIndex = activeIndex;
        this.maxIndex = groupedMonths.length - 1;

        this.setActiveYears();
      }

      this.sendAllocationPercentageEvent(EFilterAllocationOption.Total);
      this.monthSelected = false;
    }
  }

  selectMonth(month: string, year: number): void {
    this.filterByMonth = {
      month: getMonthNumber(month),
      year,
      projectId: this.allocation.projectId,
    } as IRequestAllocationByMonth;

    this.date.month = this.filterByMonth.month;
    this.date.year = this.filterByMonth.year;
    this.monthSelected = true;

    this.changeMonth.emit(this.filterByMonth);
    this.searchType = EFilterAllocationOption.Mes;
  }

  changePeriod(nextIndex: number): void {
    if (this.searchType === EFilterAllocationOption.Total) {
      if (
        (this.activeIndex === this.minIndex && nextIndex < 0) ||
        (this.activeIndex === this.maxIndex && nextIndex > 0)
      )
        return;

      this.activeIndex += nextIndex;

      this.sendAllocationPercentageEvent(EFilterAllocationOption.Total);
      this.setActiveYears();
    } else {
      let newDate = isNextMonthWithinRangeByFlag(
        this.date,
        this.period.initialDate,
        this.period.finalDate,
        nextIndex
      );

      this.date = newDate.date;

      if (nextIndex === 1) {
        this.limitReachedRight = newDate.limitReached;
        this.limitReachedLeft = false;
      } else {
        this.limitReachedLeft = newDate.limitReached;
        this.limitReachedRight = false;
      }

      this.sendAllocationPercentageEvent(EFilterAllocationOption.Mes);
      this.weeksPerMonth = getWeeksOfMonth(this.date);
    }
  }

  setActiveYears(): void {
    this.activeYears = getYearString(this.groupedMonths[this.activeIndex]);
  }

  setLimitReachedMonths(): void {
    this.limitReachedLeft = isNextMonthWithinRangeByFilter(
      this.date,
      this.period.initialDate,
      this.period.finalDate,
      -1
    ).limitReached;

    this.limitReachedRight = isNextMonthWithinRangeByFilter(
      this.date,
      this.period.initialDate,
      this.period.finalDate,
      1
    ).limitReached;
  }

  sendAllocationPercentageEvent(type: EFilterAllocationOption): void {
    switch (type) {
      case EFilterAllocationOption.Mes:
        this._allocationService.getAllocationPercentageEvent.next({
          period: { month: this.date.month, year: this.date.year },
          type: EFilterAllocationOption.Mes,
        });
        break;

      case EFilterAllocationOption.Total:
        this._allocationService.getAllocationPercentageEvent.next({
          period: this.groupedMonths[this.activeIndex],
          type: EFilterAllocationOption.Total,
        });
        break;
    }
  }
}
