
<div class="p-horizontal-xl">
  <div class=" modalConfirme">
    <div class="titleModal">
  <h4>{{ data.message }}</h4>
</div>
  <div  mat-dialog-actions>
    <button
      class="btn btn--width-xs m-2xl secondary"
      mat-button
      (click)="close()"
    >
      Não
    </button>
    <button
      class="btn btn--width-xs m-2xl primary"
      mat-button
      [mat-dialog-close]="true"
      cdkFocusInitial
    >
      {{ data.buttonTxt }}
    </button>
  </div>
</div>
</div>

