<div class="modal-dialog-close">
  <div class="p-horizontal-xl">
    <h2>{{ data.message }}</h2>
    <form [formGroup]="formRefuse" (ngSubmit)="confirmInactivation()">
      <div class="col align-center">
        <div class="">
          <mat-form-field
            class="input-outline input--width-xlx-tertiary m-vertical-lg"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Motivo</mat-label>
            <textarea
              maxlength="120"
              minlength="5"
              matInput
              placeholder="Digite o motivo aqui..."
              formControlName="reason"
            ></textarea>
          </mat-form-field>
        </div>
        <button type="submit" class="btn no-width primary m-vertical-lg" type="submit" [disabled]="!isValidReason()" (click)="submitForm()">
          {{ data.buttonTxt }}
        </button>
      </div>
    </form>
  </div>
  <div class="position">
    <button  class="position btn-icon-close" (click)="close()">
      <img src="./assets/svg/close-button.svg" alt="" />
    </button>
  </div>
</div>
