import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
})
export class LoadComponent implements OnInit {

  @Input() isLogin: boolean = false;
 
  constructor(public loader: LoaderService) { }

  ngOnInit(): void {
  }

}
