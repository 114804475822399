import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-permisions',
  templateUrl: './permisions.component.html',
  styles: [
  ]
})
export class PermisionsComponent implements OnInit {

  currentRoute!: string;
  tabSelected: number = 0;
  constructor(
    private _router: Router,
    private location: Location ) {
      this.currentRoute = this._router.url.substring(
        this._router.url.lastIndexOf('/') + 1
      );
      this.changeTab();
     }

  ngOnInit(): void {
  }
  changeTab() {
    if (this.currentRoute === 'cadastrar') this.tabSelected = 0;
    if (this.currentRoute === 'gerenciar') this.tabSelected = 1;
  }

  changeURLparams(event: any){
    this.tabSelected = event.index;
    this.tabSelected === 0?
    this.location.replaceState(`/permissoes/cadastrar` as any):
    this.location.replaceState(`/permissoes/gerenciar` as any);
    const path  = (this.location as any).path();
    this.currentRoute = path.substring(path.lastIndexOf('/') + 1);
  }
}
