<div class="app">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      #sidenav
      [mode]="menuMode"
      [opened]="menuOpened"
      class="mat-sidenav"
      [fixedInViewport]="true"
      [fixedTopGap]="0"
      [fixedBottomGap]="0"
    >
      <div class="sidenav-header">
        <div class="logo-container">
          <img
            class="logo-img p-md"
            src="./assets/img/logo-menu.png"
            alt="logo"
          />
          <img
            class="menu-icon p-xl"
            (click)="sidenav.toggle()"
            src="./assets/icons/menu.png"
            alt=""
          />
        </div>
        <div class="profile wave profile-ctn">
          <div class="profile-title p-sm">{{ nameUser }}<br /></div>
          <div class="profile-photo">
            <img [src]="imageSrc | safeResourceUrl" alt="" />
          </div>
        </div>
      </div>
      <div class="sidenav--menu">
        <ng-container *ngIf="roles && roles.length > 0">
          <app-sidenav [items]="menu"></app-sidenav>
        </ng-container>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="sidenav-ctn">
        <div class="navbar">
          <div
            [ngClass]="{ 'invisible-menu': sidenav.opened === true }"
            class="menu-toggle p-sm m-md"
          >
            <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
          </div>
          <div class="navbar--toggle">
            <mat-button-toggle-group
              [formControl]="pathControl"
              class="button-group"
              name="fontStyle"
              aria-label="Font Style"
            >
              <ng-container *ngIf="roles && roles.length > 0">
                <ng-template
                  permissions
                  [role]="'Read'"
                  [process]="'Collaborator'"
                >
                  <mat-button-toggle class="m-xs" [value]="'colaboradores'"
                    >Colaboradores</mat-button-toggle
                  >
                </ng-template>
                <ng-template permissions [role]="'Read'" [process]="'Tower'">
                  <mat-button-toggle class="m-xs" [value]="'torres-e-equipes'"
                    >Torres e Equipes</mat-button-toggle
                  >
                </ng-template>
                <ng-template permissions [role]="'Read'" [process]="'Skills'">
                  <mat-button-toggle class="m-xs" [value]="'habilidades'"
                    >Habilidades</mat-button-toggle
                  >
                </ng-template>
                <ng-template permissions [role]="'Read'" [process]="'Client'">
                  <mat-button-toggle
                    class="m-xs"
                    [value]="'clientes-e-projetos'"
                    >Clientes e Projetos</mat-button-toggle
                  >
                </ng-template>
                <ng-template
                  permissions
                  [role]="'Read'"
                  [process]="'Allocation'"
                >
                  <mat-button-toggle class="m-xs" [value]="'alocacao'"
                    >Alocação</mat-button-toggle
                  >
                </ng-template>
              </ng-container>
            </mat-button-toggle-group>
          </div>
          <div class="row justify-center">
            <button class="btn-no-border" (click)="logout()">
              <mat-icon>input</mat-icon>
              Sair
            </button>
          </div>
        </div>
        <div fxLayout="row" fxFlex="100">
          <app-load></app-load>
          <ng-container *ngIf="roles && roles.length > 0">
            <router-outlet></router-outlet>
          </ng-container>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
