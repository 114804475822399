import { Component, OnDestroy, OnInit } from '@angular/core';
import { ITower } from '../../interface/tower.interface';
import { MatTableDataSource } from '@angular/material/table';
import { SubSink } from 'subsink';
import { TowerService } from '../../repository/tower.service';
import { MatDialog } from '@angular/material/dialog';
import { ITeam } from '../../interface/team.inteface';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { TowerManageModalComponent } from '../../modal/tower-manage-modal/tower-manage-modal.component';
import { TeamManageModalComponent } from '../../modal/team-manage-modal/team-manage-modal.component';
import { IDefaultFilter } from 'src/app/shared/models/filter.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-tower-team-list',
  templateUrl: './page-tower-team-list.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PageTowerTeamListComponent implements OnInit, OnDestroy {
  expandedElement: ITower | null | undefined;

  pageTitle: string = 'Torres e equipes';
  btnText1: string = 'Cadastrar torre';
  btnText2: string = 'Cadastrar equipe';
  pageSizeOptions: Array<number> = [5, 10, 20];
  countDataSource: number = 0;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'icon',
    'description',
    'collaborator',
    'status',
  ];
  towers: ITower[] = [];

  pageSize: number = 20;
  filter: IDefaultFilter = {
    page: 1,
    take: 20,
    searchString: '',
  };

  private _subs = new SubSink();

  constructor(
    private _towerService: TowerService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getTowerOptions();
    this.getTowers();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  getTowerOptions() {
    this._subs.add(
      this._towerService.getAllTowerOptions().subscribe((res: any) => {
        if (res.success) {
          this.towers = res.result ?? [];
        }
      })
    );
  }

  getTowers(resetPage: boolean = true) {
    if (resetPage) {
      this.filter.page = 1;
      this.countDataSource = 0;
    }

    this._subs.add(
      this._towerService.getAllTowers(this.filter).subscribe((res: any) => {

        this.countDataSource = res.count;
        this.getDataSource(res.result);
      })
    );
  }

  getDataSource(res: any) {
    let arr: any[] = [];
    if (res) {
          res.map((tower: ITower) => {
        const prepareDataSource = {
          id: tower.id,
          description: tower.description,
          status: tower.inactive,
          teams: tower.teams,
        };
        arr.push(prepareDataSource);
      });
      this.dataSource.data = arr;
    }
  }

  openDialogTower(tower?: ITower) {
    let dialogRef;

    if (tower) {
      dialogRef = this.dialog.open(TowerManageModalComponent, {
        data: { id: tower.id, description: tower.description },
        panelClass: 'modal-dialog-close-type',
      });
    } else {
      dialogRef = this.dialog.open(TowerManageModalComponent, {
        panelClass: 'modal-dialog-close-type',
      });
    }

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.reload === true) this.getTowers();
    });
  }

  openDialogTeam(team?: ITeam) {
    let dialogRef;

    if (team) {
        dialogRef = this.dialog.open(TeamManageModalComponent, {
        data: { team: team, towers: this.towers },
        panelClass: 'modal-dialog-close-type',
      });
    } else {
      dialogRef = this.dialog.open(TeamManageModalComponent, {
        data: { towers: this.towers },
        panelClass: 'modal-dialog-close-type',
      });
    }

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.reload === true) this.getTowers();
    });
  }

  inactivateTeam(event: any, tower: ITower) {}

  skipPage(event: { skip: number }) {
    this.filter.page = event.skip + 1;
    this.getTowers(false);
  }

  changeItemsPerPage(items: number) {
    this.filter.page = 1;
    this.filter.take = items;

    this.getTowers();
  }

  teamDetail(teamsId: string) {
    this.router.navigate(['/detalhes-equipe', teamsId]);
  }
}
