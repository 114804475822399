import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class ProfilePermissionsService {

  endpoint: string = 'AccessProfilePermission';

  constructor(public http: HttpClient) { }

  getPermission(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(map((response: IApiResponse) => response.result));
  }

  postPermission(profileType: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.post<any>(url, profileType);
  }

  putPermission(profileType: any): Observable<any> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.put<any>(url, profileType);
  }

  getAccessPermission(): Observable<any> {
    const path = 'Permission';
    return this.http
      .get<any>(`${environment.apiUrl}/${this.endpoint}/${path}`)
      .pipe(map((response: IApiResponse) => response.result));
  }
}
