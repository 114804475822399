import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styles: [],
})
export class MessageDialogComponent implements OnInit {

  icon = {
    error: "error_outline",
    success: "check",
    warn: "warning",
    info: "info",
  }
  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: string;
      type: 'success' | 'error' | 'warn' | 'info';
      duration?: number;
    }
  ) {}

  ngOnInit(): void {
    if (this.data.duration) {
      setTimeout(() => {
        this.close();
      }, this.data.duration);
    }else{
      setTimeout(() => {
        this.close();
      }, 3000);
    }
  }
  close(): void {
    this.dialogRef.close();
  }
}
