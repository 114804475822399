<div class="p-top-lg">
  <div>
    <table
      matSort
      mat-table
      [dataSource]="dataSource"
      class="table-primary table-clickable"
    >
      <ng-container
        matColumnDef="{{ column.key }}"
        *ngFor="let column of columns | keyvalue"
      >
        <ng-container>
          <th mat-header-cell *matHeaderCellDef>
            <div class="alinhar-icon">
              <ng-container *ngIf="column.key !== 'redirect'">
                {{ column.value }}
              </ng-container>
              <ng-container
                *ngIf="
                  column.key == 'collaboratorName' || column.key == 'hours'
                "
              >
                <mat-icon (click)="changeOrder(orderOptions[column.key])"
                  ><img src="./assets/svg/orderBy.svg" alt=""
                /></mat-icon>
              </ng-container>
            </div>
          </th>
        </ng-container>
        <ng-container>
          <td
            mat-cell
            *matCellDef="let element; let index = index"
            #elRow
            [ngClass]="{ 'td-img': column.key == 'collaboratorName' }"
          >
            <ng-container *ngIf="column.key == 'collaboratorName'">
              <div class="img-ctn">
                <div class="img-table flex justify-center align-center">
                  <img
                    *ngIf="element.collaboratorImage"
                    [src]="element.collaboratorImage | safeResourceUrl"
                    alt=""
                  />
                  <img
                    *ngIf="!element.collaboratorImage"
                    src="./assets/img/account_circle.png"
                    alt=""
                  />
                </div>
              </div>
            </ng-container>

            <div
              *ngIf="
                column.key !== 'allocationsValue' &&
                column.key !== 'redirect' &&
                column.key !== 'skills' &&
                column.key !== 'hours'
              "
              [class]="'row-' + index"
            >
              {{ element[column.key] }}
            </div>
            <div *ngIf="column.key === 'redirect'">
              <div
                class="mat-column-status"
                (click)="
                  updateCollaboratorAllocation(
                    element.collaboratorId,
                    element['allocable']
                  )
                "
              >
                <mat-icon
                  ><img width="32px" src="./assets/svg/edit.svg" alt=""
                /></mat-icon>
              </div>
            </div>

            <div *ngIf="column.key === 'hours'">
              <span [ngClass]="getStatusClass(element.allocationPercentage)">
                {{ element[column.key] }}</span
              >
              <span class="status-disabled">
                {{ element.workingHoursPerPeriod }}h
              </span>
            </div>

            <div *ngIf="column.key === 'skills'">
              <span
                matTooltipClass="skills-tooltip"
                [matTooltipPosition]="'above'"
                matTooltip="{{ getSkillsTooltip(element.skills)}} "
              >
                {{ getSkills(element.skills) }}
              </span>
            </div>
          </td>
        </ng-container>
      </ng-container>
      <ng-container matColumnDef="disclaimer">
        <td mat-header-cell *matHeaderCellDef colspan="1">
          <mat-select
            (selectionChange)="changeItemsPerPage($event.value)"
            class="rounded input--width-sm m-md"
            [(ngModel)]="pageSize"
          >
            <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
              {{ option }} itens por página
            </mat-option>
          </mat-select>
        </td>
      </ng-container>
      <tr mat-footer-row *matHeaderRowDef="['disclaimer']"></tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr> -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <ng-container>
      <div class="has-content">
        <mat-card *ngIf="!hasAllocationCollaborator">
          Sem colaboradores na equipe.
        </mat-card>
      </div>
    </ng-container>
    <app-paginator
      *ngIf="countDataSource > filter.take"
      [length]="countDataSource"
      [pageSize]="filter.take"
      (skipPage)="skipPage($event)"
    ></app-paginator>
  </div>
</div>
