import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

export class CnpjValidators {
  public static cnpjValid(): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof FormControl)) return;
      const docNumber = control.value.replace(/[^\d]+/g, '');

      if (docNumber.length == 14) {
        if (docNumber == '00000000000000') return { NotValid: true };

        let docLength = docNumber.length - 2;
        let numbers = docNumber.substring(0, docLength);
        let checkDigits = docNumber.substring(docLength);
        let sum = 0;
        let pos = docLength - 7;

        for (let i = docLength; i >= 1; i--) {
          sum += numbers.charAt(docLength - i) * pos--;
          if (pos < 2) pos = 9;
        }

        let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        if (result != checkDigits.charAt(0)) return { NotValid: true };

        docLength = docLength + 1;
        numbers = docNumber.substring(0, docLength);
        sum = 0;
        pos = docLength - 7;

        for (let i = docLength; i >= 1; i--) {
          sum += numbers.charAt(docLength - i) * pos--;
          if (pos < 2) pos = 9;
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        if (result != checkDigits.charAt(1)) return { NotValid: true };
        return null;
      } else {
        return { NotValid: true };
      }
    };
  }
}
