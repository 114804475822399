import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from 'src/app/core/guards/localstorage';
import { permissionType, process } from '../../interfaces/roles.interface';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styles: [
  ]
})

export class SidenavComponent {

  @Input() items: SidenavItem[] = new Array<SidenavItem>;

  @Input() selectedItem: SidenavItem = new SidenavItem;

  @Output() sidenavClick: EventEmitter<SidenavItem> = new EventEmitter<SidenavItem>();
  localStorage = new LocalStorage();
  
  constructor(private router: Router) {
  }

  onClickSidenavItem(item: SidenavItem) {
    const { path, onClick } = item;
    if (path) {
      path === 'meu-perfil-detalhes'?
      this.router.navigate([path, this.localStorage.collaboratorId]):
      this.router.navigate([path]);
    }

    if (onClick instanceof Function) {
      onClick(item);
    }

    this.sidenavClick.emit(item);
  }
}

export class SidenavItem {
  id?: number;
  icon?: string;
  name?: string;
  process!: process;
  permission!: permissionType;
  path?: string;
  children?: SidenavItem[];
  onClick?: Function;
  data?: any;
}
