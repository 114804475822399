import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICollaboratorAllocationsRequest, ICollaboratorAllocationsResponse } from 'src/app/shared/models/collaborator-allocation.model';

import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorAllocationService {
  constructor(private http: HttpClient) {}

  getCollaboratorAllocations(
    requestData: ICollaboratorAllocationsRequest
  ): Observable<ICollaboratorAllocationsResponse> {
    return this.http.post<ICollaboratorAllocationsResponse>(
      `${environment.apiUrl}/${environment.microservices.collaborator.url}/${environment.microservices.collaborator.controllers.alocations}`,
      requestData
    );
  }
}
