export interface IClient {
  id: string;
  name: string;
  documentRegistration?: string;
  projectsCount?: number;
  inactive?: boolean;
  forAbsence?: boolean;
  internationalCustomer?: boolean;
  country?:string;

}

export interface IClientFilter {
  searchString: string | null;
  inactive: boolean | null;
  page: number;
  take: number;
  orderByParam: {
    orderBy: number | null;
    asc: boolean | null;
  };
}

export enum OrderByClient {
  NAME = 1,
  PROJECT_COUNT = 2,
  COUNTRY  = 3,
}

