import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { ClientService } from '../../repository/client.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/core/services/message.service';
import { IClient } from '../../interface/client.interface';
import { StringUtil } from 'src/app/shared/utils/string';
import { CnpjValidators } from 'src/app/shared/validators/cnpj.validator';

@Component({
  selector: 'app-client-manage-modal',
  templateUrl: './client-manage-modal.component.html',
  styles: [],
})
export class ClientManageModalComponent implements OnInit {
  clientForm!: FormGroup;
  private _subs = new SubSink();
  private _client: IClient | undefined;
  isEdit: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private _clientService: ClientService,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<ClientManageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.createFormGroup();
    this.setValueForm();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  createFormGroup() {
    this.clientForm = this._fb.group({
      documentRegistration:[''],
      name:  ['', Validators.required],
      internationalCustomer: [false],
      country: [''],
      id: '',
    });

    this.clientForm.get('documentRegistration')?.valueChanges.subscribe(() => {
      this.updateDocumentRegistrationValidator();
    });
    this.clientForm.get('internationalCustomer')?.valueChanges.subscribe((value) => {
      const countryControl = this.clientForm.get('country');
      const documentControl = this.clientForm.get('documentRegistration');
      if (value) {
        countryControl?.setValidators(Validators.required);
        documentControl?.clearValidators();
        documentControl?.reset();
        countryControl?.reset();
      } else {
        countryControl?.clearValidators();
        documentControl?.reset();
        this.updateDocumentRegistrationValidator();
      }
      countryControl?.updateValueAndValidity();
      documentControl?.updateValueAndValidity();;
      });
  }

  updateDocumentRegistrationValidator() {
    const documentControl = this.clientForm.get('documentRegistration');
    const currentValue = documentControl?.value;
    if (currentValue && currentValue.trim() !== '') {
      documentControl?.setValidators(this.cnpjValidator());
    } else {
      documentControl?.clearValidators();
    }
    documentControl?.updateValueAndValidity();
  }

  cnpjValidator() {
    return (control: any) => {
      if (!this.clientForm.get('internationalCustomer')?.value === true) {
        return CnpjValidators.cnpjValid()(control);
      }
      return null;
    };
  }

  onSubmitClient() {
    const newClient = this.clientForm.value;
    const client: IClient = {
      documentRegistration: newClient.documentRegistration ? newClient.documentRegistration : "",
      name: StringUtil.capitalize(newClient.name),
      country: StringUtil.capitalize(
        newClient.internationalCustomer ? newClient.country : 'Brasil'
      ),
      id: newClient.id ? newClient.id : undefined,
      internationalCustomer: newClient.internationalCustomer,
    };
    if (!this.isEdit) {
      delete newClient.id;
      this._subs.add(
        this._clientService.postClient(client).subscribe((response) => {
          if (response.success === true) {
            this._messageService.openSuccessMessage(
              'Cadastro realizado com sucesso.'
            );
          } else if (response.errors.length > 0) {
            this._messageService.openErrorMessage(response.errors);
          }
          this.close({ reload: true });
        })
      );
    } else {
      this._subs.add(
        this._clientService.putClient(client).subscribe((response) => {
          if (response.success === true) {
            this._messageService.openSuccessMessage(
              'Cadastro alterado com sucesso.'
            );
          } else if (response.errors.length > 0) {
            this._messageService.openErrorMessage(response.errors);
          }
          this.close({ reload: true });
        })
      );
    }
  }
  setValueForm() {
    if (this.data.client) {
      const client = this.data.client;
      this.isEdit = true;
      this.clientForm.patchValue({
        name: client.name,
        internationalCustomer: client.internationalCustomer,
        country: client.country,
        id: client.id,
      });
      if (this.clientForm.contains('documentRegistration')) {
        this.clientForm
          .get('documentRegistration')
          ?.patchValue(client.documentRegistration);
      }
    } else {
      this.isEdit = false;
    }
  }

  close(emit = { reload: false }): void {
    this.dialogRef.close(emit);
  }
}
