import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageTowerTeamListComponent } from './pages/page-tower-team-list/page-tower-team-list.component';
import { AccountGuard } from 'src/app/core/guards/account.guard';
import { PermissionGuard } from 'src/app/core/guards/permission.guard';
import { TeamsDetailComponent } from './pages/detail/containers/teams-detail/teams-detail.component';

const routes: Routes = [
  {
    path: 'torres-e-equipes',
    component: PageTowerTeamListComponent,
    canActivate: [PermissionGuard],
    data: {
      process: 'Tower',
      permission: 'Read',
    },
  },
  {
    path: 'detalhes-equipe/:teamId',
    component: TeamsDetailComponent,
    canActivate: [PermissionGuard],
    data: {
      process: 'Tower',
      permission: 'Read',
    },
  },
  {
    path: 'colaboradores-equipe',
    component: TeamsDetailComponent,
    canActivate: [PermissionGuard],
    data: {
      process: 'Tower',
      permission: 'Read',
    },
  },
  {
    path: 'projetos-alocacoes',
    component: TeamsDetailComponent,
    canActivate: [PermissionGuard],
    data: {
      process: 'Tower',
      permission: 'Read',
    },
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TowerTeamsRoutingModule { }
