  <section class="row schedule-ctn">
    <div class="period">
      <app-period-view-project-allocation
        [allocation]="allocationTotal"
        [searchType]="filterSelected"
        (changeMonth)="changeMonth($event)"
      >
      </app-period-view-project-allocation>
    </div>
    <div class="collaborator">
      <app-collaborator-view-allocation
        [projectId]="allocationTotal.projectId"
        [collaborators]="allocationTotal.allocationWithCollaborators"
      ></app-collaborator-view-allocation>
    </div>
  </section>
