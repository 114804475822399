import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageManageCollaboratorComponent } from './pages/page-manage-collaborator/page-manage-collaborator.component';
import { CollaboratorListComponent } from './pages/page-collaborator-list/components/collaborator-list/collaborator-list.component';
import { PageDetailComponent } from './pages/page-detail/containers/page-detail/page-detail.component';
import { PermissionGuard } from 'src/app/core/guards/permission.guard';
import { PageRegistrationCollaboratorComponent } from './pages/page-registration-collaborator/page-registration-collaborator.component';
import { ProfileGuard } from 'src/app/core/guards/profile.guard';
import {
  EPermissionType,
  EProcess,
} from 'src/app/shared/interfaces/roles.interface';
import { RouterInative } from 'src/app/core/guards/routerInative.guard';

const routes: Routes = [
  {
    path: 'colaboradores',
    component: CollaboratorListComponent,
    canActivate: [PermissionGuard],
    data: {
      process: EProcess.Collaborator,
      permission: EPermissionType.Read,
    },
  },
  {
    path: 'gerenciar-colaborador',
    component: PageManageCollaboratorComponent,
    canActivate: [PermissionGuard],
    data: {
      process: [EProcess.CollaboratorPersonalData, EProcess.CollaboratorAddress, EProcess.CollaboratorContractualinformation, EProcess.CollaboratorSkills],
      permission: EPermissionType.CreateUpdate,
    },
  },
  {
    path: 'gerenciar-colaborador/:collaboratorId',
    component: PageManageCollaboratorComponent,
    canActivate: [PermissionGuard, RouterInative],
    data: {
      process: [EProcess.CollaboratorPersonalData, EProcess.CollaboratorAddress, EProcess.CollaboratorContractualinformation, EProcess.CollaboratorSkills],
      permission: EPermissionType.CreateUpdate,
    },
  },
  {
    path: 'meu-perfil/:collaboratorId',
    component: PageManageCollaboratorComponent,
    canActivate: [ProfileGuard],
  },
  {
    path: 'meu-perfil-detalhes/:collaboratorId',
    component: PageDetailComponent,
    canActivate: [ProfileGuard],
  },
  {
    path: 'meu-perfil-habilidades/:collaboratorId',
    component: PageDetailComponent,
    canActivate: [ProfileGuard],
  },
  {
    path: 'meu-perfil-descanso/:collaboratorId',
    component: PageDetailComponent,
    canActivate: [ProfileGuard],
  },
  {
    path: 'detalhes-colaborador/:collaboratorId',
    component: PageDetailComponent,
    canActivate: [PermissionGuard],
    data: {
      process: [EProcess.CollaboratorPersonalData, EProcess.CollaboratorAddress, EProcess.CollaboratorContractualinformation],
      permission: EPermissionType.Read,
    },
  },
  {
    path: 'habilidades-colaborador/:collaboratorId',
    component: PageDetailComponent,
    canActivate: [PermissionGuard],
    data: {
      process: [ EProcess.CollaboratorSkills],
      permission: EPermissionType.Read,
    },
  },
  {
    path: 'cadastrar-colaborador',
    component: PageRegistrationCollaboratorComponent,
    canActivate: [PermissionGuard],
    data: {
      process: EProcess.Collaborator,
      permission: EPermissionType.CreateUpdate,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CollaboratorRoutingModule {}

export const collaboratorRouting: ModuleWithProviders<CollaboratorRoutingModule> =
  RouterModule.forChild(routes);
