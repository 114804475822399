import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorage } from './localstorage';
import { MessageService } from '../services/message.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileGuard implements CanActivate {
  localStorage = new LocalStorage();
  constructor(private router: Router,
    private _messageService: MessageService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Observable<boolean | UrlTree>((observer) => {
        let canNavigate = false;
        if(next.params['collaboratorId'] != this.localStorage.collaboratorId){
            this._messageService.openErrorMessage([
                'Acesso não autorizado',
              ]);
              setTimeout(() => {
                this.router.navigate(['meu-perfil-detalhes/', this.localStorage.collaboratorId]);
              }, 2000);
        } else if (next.params['collaboratorId'] === this.localStorage.collaboratorId){
            canNavigate = true
        }
      observer.next(canNavigate);
      observer.complete();
    });
  }
}
