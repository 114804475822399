import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog-loan',
  templateUrl: './confirm-dialog-loan.component.html',
  styleUrls: []
})
export class ConfirmDialogLoanComponent implements OnInit {
  formRefuse!: FormGroup;
  constructor(
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmDialogLoanComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      buttonTxt: string;
      title: string;
      message: string;
      }
  ) {}

  ngOnInit() {
    this.createFormGroup();
  }
  createFormGroup() {
    this.formRefuse = this._fb.group({
      reason: [''],
    });
  }
  confirmInactivation() {
     const reason = this.formRefuse.value.reason;
    let formData = {
      reason: reason,
    };
  }

submitForm() {
    const reason = this.formRefuse.value.reason;
    let formData = {
      reason: reason,
    };
    this.dialogRef.close(formData);
  }


  isValidReason(): boolean {
    const refusalReason = this.formRefuse.get('reason')?.value;
     return (
      refusalReason &&
      refusalReason.length >= 5 &&
      refusalReason.length <= 120
    );
  }
  close(): void {
    this.dialogRef.close();
  }
}
