import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styles: [],
})
export class ModalErrorComponent implements OnInit {

  icon = {
    error: "error_outline",
    success: "check",
    warn: "warning",
    info: "info",
  }

  constructor(
    public dialogRef: MatDialogRef<ModalErrorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title:string,
      message: string,
      type: 'success' | 'error' | 'warn' | 'info';
      duration?: number;
     }
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }

}
