<section>
  <ng-container *ngFor="let item of collaboratorsAllocation">
    <div class="allocation-row flex align-center">
      <div class="user-info flex align-center justify-start">
        <div class="img-ctn">
          <img
            *ngIf="item.imageURL"
            [src]="item.imageURL | safeResourceUrl"
            alt=""
          />
          <div *ngIf="!item.imageURL" class="flex justify-center align-center">
            {{ getinitialsNameCollaborator(item.collaboratorName!) }}
          </div>
        </div>
        <div class="text-ctn flex justify-center"
        matTooltipClass='info-tooltip'
        [matTooltipPosition]="'right'"
        matTooltip= "{{ item.collaboratorName}} - {{item.jobFunction}}"
       >
          <span class="truncate" >{{ item.collaboratorName }}</span>
          <span class="truncate" >{{ item.jobFunction }}</span>

      </div>
      </div>
      <div class="allocation-bar">
        <div
          *ngIf="item.nonWorkedLeft !== 0"
          name="nonWorkedLeft"
          [ngStyle]="{
            width: item.nonWorkedLeft + '%'
          }"
        ></div>
        <div
          *ngIf="item.worked !== 0"
          name="worked"
          class="flex align-center worked"
          [ngStyle]="{
            width: item.worked + '%'
          }"
        >
          <app-allocation-bar
            class="bar-ctn"
            [percentage]="item.percentage"
            [isReservation]="item.isAbsence"
            (viewCollaborator)="viewCollaborator(item)"
          ></app-allocation-bar>
        </div>
        <div
          *ngIf="item.nonWorkedRight !== 0"
          name="nonWorkedRight"
          [ngStyle]="{
            width: item.nonWorkedRight + '%'
          }"
        ></div>
      </div>
    </div>
  </ng-container>
</section>
