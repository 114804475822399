<div class="content">
  <div class="row page-header space-between p-vertical-xl">
    <div class="col page-title m-sm">
      <h1>{{ pageTitle }}</h1>
    </div>

    <div class="row align-center input-filter-collaborator">
      <mat-form-field
        class="rounded search-button flex-1 max-width-350"
        appearance="fill"
        floatLabel="always"
      >
        <input
          placeholder="Faça uma busca"
          type="text"
          matInput
          [formControl]="searchByNameCtrl"
          (keyup.enter)="searchBy()"
        />
        <mat-icon matPrefix>search</mat-icon>
        <mat-icon
          title="Limpar pesquisa"
          *ngIf="searchByNameCtrl?.value?.length >= 1"
          (click)="clearFilter()"
          matSuffix
          >backspace</mat-icon
        >
        <button
          matSuffix
          class="btn primary btn--width-xxs"
          (click)="searchBy()"
        >
          Buscar
        </button>
      </mat-form-field>
    </div>

    <ng-template permissions [role]="'Create/Update'" [process]="'Skills'">
      <div class="flex flex-1 justify-end">
        <button
          class="btn secondary btn--width-xxxs m-sm"
          (click)="openDialogSkillType()"
        >
          {{ btnText1 }}
        </button>
        <button
          class="btn secondary btn--width-xxxs m-sm"
          (click)="openDialogSkill()"
        >
          {{ btnText2 }}
        </button>
      </div>
    </ng-template>
  </div>

  <div class="m-sm table-expansive primary">
    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      class="table-primary"
    >
      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of displayedColumns"
      >
        <td
          mat-cell
          *matCellDef="let element; let index = index"
          class="cursor-pointer"
        >
          <ng-container *ngIf="column != 'status'">
            {{ element[column] }}
          </ng-container>
          <ng-container *ngIf="column == 'status'">
            <ng-template
              permissions
              [role]="'Create/Update'"
              [process]="'Skills'"
            >
              <mat-icon (click)="openDialogSkillType(element)"
                ><img src="./assets/svg/edit-white.svg" alt=""
              /></mat-icon>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="column == 'icon'">
            <div *ngIf="element.skills.length > 0">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            class="element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <ng-container>
              <div
                class="element-description col"
                *ngFor="let skill of element.skills"
              >
                <div
                  class="row"
                  [ngClass]="{ inactive: skill.inactive === true }"
                >
                  <div class="col">{{ skill.description }}</div>
                  <div class="mat-column-status">
                    <ng-template permissions [role]="'Delete'" [process]="'Skills'">
                      <mat-slide-toggle
                        [(ngModel)]="!skill.inactive"
                        (change)="inactivateSkill($event, skill)"
                        class="switch"
                      ></mat-slide-toggle>
                    </ng-template>
                  </div>
                  <ng-template
                    permissions
                    [role]="'Create/Update'"
                    [process]="'Skills'"
                  >
                    <div class="mat-column-status">
                      <mat-icon
                        (click)="openDialogSkill(skill)"
                        class="cursor-pointer"
                        ><img src="./assets/svg/edit-black.svg" alt=""
                      /></mat-icon>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="disclaimer">
        <td mat-header-cell *matHeaderCellDef colspan="1">
          <mat-select
            (selectionChange)="changeItemsPerPage($event.value)"
            class="rounded input--width-sm m-md"
            [(ngModel)]="pageSize"
          >
            <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
              {{ option }} itens por página
            </mat-option>
          </mat-select>
        </td>
      </ng-container>
      <tr mat-footer-row *matHeaderRowDef="['disclaimer']"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="element-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
      ></tr>
    </table>

    <app-paginator
      *ngIf="countDataSource > filter.take"
      [length]="countDataSource"
      [pageSize]="filter.take"
      (skipPage)="skipPage($event)"
    ></app-paginator>
  </div>
</div>
