import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import {
  IProjectAllocation,
  IRequestAllocationByMonth,
} from 'src/app/pages/allocation/interface/project-allocation.interface';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import { EFilterAllocationOption } from 'src/app/pages/collaborator/enum/period-alocation.enum';

@Component({
  selector: 'app-body-view-project-allocation',
  templateUrl: './body-view-project-allocation.component.html',
})
export class BodyViewProjectAllocationComponent implements OnChanges {
  @Input() filterSelected!: EFilterAllocationOption;
  @Input() allocationTotal!: IProjectAllocation;
  @Input() filterByMonth!: IRequestAllocationByMonth;
  @Input() projectId!: string;

  allocationByMonth!: IProjectAllocation;
  allocationActive!: IProjectAllocation;

  constructor(private _allocationService: AllocationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filterSelected']) {
      if (this.filterSelected === EFilterAllocationOption.Total) {
        this.allocationActive = this.allocationTotal;
      } else {
        if (!!this.allocationByMonth) {
          this.allocationActive = this.allocationByMonth;
        }
      }
    }
  }

  getCurrentMonthAndYear(): IRequestAllocationByMonth {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    return { month, year, projectId: this.projectId };
  }

  changeMonth(filter: IRequestAllocationByMonth): void {
    if (filter) {
      this._allocationService.changePeriodFilterEvent.next(EFilterAllocationOption.Mes);
      this.filterByMonth = filter;
    }
  }
}
