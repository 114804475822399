import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollaboratorRoutingModule } from './collaborator-routing.module';
import { PageManageCollaboratorComponent } from './pages/page-manage-collaborator/page-manage-collaborator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMaskModule } from 'ngx-mask';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { CollaboratorListComponent } from './pages/page-collaborator-list/components/collaborator-list/collaborator-list.component';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { PageDetailComponent } from './pages/page-detail/containers/page-detail/page-detail.component';
import { ProfileComponent } from './pages/page-detail/components/profile/profile.component';
import {MatCardModule} from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DirectiveModule } from 'src/app/core/directives/directives.module';
import { PageRegistrationCollaboratorComponent } from './pages/page-registration-collaborator/page-registration-collaborator.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CollaboratorSkillsComponent } from './pages/page-detail/components/collaborator-skills/collaborator-skills.component';
import { AddCollaboratorSkillComponent } from './modal/add-collaborator-skill/add-collaborator-skill.component';
import { CollaboratorRestComponent } from './pages/page-detail/components/collaborator-rest/collaborator-rest.component';
import { CollaboratorAllocationsComponent } from './pages/page-detail/components/collaborator-allocations/collaborator-allocations.component';
import { InactivateCollaboratorSkillModalComponent } from './modal/inactivate-collaborator-skill-modal/inactivate-collaborator-skill-modal.component';
@NgModule({
  declarations: [
    PageManageCollaboratorComponent,
    CollaboratorListComponent,
    PageDetailComponent,
    ProfileComponent,
    PageRegistrationCollaboratorComponent,
    CollaboratorSkillsComponent,
    AddCollaboratorSkillComponent,
    CollaboratorRestComponent,
    CollaboratorAllocationsComponent,
    InactivateCollaboratorSkillModalComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    CollaboratorRoutingModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTabsModule,
    MatCardModule,
    MatTooltipModule,
    MatSnackBarModule,
    NgxMaskModule.forRoot(),
    ComponentsModule,
    PipesModule,
    ImageCropperModule,
    DirectiveModule,
    MatDatepickerModule
  ],
})
export class CollaboratorModule {}
