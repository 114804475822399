import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PermissionRoutingModule } from './permission-routing.module';
import { CreateAccessProfileComponent } from './pages/components/create-access-profile/create-access-profile.component';
import { PermisionsComponent } from './pages/container/permisions/permisions.component';
import { ManageAccessProfileComponent } from './pages/components/manage-access-profile/manage-access-profile.component';
import { ManageAccessProfileTableComponent } from './pages/components/manage-access-profile/manage-access-profile-table/manage-access-profile-table.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxMaskModule } from 'ngx-mask';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { ProfileManageModalComponent } from './modal/profile-manage-modal/profile-manage-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DirectiveModule } from 'src/app/core/directives/directives.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [
    ManageAccessProfileComponent,
    CreateAccessProfileComponent,
    PermisionsComponent,
    ProfileManageModalComponent,
    ManageAccessProfileTableComponent,
  ],

  imports: [
    CommonModule,
    PermissionRoutingModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatTabsModule,
    FormsModule,
    ComponentsModule,
    DirectiveModule,
    NgxMaskModule,
    PipesModule,
  ],
})
export class PermissionModule {}
