import { IInputOptions } from 'src/app/shared/interfaces/input-select.interface';

export const FILTER_PERIOD: IInputOptions[] = [
  {
    id: '0',
    name: 'Semana',
  },
  {
    id: '1',
    name: 'Quinzena',
  },
  {
    id: '2',
    name: 'Mês',
  },
];
