import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { environment } from 'src/environments/environment.development';
import { IProject } from '../interface/project.interface';
import { Project } from 'src/app/shared/models/project.model';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import {
  IProjectFilter,
  IProjectMinified,
} from '../interface/datasource-project.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends BaseApiService {
  endpoint: string = 'Project';

  constructor(
    public override http: HttpClient,
  ) {
    super(http);
  }

  getAllbyProjects() {
    return this.get<IProject[]>(this.endpoint);
  }

  getAllProjectsByFilter(
    filter: IProjectFilter
  ): Observable<IApiResponse<IProjectMinified[]>> {
    const path = 'ListByFiltersAsync';
    return this.http.post<IApiResponse<IProjectMinified[]>>(
      `${environment.apiUrl}/${this.endpoint}/${path}`,
      filter
    );
  }

  getProjectById(idProject: string): Observable<Project> {
    return this.http
      .get<IApiResponse>(`${environment.apiUrl}/${this.endpoint}/${idProject}`)
      .pipe(map((response: IApiResponse) => response.result));
  }

  getListSelectProject() {
    const path = 'ProjectObjects';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    return this.http
      .get<IApiResponse>(url)
      .pipe(map((response: IApiResponse) => response.result));
  }
  postProject(project: Project): Observable<IApiResponse> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.post<IApiResponse>(url, project);
  }

  putProject(project: Project): Observable<IApiResponse> {
    const url = `${environment.apiUrl}/${this.endpoint}`;
    return this.http.put<IApiResponse>(url, project);
  }

  inactivateProject(idProject?: string): Observable<IApiResponse> {
    const path = 'inactivate';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}/${idProject}`;
    return this.http.delete<any>(url);
  }
}
