import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { TowerService } from '../../repository/tower.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/core/services/message.service';
import { StringUtil } from 'src/app/shared/utils/string';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tower-manage-modal',
  templateUrl: './tower-manage-modal.component.html',
  styles: [],
})
export class TowerManageModalComponent implements OnInit {
  private _id: string | undefined;
  private _subs = new SubSink();

  towerForm!: FormGroup;

  isEdit: boolean = false;

  constructor(
    private _router: Router,
    private _fb: FormBuilder,
    private _towerService: TowerService,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<TowerManageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.createFormGroup();
    try {
      const tower = this.data;
      this._id = tower.id;
      this.isEdit = true;
      this.towerForm.patchValue({
        description: tower.description,
        id: tower.id,
      });
    } catch (error) {
      this.isEdit = false;
    }
  }
  createFormGroup() {
    this.towerForm = this._fb.group({
      description: ['', Validators.required],
      id: '',
    });
  }

  onSubmitTower() {
    const newTower = this.towerForm.value;
    newTower.description = StringUtil.capitalize(newTower.description);
    if (!this.isEdit) {
      delete newTower.id;
      this._towerService.postTower(newTower).subscribe((response) => {
        if (response.success === true) {
          this._messageService.openSuccessMessage(
            'Cadastro realizado com sucesso.'
          );
          this.reloadComponent();
        } else if (response.errors.length > 0) {
          this._messageService.openErrorMessage(response.errors);
        }
        this.close({reload: true});
      });
    } else {
      this._subs.add(
        this._towerService.putTower(newTower).subscribe((response) => {
          if (response.success === true) {
            this._messageService.openSuccessMessage(
              'Cadastro alterado com sucesso.'
            );
            this.reloadComponent();
          } else if (response.errors.length > 0) {
            this._messageService.openErrorMessage(response.errors);
          }
          this.close({reload: true});
        })
      );
    }
  }

  close(emit = { reload: false }): void {
    this.dialogRef.close(emit);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  reloadComponent() {
    const url = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([`/${url}`]);
    });
  }

}
