import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

import { AccountService } from './pages/account/repository/account.service';
import { MessageService } from './core/services/message.service';
import { environment } from 'src/environments/environment.development';
import { UserRoleService } from './pages/account/repository/user-role.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [],
})
export class AppComponent implements OnInit {
  title = 'MRP';

  constructor(
    private authService: MsalService,
    private _accountService: AccountService,
    private _messageService: MessageService,
    private _router: Router,
    private _userRoleService: UserRoleService
  ) {}

  ngOnInit(): void {
    this.getRoles();

    this._accountService.changeDisableLogin.next(true);

    this.authService.handleRedirectObservable().subscribe({
      next: (response) => {
        if (response !== null && response.account !== null) {
          this.authService.instance.setActiveAccount(response.account);

          let token = response.accessToken;

          this._accountService.loginByToken(token).subscribe({
            next: (response) => {
              if (response.success) {
                this.handleLogin(response.result);
              } else {
                this.loginFailed();
              }
            },
            error: (error) => {
              this.loginFailed();
            },
          });
        }
      },
      error: (error) => {
        this.loginFailed();
      },
    });
  }

  getRoles() {
    let token = this._accountService.localStorage.getTokenUser();

    if (token) {
      this._userRoleService.getPermissionsByUser().subscribe((data) => {
        let roles = data.profileProcessPermissions ?? [];
        let tokenData = data.token;

        if (roles) {
          this._userRoleService.roles = roles;

          if (tokenData) window.localStorage.setItem('user.token', tokenData);

          this._userRoleService.changeUserRoles.next(true);
        }
      });
    }
  }

  handleLogin(result: any) {
    this._accountService.localStorage.saveTokenUser(
      result.accessToken,
      result.user.name,
      result.user.collaboratorID,
    );
    this._userRoleService.profile = result.user.accessProfile.name;
    this._messageService.openSuccessMessage('Olá, ' + result.user.name);

    let resgistrationCompleted: boolean = result.user.registrationCompleted;

    if (resgistrationCompleted) {
      this._router.navigate([
        'meu-perfil-detalhes',
        result.user.collaboratorID,
      ]);
    } else {
      this._router.navigate(['meu-perfil', result.user.collaboratorID]);
    }
  }

  loginFailed() {
    this._accountService.changeDisableLogin.next(true);

    this._messageService
      .openErrorMessageDefault(
        'Sem permissão para acessar a plataforma, entre em contato com um administrador!',
        5000
      )
      .subscribe((response) => {
        this.logoutAzure();
      });
  }

  logoutAzure() {
    const logoutRequest = {
      postLogoutRedirectUri: environment.azureConfig.logoutRedirectUri,
    };

    this._accountService.localStorage.clearLocalDataUser();
    this.authService.logoutRedirect(logoutRequest);
  }
}
