import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-inactivate-collaborator-skill-modal',
  templateUrl: './inactivate-collaborator-skill-modal.component.html',
  styles: [
  ]
})
export class InactivateCollaboratorSkillModalComponent implements OnInit {
  reasonInactivation = new FormControl('');

  constructor(public dialogRef: MatDialogRef<InactivateCollaboratorSkillModalComponent>) { }

  ngOnInit(): void {
  }
  
  confirmSubmit() {
    this.close({ reload: true, reason: this.reasonInactivation.value});
  }

  close(emit = { reload: false, reason : this.reasonInactivation.value}): void {
    this.dialogRef.close(emit);
  }

}
