import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientProjectComponent } from './pages/container/client-project/client-project.component';
import { PageManageProjectComponent } from './pages/components/projects/page-manage-project/page-manage-project.component';
import { PermissionGuard } from 'src/app/core/guards/permission.guard';

const routes: Routes = [
  {
    path: 'clientes-e-projetos',
    redirectTo: 'clientes-e-projetos/clientes',
  },
  {
    path: 'clientes-e-projetos/clientes',
    component: ClientProjectComponent,
    canActivate: [PermissionGuard],
    data: {
      process: 'Client',
      permission: 'Read',
    },
  },
  {
    path: 'clientes-e-projetos/projetos',
    component: ClientProjectComponent,
    canActivate: [PermissionGuard],
    data: {
      process: 'Project',
      permission: 'Read',
    },
  },
  {
    path: 'gerenciar-projeto',
    component: PageManageProjectComponent,
    canActivate: [PermissionGuard],
    data: {
      process: 'Project',
      permission: 'Create/Update',
    },
  },
  {
    path: 'gerenciar-projeto/:projectId',
    component: PageManageProjectComponent,
    canActivate: [PermissionGuard],
    data: {
      process: 'Project',
      permission: 'Create/Update',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientsProjectsRoutingModule {}
