import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';

import { Router } from '@angular/router';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  takeUntil,
} from 'rxjs';
import { ICollaboratorAllocation } from 'src/app/pages/allocation/interface/collaborator-allocation.interface';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import { EstatusAllocationEnum } from 'src/app/pages/collaborator/enum/status-alocation.enum';

import { IGenericData, IOptionsCollaborator } from 'src/app/pages/collaborator/interface/options-collaborator.interface';
import { CollaboratorService } from 'src/app/pages/collaborator/repository/collaborator.service';
import { FilterCollaboratorAllocation } from 'src/app/shared/models/filter.model';
import { COLUMNS_ALLOCATION_COLLABORATOR } from 'src/app/shared/models/table-columns/columns-allocation-collaborator';
import { SubSink } from 'subsink';
import { FILTER_PERIOD } from './period-select-options.const';
import { ForrmatDateService } from 'src/app/core/services/forrmat-date.service';
import { MatTooltip } from '@angular/material/tooltip';
import { LevelSkillsEnum } from 'src/app/pages/collaborator/enum/level-skills.enum';
import { AllocationFilterService, EFilterType } from 'src/app/core/services/filters/allocation-filter.service';

interface ISelectType {
  key: number;
  value: string;
}
@Component({
  selector: 'app-page-allocation-collaborator-list',
  templateUrl: './page-allocation-collaborator-list.component.html',
  styles: [],
})



export class PageAllocationCollaboratorListComponent
  implements OnInit, OnDestroy {


  statusSelectOptions: ISelectType[] = [];
  optionSelected!: string;
  periodSelectOptions = FILTER_PERIOD;
  collaborator$!: Observable<ICollaboratorAllocation[]>;
  optionsFilter$!: Observable<IOptionsCollaborator>;
  optionsFilter!: IOptionsCollaborator;
  pageSizeOptions: Array<number> = [5, 10, 20];
  itemsPerPage: number = 20;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  filterForm!: FormGroup;
  filterConfig: FilterCollaboratorAllocation = this.filterService.getFilter(EFilterType.COLLABORATOR);
  levelSelectOptions: ISelectType[] = [
    { key: 0, value: 'Básico' },
    { key: 1, value: 'Intermediário' },
    { key: 2, value: 'Avançado' },
  ];
  filterKey: Array<string> = [
    'jobFunctionId',
    'seniorityId',
    'skillId',
    'towerId',
    'skillLevel',
    'levelId',
    'leaderId',
    'period',
    'startDate',
    'endDate',
  ];
  searchByNameCtrl: FormControl = new FormControl();
  period: FormControl = new FormControl();
  columns = COLUMNS_ALLOCATION_COLLABORATOR;
  displayedColumns: string[] = [];
  selection = new SelectionModel<ICollaboratorAllocation>(true, []);
  chipsValue!: any;
  chipsFormControl: Array<string> = this.filterService.getChipsFormControl(EFilterType.COLLABORATOR);
  chipsList: Array<any> = this.filterService.getChipsList(EFilterType.COLLABORATOR);
  allocableCollaborator: Array<boolean> = [];
  countDataSource: number = 0;
  private _subs = new SubSink();
  private unsubscribe$ = new Subject<void>();
  @ViewChild(MatTooltip) tooltip!: MatTooltip;

  jobFunctions: IGenericData[] = [];
  skills: IGenericData[] = [];
  leaders: IGenericData[] = [];
  towers: IGenericData[] = [];

  preventValueChange = false;

  constructor(
    private _collaboratorService: CollaboratorService,
    private _allocationService: AllocationService,
    public dialog: MatDialog,
    private _fb: FormBuilder,
    private _router: Router,
    private _dateFormatService: ForrmatDateService,
    private filterService: AllocationFilterService
  ) {
    this.displayedColumns = Object.keys(this.columns);
  }
  ngOnInit(): void {
    this.searchByNameCtrl.setValue(this.filterConfig.searchString);
    // this.getTotalFiltered(); --> Removido para evitar chamadas duplicadas, como tem subscribe no form e toda vez que abre a tela muda o valor do form, não precisa desse
    this.createFormGroup();
    this.getSelectOptions();
    this.changeFilter();
    this.getStatusAllocation();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mostrarTooltip() {
    this.tooltip.position = 'above';
    this.tooltip.show();
  }

  editMultipleAllocation() {
    const ids = this.selection.selected.flatMap(
      (selection) => selection.collaboratorId
    );

    this._router.navigate(['/alocacao/gerenciar-alocacao/colaborador'], {
      state: { ids },
    });
  }

  updateCollaboratorAllocation(collaboratorId: string, allocable: boolean) {
    this._router.navigate([
      '/alocacao/gerenciar-alocacao/colaborador/',
      collaboratorId,
      true,
    ]);
  }

  changeChipList(
    event: any = null,
    controlName: string,
    value?: any
  ): void {    
    if (!event.isUserInput) {
      return;
    }

    setTimeout(() => {
      this.filterForm.controls[controlName].setValue(value, { emitEvent: false });
      this.handleFilterChange(this.filterForm.value);
    }, 300);
  }

  changeFilter() {
      this.filterForm.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {        
        this.handleFilterChange(this.filterForm.value);
      });
  }

  handleFilterChange(filterValue: any): void {
    let lastFilterValue = this.filterConfig;

    if (JSON.stringify(filterValue) === JSON.stringify(lastFilterValue)) {
      return;
    }

    lastFilterValue = { ...filterValue };
    this.dataSource.filter = '';
    Object.keys(filterValue).forEach((key) => {
      const value = filterValue[key];
      if (value == null) {
        delete filterValue[key];
      } else if (key === 'statusAllocation') {
        this.filterConfig.filterChange(key, this.getStatusAllocation());
      } else if (key === 'skillLevel') {
        if (!(this.chipsList.some((chip) => chip.id === value.id))) {
          this.filterConfig.filterChange(key, value);
          this.replaceFilterValueChip(value, key);
        }
      } else if (key !== 'startDate' && key !== 'endDate' && key !== 'periodId' && value) {
        if (!(this.chipsList.some((chip) => chip.id === value.id))) {
          this.filterConfig.filterChange(key, value);
          this.addFilterValueToChips(value, key);
        }
      }
    });
    if (filterValue) {
      this.filterConfig.endDate = filterValue.endDate || null;
      this.filterConfig.startDate = filterValue.startDate || null;
      this.getTotalFiltered();
    }
  }

  getTotalFiltered() {
    this.filterService.setChipsFormControl(this.chipsFormControl, EFilterType.COLLABORATOR);
    this.filterService.setFilter(this.filterConfig, EFilterType.COLLABORATOR);
    this.filterService.setChipsList(this.chipsList, EFilterType.COLLABORATOR);
    this._allocationService
      .getAllByCollaboratorFilter(this.filterConfig)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res && res.result && res.result.length > 0) {
            this.getDataSource(res.result);
            this.countDataSource = res._count;
          } else {
            this.clearTableData();
          }
        },
        (error) => {
          console.error('Erro ao obter dados filtrados:', error);
          this.clearTableData();
        }
      );
  }

  clearTableData() {
    this.dataSource.data = [];
    this.countDataSource = 0;
  }

  startDateSelection() {
    this.filterConfig.getWeek(this.filterForm.controls['startDate'].value);
    this.filterForm.controls['startDate'].setValue(this.filterConfig.startDate);
  }

  getSelectOptions() {
    this.optionsFilter$ = this._collaboratorService.getCollaboratorSelect();
    this._subs.add(
      this.optionsFilter$.subscribe((options: IOptionsCollaborator) => {
        this.optionsFilter = options;
        this.sortOptions();
        this.setFormValues();
        this.jobFunctions = [...this.optionsFilter.jobFunctions];
        this.skills = [...this.optionsFilter.skills];
        this.leaders = [...this.optionsFilter.leaders] as IGenericData[];
        this.towers = [...this.optionsFilter.towers];
      })
    );
  }

  private sortOptions() {
    if (this.optionsFilter && typeof this.optionsFilter === 'object') {
      const propertyMap: { [key: string]: string } = {
        contractTypes: 'description',
        jobFunctions: 'description',
        teams: 'description',
        senioritys: 'description',
        towers: 'description',
        skills: 'description',
        skillLevel: 'description',
        accessProfile: 'name',
        leaders: 'name',
        cities: 'description'
      };
  
      Object.keys(this.optionsFilter).forEach((key) => {
        const value = this.optionsFilter[key];
        if (Array.isArray(value) && propertyMap[key]) {
          this.sortByProperty(value, propertyMap[key]);
        }
      });
    }
  }
  
  private sortByProperty(array: any[], property: string) {
    array.sort((a, b) => {
      const propA = a[property] ? a[property].toString() : '';
      const propB = b[property] ? b[property].toString() : '';
      return propA.localeCompare(propB);
    });
  }

  searchBy() {
    this.filterConfig.page = 1;
    this.filterConfig.searchString = this.searchByNameCtrl.value;
    this.getTotalFiltered();
  }

  clearFilter() {
    this.filterConfig.searchString = '';
    this.searchByNameCtrl.reset();
    this.getTotalFiltered();
  }

  createFormGroup() {
    this.filterForm = this._fb.group({
      jobFunctionId: null,
      seniorityId: null,
      skillId: null,
      statusAllocation: null,
      towerId: null,
      skillLevel: null,
      leaderId: null,
      levelId: null,
      periodId: null,
      optionSelected: null,
      startDate: this.filterConfig.startDate,
      endDate: this.filterConfig.endDate,
    });

    this.filterForm.setControl('jobFunctionId', new FormControl(null, { updateOn: 'submit' }));
    this.filterForm.setControl('leaderId', new FormControl(null, { updateOn: 'submit' }));
    this.filterForm.setControl('skillId', new FormControl(null, { updateOn: 'submit' }));
    this.filterForm.setControl('towerId', new FormControl(null, { updateOn: 'submit' }));

    this.period.setValue(this.periodSelectOptions[0].name);
  }

  fillDate(dateString: string | undefined) {
    if (dateString) {
      const date = new Date(dateString);
      let day = date.getDate().toString();
      let month = (date.getMonth() + 1).toString();
      if (date.getDate() < 10) day = day.substr(0, 0) + '0' + day.substr(0);
      if (date.getMonth() < 9)
        month = month.substr(0, 0) + '0' + month.substr(0);
      return `${date.getFullYear()}-${month}-${day}`;
    }
    return null;
  }

  addFilterValueToChips(filterValue: any, formName: string) {
    this.chipsFormControl.push(formName);
    this.chipsList.push(filterValue);
  }

  replaceFilterValueChip(filterValue: any, formName: string) {
    if (this.chipsFormControl.includes(formName)) {
      const chipIndex = this.chipsFormControl.indexOf(formName);
      this.chipsList.splice(chipIndex, 1, filterValue);
    } else
      this.addFilterValueToChips(filterValue, formName);
  }

  removeFilterValueChips(chip: any) {
    const chipIndex = this.chipsList.indexOf(chip);
    this.chipsList.splice(chipIndex, 1);
    Object.keys(this.filterForm.value).forEach((controlName) => {
      if (this.chipsFormControl.at(chipIndex) === controlName) {
        controlName === 'skillLevel' ?
          this.filterConfig.removeskillLevel() :
          this.filterConfig.cleanFilter(controlName, chip);
          
        this.filterForm.controls[controlName].setValue(null, { emitEvent: false });
      }
    });
    this.chipsFormControl.splice(chipIndex, 1);
    this.resetAutocompleteFilters();
  }

  removeAllChips() {
    this.filterConfig.removeAll();
    this.chipsList = [];
    this.chipsFormControl = [];
    this.searchByNameCtrl.reset();
    this.filterForm.patchValue({
      statusAllocation: '',
      jobFunctionId: null,
      seniorityId: null,
      skillId: null,
      skillLevel: null,
      levelId: null,
      leaderId: null,
      towerId: null,
      periodId: null,
      startDate: this.filterConfig.startDate,
      endDate: this.filterConfig.endDate,
    });
    this.resetAutocompleteFilters();
  }

  resetAutocompleteFilters() {
    this.jobFunctions = [...this.optionsFilter.jobFunctions];
    this.skills = [...this.optionsFilter.skills];
    this.leaders = [...this.optionsFilter.leaders] as IGenericData[];
    this.towers = [...this.optionsFilter.towers];
  }

  getDataSource(res: any) {
    let arr: any[] = [];
    let allocable: boolean[] = [];
    try {
      res.map((val: any) => {
        const workingPercentageNumber = parseFloat(val.workingPercentage);
        const prepareDataSource = {
          collaboratorId: val.collaboratorId,
          fullName: val.fullName,
          jobFunction: val?.jobFunction,
          seniority: val?.seniority,
          leader: val?.leader,
          hours: `(${val.workingPercentage}) ${val.hours}/ `,
          workingHoursPerPeriod: val.journey,
          overAllocated: val.overAllocated,
          workingPercentage: workingPercentageNumber,
          allocable: val.allocable,
          absence: val.absence,
        };
        arr.push(prepareDataSource);
        if (val.allocable) allocable.push(true);
      });
    } catch (error) {
      console.error(error);
      this.getSelectOptions();
    }
    this.allocableCollaborator = allocable;
    this.dataSource.data = arr;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.allocableCollaborator.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected() || this.selection.selected.length > 0) {
      this.selection.clear();
      return;
    }
    this.dataSource.data.forEach((row) => {
      if (row) this.selection.select(row);
    });
  }

  checkboxLabel(row?: ICollaboratorAllocation): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${1}`;
  }

  getStatusAllocation(): any {
    this.createSelectOptions();
    return this.filterForm.controls['statusAllocation'].value;
  }

  createSelectOptions() {
    this.statusSelectOptions = Object.entries(EstatusAllocationEnum)
      .filter(([key, value]) => !isNaN(Number(key))) // Filtra apenas as entradas numéricas
      .map(([key, value]) => ({ key: Number(key), value: value as string }));
  }

  onPeriodChange(event: MatSelectChange) {
    this.filterConfig.page = 1;
    const selectedPeriod = event.value;
    this.filterConfig.periodId = this.periodSelectOptions.find(
      (option) => option.name === selectedPeriod
      )?.id;
    this._dateFormatService.setSelectedPeriod(selectedPeriod);
    if (selectedPeriod === 'Semana') {
      this.filterForm.patchValue({
        startDate: this.getMonday(new Date()),
        endDate: this.getFriday(new Date()),
      });
    } else if (selectedPeriod === 'Mês') {
      const firstDayOfMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      );
      this.filterForm.patchValue({
        startDate: firstDayOfMonth,
        endDate: lastDayOfMonth,
      });
      this.filterConfig.startDate = firstDayOfMonth.toISOString();
      this.filterConfig.endDate = lastDayOfMonth.toISOString();
      this.filterForm.controls['startDate'].setValue(firstDayOfMonth);
      this.filterForm.controls['endDate'].setValue(lastDayOfMonth);
    } else if (selectedPeriod === 'Quinzena') {
      const currentDate = new Date();
      const endDate = new Date(
        currentDate.getTime() + 20 * 24 * 60 * 60 * 1000
      );
      this.filterForm.patchValue({
        startDate: currentDate,
        endDate: endDate,
      });
      this.filterConfig.startDate = currentDate.toISOString();
      this.filterConfig.endDate = endDate.toISOString();
      this.filterForm.controls['startDate'].setValue(currentDate);
      this.filterForm.controls['endDate'].setValue(endDate);
    }
  }

  getMonday(date: Date) {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

  getFriday(date: Date) {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 5);
    return new Date(date.setDate(diff));
  }

  getOrderByValue(key: string): number {
    switch (key) {
      case 'fullName':
        return 1;
      case 'seniority':
        return 2;
      case 'leader':
        return 3;
      case 'jobFunction':
        return 4;
      default:
        return 0;
    }
  }

  ordenar(orderBy: number) {
    if (this.filterConfig.orderByParam.orderBy === orderBy) {
      this.filterConfig.orderByParam.asc = !this.filterConfig.orderByParam.asc;
    } else {
      this.filterConfig.orderByParam.orderBy = orderBy;
      this.filterConfig.orderByParam.asc = true;
    }
    this.getTotalFiltered();
  }

  changeItemsPerPage(event: number) {
    this.filterConfig.changeTake(event);
    this.getTotalFiltered();
  }

  skipPage(event: { skip: number }) {
    this.filterConfig.skipPage(event);
    this.getTotalFiltered();
  }

  getStatusClass(workingPercentage: number | null): object {
    return {
      'status-low': workingPercentage !== null && workingPercentage <= 50,
      'status-possibility':
        workingPercentage !== null &&
        workingPercentage > 50 &&
        workingPercentage <= 99,
      'status-ideal': workingPercentage === 100,
      'status-exceeded': workingPercentage !== null && workingPercentage > 100,
    };
  }

  getStatusColor(status: number) {
    switch (status) {
      case EstatusAllocationEnum.Todos:
        return 'black';
      case EstatusAllocationEnum['Desalocado (0% a 50%)']:
        return '#E74C3C';
      case EstatusAllocationEnum['Alocável (51% a 99%)']:
        return '#F1C40F';
      case EstatusAllocationEnum['Alocado (100%)']:
        return '#2ECC71';
      case EstatusAllocationEnum['Super alocado (>100%)']:
        return '#8E44AD';
      case EstatusAllocationEnum['Pré-venda']:
        return 'black';
      case EstatusAllocationEnum['Ausência']:
        return 'black';
      default:
        return '';
    }
  }

  getStatusEnumName(statusValue: number): string {
    return EstatusAllocationEnum[statusValue];
  }

  setFormValues() {
    // Definir os valores do formulário após o carregamento do optionsFilter
    this.filterForm.patchValue({
      jobFunctionId: this.optionsFilter?.jobFunctions.find(
        (option) => option.id === this.filterConfig.jobFunctionId[0]
      ) || null,
      seniorityId: this.optionsFilter?.senioritys.find(
        (option) => option.id === this.filterConfig.seniorityId[0]
      ) || null,
      skillId: this.optionsFilter?.skills.find(
        (option) => option.id === this.filterConfig.skillId[0]
      ) || null,
      statusAllocation: this.statusSelectOptions.find(
        (option) => option.key === this.filterConfig.statusAllocation
      )?.key || null,
      towerId: this.optionsFilter?.towers.find(
        (option) => option.id === this.filterConfig.towerId[0]
      ) || null,
      skillLevel: this.levelSelectOptions.find(
        (option) => option.key === this.filterConfig.skillLevel
      ) || null,
      leaderId: this.optionsFilter?.leaders.find(
        (option) => option.id === this.filterConfig.leaderId[0]
      ) || null,
      periodId: this.periodSelectOptions.find(
        (option) => option.id === this.filterConfig.periodId
      ) || null,
    });
    this.period.setValue(this.periodSelectOptions.find(
      (option) => option.id === this.filterConfig.periodId
    )?.name || this.periodSelectOptions[0].name)
  }

  onFilterSelected() {
    this.filterConfig.page = 1;
  }

  displayFn(control: string, option: any): string {        
    if(!this.optionsFilter) {
      return '';
    }
    
    return option ? (option?.description ?? option?.name) : '';
  }

  filterAutocomplete(controlName: string, target: any) {
    let value = target?.value ?? '';
    
    switch(controlName) {
      case 'jobFunctionId':
        this.jobFunctions = this.optionsFilter.jobFunctions.filter((option) => option.description?.toLowerCase().includes(value?.trim()?.toLowerCase()));
        break;
      case 'skillId':
        this.skills = this.optionsFilter.skills.filter((option) => option.description?.toLowerCase().includes(value?.trim()?.toLowerCase()));
        break;
      case 'leaderId':
        this.leaders = this.optionsFilter.leaders.filter((option) => option.name?.toLowerCase().includes(value?.trim()?.toLowerCase())) as IGenericData[];
        break;
      case 'towerId':
        this.towers = this.optionsFilter.towers.filter((option) => option.description?.toLowerCase().includes(value?.trim()?.toLowerCase()));
        break;
      default:
        break
    }
  }
}
