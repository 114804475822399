<div class="col position-relative">
  <mat-tab-group
    animationDuration="0ms"
    class="tab-page"
    [selectedIndex]="tabSelected"
    (selectedTabChange)="changeURLparams($event)"
  >
    <mat-tab label="Visualizar por colaborador">
      <app-page-allocation-collaborator-list
        *ngIf="tabSelected === 0"
      ></app-page-allocation-collaborator-list>
    </mat-tab>
    <mat-tab label="Visualizar por projeto">
      <app-page-allocation-project-list
        *ngIf="tabSelected === 1"
      ></app-page-allocation-project-list>
    </mat-tab>
    <mat-tab  *ngIf=" role === eRoleUser.TribeHead || role === eRoleUser.ADM  " label="Visualizar empréstimos">
     <app-lending-view
     *ngIf="tabSelected === 2"
     ></app-lending-view>

    </mat-tab>
  </mat-tab-group>
  <ng-template permissions [role]="'Create/Update'" [process]="'Allocation'">
    <div *ngIf="tabSelected != 2" class="row justify-end position-absolut right top">
      <button
        class="btn secondary btn--width-xxxs p-horizontal-md m-horizontal-md s-vertical-xs"
        (click)="openAllocationModal()"
      >
        Cadastrar alocação
      </button>
    </div>
  </ng-template>
</div>
