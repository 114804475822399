<div class="row page-header space-between p-lg">
  <div class="col page-title bold">
    <h2>Perfis de acesso</h2>
  </div>
  <button
    class="btn secondary btn--width-sm m-2xl"
    (click)="openDialogProfile()"
  >
    Cadastrar perfil
  </button>
</div>

<div class="m-xl table-expansive primary">
  <table
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows
    class="table-primary"
  >
    <ng-container
      matColumnDef="{{ column }}"
      *ngFor="let column of displayedColumns"
    >
      <td
        (click)="getId(element.id)"
        mat-cell
        *matCellDef="let element; let index = index"
      >
        <ng-container *ngIf="column === 'description'">
          {{ element.name }}
        </ng-container>
        <ng-container *ngIf="column === 'status'">
          <mat-icon (click)="openEdit(element.id)" class="icon"
            ><img src="./assets/svg/edit-white.svg" alt=""
          /></mat-icon>
        </ng-container>
        <ng-container *ngIf="column === 'edits'">
          <mat-slide-toggle
            [(ngModel)]="!element.status"
            (click)="$event.stopPropagation()"
            (change)="inactivateProfile($event, element)"
            class="switch"
          ></mat-slide-toggle>
        </ng-container>
        <ng-container
          *ngIf="column === 'icon'"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 8px;
          "
        >
          <mat-icon>keyboard_arrow_down</mat-icon>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="displayedColumns.length"
      >
        <div
          class="element-detail"
          [@detailExpand]="
            element == expandedElement
              ? element.status
                ? 'collapsed'
                : 'expanded'
              : 'collapsed'
          "
        >
          <ng-container>
            <div
              class="element-description col"
              *ngFor="let permission of pemissions; let i = index"
            >
              <ng-container
                [formGroup]="getFormControl(formPermissions, element.id)"
              >
                <div class="row checkbox-permission">
                  <mat-checkbox
                    [checked]="checkAll(permission, element.id)"
                    (change)="setAll($event.checked, permission, element.id)"
                    class="checkbox m-horizontal-xs bold"
                  >
                    {{ permission.process }}
                  </mat-checkbox>

                  <ng-container
                    [formGroup]="
                      getFormControl(
                        getFormControl(formPermissions, element.id),
                        permission.process
                      )
                    "
                  >
                    <div *ngFor="let subPermission of permission.permissions">
                      <mat-checkbox
                        [formControlName]="subPermission.id"
                        class="checkbox m-horizontal-xs"
                        [checked]="subPermission.permission"
                      >
                        {{ subPermission.name }}
                      </mat-checkbox>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <div class="row m-xs p-xs justify-end">
              <button
                (click)="cancelProfileCreation()"
                class="btn secondary m-md"
                type="button"
              >
                Cancelar
              </button>
              <button
                (click)="onSubmitProfilePemission(element.id)"
                type="button"
                class="btn primary m-md"
              >
                Salvar
              </button>
            </div>
          </ng-container>
        </div>
      </td>
    </ng-container>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="element-row"
      [class.expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="detail-row"
    ></tr>
  </table>

  <!-- <app-paginator
  *ngIf="countDataSource > filterConfig.take"
  [length]="countDataSource"
  [pageSize]="filterConfig.take"
  (skipPage)="skipPage($event)"
></app-paginator> -->
</div>
