import { jwtDecode } from 'jwt-decode';
import { Subject } from 'rxjs';

export class LocalStorage {
  changeNameUser = new Subject<string>();

  public saveTokenUser(
    token: string,
    userName: string,
    collaboratorId: string,
   ) {
    window.localStorage.setItem('user.token', token);
    window.localStorage.setItem('user.name', userName);
    window.localStorage.setItem('user.collaboratorId', collaboratorId);
  }
  public saveTokenUserSession(
    token: string,
    userName: string,
    collaboratorId: string,
   ) {
    window.sessionStorage.setItem('user.token', token);
    window.sessionStorage.setItem('user.name', userName);
    window.sessionStorage.setItem('user.collaboratorId', collaboratorId);
  }
  public getTokenUser() {
    return (
      window.localStorage.getItem('user.token') ||
      window.sessionStorage.getItem('user.token')
    );
  }

  public getNameUser(): string {
    return (
      window.localStorage.getItem('user.name') ||
      (window.sessionStorage.getItem('user.name') as string)
    );
  }

  public setNameUser(name: string) {
    window.localStorage.setItem('user.name', name);
    this.changeNameUser.next(name);
  }

  public get collaboratorId() {
    return (
      localStorage.getItem('user.collaboratorId') ||
      (window.sessionStorage.getItem('user.collaboratorId') as string)
    );
  }


  decodeToken() {
  const token = this.getTokenUser();
  if (!token) return null;
  const decodedToken = jwtDecode(token);
  return decodedToken;
}

  tokenIsValid(): boolean {
    const token = this.decodeToken();
    if (token) {
      return true;
    } else {
      this.clearLocalDataUser();
      return false;
    }
  }

  public clearLocalDataUser() {
    window.localStorage.removeItem('user.token');
    window.localStorage.removeItem('user.user');
    window.localStorage.removeItem('user.name');
    window.localStorage.removeItem('user.collaboratorId');
    window.sessionStorage.removeItem('user.token');
    window.sessionStorage.removeItem('user.user');
    window.sessionStorage.removeItem('user.name');
    window.sessionStorage.removeItem('user.collaboratorId');
  }

  public getProfile() {
    const decodedToken = this.decodeToken() as any;
     if (!decodedToken || !decodedToken.AccessProfileCode) return null;
     return parseInt(decodedToken.AccessProfileCode);
  }
}
