export enum EstatusAllocationEnum {

  'Todos' = 0,
  'Desalocado (0% a 50%)' = 1,
  'Alocável (51% a 99%)' = 2,
  'Alocado (100%)' = 3,
  'Super alocado (>100%)' = 4,
  'Pré-venda' = 5,
  'Ausência' = 6,
}

export enum EstatusDescansoEnum {

  'Em análise' = 0,
  'Coletivo' = 1,
  'Aprovado' = 2,
  'Recusado' = 3,

}

