import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import {
  ICollaborator,
  ICollaboratorFilter,
  ICollaboratorMinified,
} from '../interface/collaborator.interface';
import { environment } from 'src/environments/environment.development';
import { IApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { LocalStorage } from 'src/app/core/guards/localstorage';
import { ICollaboratorAllocation } from '../../allocation/interface/collaborator-allocation.interface';
import { ICollaboratorProfile } from '../interface/collaborator-profile.interface';
import { ICollaboratorRestlFilter } from '../interface/collaborator-rest-filter.interface';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorService extends BaseApiService {
  endpoint: string = 'Collaborator';
  localStorage = new LocalStorage();

  constructor(
    public override http: HttpClient,
  ) {
    super(http);
  }

  getCollaboratorSelect(): Observable<any> {
    const path = 'ListSelectsItens';
    return this.http
      .get<any>(`${environment.apiUrl}/${this.endpoint}/${path}`)
      .pipe(map((response: IApiResponse) => response.result));
  }

  getCollaboratorById(idCollaborator: string): Observable<ICollaborator> {
    return this.http
      .get<any>(`${environment.apiUrl}/${this.endpoint}/${idCollaborator}`)
      .pipe(map((response: IApiResponse) => response.result));
  }

  getAllCollaborators(): Observable<ICollaborator[]> {
    return this.get<ICollaborator[]>(this.endpoint);
  }

  getAllCollaboratorsAllocation(): Observable<ICollaboratorAllocation[]> {
    const path = 'ListCollaboratorsAllocationsAsync';
    return this.get<ICollaboratorAllocation[]>(this.endpoint, path);
  }

  getCollaboratorAllocationById(
    collaboratorId: string
  ): Observable<ICollaboratorAllocation> {
    const path = 'GetAllocationsCollaboratorId';
    return this.get<ICollaboratorAllocation>(
      this.endpoint,
      path,
      collaboratorId
    );
  }

  getAllCollaboratorsByFilter(
    filter: ICollaboratorFilter
  ): Observable<IApiResponse<ICollaboratorMinified[]>> {
    const path = 'ListByFiltersAsync';
    return this.http.post<IApiResponse<ICollaboratorMinified[]>>(
      `${environment.apiUrl}/${this.endpoint}/${path}`,
      filter
    );
  }

  postCollaborator(collaborator: ICollaborator): Observable<any> {
    const path = 'Collaborator';
    const url = `${environment.apiUrl}/${path}`;

    return this.http.post<ICollaborator>(url, collaborator);
  }

  putCollaborator(collaborator: ICollaborator): Observable<any> {
    const path = 'Collaborator';
    const url = `${environment.apiUrl}/${path}`;

    return this.http.put<ICollaborator>(url, collaborator);
  }

  inactivateCollaborator(idCollaborator: string) {
    const path = 'inactivate';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}/${idCollaborator}`;
    return this.http.delete<any>(url);
  }

  collaboratorInactivate(collaboratorId: string,  exclusionReason: string,  exclusionDate: Date) {
    const path = 'inactivate';
    const url = `${environment.apiUrl}/${this.endpoint}/${path}`;
    const body = {
      exclusionReason: exclusionReason,
      exclusionDate: exclusionDate,
      collaboratorId: collaboratorId
    };
    return this.http.delete<any>(url,{body});
  }

  postProfileCollaborator(collaborator: ICollaboratorProfile): Observable<any> {
    const path = 'Collaborator';
    const url = `${environment.apiUrl}/${path}`;
    return this.http.post<ICollaboratorProfile>(url, collaborator);
  }

  getRests() {
     return [
      {
        startDate: '22/07/2023',
        endDate: '22/08/2024',
        manager: 'Arthur Santana',
        statusRest: 'Em análise',
      },
      {
        startDate: '22/08/2023',
        endDate: '22/09/2024',
        manager: 'Alex Bezerra',
        statusRest: 'Coletivo',
      },
      {
        startDate: '17/08/2023',
        endDate: '30/11/2024',
        manager: 'Rafael Bezerra',
        statusRest: 'Aprovado',
      },
      {
        startDate: '13/08/2023',
        endDate: '22/10/2024',
        manager: 'Leonardo Maduro',
        statusRest: 'Recusado',
      },
    ];
  }
  getAllRests(){
    const path = 'ListRests';
    return this.get<any[]>(this.endpoint, path);
  }

  getAllCollaboratorsRestByFilter(
      filter: ICollaboratorRestlFilter
  ): Observable<IApiResponse<ICollaboratorRestlFilter[]>> {
    const path = 'ListByFiltersAsync';
    return this.http.post<IApiResponse<ICollaboratorRestlFilter[]>>(
      `${environment.apiUrl}/${this.endpoint}/${path}`,
      filter
    );
  }
}
