import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styles: [],
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() length: number = 0;
  @Input() pageSize: number = 0;
  @Input() currentPage: number = 1;
  @Output() skipPage = new EventEmitter();

  public canGoNext: boolean = true;
  public canComeBack: boolean = false;
  numberOfpage: number[] = [0];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.addPagination();
  }

  ngOnInit(): void {
  }

  addPagination() {
    this.numberOfpage = Array.from(
      { length: Math.ceil(this.length / this.pageSize) },
      (v, k) => k + 1
    );
  }

  getPageByNumber(page: number) {
    this.currentPage = page;
    if (this.currentPage < this.numberOfpage.length) {
      this.canGoNext = true;
    } else {
      this.canGoNext = false;
    }
    if (this.currentPage > 1) {
      this.canComeBack = true;
    }
    if (this.currentPage === 1) {
      this.canComeBack = false;
    }
    this.skipPage.emit({ 'skip': this.currentPage - 1 })
  }

  getNextPage() {
    this.currentPage += 1;

    if (this.currentPage < this.numberOfpage.length) {
      this.canGoNext = true;
    } else {
      this.canGoNext = false;
    }

    if (this.currentPage > 1) {
      this.canComeBack = true;
    }
    this.skipPage.emit({ 'skip': this.currentPage - 1 })
  }

  getPreviousPage() {
    this.currentPage -= 1;

    if (this.currentPage > 1) {
      this.canComeBack = true;
    } else {
      this.canComeBack = false;
    }
    if (this.currentPage < this.numberOfpage.length) {
      this.canGoNext = true;
    }
    this.skipPage.emit({ 'skip': this.currentPage -1})
  }
}
