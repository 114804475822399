<div class="modal-dialog-close">
  <div class="p-horizontal-xl">
    <h1 *ngIf="!isEdit">Cadastrar Equipe</h1>
    <h1 *ngIf="isEdit">Editar Equipe</h1>
    <form [formGroup]="teamForm" (ngSubmit)="onSubmitTeam()">
      <div class="col align-center">
        <mat-form-field
          class="input-outline input--width-md m-bottom-lg"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>Torre</mat-label>
          <mat-select
            formControlName="towerId"
            (selectionChange)="getLeaderOptions()"
          >
            <mat-option *ngFor="let option of optionsTower" [value]="option.id">
              {{ option.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="input-outline input--width-md m-bottom-lg"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>Nome da equipe</mat-label>
          <input
            type="text"
            matInput
            formControlName="description"
            maxlength="50"
          />
        </mat-form-field>
        <mat-form-field
          class="input-outline input--width-md m-bottom-lg"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>Líder</mat-label>
          <mat-select
            [disabled]="!optionsCollaborator"
            formControlName="leaderId"
          >
            <mat-option> Selecione um líder... </mat-option>
            <ng-container *ngFor="let option of optionsCollaborator">
              <mat-option *ngIf="option.isLeader === true" [value]="option.id">
                {{ option.fullName }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <button
          class="btn btn--width-md primary "
          [disabled]="teamForm.invalid"
          type="submit"
        >
          {{ isEdit ? "Salvar" : "Cadastrar" }}
        </button>
      </div>
    </form>
  </div>
  <div class="position">
    <button class="position btn-icon-close" (click)="close()"><img src="./assets/svg/close-button.svg" alt=""></button>
  </div>
</div>
