<!-- <div class="row align-center justify-center">
    <ng-container *ngFor="let page of numberOfpage">
        <span>{{page}}</span>
    </ng-container>
</div> -->


<ul class="menu-pagination">
    <li class="icon">
      <mat-icon
        *ngIf="this.canComeBack"
        class="previous"
        (click)="getPreviousPage()"
      >
        keyboard_arrow_left</mat-icon
      >
    </li>
    <li
      class="pages-number p-md"
      *ngFor="let i of numberOfpage"
      [ngClass]="{ current: this.currentPage == i }"
      (click)="getPageByNumber(i)"
    >
      {{ i }}
    </li>
    <li class="icon">
      <mat-icon
        *ngIf="this.canGoNext"
        class="next"
        (click)="getNextPage()"
      >
        keyboard_arrow_right</mat-icon
      >
    </li>
  </ul>