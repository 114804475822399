<form [formGroup]="emailForm" (ngSubmit)="onSubmitGenerateCode()">
    <div class="col align-center">
      <div class="logo p-xl m-vertical-xl">
        <img src="/assets/img/Logo1_color_cinza.png" alt="" />
      </div>
      <span class="p-vertical-md"> Informe seu e-mail cadastrado. </span>
      <mat-form-field
        class="input-outline input--width-md m-vertical-lg"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Email</mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          placeholder="Ex. email@example.com"
        />
      </mat-form-field>
    </div>
    <div class="col align-center">
        <button
        class="btn secondary btn--width-md m-lg"
        type="submit"
        [disabled]="emailForm.invalid"
      >
        Enviar
      </button>
    </div>
    <div class="col">
        <button class="btn-no-border m-left-xl" (click)="returnTologin()">
          Login
        </button>
      </div>
  </form>