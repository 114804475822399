<mat-tab-group
  animationDuration="0ms"
  class="tab-page"
  [selectedIndex]="tabSelected"
  (selectedTabChange)="changeURLparams($event)"
>
  <mat-tab label="Perfil Geral">
    <div *ngIf="collaborator" class="row p-top-xxl profile-title">
      <ng-template
        permissions
        [role]="EPermissionType.Read"
        [process]="
          isUserCollaborator ? EProcess.Profile : EProcess.Collaborator
        "
      >
        <button class="btn-backpage" (click)="navigateBack()">
          <mat-icon>chevron_left</mat-icon>
        </button>
      </ng-template>
    </div>
    <ng-container *ngIf="collaborator">
      <app-profile [collaborator]="collaborator"></app-profile>
      <app-collaborator-allocations></app-collaborator-allocations>
    </ng-container>
  </mat-tab>
  <ng-template
    permissions
    *ngIf="
      currentRoute === ERouteProfile.Collaborator ||
      currentRoute === ERouteProfile.CollaboratorSkill
    "
    [role]="EPermissionType.Read"
    [process]="EProcess.CollaboratorSkills"
  >
    <mat-tab label="Habilidades">
      <ng-container *ngIf="collaborator">
        <div class="row p-xl profile-title">
          <button class="btn-backpage" (click)="navigateBack()">
            <mat-icon>chevron_left</mat-icon>
          </button>

          <div class="col-md-2 p-left-xl">
            <h1 class="p-left-xl">
              {{ collaborator.fullName }}
            </h1>
          </div>
          <div class="col-md-10">
            <div class="border-division"></div>
          </div>
        </div>
        <app-collaborator-skills
          [collaboratorId]="collaboratorId"
          [collaborator]="collaborator"
        ></app-collaborator-skills>
      </ng-container>
    </mat-tab>
  </ng-template>

  <ng-template
    *ngIf="
      currentRoute === ERouteProfile.Profile ||
      currentRoute === ERouteProfile.ProfileSkill
    "
    permissions
    [role]="EPermissionType.Read"
    [process]="EProcess.Profile"
  >
  <mat-tab label="Habilidades">
    <ng-container *ngIf="collaborator">
      <div class="row p-xl profile-title">
        <button class="btn-backpage" (click)="navigateBack()">
          <mat-icon>chevron_left</mat-icon>
        </button>

        <div class="col-md-2 p-left-xl">
          <h1 class="p-left-xl">
            {{ collaborator.fullName }}
          </h1>
        </div>
        <div class="col-md-10">
          <div class="border-division"></div>
        </div>
      </div>
      <app-collaborator-skills
        [collaboratorId]="collaboratorId"
        [collaborator]="collaborator"
      ></app-collaborator-skills>
    </ng-container>
  </mat-tab>
</ng-template>
  <!-- <mat-tab *ngIf="currentRoute !== 'detalhes-colaborador' " label="Descanso">
    <ng-template
      permissions
      [role]="EPermissionType.Read"
      [process]="EProcess.Profile"
    >
      <ng-container *ngIf="collaborator">
        <div class="row p-xl profile-title">
          <button class="btn-backpage" (click)="navigateBack()">
            <mat-icon>chevron_left</mat-icon>
          </button>

          <div class="col-md-2 p-left-xl">
            <h1 class="p-left-xl">
              {{ collaborator.fullName }}
            </h1>
          </div>
          <div class="col-md-10">
            <div class="border-division"></div>
          </div>
        </div>
        <app-collaborator-skills
          [collaboratorId]="collaboratorId"
          [collaborator]="collaborator"
        ></app-collaborator-skills>
      </ng-container>
    </mat-tab>
  </ng-template>
  <ng-template
    permissions
    *ngIf="
      currentRoute === ERouteProfile.Profile ||
      currentRoute === ERouteProfile.ProfileSkill ||
      currentRoute === ERouteProfile.Absence
    "
    [role]="EPermissionType.Read"
    [process]="EProcess.Profile"
  >
    <mat-tab label="Descanso">
      <ng-container *ngIf="collaborator">
        <div class="flex row p-3xl profile-title align-center justify-between">
          <button class="btn-backpage" (click)="navigateBack()">
            <mat-icon>chevron_left</mat-icon>
          </button>

          <div class="col-md-2 p-left-xl">
            <h1 class="p-left-xxl">
              {{ collaborator.fullName }}
            </h1>
          </div>
          <div class="col-md-1">
            <div class="border-t-small-gray"></div>
          </div>
          <div class="col-md-2">
            <h1 class="border-l-large-blue p-left-sm">
              <strong> 0 dias </strong> disponíveis
            </h1>
          </div>
          <div class="col-md-2">
            <h1 class="border-l-large-black p-left-sm">
              <strong> 0 dias </strong> descansados
            </h1>
          </div>
          <div class="col-md-2">
            <h1 class="border-l-large-red p-left-sm">
              <strong> 0 dias </strong> vencidos
            </h1>
          </div>
          <button
            class="btn primary btn--width-xxxs"
            (click)="redirectToRestRequest()"
          >
            Solicitar descanso
          </button>
        </div>
        <app-collaborator-rest
        [collaboratorId]="collaboratorId"
        [collaborator]="collaborator"
        >
        </app-collaborator-rest>
      </ng-container>
    </ng-template>
  </mat-tab> -->
</mat-tab-group>
