import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { MessageService } from 'src/app/core/services/message.service';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import { IClient } from 'src/app/pages/clients-projects/interface/client.interface';
import { IProject } from 'src/app/pages/clients-projects/interface/project.interface';
import { ClientService } from 'src/app/pages/clients-projects/repository/client.service';
import { ProjectService } from 'src/app/pages/clients-projects/repository/project.service';
import { ICollaborator } from 'src/app/pages/collaborator/interface/collaborator.interface';
import { CollaboratorService } from 'src/app/pages/collaborator/repository/collaborator.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Allocation } from 'src/app/shared/models/allocation.model';
import { SubSink } from 'subsink';
import { ITemplateForm } from '../form-allocation-template/form-allocation-template.component';
import { ICollaboratorAllocation } from 'src/app/pages/allocation/interface/collaborator-allocation.interface';
import { ConfirmDialogNewComponent } from 'src/app/shared/components/confirm-dialog-new/confirm-dialog-new.component';

@Component({
  selector: 'app-page-update-collaborator-allocation',
  templateUrl: './page-update-collaborator-allocation.component.html',
  styles: [],
})
export class PageUpdateCollaboratorAllocationComponent implements OnInit {
  @Input() collaboratorId!: string;
  @Input() allocable!: boolean;
  @Input() projectId!: string;
  @Input() viewByProject: boolean = false;

  btnText: string = 'Cadastrar nova alocação';
  pageTitle: string = 'Alocações em andamento';
  allocation: Array<any> = [];
  jobFunction!: string;
  optionsCollaborator$!: Observable<ICollaboratorAllocation>;
  optionsAllocation$!: Observable<Allocation[]>;
  optionsProject$!: Observable<IProject[]>;
  optionsClients$!: Observable<IClient[]>;
  optionsCollaborator!: ICollaboratorAllocation;
  optionsClients!: IClient[];
  optionsProject!: IProject[];
  disableButton: boolean = true;
  overAllocated: boolean = false;
  overAbsence: boolean = false;
  private _subs = new SubSink();
  disableButtonReplyAllocation: Array<boolean> = [true];
  formEnabledList: Array<ITemplateForm> = new Array();
  allocationSubmit: Array<number> = [];
  monthlyHours!: number;
  weeksTotals!: number;
  currentYear!: number;
  currentMonth!: string;

  constructor(
    private _projectService: ProjectService,
    private _collaboratorService: CollaboratorService,
    private _clientsService: ClientService,
    private _allocationService: AllocationService,
    private _messageService: MessageService,
    public dialog: MatDialog,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.getSelectOptions();
    this.setCurrentDate();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
  getSelectOptions() {
    this.optionsAllocation$ = this._allocationService.getAllbyCollaborator(
      this.collaboratorId
    );
    this.optionsProject$ = this._projectService.getAllbyProjects();
    this.optionsCollaborator$ =
      this._collaboratorService.getCollaboratorAllocationById(
        this.collaboratorId
      );
    this.optionsClients$ = this._clientsService.getAllClients();
    this._subs.add(
      this.optionsAllocation$.subscribe((allocationCollaborator: any) => {
        allocationCollaborator.allocationByCollaboratorGrouped.map(
          (allocation: Allocation) => {
            this.initAllocation(allocation);
          }
        );
        this.monthlyHours = allocationCollaborator.monthlyHours;
        this.weeksTotals = allocationCollaborator.weekHours;

        if (this.viewByProject && this.projectId) {
          let index = this.allocation.findIndex(
            (item) => item.projectId === this.projectId
          );

          if (index !== -1) {
            let alloc = this.allocation.splice(index, 1)[0];
            this.allocation.unshift(alloc);
          }
        }
      }),
      this.optionsProject$.subscribe((projects: IProject[]) => {
        const projectsId = this.allocation.flatMap(
          (allocation) => allocation.projectId
        );
        this.formEnabledList.push({
          type: 'project',
          index: 1,
          list: projects,
          value: projectsId,
          isDisabled: true,
        });
        this.formEnabledList.push({
          type: 'projectResponsible',
          index: 2,
          isDisabled: true,
        });
        this.optionsProject = projects;
      }),
      this.optionsCollaborator$.subscribe(
        (collaborator: ICollaboratorAllocation) => {
          this.optionsCollaborator = collaborator;
        }
      ),
      this.optionsClients$.subscribe((clients: IClient[]) => {
        this.optionsClients = clients;
        this.formEnabledList.push({
          type: 'client',
          index: 0,
          list: clients,
          isDisabled: true,
        });
      })
    );
  }

  getinitialsNameCollaborator(collaboratorName: string): string {
    const initialsArray = collaboratorName.split(' ').filter((name) => {
      if (name) return name;
      return '';
    });
    const firstLetter = initialsArray[0][0];
    const secondLetter =
      initialsArray.length > 1
        ? initialsArray[initialsArray.length - 1][0]
        : '';
    return `${firstLetter}${secondLetter}`;
  }

  initAllocation(allocation: Allocation) {
    const newAllocation = new Allocation();
    newAllocation.setAllocation(allocation);
    newAllocation.isPercentage = true;
    newAllocation.checkIsPercentage(allocation.percentage);

    this.allocation.push(newAllocation);
  }

  receiveStatusForm(validForm: boolean, buttonIndex: number) {
    this.disableButtonReplyAllocation[buttonIndex] = !validForm;
    this.disableButton = !validForm;
  }

  receiveSubmit(event: any, formIndex: number) {
    this.allocationSubmit.push(formIndex);
    this.disableButton = this.hasInvalidAllocation();
    this.allocation.at(formIndex)?.setAllocation(event.data);
    if (event.data.projectId) {
      this.allocation.at(formIndex)?.createAllocationByReservation();
    }
  }

  addAllocation(
    reply: boolean = false,
    lastAllocation?: Allocation,
    formIndex: number = 0
  ) {
    const newAllocation = new Allocation();
    if (reply === true) {
      if (lastAllocation) newAllocation.replyAllocation(lastAllocation);
      this.allocation.splice(formIndex + 1, 0, newAllocation);
      this.disableButtonReplyAllocation.splice(formIndex + 1, 0, true);
    } else {
      this.allocation.push(newAllocation);
      this.disableButtonReplyAllocation.push(true);
    }
    this.disableButton = true;
  }

  putAllocation() {
    const overAllocated = this.allocation.some(
      (allocation) => allocation.overAllocation === true
    );

    const overAbsence = this.allocation.some(
      (allocation) => allocation.overAbsence === true
    );

    const hasReservation = this.allocation.some(
      (allocation) => allocation.isReservation && !allocation.id
    );

    if (overAllocated && !overAbsence) {
      this.handleOverAllocated(hasReservation);
    } else if (overAbsence && overAllocated) {
      this.handleOverAbsenceWithOverAllocation(hasReservation);
    } else if (overAbsence) {
      this.handleOverAbsence();
    } else if (hasReservation) {
      this.handleHasReservation();
    } else {
      this.performAllocationRequest();
    }
  }

  handleOverAllocated(hasReservation: boolean) {
    const message =
      'Você selecionou um colaborador que já está super alocado. Tem certeza que deseja continuar com a alocação?';
    const buttonTxt = 'Sim';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.handleReservation(hasReservation);
      }
    });
  }

  handleOverAbsenceWithOverAllocation(hasReservation: boolean) {
    const message =
      'Este colaborador possui uma ausência dentro do período, deseja continuar com a alocação?';
    const buttonTxt = 'Sim';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const messages =
          'Você selecionou um colaborador que já está super alocado. Tem certeza que deseja continuar com a alocação?';
        const buttonTxts = 'Sim';
        const secondDialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: { message: messages, buttonTxt: buttonTxts, confirm: false },
        });
        secondDialogRef.afterClosed().subscribe((secondResult) => {
          if (secondResult) {
            this.handleReservation(hasReservation);
          }
        });
      }
    });
  }

  handleOverAbsence() {
    const message =
      'Este colaborador possui uma ausência dentro do período, deseja continuar com a alocação? ';
    const buttonTxt = 'Sim';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.performAllocationRequest();
    });

  }

  handleHasReservation() {
    const message =
      'O cadastro de PRÉ-VENDA tem validade de 15 dias corridos e após esse prazo é necessário refazer o processo.';
    const buttonTxt = 'OK';
    const reservationDialogRef = this.dialog.open(
      ConfirmDialogNewComponent,
      {
        data: { message: message, buttonTxt: buttonTxt, confirm: false },
      }
    );
    reservationDialogRef.afterClosed().subscribe(() => {
      this.performAllocationRequest();
    });
  }

  handleReservation(hasReservation: boolean) {
    if (hasReservation) {
      const reservationMessage =
        'Sua pré-venda vai expirar automaticamente depois de 15 dias corridos, mas te avisaremos quando o dia chegar.';
      const reservationButtonTxt = 'OK';
      const reservationDialogRef = this.dialog.open(
        ConfirmDialogNewComponent,
        {
          data: {
            message: reservationMessage,
            buttonTxt: reservationButtonTxt,
            confirm: false,
          },
        }
      );
      reservationDialogRef.afterClosed().subscribe((reservationResult) => {
        if (reservationResult) {
      this.performAllocationRequest();

        }
      });
    } else {
     this.performAllocationRequest();
      }
  }


  private performAllocationRequest() {
    const allocationToSubmit: Allocation[] = [];
    const indexToSubmit = [...new Set(this.allocationSubmit)];

    if (indexToSubmit.length > 0) {
      indexToSubmit.forEach((updateIndex) => {
        allocationToSubmit.push(this.allocation.at(updateIndex));
      });
    }

    const allocationsUpdate: Allocation[] = [];
    const allocationsCreate: Allocation[] = [];

    allocationToSubmit.forEach((allocation) => {
      if (allocation.id == null) {
        delete allocation.id;
        allocationsCreate.push(allocation);
      } else {
        allocationsUpdate.push(allocation);
      }
    });

    const create$ =
      allocationsCreate.length > 0
        ? this._allocationService.postAllocation(allocationsCreate)
        : of([]);
    const update$ =
      allocationsUpdate.length > 0
        ? this._allocationService.putAllocation(allocationsUpdate)
        : of([]);

    this._subs.add(
      forkJoin([create$, update$]).subscribe({
        next: (response) => {
          if (response[0].success === true || response[1].success === true) {
            this._messageService.openSuccessMessage(
              'Cadastro atualizado com sucesso.'
            );
            this.reloadComponent();
          }
        },
      })
    );
  }

  removeAllocation(formIndex: number, item: Allocation) {
    if (formIndex === 0 && this.allocation.length === 1) {
      this.addAllocation();
    }
    this.allocation.splice(formIndex, 1);
    this.allocationSubmit.splice(formIndex, 1);
    this.disableButton = this.hasInvalidAllocation();
    this.disableButtonReplyAllocation.splice(formIndex, 1);
  }

  hasInvalidAllocation() {
    const hasInvalidAllocation = this.allocation.filter(
      (allocation: Allocation) => {
        return !allocation.isValid();
      }
    );
    let disableButton: boolean;
    hasInvalidAllocation.length
      ? (disableButton = true)
      : (disableButton = false);
    return disableButton;
  }

  cancelAllocation() {
    const message: string =
      'Você tem certeza que deseja cancelar essa alocação?';
    const buttonTxt: string = 'Sim';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (!this.viewByProject) {
          this._router.navigate(['/alocacao/colaboradores']);
        } else {
          this._router.navigate([
            '/alocacao/visualizar-alocacao',
            this.projectId,
          ]);
        }
      }
    });
  }

  reloadComponent() {
    const url = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([`/${url}`]);
    });
  }
  private setCurrentDate() {
    const dateAtual = new Date();
    this.currentYear = dateAtual.getFullYear();
    this.currentMonth = this.capitalizeFirstLetter(
      dateAtual.toLocaleString('pt-BR', { month: 'long' }).toLowerCase()
    );
  }

  private capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
