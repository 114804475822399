import {
  AllocationProjectCollaborator,
  DateInputAllocation,
  GroupedMonths,
  IDaysPerWeekAllocation,
  IMonthYearPeriod,
  IWeeksPerMonthAllocation,
} from 'src/app/pages/allocation/interface/project-allocation.interface';

export const months = [
  'janeiro',
  'fevereiro',
  'março',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro',
];

function getDaysOfMonth(year: number, month: number) {
  const daysInMonth = new Date(year, month, 0).getDate();
  const daysArray = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(year, month - 1, day);
    daysArray.push(date);
  }

  return daysArray;
}

export function getWeeksOfMonth(
  date: DateInputAllocation
): IWeeksPerMonthAllocation[] {
  const daysOfMonth = getDaysOfMonth(date.year, date.month);
  const weeksArray: IWeeksPerMonthAllocation[] = [];
  const currentDate = new Date();
  let currentWeek: IWeeksPerMonthAllocation | null = null;

  daysOfMonth.forEach((day, index) => {
    if (day.getDay() === 0 || currentWeek === null) {
      currentWeek = {
        current: false,
        days: [],
      };
      weeksArray.push(currentWeek);
    }

    const isCurrent =
      day.getDate() === currentDate.getDate() &&
      day.getMonth() + 1 === currentDate.getMonth() + 1 &&
      day.getFullYear() === currentDate.getFullYear();

    const isWeekendDay = day.getDay() === 0 || day.getDay() === 6;

    const dayObject: IDaysPerWeekAllocation = {
      current: isCurrent,
      weekendDay: isWeekendDay,
      day: index + 1,
    };

    if (isCurrent) {
      currentWeek!.current = true;
    }

    currentWeek!.days.push(dayObject);
  });

  return weeksArray;
}

export function getMonthsInPeriod(
  initialDate: Date,
  finalDate: Date
): { groupedMonths: GroupedMonths; activeIndex: number } {
  const months: IMonthYearPeriod[] = [];
  const today = new Date();

  const maxItemsPerGroup = 4;

  let currentDate = new Date(initialDate);
  currentDate.setDate(1);

  while (currentDate <= finalDate) {
    const month = currentDate.toLocaleString('pt-BR', { month: 'long' });
    const year = currentDate.getFullYear();
    const current =
      currentDate.getMonth() === today.getMonth() &&
      currentDate.getFullYear() === today.getFullYear();

    months.push({ month, year, current });

    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  const totalGroups = Math.ceil(months.length / maxItemsPerGroup);

  const groupedMonths: GroupedMonths = Array.from(
    { length: totalGroups },
    (_, index) => {
      const start = index * maxItemsPerGroup;
      const end = start + maxItemsPerGroup;
      return months.slice(start, end);
    }
  );

  if (
    groupedMonths.length > 1 &&
    groupedMonths[groupedMonths.length - 1].length === 1
  ) {
    let monthLeft = groupedMonths[groupedMonths.length - 1][0];
    groupedMonths.splice(groupedMonths.length - 1, 1);
    groupedMonths[groupedMonths.length - 1].push(monthLeft);
  }

  let activeIndex = groupedMonths.findIndex((group) =>
    group.some((month) => month.current)
  );

  activeIndex = activeIndex === -1 ? 0 : activeIndex;

  return { groupedMonths, activeIndex };
}

export function getYearString(months: IMonthYearPeriod[]): string {
  const uniqueYears = Array.from(new Set(months.map((month) => month.year)));

  if (uniqueYears.length === 1) {
    return uniqueYears[0].toString();
  } else {
    return uniqueYears.join('/');
  }
}

export function isDateInRange(startDate: Date, endDate: Date): boolean {
  const currentDate = new Date();

  return currentDate >= startDate && currentDate <= endDate;
}

export function getNextMonth(
  currentDate: DateInputAllocation,
  step: number
): DateInputAllocation {
  const newDate = { ...currentDate };
  newDate.month += step;

  if (newDate.month > 12) {
    newDate.month = 1;
    newDate.year += 1;
  } else if (newDate.month < 1) {
    newDate.month = 12;
    newDate.year -= 1;
  }

  return newDate;
}

export function isNextMonthWithinRangeByFilter(
  currentDate: DateInputAllocation,
  startDate: Date,
  endDate: Date,
  step: number
): { date: DateInputAllocation; limitReached: boolean } {
  const nextMonth = getNextMonth(currentDate, step);

  if (step === -1) {
    if (
      nextMonth.year < startDate.getFullYear() ||
      (nextMonth.year === startDate.getFullYear() &&
        nextMonth.month < startDate.getMonth() + 1)
    ) {
      return { date: nextMonth, limitReached: true };
    }
  } else if (step === 1) {
    if (
      nextMonth.year > endDate.getFullYear() ||
      (nextMonth.year === endDate.getFullYear() &&
        nextMonth.month > endDate.getMonth() + 1)
    ) {
      return { date: nextMonth, limitReached: true };
    }
  }

  return { date: nextMonth, limitReached: false };
}

export function isNextMonthWithinRangeByFlag(
  currentDate: DateInputAllocation,
  startDate: Date,
  endDate: Date,
  step: number
): { date: DateInputAllocation; limitReached: boolean } {
  const nextMonth = getNextMonth(currentDate, step);

  if (step === -1) {
    if (
      nextMonth.year < startDate.getFullYear() ||
      (nextMonth.year === startDate.getFullYear() &&
        nextMonth.month <= startDate.getMonth() + 1)
    ) {
      return { date: nextMonth, limitReached: true };
    }
  } else if (step === 1) {
    if (
      nextMonth.year > endDate.getFullYear() ||
      (nextMonth.year === endDate.getFullYear() &&
        nextMonth.month >= endDate.getMonth() + 1)
    ) {
      return { date: nextMonth, limitReached: true };
    }
  }

  return { date: nextMonth, limitReached: false };
}

export function calculatePercentagesForMonth(
  allocations: AllocationProjectCollaborator[],
  period: { month: number; year: number }
): any {
  return allocations.map((collaborator) => {
    let nonWorkedLeft = 0;
    let workedDays = 0;
    let nonWorkedRight = 0;

    if (
      isMonthWithinRange(collaborator.startDate, collaborator.endDate, period)
    ) {
      let percentages = calculateWorkedDays(
        collaborator.startDate,
        collaborator.endDate,
        period
      );
      workedDays = percentages.workedDays;
      nonWorkedLeft = percentages.nonWorkedLeft;
      nonWorkedRight = percentages.nonWorkedRight;
    }

    return {
      ...collaborator,
      nonWorkedLeft,
      worked: workedDays,
      nonWorkedRight,
    };
  });
}

function isMonthWithinRange(
  startDate: string,
  endDate: string,
  period: { month: number; year: number }
): boolean {
  const startDateAsDate = new Date(startDate);
  const endDateAsDate = new Date(endDate);

  startDateAsDate.setDate(1);
  endDateAsDate.setMonth(endDateAsDate.getMonth() + 1, 0);

  const periodAsDate = new Date(period.year, period.month - 1, 1);

  return periodAsDate >= startDateAsDate && periodAsDate <= endDateAsDate;
}

function calculateWorkedDays(
  startDate: string,
  endDate: string,
  period: { month: number; year: number }
): { workedDays: number; nonWorkedLeft: number; nonWorkedRight: number } {
  const totalDaysInMonth = new Date(period.year, period.month, 0).getDate();

  const startDateAsDate = new Date(startDate);
  const endDateAsDate = new Date(endDate);

  let firstDayOfMonth = 1;
  let lastDayOfMonth = totalDaysInMonth;

  let nonWorkedLeft = 0;
  let nonWorkedRight = 0;

  const isFirstMonth =
    startDateAsDate.getMonth() + 1 === period.month &&
    startDateAsDate.getFullYear() === period.year;
  const isLastMonth =
    endDateAsDate.getMonth() + 1 === period.month &&
    endDateAsDate.getFullYear() === period.year;

  if (isFirstMonth) {
    firstDayOfMonth = startDateAsDate.getDate();
    nonWorkedLeft = getPercentage(firstDayOfMonth - 1, totalDaysInMonth);
  }

  if (isLastMonth) {
    lastDayOfMonth = endDateAsDate.getDate();
    nonWorkedRight = getPercentage(
      totalDaysInMonth - lastDayOfMonth,
      totalDaysInMonth
    );
  }

  let workedDays = getPercentage(
    lastDayOfMonth - firstDayOfMonth + 1,
    totalDaysInMonth
  );

  return { workedDays, nonWorkedLeft, nonWorkedRight };
}

export function calculatePercentagesForPeriod(
  allocations: AllocationProjectCollaborator[],
  period: { month: number; year: number }[]
): any {
  let periodNumber = period.map((item) => {
    return { month: getMonthNumber(item.month.toString()), year: item.year };
  });

  return allocations.map((collaborator) => {
    let nonWorkedLeft = 0;
    let workedMonths = 0;
    let nonWorkedRight = 0;

    if (
      isPeriodWithinRange(
        collaborator.startDate,
        collaborator.endDate,
        periodNumber
      )
    ) {
      let percentages = calculateWorkedMonths(
        collaborator.startDate,
        collaborator.endDate,
        periodNumber
      );
      workedMonths = percentages.workedDays;
      nonWorkedLeft = percentages.nonWorkedLeft;
      nonWorkedRight = percentages.nonWorkedRight;
    }

    return {
      ...collaborator,
      nonWorkedLeft,
      worked: workedMonths,
      nonWorkedRight,
    };
  });
}

function isPeriodWithinRange(
  startDate: string,
  endDate: string,
  period: { month: number; year: number }[]
): boolean {
  const startDateAsDate = new Date(startDate);
  const endDateAsDate = new Date(endDate);

  startDateAsDate.setDate(1);
  endDateAsDate.setMonth(endDateAsDate.getMonth() + 1, 0);

  return period.some((period) => {
    const periodAsDate = new Date(period.year, period.month - 1, 1);
    return periodAsDate >= startDateAsDate && periodAsDate <= endDateAsDate;
  });
}

function calculateTotalDaysInPeriod(period: { month: number; year: number }[]) {
  return period.reduce((totalDays, month) => {
    const daysInMonth = new Date(month.year, month.month, 0).getDate();
    return totalDays + daysInMonth;
  }, 0);
}

function calculateWorkedMonths(
  startDate: string,
  endDate: string,
  period: { month: number; year: number }[]
): { workedDays: number; nonWorkedLeft: number; nonWorkedRight: number } {
  const totalDaysInPeriod = calculateTotalDaysInPeriod(period);

  let nonWorkedLeft = 0;
  let nonWorkedRight = 0;
  let workedDays = 0;

  const startDateAsDate = new Date(startDate);
  const endDateAsDate = new Date(endDate);

  period.forEach((item) => {
    const startOfMonth = new Date(item.year, item.month - 1, 1);
    const endOfMonth = new Date(item.year, item.month, 0);
    const totalDaysInMonth = new Date(item.year, item.month, 0).getDate();

    if (startOfMonth <= endDateAsDate && endOfMonth >= startDateAsDate) {
      const isFirstMonth =
        item.month === startDateAsDate.getMonth() + 1 &&
        item.year === startDateAsDate.getFullYear();
      const isLastMonth =
        item.month === endDateAsDate.getMonth() + 1 &&
        item.year === endDateAsDate.getFullYear();

      if (isFirstMonth && isLastMonth) {
        nonWorkedLeft += startDateAsDate.getDate() - 1;
        nonWorkedRight += totalDaysInMonth - endDateAsDate.getDate();
        workedDays += endDateAsDate.getDate() - startDateAsDate.getDate() + 1;
      } else if (isFirstMonth) {
        let difference = totalDaysInMonth - startDateAsDate.getDate();
        workedDays += difference + 1;
        nonWorkedLeft += startDateAsDate.getDate() - 1;
      } else if (isLastMonth) {
        let difference = totalDaysInMonth - endDateAsDate.getDate();
        workedDays += endDateAsDate.getDate();
        nonWorkedRight += difference;
      } else {
        workedDays += totalDaysInMonth;
      }
    } else {
      workedDays === 0
        ? (nonWorkedLeft += totalDaysInMonth)
        : (nonWorkedRight += totalDaysInMonth);
    }
  });

  workedDays = getPercentage(workedDays, totalDaysInPeriod);
  nonWorkedLeft = getPercentage(nonWorkedLeft, totalDaysInPeriod);
  nonWorkedRight = getPercentage(nonWorkedRight, totalDaysInPeriod);

  return { workedDays, nonWorkedLeft, nonWorkedRight };
}

function getPercentage(number: number, total: number): number {
  const porcentagem = (number / total) * 100;
  return Math.round(porcentagem);
}

export function getMonthNumber(monthName: string): number {
  const monthIndex = months.findIndex(
    (month) => month.toLowerCase() === monthName.toLowerCase()
  );

  return monthIndex + 1;
}
