import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

import { SkillService } from '../../repository/skill.service';
import { SubSink } from 'subsink';
import { ISkillType } from '../../interface/skill-type.interface';
import { SkillTypeManageModalComponent } from '../../modal/skill-type-manage-modal/skill-type-manage-modal.component';
import { SkillManageModalComponent } from '../../modal/skill-manage-modal/skill-manage-modal.component';
import { SkillTypeService } from '../../repository/skill-type.service';
import { ISkill } from 'src/app/pages/collaborator/interface/collaborator-skills.interface';
import { MessageService } from 'src/app/core/services/message.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { IDefaultFilter } from 'src/app/shared/models/filter.model';

@Component({
  selector: 'app-page-skill-list',
  templateUrl: './page-skill-list.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PageSkillListComponent implements OnInit, OnDestroy {
  expandedElement: ISkillType | null | undefined;

  pageSizeOptions: Array<number> = [5, 10, 20];
  countDataSource: number = 0;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = ['icon', 'description', 'status'];
  skillType: ISkillType[] = [];
  pageSize: number = 20;
  searchByNameCtrl: FormControl = new FormControl();

  filter: IDefaultFilter = {
    page: 1,
    take: 20,
    searchString: '',
  };

  pageTitle: string = 'Habilidades';
  btnText1: string = 'Cadastrar papel';
  btnText2: string = 'Cadastrar habilidade';
  private _subs = new SubSink();

  constructor(
    private _skillsTypeService: SkillTypeService,
    private _skillsService: SkillService,
    public dialog: MatDialog,
    private _messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getSkillTypeOptions();
    this.getSkillType();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  getSkillTypeOptions() {
    this._subs.add(
      this._skillsTypeService.getAllSkillTypeOptions().subscribe((res) => {
        if (res.success) {
          this.skillType = res.result ?? [];
        }
      })
    );
  }

  getSkillType(resetPage: boolean = true) {
    if (resetPage) {
      this.filter.page = 1;
      this.countDataSource = 0;
    }

    this._subs.add(
      this._skillsTypeService
        .getAllSkillType(this.filter)
        .subscribe((res: any) => {
          if (res.success) {
            this.countDataSource = res.count;
            this.getDataSource(res.result);
          }
        })
    );
  }

  getDataSource(res: any) {
    let arr: any[] = [];
    if (res) {
      res.map((skillType: ISkillType) => {
        const prepareDataSource = {
          id: skillType.id,
          description: skillType.description,
          status: skillType.inactive,
          skills: skillType.skills,
        };
        arr.push(prepareDataSource);
      });

      this.dataSource.data = arr;
    }
  }

  openDialogSkillType(skillType?: ISkillType): void {
    let dialogRef;

    if (skillType) {
      dialogRef = this.dialog.open(SkillTypeManageModalComponent, {
        data: { id: skillType.id, description: skillType.description },
        panelClass: 'modal-dialog-close-type',
      });
    } else {
      dialogRef = this.dialog.open(SkillTypeManageModalComponent, {
        panelClass: 'modal-dialog-close-type',
      });
    }

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.reload === true) {
        this.getSkillType();
        this.getSkillTypeOptions();
      }
    });
  }

  openDialogSkill(skill?: ISkill): void {
    let dialogRef;
    if (skill) {
      dialogRef = this.dialog.open(SkillManageModalComponent, {
        data: { skill: skill, skillType: this.skillType },
        panelClass: 'modal-dialog-close-type',
      });
    } else {
      dialogRef = this.dialog.open(SkillManageModalComponent, {
        data: { skillType: this.skillType },
        panelClass: 'modal-dialog-close-type',
      });
    }

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.reload === true) this.getSkillType();
    });
  }

  inactivateSkill(event: any, skill: ISkill) {
    let message: string = '';
    let buttonTxt: string = '';
    const skillId = skill.id;
    if (event.source.checked === false) {
      message = 'Deseja inativar habilidade?';
      buttonTxt = 'Sim';
    } else if (event.source.checked === true) {
      message = 'Deseja ativar habilidade?';
      buttonTxt = 'Sim';
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._subs.add(
          this._skillsService.inactivateSkill(skillId).subscribe(
            (response) => {
              if (response.success === true) {
                this.getSkillType();
                this._messageService.openSuccessMessage(
                  'Operação realizada com sucesso'
                );
              } else if (response.errors.length > 0) {
                this._messageService.openErrorMessage(response.errors);
                event.source.checked = !event.source.checked;
              }
            },
            () => {
              this._messageService.openErrorMessage([
                'Erro encontrado, tente novamente',
              ]);
              event.source.checked = !event.source.checked;
            }
          )
        );
      } else {
        event.source.checked = !event.source.checked;
      }
    });
  }

  searchBy() {
    this.filter.searchString = this.searchByNameCtrl.value;
    this.getSkillType();
  }

  clearFilter() {
    this.searchByNameCtrl.reset();
    this.filter.searchString = null;
    this.getSkillType();
  }

  skipPage(event: { skip: number }) {
    this.filter.page = event.skip + 1;
    this.getSkillType(false);
  }

  changeItemsPerPage(items: number) {
    this.filter.page = 1;
    this.filter.take = items;

    this.getSkillType();
  }
}
