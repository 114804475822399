import { Component, Input, OnInit } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { AllocationService } from 'src/app/pages/allocation/repository/allocation.service';
import { IProject } from 'src/app/pages/clients-projects/interface/project.interface';

import {
  IOptionsCollaborator,
  IGenericData,
} from 'src/app/pages/collaborator/interface/options-collaborator.interface';
import { Allocation } from 'src/app/shared/models/allocation.model';
import { SubSink } from 'subsink';
import { ITemplateForm } from '../../collaborator/form-allocation-template/form-allocation-template.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/core/services/message.service';
import { CollaboratorService } from 'src/app/pages/collaborator/repository/collaborator.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FilterProjectsAllocation } from 'src/app/shared/models/filter-projects.model';
import { ICollaboratorAllocation } from 'src/app/pages/allocation/interface/collaborator-allocation.interface';
import { IProjectAllocation } from 'src/app/pages/allocation/interface/project-allocation.interface';

const DefaultlId = '00000000-0000-0000-0000-000000000000';

@Component({
  selector: 'app-page-update-project-allocation',
  templateUrl: './page-update-project-allocation.component.html',
  styles: [],
})
export class PageUpdateProjectAllocationComponent implements OnInit {
  @Input() projectId!: string;
  project!: IProjectAllocation;
  allocation: Array<any> = [];
  optionsCollaborator$!: Observable<ICollaboratorAllocation[]>;
  optionsJobFunction$!: Observable<IOptionsCollaborator>;
  optionsCollaborator!: ICollaboratorAllocation[];
  optionsJobfunction!: IGenericData[];
  allocationWithCollaborators!: Array<any>;
  formAllocationList: Array<ITemplateForm> = new Array();
  disableButtonReplyAllocation: Array<boolean> = [];
  disableButton: boolean = false;
  projectSelected!: IProject;
  filterConfig: FilterProjectsAllocation = new FilterProjectsAllocation();
  allocationSubmit: Array<number> = [];
  pageTitle: string = '';

  private _subs = new SubSink();
  constructor(
    private _allocationService: AllocationService,
    private _collaboratorService: CollaboratorService,
    private _messageService: MessageService,
    public dialog: MatDialog,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.projectId = this._route.snapshot.params['projectId'];
  }
  ngOnInit() {
    try {
      this.getProjectById();
      this.getSelectOptions();
    } catch (error) {
      console.error(error);
      // this._router.navigate(['/alocacao/projetos']);
    }
  }
  getProjectById() {
    this._allocationService
      .getAllocationByProject(this.projectId)
      .subscribe((res: IProjectAllocation[]) => {
        this.project = res[0];
        this.allocationWithCollaborators = res[0].allocationWithCollaborators;

        this.allocationWithCollaborators.map((allocation: any) =>
          this.initAllocation(allocation)
        );
      });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  getSelectOptions() {
    this.optionsJobFunction$ =
      this._collaboratorService.getCollaboratorSelect();
    this.optionsCollaborator$ =
      this._collaboratorService.getAllCollaboratorsAllocation();
    this._subs.add(
      this.optionsJobFunction$.subscribe((options: IOptionsCollaborator) => {
        this.optionsJobfunction = options.jobFunctions;
        this.formAllocationList.push({
          type: 'jobFunction',
          index: 0,
          list: options.jobFunctions,
          isDisabled: true,
        });
      }),
      this.optionsCollaborator$.subscribe(
        (collaborator: ICollaboratorAllocation[]) => {
          this.optionsCollaborator = collaborator;
          this.formAllocationList.push({
            type: 'collaborator',
            index: 1,
            list: collaborator,
            isDisabled: true,
          });
        }
      )
    );
  }

  initAllocation(allocation?: Allocation) {
    const newAllocation = new Allocation();
    newAllocation.setProjectToAllocation(this.project);
    newAllocation.setIdDefault();

    if (allocation) {
      newAllocation.checkIsPercentage(allocation.percentage);
      newAllocation.jobFunctionId = allocation.jobFunctionId;
      newAllocation.collaboratorId = allocation.collaboratorId;
      newAllocation.id = allocation.id;
      newAllocation.startDate = allocation.startDate;
      newAllocation.endDate = allocation.endDate;
      newAllocation.observation = allocation.observation;
      newAllocation.hours = allocation.hours;
      this.disableButtonReplyAllocation.push(false);
    } else this.disableButtonReplyAllocation.push(true);
    this.allocation.push(newAllocation);
  }

  receiveAllocationSubmit(allocation: any, formIndex: number) {
    this.allocationSubmit.push(formIndex);
    this.allocation.at(formIndex)?.setAllocation(allocation.data);
    this.disableButtonReplyAllocation[formIndex] = false;
    this.disableButton = false;
  }
  addAllocation(
    reply: boolean = false,
    lastAllocation?: Allocation,
    formIndex: number = 0
  ) {
    const newAllocation = new Allocation();
    if (reply === true) {
      if (lastAllocation) newAllocation.replyAllocation(lastAllocation);
      this.allocation.splice(formIndex + 1, 0, newAllocation);
      this.disableButtonReplyAllocation.splice(formIndex + 1, 0, true);
    } else {
      this.initAllocation();
    }
    this.disableButton = true;
  }

  removeAllocation(formIndex: number, item: Allocation) {
    if (formIndex === 0 && this.allocation.length === 1) {
      this.addAllocation();
    }
    this.allocation.splice(formIndex, 1);
    this.allocationSubmit.splice(formIndex, 1);
    this.disableButton = this.hasInvalidAllocation();
    this.disableButtonReplyAllocation.splice(formIndex, 1);
  }

  hasInvalidAllocation() {
    const hasInvalidAllocation = this.allocation.filter(
      (allocation: Allocation) => {
        return !allocation.isValid();
      }
    );
    let disableButton: boolean;
    hasInvalidAllocation.length
      ? (disableButton = true)
      : (disableButton = false);
    return disableButton;
  }
  cancelAllocation() {
    const message: string =
      'Você tem certeza que deseja cancelar essa alocação?';
    const buttonTxt: string = 'Sim';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, buttonTxt: buttonTxt, confirm: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._router.navigate(['/alocacao/projetos']);
      }
    });
  }
  putAllocation() {
    const overAllocated = this.allocation.some((allocation) => {
      return allocation.overAllocation === true;
    });

    if (overAllocated) {
      const message: string =
        'Você selecionou um colaborador que já está super alocado. Tem certeza que deseja continuar com a alocação?';
      const buttonTxt: string = 'Sim';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: message,
          buttonTxt: buttonTxt,
          confirm: false,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.allocationRequest();
        }
      });
    } else {
      this.allocationRequest();
    }
  }

  private allocationRequest() {
    let allocationsToUpdate: Array<Allocation> = [];
    let allocationsToCreate: Array<Allocation> = [];

    const indexToSubmit = [...new Set(this.allocationSubmit)];
    if (indexToSubmit.length > 0) {
      indexToSubmit.map((updateIndex) => {
        if (this.allocation.at(updateIndex))
          allocationsToUpdate.push(this.allocation.at(updateIndex));
      });
    }
    allocationsToUpdate.forEach((allocation) => {
      if (allocation.id == null) {
        delete allocation.id;
      }
    });

    allocationsToCreate = allocationsToUpdate.filter(
      (allocation) => !allocation?.id || allocation.id === DefaultlId
    );
    allocationsToUpdate = allocationsToUpdate.filter(
      (allocation) => allocation?.id && allocation.id !== DefaultlId
    );

    let onlyUpdate = allocationsToCreate.length === 0;

    const putAllocations$ =
      this._allocationService.putAllocation(allocationsToUpdate);
    const postAllocations$ = onlyUpdate
      ? of([])
      : this._allocationService.postAllocation(allocationsToCreate);

    const joinRequest$ = forkJoin([putAllocations$, postAllocations$]);

    this._subs.add(
      joinRequest$.subscribe(([updateResponse, createResponse]) => {
        if (
          (updateResponse.success === true &&
            createResponse.success === true) ||
          (onlyUpdate && updateResponse.success === true)
        ) {
          this.reloadComponent();
          this._messageService.openSuccessMessage(
            'Cadastro realizado com sucesso.'
          );
        } else if (
          updateResponse.errors.length > 0 ||
          createResponse.errors.length > 0
        ) {
          let errors = [
            ...(updateResponse.errors ?? []),
            ...(createResponse.errors ?? []),
          ];
          this._messageService.openErrorMessage(errors);
        }
      })
    );
  }

  reloadComponent() {
    const url = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([`/${url}`]);
    });
  }
}
