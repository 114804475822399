<ul routerLinkActive="active">
  <cdk-accordion class="accordion">
    <cdk-accordion-item
      #accordionItem="cdkAccordionItem"
      *ngFor="let item of items; first as isFirst"
      [expanded]="isFirst"
      class="accordion--item"
      role="button"
      [attr.aria-expanded]="accordionItem.expanded"
    >
    <ng-template permissions [role]="item.permission" [process]="item.process">
      <div class="accordion--title row width-auto space-between p-md" (click)="accordionItem.toggle()">
        <span class="accordion--title-text">{{ item.name }}</span>
        <mat-icon class="accordion--expand-icon m-left-xl" *ngIf="item.children"
          >expand_more</mat-icon
        >
      </div>
      <div role="region" [style.display]="accordionItem.expanded ? '' : 'none'">
        <li
          class="sidenav-item"
          *ngFor="let subItem of item.children"
          [class.--selected]="selectedItem === item"
          (click)="onClickSidenavItem(subItem)"
        >
          <i class="sidenav-icon">{{ subItem.icon }}</i>
          <a class="sidenav-text" routerLink=subItem.path>{{ subItem.name }}</a>
        </li>
      </div>
    </ng-template>
    
    </cdk-accordion-item>
  </cdk-accordion>
</ul>
