<div
  class="card--border p-xl m-sm"
  [ngClass]="{ 'warn-card': overAllocate || overAbsence && isRemovable === true }"
>
  <div
    class="col width-auto"
  >
    <span class="hint align-center m-bottom-lg" *ngIf="showAllocationWarning">
      {{ textAllocationWarning }} <br></span
    >
    <!-- <mat-icon class="warn">info</mat-icon> -->
    <span class="hint align-center m-bottom-md" *ngIf="overAllocate || overAbsence && isRemovable === true">
      {{ overMessage }}</span
    >
  </div>
  <button
    *ngIf="
      (!allocation.id || allocation.id === defaultIdCollaborator) &&
      isRemovable === true
    "
    class="btn-no-border btn--remove-card"
    (click)="removeAllocation()"
    type="button"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div
    class="btn--remove-card p-md"
    *ngIf="
      allocation.id &&
      allocation.id !== defaultIdCollaborator &&
      allocable === true
    "
  >
    <mat-slide-toggle
      [ngModel]="true"
      (change)="inactivateAllocation($event)"
      class="switch"
    ></mat-slide-toggle>
  </div>

  <form [formGroup]="allocationForm" (ngSubmit)="onSubmitAllocation()">
    <div class="section-form">
      <div class="row p-vertical-lg">
        <ng-container *ngFor="let item of formEnabledList; index as index">
          <ng-container
            *ngIf="item.type === 'jobFunction'"
            [ngTemplateOutlet]="jobFunction"
            [ngTemplateOutletContext]="{ item: item }"
          ></ng-container>
          <ng-container
            *ngIf="item.type === 'collaborator'"
            [ngTemplateOutlet]="collaborator"
            [ngTemplateOutletContext]="{ item: item }"
          ></ng-container>
          <ng-container
            *ngIf="item.type === 'project' && !isReservationOrAbsence"
            [ngTemplateOutlet]="project"
            [ngTemplateOutletContext]="{ item: item }"
          >
          </ng-container>
          <ng-container
            *ngIf="item.type === 'client'"
            [ngTemplateOutlet]="client"
            [ngTemplateOutletContext]="{ item: item }"
          >
          </ng-container>
          <ng-container
            *ngIf="
              item.type === 'projectResponsible' && !isReservationOrAbsence
            "
            [ngTemplateOutlet]="projectResponsible"
            [ngTemplateOutletContext]="{ item: item }"
          >
          </ng-container>
          <ng-container
            *ngIf="item.type === 'projectStatus'"
            [ngTemplateOutlet]="projectStatus"
            [ngTemplateOutletContext]="{ item: item }"
          >
          </ng-container>

        </ng-container>
        <ng-container [ngTemplateOutlet]="startDate"> </ng-container>
        <ng-container [ngTemplateOutlet]="endDate"></ng-container>
        <div class="col min-content">
          <ng-container
            *ngIf="isRemovable"
            [ngTemplateOutlet]="allocationPeriod"
          ></ng-container>
        </div>
      </div>
      <ng-container
        *ngIf="
          isReservationOrAbsence ||
          (!allocation.id && !allocation.projectId && allocation.collaboratorId)
        "
        [ngTemplateOutlet]="reservation"
      ></ng-container>
    </div>
  </form>
</div>
<ng-template #project let-item="item">
  <div class="col min-content">
    <ng-container [formGroup]="allocationForm">
      <mat-label class="input-label--custom-create">Pip/Projeto</mat-label>
      <span
        class="label-info"
        *ngIf="
          item.isDisabled === true &&
          editable === false &&
          isReservationOrAbsence === false
        "
        >{{ allocationForm.controls['project'].value?.name}}</span
      >
      <mat-form-field
        *ngIf="
          item.isDisabled === false ||
          editable === true ||
          isReservationOrAbsence === true
        "
        class="input-outline input--width-lg-secondary"
        appearance="outline"
        floatLabel="always"
        [matTooltip]="allocationForm.controls['project'].value?.name"
      >
        <input
          type="text"
          matInput
          formControlName="project"
          [matAutocomplete]="autoProject"
        />
        <mat-autocomplete
          #autoProject="matAutocomplete"
          [displayWith]="displayFnProject"
        >
          <ng-container *ngFor="let option of filteredOptionsProject | async">
            <mat-option
              *ngIf="clientIdSelected === option.clientId"
              [value]="option"
              [disabled]="item.value.includes(option.id)"
              matTooltip="Este colaborador já está alocado no projeto"
              [matTooltipDisabled]="!item.value.includes(option.id)"
            >
             {{option.projectPIP}} - {{ option.name }}
            </mat-option></ng-container
          >
        </mat-autocomplete>
        <span *ngIf="allocation.isReservation === true" class="input-hint">
          Para alocar selecione o projeto.
        </span>
      </mat-form-field>
    </ng-container>
  </div>
</ng-template>

<ng-template #client let-item="item">
  <div class="col min-content">
    <ng-container [formGroup]="allocationForm">
      <mat-label class="input-label--custom-create">Cliente</mat-label>
      <span
        class="label-info"
        *ngIf="item.isDisabled === true && editable === false"
        >{{ allocationForm.controls["client"].value["name"] }}</span
      >
      <!-- <span class="hint" style="max-width: 300px" *ngIf="overAllocate">{{
        overAllocateMessage
      }}</span> -->
      <mat-form-field
        *ngIf="item.isDisabled === false || editable === true"
        class="input-outline input--width-mds-scondary"
        appearance="outline"
        floatLabel="always"
        [matTooltip]="allocationForm.controls['client'].value?.name"
      >
        <input
          type="text"
          matInput
          formControlName="client"
          [matAutocomplete]="autoClient"
        />
        <mat-autocomplete
          #autoClient="matAutocomplete"
          [displayWith]="displayFnClient"
        >
          <ng-container *ngIf="isAbsence">
            <mat-option
              *ngFor="let option of filteredOptionsClients | async"
              [value]="option"
              [disabled]="option.forAbsence === false"
            >
              {{ option.name }}
            </mat-option>
          </ng-container>
          <ng-container *ngIf="!isAbsence">
            <mat-option
              *ngFor="let option of filteredOptionsClients | async"
              [value]="option"
              [disabled]="option.forAbsence === true"
            >
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
  </div>
</ng-template>

<ng-template #projectResponsible let-item="item">
  <div class="col min-content">
    <ng-container [formGroup]="allocationForm">
      <mat-label class="input-label--custom-create">Responsável</mat-label>
      <span
        class="label-info"
        *ngIf="
          allocation.projectId &&
          editable === false &&
          isReservationOrAbsence === false
        "
        >
        {{ allocationForm.controls['project'].value?.projectManager.fullName}}
        </span
      >
      <mat-form-field
        *ngIf="
          item.isDisabled === false ||
          editable === true ||
          isReservationOrAbsence === true
        "
        class="input-outline input--width-mds-scondary"
        appearance="outline"
        floatLabel="always"
      >
        <input
          type="text"
          matInput
          [(ngModel)]="projectManager"
          formControlName="projectResponsible"
        />
      </mat-form-field>
    </ng-container>
  </div>
</ng-template>

<ng-template #projectStatus let-item="item">
  <div class="col min-content">
    <ng-container [formGroup]="allocationForm">
      <mat-label class="input-label--custom-create">Status</mat-label>
      <span
        class="label-info"
        *ngIf="allocation.projectId && editable === false"
        >{{
          allocationForm.controls["project"].value["projectStatus"][
            "description"
          ]
        }}</span
      >
      <mat-form-field
        *ngIf="item.isDisabled === false || editable === true"
        class="input-outline input--width-mds-scondary"
        appearance="outline"
        floatLabel="always"
      >
        <input
          type="text"
          matInput
          [(ngModel)]="status"
          formControlName="projectStatus"
        />
      </mat-form-field>
    </ng-container>
  </div>
</ng-template>

<ng-template #collaborator let-item="item">
  <div class="col min-content">
    <ng-container [formGroup]="allocationForm">
      <mat-label class="input-label--custom-create">Colaborador</mat-label>
      <span
        class="label-info"
        *ngIf="
          allocation.collaboratorId &&
          editable === false &&
          allocationForm.controls['collaborator'].value
        "
        >{{
          allocationForm.controls["collaborator"].value["collaboratorName"]
        }}</span
      >
      <mat-form-field
        *ngIf="item.isDisabled === false || editable === true"
        class="jobFunction-center input-outline input--width-lgs-secondary  autocomplete--prefix-image"
        [ngClass]="{ 'warn-outline': overAllocate || overAbsence}"
        appearance="outline"
        floatLabel="always"
      >
        <div class="autocomplete--prefix-container" *ngIf="imageSrc" matPrefix>
          <img [src]="imageSrc | safeResourceUrl" alt="" />
        </div>
        <input
          type="text"
          matInput
          formControlName="collaborator"
          [matAutocomplete]="autoCollaborator"
        />
        <!-- <span matHint *ngIf="imageSrc">{{allocationForm.controls['collaborator'].value.jobFunctionId}}</span> -->

        <mat-autocomplete
          #autoCollaboratorImage
          #autoCollaborator="matAutocomplete"
          [displayWith]="displayFnCollaborator"
        >
          <ng-container
            *ngFor="let option of filteredOptionsCollaborator | async"
          >
            <mat-option
              *ngIf="jobFunctionIdSelected === option.jobFunction.id"
              [value]="option"
              [disabled]="collaboratorDisabled(option)"
            >
              {{ option.collaboratorName }}
            </mat-option>

          </ng-container>
        </mat-autocomplete>
        <ng-container *ngIf="allocationForm.controls['collaborator']?.value">
          <small class="show-jobFunction">{{
            jobFunctionCollaborator
          }}</small>
          <ng-template #tooltipTemplate>
            <strong>Papel:</strong> {{jobFunctionCollaborator || '-'}}<br>
            <strong>Habilidades:</strong> {{skills || '-'}}<br>
            <strong>Torre:</strong> {{tower || '-'}}<br>
            <strong>Líder:</strong> {{leader || '-'}}<br>
            <strong>Senioridade:</strong> {{seniority || '-'}}
          </ng-template>

          <mat-icon
            class="tooltip-allocation"
            [tooltip]="tooltipTemplate"
            [adaptivePosition]="false"
            placement="top"
            >info
          </mat-icon>
        </ng-container>


      </mat-form-field>
    </ng-container>
  </div>
</ng-template>

<ng-template #jobFunction let-item="item">
  <div class="col min-content">
    <ng-container [formGroup]="allocationForm">
      <mat-label class="input-label--custom-create">Papel</mat-label>
      <span
        class="label-info"
        *ngIf="allocation.jobFunctionId && editable === false"
        >{{ allocationForm.controls["jobFunction"].value["description"] }}</span
      >
      <mat-form-field
        *ngIf="item.isDisabled === false || editable === true"
        class="input-outline input--width-lg-tertiary"
        appearance="outline"
        floatLabel="always"
        [matTooltip]="allocationForm.controls['jobFunction'].value?.description"
      >
        <input
          type="text"
          matInput
          formControlName="jobFunction"
          [matAutocomplete]="autoJobfunction"
        />
        <mat-autocomplete
          #autoJobfunction="matAutocomplete"
          [displayWith]="displayFnJobFunction"
        >
          <mat-option
            *ngFor="let option of filteredOptionsJobFunction | async"
            [value]="option"
          >
            {{ option.description }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
  </div>
</ng-template>

<ng-template #startDate>
  <div class="col min-content">
    <ng-container [formGroup]="allocationForm">
      <mat-label class="input-label--custom-create">{{ isFirst ? 'Data de início' : 'Data de início da alocação' }}</mat-label>
      <span class="label-info" *ngIf="allocable === false">{{
        formatDate(allocation.startDate)
      }}</span>
      <mat-form-field
        class="input-outline input--width-xs-tertiary"
        appearance="outline"
        *ngIf="allocable === true"
        floatLabel="always"
      >
        <input
          [matDatepicker]="pickerInitial"
          [min]="minDate"
          [max]="maxDate"
          matInput
          formControlName="startDate"
          required
        />
        <mat-datepicker-toggle
        matSuffix
        [for]="pickerInitial"
      ></mat-datepicker-toggle>
      <mat-datepicker #pickerInitial></mat-datepicker>
      </mat-form-field>
    </ng-container>
  </div>
</ng-template>

<ng-template #endDate>
  <div class="col min-content">
    <ng-container [formGroup]="allocationForm">
      <mat-label class="input-label--custom-create">{{ isFirst ? 'Data de fim' : 'Data de fim da alocação' }}</mat-label>
      <span class="label-info" *ngIf="allocable === false">{{
        formatDate(allocation.endDate)
      }}</span>
      <mat-form-field
        class="input-outline input--width-xs-tertiary"
        appearance="outline"
        floatLabel="always"
        *ngIf="allocable === true"
      >
        <input
          [matDatepicker]="pickerEnd"
          [min]="allocationForm.controls['startDate'].value"
          [max]="maxDate"
          matInput
          formControlName="endDate"
          required
        />
        <mat-datepicker-toggle
        matSuffix
        [for]="pickerEnd"
      ></mat-datepicker-toggle>
      <mat-datepicker #pickerEnd></mat-datepicker>
      </mat-form-field>
    </ng-container>
  </div>
</ng-template>

<ng-template #allocationPeriod >
  <mat-label class="input-label--custom-create position-relative"
    >Horas ou percentual da alocação
    <mat-icon
    class="tooltip-allocation-period"
    [tooltip]="tooltipTemplateCalculationPeriod"
    [adaptivePosition]="false"
    placement="top"
    >info
  </mat-icon>
    </mat-label
  >
  <ng-container>
    <ng-template #tooltipTemplateCalculationPeriod>
      O tempo que um colaborador é designado para um
      <strong>projeto é calculado pelo período escolhido</strong> .
      Dizer que um colaborador está alocado por 80 horas não significa
      que são horas semanais ou mensais, mas sim 80 horas durante o
      projeto. <br />
      <br />
      Quando mostramos um <strong>percentual</strong>, indicamos as
      horas relacionadas ao período escolhido para o colaborador.
    </ng-template>
  </ng-container>
  <div class="row nowrap">
    <ng-container [formGroup]="allocationForm">
      <div class="col min-content">
        <mat-form-field
          *ngIf="allocable === true"
          class="input-outline input--width-xx-tertiary"
          appearance="outline"
          floatLabel="always"
        >
          <input
            type="text"
            mask="999"
            [suffix]="suffixPeriod"
            matInput
            [disabled]="isAbsence"
            formControlName="allocationPeriod"
            required
          />
        </mat-form-field>

      </div>

      <div class="min-content" [ngClass]="{'flex align-center': allocable === false, col: allocable === true}">
        <span class="p-right-lg" *ngIf="allocable === false">{{
          allocationForm.controls['allocationPeriod'].value + suffixPeriod
        }}</span>
        <mat-form-field
          class="input-outline input--width-xxs-tertiary"
          appearance="outline"
          floatLabel="always"
        >
          <mat-select
            formControlName="isPercentage"
            (selectionChange)="changePeriod($event)"
            [disabled]="isAbsence"
          >
            <mat-option [value]="true"> Percentual </mat-option>
            <mat-option [value]="false"> Horas </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </ng-container>

  </div>
  <ng-container>
    <span>{{ hoursSelected }}</span>
  </ng-container>
</ng-template>

<ng-template #reservation>
  <ng-container [formGroup]="allocationForm">
    <div class="row box-spacing">
      <ng-container *ngIf="isReservation || !allocation.id">
        <mat-checkbox
          class="checkbox"
          formControlName="isReservation"
          [value]="occurrence[0].id"
          [disabled]="!!allocation.isReservation && !!allocation.id"
          (change)="onCheckboxChangeReservation($event, 'reservation')"
        >
          {{ occurrence[0].name }}
        </mat-checkbox>
      </ng-container>
      <ng-container *ngIf="isAbsence || !allocation.id">
        <mat-checkbox
          class="checkbox"
          formControlName="isAbsence"
          [value]="occurrence[1].id"
          [disabled]="!!allocation.isAbsence && !!allocation.id"
          (change)="onCheckboxChangeReservation($event, 'absence')"
        >
          {{ occurrence[1].name }}
        </mat-checkbox>
      </ng-container>
    </div>
    <div class="row">
      <mat-form-field
        *ngIf="isReservationOrAbsence"
        class="input-outline input--width-xl"
        appearance="outline"
        floatLabel="always"
        >
        <textarea
        maxlength="120"
          matInput
          [placeholder]="isAbsence ? textAreaPlaceholderAbsent : textAreaPlaceholderPreSale"
          formControlName="observation"
        ></textarea>
      </mat-form-field></div
  ></ng-container>
</ng-template>

