import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styles: [
  ]
})
export class TitleComponent implements OnInit {

  @Input() title!: string;
  @Input() btnText!: string;

  @Output() btnClick: EventEmitter<boolean> = new EventEmitter;

  
  constructor() { }

  ngOnInit(): void {
  }

  onClickEmmiter(){
    this.btnClick.emit(true);
  }
}
