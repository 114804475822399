<div class="p-horizontal-xl">
  <form [formGroup]="formInative" (ngSubmit)="confirmInactivation()">
  <div class="modalConfirme">
    <div class="titleModal">
      <!-- <h3>{{ data.message }}</h3> -->
      <mat-form-field
       class="input-outline input--width-xlx-tertiary m-vertical-lg"
        appearance="outline"
        floatLabel="always"
      >
      <mat-label>Motivo do desligamento</mat-label>
        <textarea
          maxlength="120"
          minlength="5"
          matInput
          placeholder="Digite o motivo aqui..."
          formControlName="exclusionReason"
         ></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always" class="input-outline input--width-xlx-tertiary">
        <mat-label>Data de desligamento</mat-label>
        <input
        matInput
        [matDatepicker]="picker"
        [min]="minDate"
        placeholder="Ex: dd/mm/aaaa"
        formControlName="exclusionDate"
             >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button class="btn btn--width-xs m-xl secondary" mat-button (click)="close()">Cancelar</button>
      <button type="submit" class="btn btn--width-xs m-2xl primary" cdkFocusInitial [disabled]="!isValidReason() || formInative.get('exclusionDate')?.invalid " (click)="submitForm()">
        {{ data.buttonTxt }}
      </button>
    </div>
  </div>
  </form>
</div>
