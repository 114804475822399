import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, startWith } from 'rxjs';
import { MessageService } from 'src/app/core/services/message.service';
import { IOptionsProject } from 'src/app/pages/clients-projects/interface/options-project.interface';
import { ProjectService } from 'src/app/pages/clients-projects/repository/project.service';
import { Project } from 'src/app/shared/models/project.model';
import { StringUtil } from 'src/app/shared/utils/string';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-page-manage-project',
  templateUrl: './page-manage-project.component.html',
  styles: [],
})
export class PageManageProjectComponent implements OnInit, OnDestroy {
  projectForm!: FormGroup;
  projectId!: string;
  project?: Project;

  optionsProject$!: Observable<IOptionsProject>;
  optionsProject!: IOptionsProject;

  isEdit: boolean = false;
  private _subs = new SubSink();
  constructor(
    private _fb: FormBuilder,
    private _projectService: ProjectService,
    private _messageService: MessageService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    try {
      this.projectId = this._route.snapshot.paramMap.get('projectId') as string;
      if (this.projectId) this.isEdit = true;
      this.createFormGroup();
      this.getSelectOptions();
      const projectInitiationControl = this.projectForm.get('projectInitiation');
      if (projectInitiationControl) {
        this._subs.add(
          projectInitiationControl.valueChanges.subscribe(() => {
            this.projectForm.get('projectConclusion')?.updateValueAndValidity();
          })
        );
      }
    } catch (error) {
      console.error(error);
      this.isEdit = false;
    }
  }

  createFormGroup() {
    const numberPattern = /^[0-9]*$/;
    this.projectForm = this._fb.group({
      name: ['', [Validators.required]],
      projectPIP: ['', [Validators.required, Validators.pattern(numberPattern)]],
      projectInitiation: ['', [Validators.required]],
      projectConclusion: [
        '',
        [Validators.required, this.dateValidator.bind(this)],
      ],

      clientId: ['', [Validators.required]],
      towerId: ['', [Validators.required]],
      projectStatusId: ['', [Validators.required]],
      teamId: ['', [Validators.required]],
      projectManagerId: ['', [Validators.required]],
    });
  }

  getProjectById() {
    this._subs.add(
      this._projectService.getProjectById(this.projectId).subscribe(
        (response) => {
          if (response) {
            this.project = response;
            this.setPathValue(response);
          } else {
            console.error('Invalid response:', response);
          }
        },
        (error) => {
          console.error('Error:', error);
        }
      )
    );
  }

  extractNumbers(projectPIP: string): string {
    return projectPIP.replace(/^PIP-?(\d+)$/, '$1');
  }

  setPathValue(project: Project) {
    this.projectForm.patchValue({
      name: project.name,
      projectPIP: this.extractNumbers(project.projectPIP),
      projectInitiation: this.fillDate(project.projectInitiation),
      projectStatusId: project.projectStatusId,
      projectConclusion: this.fillDate(project.projectConclusion),
      clientId: project.clientId,
      towerId: project.towerId,
    });
    this.selectTower();
    this.projectForm.patchValue({ teamId: project.teamId });
    this.selectTeam();
    this.projectForm.patchValue({ projectManagerId: project.projectManagerId });
  }

  fillDate(DateString: string) {
    const date = new Date(DateString);
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();

    if (date.getDate() < 10) day = day.substr(0, 0) + '0' + day.substr(0);
    if (date.getMonth() < 9) month = month.substr(0, 0) + '0' + month.substr(0);

    return `${date.getFullYear()}-${month}-${day}`;
  }

  selectTower() {
    if (this.optionsProject && this.optionsProject.towers) {
      const towerId = this.projectForm.controls['towerId'].value;
      this.optionsProject.towers.map((tower) => {
        if (tower.id === towerId) {
          this.optionsProject.teams = tower.teams || [];
          this.projectForm.controls['teamId'].setValue('');
          this.projectForm.controls['projectManagerId'].setValue('');
          this.optionsProject.collaborators = [];
        }
      });
    }
  }

  selectTeam() {
    if (this.optionsProject && this.optionsProject.teams) {
      const teamId = this.projectForm.controls['teamId'].value;
      this.optionsProject.teams?.map((team) => {
        if (team.id === teamId) {
          this.optionsProject.collaborators =
            team.collaborators?.filter(
              (collaborator) => collaborator.isLeader === true
            ) || [];
        }
      });
    }
  }

  getSelectOptions() {
    this.optionsProject$ = this._projectService.getListSelectProject();
    this._subs.add(
      this.optionsProject$.subscribe((options: IOptionsProject) => {
        this.optionsProject = options;
        this.getProjectById();
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  returnToProjectsList() {
    this._router.navigate(['clientes-e-projetos/projetos']);
  }

  onSubmitProject() {
    this.validatePIP();
    const formData = this.projectForm.value;
    formData.name = StringUtil.capitalize(formData.name);
    if (this.isEdit) {
      this._projectService
        .putProject({ ...this.project, ...formData })
        .subscribe(
          (response) => this.handleResponse(response, false),
          (error) => this.handleError(error)
        );
    } else {
      const newProject = new Project(formData);
      this._projectService.postProject(newProject).subscribe(
        (response) => this.handleResponse(response, true),
        (error) => this.handleError(error)
      );
    }
  }

  validatePIP() {
    const projectPIP = this.projectForm.controls['projectPIP'].value as string;

    if (!projectPIP.startsWith('PIP-')) {
      this.projectForm.controls['projectPIP'].setValue(`PIP-${projectPIP}`);
    }
  }

  private handleResponse(response: any, isCreate: boolean) {
    if (response.success === true) {
      let message = isCreate ? 'Cadastro realizado com sucesso.' : 'Cadastro alterado com sucesso.';
      this._messageService.openSuccessMessage(message);
      this._router.navigate(['clientes-e-projetos/projetos']);
    } else if (response.errors.length > 0) {
      this._messageService.openErrorMessage(response.errors);
    }
  }

  private handleError(error: any) {
    console.error('Error:', error);
  }

  dateValidator(control: AbstractControl): { [key: string]: any } | null {
    const projectInitiationControl = this.projectForm?.get('projectInitiation');
    const conclusionDateControl = this.projectForm?.get('projectConclusion');
    if (!projectInitiationControl || !conclusionDateControl) {
      return null;
    }
    const projectInitiation = projectInitiationControl.value;
    const conclusionDate = conclusionDateControl.value;
    if (projectInitiation > conclusionDate) {
      return { dateError: true };
    } else {
      return null;
    }
  }

}
