export class Project {
  id?: string;
  name: string;
  projectPIP: string;
  projectInitiation: string;
  projectConclusion: string;
  towerId: string;
  teamId: string;
  clientId: string;
  projectManagerId: string;
  projectStatusId: string;

  constructor(project: Project) {
    this.name = project.name;
    this.projectPIP = project.projectPIP;
    this.projectInitiation = this.dateToString(project.projectInitiation);
    this.projectConclusion = this.dateToString(project.projectConclusion);
    this.clientId = project.clientId;
    this.projectManagerId = project.projectManagerId;
    this.teamId = project.teamId;
    this.towerId = project.towerId;
    this.projectStatusId = project.projectStatusId;
    if(project.id) this.updateProject(project);
  }

  private updateProject(project: Project){
    this.id = project.id;
  }
  
  prepareProject(project: Project){
    this.name = project.name;
    this.projectPIP = project.projectPIP;
    this.projectInitiation = this.dateToString(project.projectInitiation);
    this.projectConclusion = this.dateToString(project.projectConclusion);
    this.clientId = project.clientId;
    this.projectManagerId = project.projectManagerId;
    this.teamId = project.teamId;
    this.towerId = project.towerId;
    this.projectStatusId = project.projectStatusId;
  }

  public idProject(id: string){
    this.id = id;
  }

  dateToString(date: string | number | Date){
    return new Date(date).toISOString();
  }
}
