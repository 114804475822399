<form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()">
  <div class="col align-center">
    <div class="logo p-xl m-vertical-xl">
      <img src="./assets/img/Logo1_color_cinza.png" alt="" />
    </div>
  </div>
  <div class="col align-center">
    <button
      class="btn primary btn--width-sm m-lg"
      type="button"
      [disabled]="buttonDisabled"
      (click)="loginRedirect()"
    >
      Entrar
    </button>
  </div>
</form>
