import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';

import {
  IMaganeAccessProfile,
  IManageAccessFilter,
  IManageAccessFilterForm,
  IOptionsFilter,
  Ordenation,
  Pagination,
} from '../../../interface/manage-access';
import { CollaboratorService } from 'src/app/pages/collaborator/repository/collaborator.service';
import { ProfileService } from '../../../repository/profile.service';
import { MessageService } from 'src/app/core/services/message.service';

type chip = {
  controlName: string;
  description: string;
};

@Component({
  selector: 'app-manage-access-profile',
  templateUrl: './manage-access-profile.component.html',
  styles: [],
})
export class ManageAccessProfileComponent implements OnInit {
  dataSource: MatTableDataSource<IMaganeAccessProfile> =
    new MatTableDataSource<IMaganeAccessProfile>();

  collaborators: IMaganeAccessProfile[] = [];
  count!: number;
  functionOptions: IOptionsFilter[] = [];
  profileOptions: IOptionsFilter[] = [];
  statusOptions: any[] = [
    { id: true, description: 'Cadastrado' },
    { id: false, description: 'Pré-Cadastrado' },
  ];

  form!: FormGroup<IManageAccessFilterForm>;
  search: FormControl<string | null> = new FormControl('');
  pagination: Pagination = { skip: 1, take: 10 };
  ordenation: Ordenation = { orderBy: 1, asc: true };
  filter: IManageAccessFilter = {
    searchString: null,
    page: 1,
    take: 10,
    jobFunctionId: null,
    accessProfileId: null,
    registrationCompleted: null,
    orderByParam: { orderBy: 1, asc: true },
  };

  chipsList: Array<chip> = [];

  constructor(
    private _router: Router,
    private _collaboratorService: CollaboratorService,
    private _profileService: ProfileService,
    private _messageService: MessageService
  ) {}
  pageTitle: string = 'Visualização e gestão de perfis de Acesso';

  ngOnInit(): void {
    this.buildForm();
    this.getOptions();
    this.getCollaborators();
  }

  getOptions(): void {
    this._collaboratorService.getCollaboratorSelect().subscribe((response) => {
      this.functionOptions = response.jobFunctions;
      this.profileOptions = response.accessProfile;

      if (this.collaborators.length > 0) {
        this.setAccessProfileName();
      }
    });
  }

  getCollaborators(resetPage: boolean = true): void {
    if (resetPage) this.count = 0;

    let filterValue: IManageAccessFilter = {
      searchString: this.search.value,
      page: resetPage ? 1 : this.pagination.skip,
      take: this.pagination.take,
      jobFunctionId: this.form.get('function')?.value ?? null,
      accessProfileId: this.form.get('profile')?.value ?? null,
      registrationCompleted: this.form.get('status')?.value ?? null,
      orderByParam: {
        orderBy: this.ordenation.orderBy ?? 1,
        asc: this.ordenation.asc ?? true,
      },
    };

    this._profileService
      .getCollaboratorsWithAccessProfile(filterValue)
      .subscribe((response) => {
        this.collaborators = response.result;
        this.count = response.count;

        if (this.profileOptions.length > 0) {
          this.setAccessProfileName();
        }
      });
  }

  setAccessProfileName(): void {
    this.collaborators.forEach((item) => {
      let data = this.profileOptions.find(
        (profile) => profile.id === item.accessProfileId
      );
      item.accessProfileName = data?.name;
      item.accessProfileIdAux = item.accessProfileId;
    });

    this.dataSource.data = this.collaborators;
  }

  buildForm(): void {
    this.form = new FormGroup<IManageAccessFilterForm>({
      function: new FormControl<string>(''),
      profile: new FormControl<string>(''),
      status: new FormControl<boolean | null>(null),
    });
  }

  changeOptions(controlName: string, optionList: IOptionsFilter[]): void {
    let index = this.chipsList.findIndex(
      (item) => item.controlName === controlName
    );
    let value = this.form.get(controlName)?.value;

    if (!value && value !== false) return;

    let data = optionList.find((item) => item.id === value);

    let description =
      controlName === 'profile' ? data?.name : data?.description;

    if (index >= 0) {
      this.chipsList[index].description = description!;
    } else {
      if (description) this.chipsList.push({ controlName, description });
    }

    this.getCollaborators();
  }

  handleSearch(): void {
    this.getCollaborators();
  }

  manageProject(projectId: string = '') {
    this._router.navigate([`/permissoes/gerenciar/${projectId}`]);
  }

  changeFilter(filter: {
    pagination: Pagination;
    ordenation: Ordenation;
  }): void {
    this.pagination = filter.pagination;
    this.ordenation = filter.ordenation;
    this.getCollaborators(false);
  }

  removeFilterValueChips(item: chip): void {
    let index = this.chipsList.indexOf(item);
    this.chipsList.splice(index, 1);

    this.form.get(item.controlName)?.reset();
    this.getCollaborators();
  }

  removeAllChips(): void {
    this.chipsList = [];
    this.form.reset();
    this.getCollaborators();
  }

  clearSearch(): void {
    this.search.reset();
    this.getCollaborators();
  }

  changeCollaborator(collaborator: IMaganeAccessProfile): void {
    let index = this.collaborators.findIndex(
      (item) => item.id === collaborator.id
    );

    this.collaborators[index] = collaborator;
    this.setAccessProfileName();
    this.showSuccessMessage();
  }

  showSuccessMessage(): void {
    this._messageService.openSuccessMessage('Alterações confirmadas');
  }
}
